import type {
  Connection,
  RawSignalPaths,
  SignalPathArray,
  SignalPathEntitiesMap,
  Terminal,
} from "../types";
import type { TerminalReferenceId } from "../../ElectricPart";
import {
  getComponentFromTerminal,
  getConnectorFromTerminal,
} from "./extractEntities";
import type { Dictionary } from "../../../../types";

export function transformTerminalId(terminalId: string) {
  return terminalId.replace(":", "/");
}

const isNonNullable = <GType>(item: GType): item is NonNullable<GType> =>
  typeof item !== "undefined" && item !== null;

/**
 * Transforms signal path elements from the back-end into the objects we use
 * in the whole app.
 */
export function normaliseRawSignalPaths({
  connections,
  paths,
  terminals,
}: RawSignalPaths) {
  return {
    terminals: terminals.map((serverTerminal) => {
      const referenceId = transformTerminalId(
        serverTerminal.id,
      ) as TerminalReferenceId;
      const appTerminal: Terminal = {
        ...serverTerminal,
        entityType: "terminal",
        id: referenceId,
        referenceId,
        coordinates: serverTerminal.coordinates.map(
          ({ coordinate }) => coordinate,
        ),
        connectorColor: serverTerminal.connectorColor ?? null,
      };
      return appTerminal;
    }),
    connections: connections.map((serverConnection) => {
      const appConnection: Connection = {
        ...serverConnection,
        entityType: "connection",
        colors: serverConnection.colors.filter(isNonNullable),
        partIds: serverConnection.partIds || [],
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        segmentFiles: serverConnection.segmentFiles || [],
      };

      return appConnection;
    }),
    paths: paths.map(({ path }) =>
      path.map((terminalOrConnectionId, index) => {
        const isTerminalId = index % 2 === 0;
        if (isTerminalId) {
          return transformTerminalId(terminalOrConnectionId);
        }

        return terminalOrConnectionId;
      }),
    ),
  };
}
export function expandSignalPaths(
  paths: string[][],
  {
    connections,
    terminals,
  }: {
    terminals: Terminal[];
    connections: Connection[];
  },
) {
  const entities: SignalPathEntitiesMap = {
    components: {},
    connectors: {},
    terminals: {},
    connections: {},
  };

  const addIfNotThere = (
    map: Record<string, unknown>,
    id: string,
    value: unknown,
  ) => {
    if (!(id in map)) {
      map[id] = value;
    }
  };

  terminals.forEach((appTerminal) => {
    const component = getComponentFromTerminal(appTerminal);
    const connector = getConnectorFromTerminal(appTerminal);

    addIfNotThere(entities.components, component.referenceId, component);
    addIfNotThere(entities.connectors, connector.referenceId, connector);
    addIfNotThere(entities.terminals, appTerminal.referenceId, appTerminal);

    return appTerminal;
  });

  connections.forEach((appConnection) => {
    addIfNotThere(entities.connections, appConnection.id, appConnection);

    return appConnection;
  });

  const signalPathEntities: Dictionary<string, Terminal | Connection> = {
    ...entities.terminals,
    ...entities.connections,
  };

  const expandedPaths = paths.map((signalPath): SignalPathArray => {
    return signalPath.map((id: string) => {
      const terminalOrConnection = signalPathEntities[id];
      if (terminalOrConnection === undefined) {
        throw new Error(
          `Error expanding signal paths: entity with ID ${id} was not found.`,
        );
      }

      return terminalOrConnection;
    });
  });

  return {
    entities,
    signalPaths: expandedPaths,
  };
}

export function expandRawSignalPaths(rawSignalPaths: Partial<RawSignalPaths>) {
  const { connections, paths, terminals } = normaliseRawSignalPaths({
    terminals: rawSignalPaths.terminals ?? [],
    connections: rawSignalPaths.connections ?? [],
    paths: rawSignalPaths.paths ?? [],
  });
  return expandSignalPaths(paths, { terminals, connections });
}
