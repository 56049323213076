// #region @spread
import React from "react";
import type { WidgetProps } from "widgets/BaseWidget";
import ContainerState from "spread/components/container/ContainerState";
import { ButtonVariantTypes, ButtonStyleTypes } from "components/constants";
import { TAILWIND_COLORS } from "constants/ThemeConstants";
import { ButtonType } from "widgets/ButtonWidget/component";
import { Loader } from "spread/components/Loader";
import { ProgressVariant } from "widgets/ProgressWidget/constants";
import type { ContainerWidgetProps } from "widgets/ContainerWidget/widget";
import { getContainerStatus } from "./getContainerStatus";
import { CONTAINER_STATUS } from "../constants";

const ContainerChildren = (props: ContainerWidgetProps<WidgetProps>) => {
  const {
    addEmptyStateActionButton,
    buttonColor,
    componentHeight,
    emptyStateActionButtonClick,
    emptyStateActionButtonIconName,
    emptyStateActionButtonLabel,
    emptyStateDescription,
    emptyStateIconName,
    emptyStateTitle,
    emptyStateTooltip,
    errorStateActionButton,
    errorStateActionButtonClick,
    errorStateActionButtonIconName,
    errorStateActionButtonLabel,
    errorStateDescription,
    errorStateIconName,
    errorStateTitle,
    ErrorTooltip,
    handleActionButtonClick,
    iconFillColor,
    isEmptyStateVisible,
    isErrorStateVisible,
    isLoading,
    isLoadingStateVisible,
    loadingStateIconName,
    loadingStateTitle,
    progressBarFillColor,
  } = props;
  const stateContainerHeight = componentHeight;

  const containerStatusProps = {
    isLoading,
    isLoadingStateVisible,
    isErrorStateVisible,
    isEmptyStateVisible,
  };
  const containerState = getContainerStatus(containerStatusProps);

  if (containerState === CONTAINER_STATUS.ERROR)
    return (
      <ContainerState
        actionButton={{
          text: errorStateActionButtonLabel,
          stateActionButton: errorStateActionButton
            ? errorStateActionButton
            : false,
          onClick: () =>
            handleActionButtonClick(
              "errorStateActionButtonClick",
              props.errorStateActionButtonClick,
            ),

          buttonVariant: ButtonVariantTypes.SECONDARY,
          type: ButtonType.BUTTON,
          buttonColor: "#485162",
          buttonStyle: ButtonStyleTypes.CUSTOM,
          icon: errorStateActionButtonIconName
            ? errorStateActionButtonIconName
            : undefined,
          disabled: !!errorStateActionButtonClick ? false : true,
        }}
        containerHeight={stateContainerHeight}
        description={errorStateDescription}
        iconFillColor={TAILWIND_COLORS.red[500]}
        iconName={errorStateIconName}
        isError
        title={errorStateTitle}
        tooltipContent={ErrorTooltip}
      />
    );
  else if (containerState === CONTAINER_STATUS.LOADING)
    return (
      <ContainerState
        containerHeight={stateContainerHeight}
        iconFillColor={iconFillColor}
        iconName={loadingStateIconName}
        isError={false}
        title={loadingStateTitle}
      >
        <Loader
          borderRadius="0px"
          fillColor={progressBarFillColor}
          height={4}
          isVisible
          progressValue={50}
          variant={ProgressVariant.INDETERMINATE}
        />
      </ContainerState>
    );
  else if (containerState === CONTAINER_STATUS.EMPTY)
    return (
      <ContainerState
        actionButton={{
          text: emptyStateActionButtonLabel,
          stateActionButton: addEmptyStateActionButton
            ? addEmptyStateActionButton
            : false,
          onClick: () => {
            handleActionButtonClick(
              "emptyStateActionButtonClick",
              emptyStateActionButtonClick,
            );
          },
          buttonVariant: ButtonVariantTypes.SECONDARY,
          type: ButtonType.BUTTON,
          buttonColor: buttonColor,
          buttonStyle: ButtonStyleTypes.CUSTOM,
          icon: emptyStateActionButtonIconName
            ? emptyStateActionButtonIconName
            : undefined,
          disabled: !!emptyStateActionButtonClick ? false : true,
        }}
        containerHeight={stateContainerHeight}
        description={emptyStateDescription}
        iconFillColor={iconFillColor}
        iconName={emptyStateIconName}
        isError={false}
        title={emptyStateTitle}
        tooltipContent={emptyStateTooltip}
      />
    );
  return <div />;
};

export default ContainerChildren;
// #endregion
