import React from "react";

import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import { equals, nth } from "ramda";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { WithMeta } from "widgets/MetaHOC";
import { EventType } from "../../../constants/AppsmithActionConstants/ActionConstants";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import type {
  AutoLayoutConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import type { PrecedenceGraphComponentProps } from "../component";
import { PrecedenceGraph } from "../component";
import { contentConfig } from "./contentConfig";
import { propertyPaneStyleConfig } from "./styleConfig";
import type { Edge, EdgeId, Node, NodeId, NodePositionsMap } from "./types";

import IconSVG from "../icon.svg";
import ThumbnailSVG from "../../spread_tmp_thumbnail.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import type { ContextMenuData } from "../component/types";

const defaultProps = {
  edges: [
    {
      id: "0",
      from: "0",
      to: "2",
    },
    {
      id: "1",
      from: "1",
      to: "2",
    },
    {
      id: "3",
      from: "3",
      to: "4",
    },
    {
      id: "4",
      from: "6",
      to: "5",
    },
    {
      id: "5",
      from: "6",
      to: "7",
    },
  ],
  nodes: [
    {
      id: "0",
      text: "Matched-default",
    },
    {
      id: "1",
      text: "Unmatched-default",
    },
    {
      id: "2",
      text: "Matched-default",
    },
    {
      id: "3",
      text: "Unmatched-default",
    },
    {
      id: "4",
      text: "Matched-default",
    },
    {
      id: "5",
      text: "Unmatched-default",
    },
    {
      id: "6",
      text: "Matched-default",
    },
    {
      id: "7",
      text: "Unmatched-default",
    },
  ],
  defaultSelectedNodeId: undefined,
  defaultHiddenNodeIds: [],
  defaultRemovedNodeIds: [],
  defaultRemovedEdgeIds: [],
  defaultUnmatchedNodeIds: [],
  hasToolbar: true,
  contextMenuData: undefined,
};

class PrecedenceGraphWidget extends BaseWidget<
  PrecedenceGraphWidgetProps,
  WidgetState
> {
  static type = "PRECEDENCE_GRAPH_WIDGET";

  static getConfig() {
    return {
      name: "Precedence Graph", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      thumbnailSVG: ThumbnailSVG,
      tags: [WIDGET_TAGS.BETA],
      needsMeta: true, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
    };
  }

  static getDefaults() {
    return {
      ...defaultProps,
      widgetName: "PrecedenceGraph",
      rows: 60,
      columns: 15,
      version: 1,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    // TODO: add proper auto layout config
    return {};
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      selectedNodeId: {
        "!type": "string",
        "!doc": "The selected node id of the precedence graph",
      },
      hiddenNodeIds: {
        "!type": "[string]",
        "!doc": "The node ids of the precedence graph that were hidden",
      },
      removedNodeIds: {
        "!type": "[string]",
        "!doc": "The node ids of the precedence graph that were removed",
      },
      currentNodes: {
        "!type": "?",
        "!doc": "Current nodes of precedence graph",
      },
      currentEdges: {
        "!type": "?",
        "!doc": "Current edges of precedence graph",
      },
      removedEdgeIds: {
        "!type": "?",
        "!doc": "Removed edges ids of graph",
      },
      isGraphValid: {
        "!type": "bool",
        "!doc": "Defines if the graph is valid or not",
      },
      contextMenuData: {
        "!type": "?",
        "!doc": "Position of context menu",
      },
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      selectedNodeId: `{{this.selectedNodeId}}`,
      hiddenNodeIds: `{{this.hiddenNodeIds}}`,
      removedNodeIds: `{{this.removedNodeIds}}`,
    };
  }

  static getMetaPropertiesMap(): MetaProperties {
    return {
      selectedNodeId: undefined,
      hiddenNodeIds: undefined,
      removedNodeIds: undefined,
      currentNodes: [],
      currentEdges: [],
      selectedEdgeId: undefined,
      newEdges: [],
      newEdgeFromNodeId: undefined,
      nodePositions: {},
      contextMenuData: undefined,
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      progressBarFillColor: "{{appsmith.theme.colors.primaryColor}}",
    };
  }

  static getPropertyPaneContentConfig() {
    return contentConfig;
  }

  static getPropertyPaneStyleConfig() {
    return propertyPaneStyleConfig;
  }

  constructor(props: PrecedenceGraphWidgetProps) {
    super(props);
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setIsForcedLoading: {
          path: "isForcedLoading",
          type: "boolean",
        },
      },
    };
  }

  componentDidMount() {
    this.updateSelectedNodeIdMeta(
      this.props.defaultSelectedNodeId
        ? [this.props.defaultSelectedNodeId]
        : [],
    );
    this.updateHiddenNodeIdsMeta(this.props.defaultHiddenNodeIds);
    this.updateRemovedNodeIdsMeta(this.props.defaultRemovedNodeIds);
    this.updateRemovedEdgeIdsMeta(this.props.defaultRemovedEdgeIds);
    this.updateUnmatchedNodeIdsMeta(this.props.defaultUnmatchedNodeIds);
    this.updateNewEdgesMeta([]);
    this.updateNodePositionsMeta({});
  }

  componentDidUpdate(prevProps: PrecedenceGraphWidgetProps): void {
    if (
      !equals(prevProps.edges, this.props.edges) ||
      !equals(prevProps.nodes, this.props.nodes)
    ) {
      this.updateSelectedNodeIdMeta([]);
      this.updateRemovedNodeIdsMeta(this.props.defaultRemovedNodeIds);
      this.updateRemovedEdgeIdsMeta(this.props.defaultRemovedEdgeIds);
      this.updateNewEdgesMeta([]);
      this.updateNodePositionsMeta({});
    }

    if (
      !equals(prevProps.defaultRemovedEdgeIds, this.props.defaultRemovedEdgeIds)
    ) {
      this.updateRemovedEdgeIdsMeta(this.props.defaultRemovedEdgeIds);
    }

    if (
      !equals(prevProps.defaultRemovedNodeIds, this.props.defaultRemovedNodeIds)
    ) {
      this.updateRemovedNodeIdsMeta(this.props.defaultRemovedNodeIds);
    }

    if (
      !equals(prevProps.defaultSelectedNodeId, this.props.defaultSelectedNodeId)
    ) {
      this.updateSelectedNodeIdMeta(
        this.props.defaultSelectedNodeId
          ? [this.props.defaultSelectedNodeId]
          : [],
      );
    }

    if (
      !equals(prevProps.defaultHiddenNodeIds, this.props.defaultHiddenNodeIds)
    ) {
      this.updateHiddenNodeIdsMeta(this.props.defaultHiddenNodeIds);
    }

    if (
      !equals(
        prevProps.defaultUnmatchedNodeIds,
        this.props.defaultUnmatchedNodeIds,
      )
    ) {
      this.updateUnmatchedNodeIdsMeta(this.props.defaultUnmatchedNodeIds);
    }
  }

  updateSelectedNodeIdMeta = (ids: NodeId[]) => {
    this.props.updateWidgetMetaProperty("selectedNodeId", nth(0, ids), {
      triggerPropertyName: "onSelectedNodeIdsChange",
      dynamicString: this.props.onSelectedNodeIdsChange,
      event: {
        type: EventType.ON_CLICK,
      },
    });
  };

  updateUnmatchedNodeIdsMeta = (ids?: NodeId[]) => {
    this.props.updateWidgetMetaProperty("unmatchedNodeIds", ids);
  };

  updateHiddenNodeIdsMeta = (ids?: NodeId[]) => {
    this.props.updateWidgetMetaProperty("hiddenNodeIds", ids);
  };

  updateRemovedNodeIdsMeta = (ids?: NodeId[]) => {
    this.props.updateWidgetMetaProperty("removedNodeIds", ids);
  };

  updateEdgesMeta = (edges: Edge[]) => {
    this.props.updateWidgetMetaProperty("currentEdges", edges);
  };

  updateNodesMeta = (nodes: Node[]) => {
    this.props.updateWidgetMetaProperty("currentNodes", nodes);
  };

  updateSelectedEdgeIdMeta = (id?: EdgeId) => {
    this.props.updateWidgetMetaProperty("selectedEdgeId", id);
  };

  updateRemovedEdgeIdsMeta = (ids?: NodeId[]) => {
    this.props.updateWidgetMetaProperty("removedEdgeIds", ids);
  };

  updateIsGraphValidMeta = (isValid: boolean) => {
    this.props.updateWidgetMetaProperty("isGraphValid", isValid);
  };

  updateNewEdgesMeta = (edges: Edge[]) => {
    this.props.updateWidgetMetaProperty("newEdges", edges);
  };

  updateNewEdgeFromNodeIdMeta = (nodeId?: NodeId) => {
    this.props.updateWidgetMetaProperty("newEdgeFromNodeId", nodeId);
  };

  updateNodePositionsMeta = (nodePositions: NodePositionsMap) => {
    this.props.updateWidgetMetaProperty("nodePositions", nodePositions);
  };

  updateContextMenuPosition = (contextMenuData?: ContextMenuData) => {
    this.props.updateWidgetMetaProperty("contextMenuData", contextMenuData);
  };

  onRemovedEdgeIdsChange = (ids: EdgeId[]) => {
    this.updateRemovedEdgeIdsMeta(ids);
    this.updateSelectedEdgeIdMeta(undefined);

    // remove edge from newEdges
    const currentNewEdges = this.props.newEdges ?? [];
    const removedEdgeIdsSet = new Set(ids);
    this.props.updateWidgetMetaProperty(
      "newEdges",
      currentNewEdges.filter((edge) => !removedEdgeIdsSet.has(edge.id)),
    );
  };

  onSelectedEdgeIdChange = (id?: EdgeId) => {
    this.updateSelectedEdgeIdMeta(id);
  };

  onNodeForNewEdgeChosen = (node: NodeId) => {
    this.updateNewEdgeFromNodeIdMeta(node);
  };

  onEdgeAdd = (edge: Edge) => {
    this.updateNewEdgeFromNodeIdMeta(undefined);

    this.updateNewEdgesMeta([...(this.props.newEdges ?? []), edge]);
  };

  onNodePositionsChange = (nodePositions: NodePositionsMap) => {
    this.updateNodePositionsMeta(nodePositions);
  };

  onAddNode = () => {
    super.executeAction({
      triggerPropertyName: "onAddNode",
      dynamicString: this.props.onAddNode,
      event: {
        type: EventType.ON_ADD_NODE_CONTEXT_MENU,
      },
    });
  };

  onNodeClick = () => {
    super.executeAction({
      triggerPropertyName: "onNodeClick",
      dynamicString: this.props.onNodeClick,
      event: {
        type: EventType.ON_CLICK,
      },
    });
  };

  onUnmatchedNodeRemove = () => {
    super.executeAction({
      triggerPropertyName: "onUnmatchedNodeRemove",
      dynamicString: this.props.onUnmatchedNodeRemove,
      event: {
        type: EventType.ON_CLICK,
      },
    });
  };

  onBackgroundClick = () => {
    this.updateNewEdgeFromNodeIdMeta(undefined);
    super.executeAction({
      triggerPropertyName: "onBackgroundClick",
      dynamicString: this.props.onBackgroundClick,
      event: {
        type: EventType.ON_CLICK,
      },
    });
  };

  getWidgetView() {
    const { edges, hasToolbar, isForcedLoading, nodes } = this.props;

    // Style props
    const { progressBarFillColor } = this.props;

    const metaProperties = {
      hiddenNodeIds: this.props.hiddenNodeIds || [],
      removedEdgeIds: this.props.removedEdgeIds || [],
      removedNodeIds: this.props.removedNodeIds || [],
      selectedNodeId: this.props.selectedNodeId || "",
      unmatchedNodeIds: this.props.unmatchedNodeIds || [],
      selectedEdgeId: this.props.selectedEdgeId || "",
      newEdges: this.props.newEdges || [],
      newEdgeFromNodeId: this.props.newEdgeFromNodeId || "",
      nodePositions: this.props.nodePositions || {},
      isValidatingGraph: this.props.isValidatingGraph || false,
    };

    return (
      <PrecedenceGraph
        {...metaProperties}
        edges={edges || []}
        hasToolbar={hasToolbar}
        isForcedLoading={isForcedLoading}
        nodes={nodes || []}
        onAddNode={this.onAddNode}
        onBackgroundClick={this.onBackgroundClick}
        onContextMenuPositionChange={this.updateContextMenuPosition}
        onEdgeAdd={this.onEdgeAdd}
        onEdgesChange={this.updateEdgesMeta}
        onHiddenNodeIdsChange={this.updateHiddenNodeIdsMeta}
        onIsGraphValidChange={this.updateIsGraphValidMeta}
        onNodeClick={this.onNodeClick}
        onNodeForNewEdgeChosen={this.onNodeForNewEdgeChosen}
        onNodePositionsChange={this.onNodePositionsChange}
        onNodesChange={this.updateNodesMeta}
        onRemovedEdgeIdsChange={this.onRemovedEdgeIdsChange}
        onRemovedNodeIdsChange={this.updateRemovedNodeIdsMeta}
        onSelectedEdgeIdChange={this.onSelectedEdgeIdChange}
        onSelectedNodeIdsChange={this.updateSelectedNodeIdMeta}
        onUnmatchedNodeRemove={this.onUnmatchedNodeRemove}
        // Old widgets have progressBarFillColor equal undefined
        progressBarFillColor={
          progressBarFillColor ? progressBarFillColor : "#553DE9"
        }
      />
    );
  }
}

interface MetaProperties {
  selectedEdgeId?: EdgeId;
  selectedNodeId?: NodeId;
  hiddenNodeIds?: NodeId[];
  removedNodeIds?: NodeId[];
  currentNodes?: Node[];
  currentEdges?: Edge[];
  removedEdgeIds?: EdgeId[];
  isGraphValid?: boolean;
  newEdges?: Edge[];
  newEdgeFromNodeId?: NodeId;
  nodePositions?: NodePositionsMap;
  isValidatingGraph?: boolean;
  contextMenuData?: ContextMenuData;
}

interface PrecedenceGraphWidgetProps
  extends Omit<
      PrecedenceGraphComponentProps,
      | keyof MetaProperties
      | "onBackgroundClick"
      | "onNodeClick"
      | "onAddNode"
      | "onSelectedNodeIdsChange"
      | "onUnmatchedNodeRemove"
    >,
    MetaProperties,
    WithMeta,
    WidgetProps {
  defaultHiddenNodeIds?: NodeId[];
  defaultRemovedEdgeIds?: EdgeId[];
  defaultRemovedNodeIds?: NodeId[];
  defaultSelectedNodeId?: NodeId;
  defaultUnmatchedNodeIds?: NodeId[];

  nodes: Node[];
  edges: Edge[];

  onBackgroundClick: string;
  onNodeClick: string;
  onAddNode: string;
  onSelectedNodeIdsChange: string;
  onUnmatchedNodeRemove: string;
}

export default PrecedenceGraphWidget;
