import type { Link } from "../types";

type Edge = Link;
type Graph = Map<string, string[]>;

export function buildGraph(edges: Edge[]): Graph {
  const graph: Graph = new Map();
  edges.forEach((edge) => {
    if (!graph.has(edge.from)) {
      graph.set(edge.from, []);
    }
    graph.get(edge.from)?.push(edge.to);

    // For an undirected graph, we add the inverse edge
    if (!graph.has(edge.to)) {
      graph.set(edge.to, []);
    }

    graph.get(edge.to)?.push(edge.from);
  });
  return graph;
}

export function depthFirstSearch(graph: Graph, starts: string[]) {
  const visited = new Set<string>();
  const stack: string[] = [];

  starts.forEach((start) => {
    if (!visited.has(start)) {
      stack.push(start);

      while (stack.length) {
        const node = stack.pop();
        if (node && !visited.has(node)) {
          visited.add(node);
          const neighbors = graph.get(node);
          if (neighbors) {
            neighbors.forEach((neighbor) => {
              if (!visited.has(neighbor)) {
                stack.push(neighbor);
              }
            });
          }
        }
      }
    }
  });

  return visited;
}
