import { isEqual, nth, omit } from "lodash";

import type { Marker, SpreadRenderedMarker } from "./types";

export function markersEqualDisregardingNodeId(
  marker1: Marker,
  marker2: Marker,
) {
  return isEqual(omit(marker1, ["nodeId"]), omit(marker2, ["nodeId"]));
}

/**
 * Compares two arrays of markers (disregarding `nodeId`s) and call the given callback function
 * depending on the operation that happened to the marker when updating from `currentMarkers` to `newMarkers`.
 *
 * Markers are compared using ramda's `equals` function.
 *
 * **Important:** return the marker with its `nodeId` in `addMarker`.
 *
 * @returns The updated markers array, with the up to date markers.
 *
 * @example
 * // marker objects reduced for clarity
 * const currentMarkers = [
 *   { label: 'A101',  shapeColor: 'yellow', nodeId: 123, ... },
 *   { label: 'X25/7', shapeColor: 'yellow', nodeId: 456, ... },
 *   { label: 'N3/48', shapeColor: 'yellow', nodeId: 789, ... },
 * ];
 * const newMarkers = [
 *   { label: 'A101',  shapeColor: 'yellow', ... }, // no changes
 *   { label: 'X25/7', shapeColor: 'orange', ... }, // marker updated, shapeColor changed
 *   { label: 'N3/48', shapeColor: 'yellow', ... }, // no changes
 *   { label: 'W123',  shapeColor: 'yellow', ... }, // marker added
 * ];
 * const updatedMarkers = applyMarkersChange(currentMarkers, newMarkers, {
 *   addMarker: (markerToRender) => {
 *     // will be called once with newMarkers[3]
 *     const nodeId = addNode(markerToRender);
 *     return {
 *       ...markerToRender,
 *       nodeId: nodeId,
 *     }
 *   },
 *   updateMarker(currentMarker, newMarker) {
 *     // will be called once with currentMarkers[1] and newMarkers[1]
 *     const updatedMarker = { ...newMarker, nodeId: currentMarker.nodeId };
 *     setNodeEnabled(updatedMarker.nodeId, updatedMarker.enabled);
 *     return updatedMarker;
 *   },
 *   removeMarker: (markerToRemove) => {
 *     // not called in this example
 *     removeNode(markerToRemove);
 *   },
 * });
 */
export async function patchMarkers<GMarkerMetadata>(
  currentMarkers: SpreadRenderedMarker<GMarkerMetadata>[],
  newMarkers: Marker<GMarkerMetadata>[],
  {
    addMarker,
    removeMarker,
    updateMarker,
  }: {
    addMarker: (
      markerToRender: Marker<GMarkerMetadata>,
    ) => Promise<SpreadRenderedMarker<GMarkerMetadata>>;
    updateMarker: (
      updatedMarker: SpreadRenderedMarker<GMarkerMetadata>,
    ) => void;
    removeMarker: (
      markerToRemove: SpreadRenderedMarker<GMarkerMetadata>,
    ) => void;
  },
) {
  if (currentMarkers.length === 0 && newMarkers.length === 0) {
    return [];
  }

  const updatedList: SpreadRenderedMarker<GMarkerMetadata>[] = [];
  for (let index = 0; index < currentMarkers.length; index++) {
    const currentMarker = nth(currentMarkers, index);
    const newMarker = nth(newMarkers, index);

    if (!currentMarker) {
      // This won't happen, just a type guard.
      throw new Error("Current marker cannot be an undefined value.");
    }

    if (!newMarker) {
      removeMarker(currentMarker);
    } else if (!markersEqualDisregardingNodeId(currentMarker, newMarker)) {
      const updatedMarker = {
        ...newMarker,
        nodeId: currentMarker.nodeId,
      };
      updateMarker(updatedMarker);
      updatedList.push(updatedMarker);
    } else {
      // no changes
      updatedList.push(currentMarker);
    }
  }

  // add all remaining new markers
  if (newMarkers.length > currentMarkers.length) {
    const markersToAdd = newMarkers.slice(currentMarkers.length);

    const markersId: SpreadRenderedMarker<GMarkerMetadata>[] = [];

    for (const marker of markersToAdd) {
      const markerId = await addMarker(marker);
      markersId.push(markerId);
    }

    updatedList.push(...markersId);
  }

  return updatedList;
}
