import type { Marker } from "widgets/RendererWidget/widget/types";
import type { MarkersStyles } from "widgets/RendererWidget/widget/styleConfig";

import type { Marker as MarkerInternal } from "./types";

/**
 * Generate `markers` argument for `useMarkers` hook from widget input
 */
export const buildMarkersList = ({
  markers,
  selectedMarkerId,
  style: {
    selectedMarkerDigitColor,
    selectedMarkerScale,
    selectedMarkerShapeColor,
    unselectedMarkerDigitColor,
    unselectedMarkerScale,
    unselectedMarkerShapeColor,
  },
}: {
  markers: Marker[];
  selectedMarkerId: string;
  style: MarkersStyles;
}) =>
  markers.map((marker) => {
    const shapeColor =
      marker.id === selectedMarkerId
        ? selectedMarkerShapeColor
        : unselectedMarkerShapeColor;

    const numberColor =
      marker.id === selectedMarkerId
        ? selectedMarkerDigitColor
        : unselectedMarkerDigitColor;

    const scale =
      marker.id === selectedMarkerId
        ? selectedMarkerScale
        : unselectedMarkerScale;

    const result: MarkerInternal = {
      id: marker.id,
      label: marker.label,
      shape: marker.shape,
      shapeColor,
      coordinates: [marker.position.x, marker.position.y, marker.position.z],
      isEnabled: true,
      scale,
      number: marker.digit,
      numberColor,
      metadata: {},
    };

    return result;
  });
