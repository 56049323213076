import React, { useMemo } from "react";
import {
  decodeBase64,
  parseSvg,
  serializeSvg,
  resizeSvg,
  updateSvgColors,
  stripDataUriPrefix,
} from "./svgUtils";

const getIcon = (
  base64String: string,
  color: string,
  defaultIcon: JSX.Element,
) => {
  try {
    const base64Data = stripDataUriPrefix(base64String);
    const svgContent = decodeBase64(base64Data);
    let svgElement = parseSvg(svgContent);
    svgElement = resizeSvg(svgElement, 25, 14);
    svgElement = updateSvgColors(svgElement, color);

    return (
      <div
        dangerouslySetInnerHTML={{ __html: serializeSvg(svgElement) }}
        data-testid="svg-container"
      />
    );
  } catch (error: unknown) {
    return defaultIcon;
  }
};

const SVGComponent = ({
  base64String,
  color = "#000000",
  defaultIcon,
}: {
  base64String: string;
  color: string;
  defaultIcon: JSX.Element;
}) => {
  const updatedSvgContentMemoized = useMemo(() => {
    return getIcon(base64String, color, defaultIcon);
  }, [base64String, color, defaultIcon]);

  return updatedSvgContentMemoized;
};

export default SVGComponent;
