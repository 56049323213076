import { useEffect, useState } from "react";
import type { ViewerApi } from "../ViewerApi";
import type { PillData, PillInputData } from "./types";

export const usePills = (
  viewer: ViewerApi | undefined,
  isModelAdded: boolean,
  pillsInputData: PillInputData[],
  resetDependencies: unknown[],
) => {
  const [pillData, setPillData] = useState<PillData[] | null>(null);

  useEffect(() => {
    const renderSelectedTerminalSelectionPillData = () => {
      if (!pillsInputData.length || !isModelAdded || !viewer) {
        setPillData(null);
        return null;
      }

      const pillData = pillsInputData.map((pillInputData) => {
        const { coordinates, ...rest } = pillInputData;
        const position = viewer.projectPointToCanvas(coordinates);
        const pillData: PillData = {
          ...rest,
          position,
        };

        return pillData;
      });

      setPillData(pillData);
    };

    renderSelectedTerminalSelectionPillData();

    const removeViewChangeHandler =
      viewer && pillsInputData.length
        ? viewer.addEventListener(
            "viewchange",
            renderSelectedTerminalSelectionPillData,
          )
        : null;
    return () => {
      if (removeViewChangeHandler && viewer) {
        removeViewChangeHandler();
      }
    };
  }, [pillsInputData, viewer, isModelAdded, ...resetDependencies]);

  return pillData;
};
