import React from "react";
import ProgressComponent from "../../widgets/ProgressWidget/component";
import type { ProgressVariant } from "../../widgets/ProgressWidget/constants";
import { ProgressType } from "../../widgets/ProgressWidget/constants";
import styled from "styled-components";

const LoaderWrapper = styled.div<{
  height: number;
  backgroundColor?: string;
}>`
  height: ${({ height }) => height}px;
  width: 100%;
  backgroundcolor: ${({ backgroundColor }) => backgroundColor};
`;

interface LoaderProps {
  height?: number;
  borderRadius?: string;
  progressValue: number;
  isVisible: boolean;
  variant: ProgressVariant;
  fillColor: string;
}
export const Loader: React.FC<LoaderProps> = ({
  borderRadius = "0",
  fillColor,
  height = 0,
  isVisible,
  progressValue,
  variant,
}) => {
  return (
    <LoaderWrapper height={height}>
      {isVisible && (
        <ProgressComponent
          borderRadius={borderRadius}
          counterClockwise
          fillColor={fillColor}
          isScaleY={false}
          linearProgressHeightRatio={100}
          showResult={false}
          steps={1}
          type={ProgressType.LINEAR}
          value={progressValue}
          variant={variant}
        />
      )}
    </LoaderWrapper>
  );
};
