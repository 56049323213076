import { useEffect, useMemo, useState } from "react";
import { requestCadModels } from "./api";
import { convertCadModelsResponseIntoOptions } from "./utils";
import type { ModelOption } from "./constants";

interface UseCadDomainArgs {
  defaultOptions: ModelOption[];
}

export const useCadModelsOptions = ({
  defaultOptions = [],
}: UseCadDomainArgs) => {
  const [cadDomainOptions, setCadDomainOptions] = useState<ModelOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const options = useMemo(
    () => [...defaultOptions, ...cadDomainOptions],
    [cadDomainOptions, defaultOptions],
  );

  useEffect(() => {
    setLoading(true);

    requestCadModels()
      .then((data) => {
        const options = convertCadModelsResponseIntoOptions(data);
        setCadDomainOptions(options);
        setLoading(false);
      })
      .catch((error) => {
        setCadDomainOptions([]);
        setError(error);
        setLoading(false);
      });
  }, []);

  return {
    options,
    loading,
    error,
  };
};
