import React from "react";
import Editor from "@monaco-editor/react";

import SpinnerLoader from "pages/common/SpinnerLoader";

import type { SupportedLanguage } from "../utils/languages";
import { EditorTheme } from "../utils/theme";

interface CodeEditorProps {
  // Content
  value: string;
  language: SupportedLanguage;

  // Style
  theme: EditorTheme;

  // System
  onChange: (value?: string) => void;
}

const CodeEditor = (props: CodeEditorProps) => (
  <Editor
    language={props.language}
    loading={<SpinnerLoader size="lg" />}
    onChange={props.onChange}
    theme={props.theme || EditorTheme.Dark}
    value={props.value}
  />
);

export default CodeEditor;
