import * as go from "gojs";
import { useEffect, useRef } from "react";
import type { ReactDiagram } from "gojs-react";
import type React from "react";

import { resetDiagramStyles } from "./nodeSelection";
import {
  updateNodeDataWithPorts,
  assignPortIdsToLinks,
  updateLinkSides,
} from "./nodePorts";
import type { Edge, Node } from "../widget/types";

export interface UseDiagramConfig {
  nodes: Node[];
  edges: Edge[];
  onEdgesChange: (edges: any[]) => void;
  onNodesChange: (nodes: any[]) => void;
}

const updateNodes = (
  diagram: go.Diagram,
  newNodes: Node[],
  oldNodesMap: Map<string | number, Node>,
) => {
  const newNodesMap = new Map(newNodes.map((node) => [node.id, node]));

  // Remove nodes that are not in the new set
  oldNodesMap.forEach((oldNode, id) => {
    if (!newNodesMap.has(id)) {
      diagram.model.removeNodeData(oldNode);
    }
  });

  // Add or update nodes
  newNodes.forEach((node) => {
    if (!oldNodesMap.has(node.id)) {
      diagram.model.addNodeData({ ...node });
    } else if (
      JSON.stringify(oldNodesMap.get(node.id)) !== JSON.stringify(node)
    ) {
      const nodeData = oldNodesMap.get(node.id);
      if (nodeData) {
        for (const key in node) {
          (nodeData as any)[key] = (node as any)[key];
        }
        diagram.model.updateTargetBindings(nodeData);
      }
    }
  });
};

const updateEdges = (
  diagram: go.Diagram,
  newEdges: Edge[],
  oldEdgesMap: Map<string | number, Edge>,
) => {
  if (!(diagram.model instanceof go.GraphLinksModel)) return;

  const newEdgesMap = new Map(newEdges.map((edge) => [edge.id, edge]));

  // Remove edges that are not in the new set
  oldEdgesMap.forEach((oldEdge, id) => {
    if (!newEdgesMap.has(id)) {
      (diagram.model as go.GraphLinksModel).removeLinkData(oldEdge);
    }
  });

  // Add or update edges
  newEdges.forEach((edge) => {
    if (!oldEdgesMap.has(edge.id)) {
      (diagram.model as go.GraphLinksModel).addLinkData(edge);
    } else if (
      JSON.stringify(oldEdgesMap.get(edge.id)) !== JSON.stringify(edge)
    ) {
      const edgeData = oldEdgesMap.get(edge.id);
      if (edgeData) {
        for (const key in edge) {
          (edgeData as any)[key] = (edge as any)[key];
        }
        diagram.model.updateTargetBindings(edgeData);
      }
    }
  });
};

function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

function useDeepCompareEffect<T extends any[]>(
  callback: React.EffectCallback,
  dependencies: T,
): void {
  const currentDependenciesRef = useRef<T>();

  if (!deepEqual(currentDependenciesRef.current, dependencies)) {
    currentDependenciesRef.current = dependencies;
  }

  useEffect(callback, [currentDependenciesRef.current]);
}

export const useDiagram = ({
  edges,
  nodes,
  onEdgesChange,
  onNodesChange,
}: UseDiagramConfig) => {
  const ref = useRef<ReactDiagram>(null);

  useDeepCompareEffect(() => {
    const diagram = ref.current?.getDiagram();
    if (!diagram || !(diagram.model instanceof go.GraphLinksModel)) return;

    diagram.startTransaction("updateModel");
    const model = diagram.model as go.GraphLinksModel;
    const oldNodeData = model.nodeDataArray as Node[];
    const oldEdgeData = model.linkDataArray as Edge[];

    const oldNodesMap = new Map(oldNodeData.map((node) => [node.id, node]));
    const oldEdgesMap = new Map(oldEdgeData.map((edge) => [edge.id, edge]));

    // If any data in model data changes, update the model
    updateNodes(diagram, nodes, oldNodesMap);
    updateEdges(diagram, edges, oldEdgesMap);
    diagram.commitTransaction("updateModel");

    // Update the ports and links
    diagram.startTransaction("updatePorts");
    updateLinkSides(diagram);
    updateNodeDataWithPorts(diagram);
    assignPortIdsToLinks(diagram);
    diagram.commitTransaction("updatePorts");

    // Reset the diagram state (selection, layout, zoom)
    diagram.startTransaction("resetState");
    diagram.clearSelection(true);
    diagram.requestUpdate();
    if (nodes.length > 0) {
      diagram.layoutDiagram(true);
      resetDiagramStyles(diagram, true);
      diagram.zoomToFit();
    }
    diagram.commitTransaction("resetState");
  }, [nodes, edges]);

  const onModelChange = () => {
    const diagram = ref.current?.getDiagram();
    if (!diagram || !diagram?.model) return;

    // Keep track of graph state in meta properties
    const model = JSON.parse(diagram.model.toJson());
    const nodeDataArray = model.nodeDataArray as Node[];
    const linkDataArray = model.linkDataArray as Edge[];

    onNodesChange(nodeDataArray);
    onEdgesChange(linkDataArray);
  };

  return {
    ref,
    onModelChange,
  };
};
