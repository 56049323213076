import type { Edge, Node } from "./index";
import { useMemo, useRef, useState } from "react";
import type { NodeId } from "./makeInitDiagram";
import { equals } from "ramda";

export type GraphNodeSelectionState = "selected" | "neutral";

interface GraphNode extends Node {
  selectionState: GraphNodeSelectionState;
}

interface UseDiagramDataProps {
  graphNodes: Node[];
  graphEdges: Edge[];
  hiddenNodeIds: NodeId[];
  updateSelectedNodesInMeta: (selectedNodesIds: Set<NodeId>) => void;
  defaultSelectedNodes: NodeId[];
  onSelectionChange: () => void;
  backgroundColor: string;
  selectedNodeBackgroundColor?: string;
  nodeColor?: string;
  onRightClick: () => void;
}

const getColor = (
  node: Node,
  {
    isSelected,
    nodeColor,
    selectedNodeBackgroundColor,
  }: {
    isSelected: boolean;
    selectedNodeBackgroundColor?: string;
    nodeColor?: string;
  },
) => {
  if (isSelected && selectedNodeBackgroundColor) {
    return selectedNodeBackgroundColor;
  }
  return nodeColor || node.color;
};

function updateObjectsById(
  nodes: Node[],
  ids: NodeId[],
  updatedProperties: Partial<GraphNode>,
) {
  const result = [...nodes];
  for (const id of ids) {
    const index = result.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      result[index] = { ...result[index], ...updatedProperties };
    }
  }

  return result;
}

export const useGraphData = ({
  backgroundColor,
  defaultSelectedNodes,
  graphEdges,
  graphNodes,
  hiddenNodeIds,
  nodeColor,
  onRightClick,
  onSelectionChange,
  selectedNodeBackgroundColor,
  updateSelectedNodesInMeta,
}: UseDiagramDataProps) => {
  const [selectedNodeIds, setSelectedNodeIds] = useState(new Set<NodeId>());
  const previousSelectedNodeIds = useRef(selectedNodeIds);

  const edges = useMemo(
    () =>
      graphEdges
        .filter(
          (edge) =>
            !(
              hiddenNodeIds.includes(edge.from) ||
              hiddenNodeIds.includes(edge.to)
            ),
        )
        .map((edge) => ({
          ...edge,
          labelBackground: edge.label ? backgroundColor : "rgba(0, 0, 0, 0)",
        })),
    [graphEdges, hiddenNodeIds],
  );

  const nodes = useMemo(() => {
    const result = graphNodes
      .filter((node) => !hiddenNodeIds.includes(node.id))
      .map((node) => ({
        ...node,
        color: getColor(node, {
          isSelected: selectedNodeIds.has(node.id),
          selectedNodeBackgroundColor,
          nodeColor,
        }),
        selectionState: "neutral",
      }));

    return selectedNodeIds.size > 0
      ? updateObjectsById(result, [...selectedNodeIds], {
          selectionState: "selected",
        })
      : result;
  }, [
    graphNodes,
    selectedNodeIds,
    nodeColor,
    selectedNodeBackgroundColor,
    hiddenNodeIds,
  ]);
  const onNodeRightClick = (nodeId: NodeId) => {
    const ids = new Set([nodeId]);
    setSelectedNodeIds(ids);
    previousSelectedNodeIds.current = ids;
    updateSelectedNodesInMeta(ids);
    onRightClick();
  };
  const updateSelectedNodes = () => {
    const ids = new Set(defaultSelectedNodes || []);
    updateSelectedNodesInMeta(ids);
    setSelectedNodeIds(ids);
  };
  const onClearSelection = () => {
    const ids = new Set<NodeId>();
    updateSelectedNodesInMeta(ids);
    setSelectedNodeIds(ids);
  };

  const onAddNodeToSelection = (nodeId: NodeId) => {
    setSelectedNodeIds((state) => {
      const ids = new Set(state);

      ids.add(nodeId);
      /*
       This is hack, cb fn in setter is called 2 times in react strict mode, this was causing
       onSelectionChange to be called twice
      */
      if (
        !equals([...previousSelectedNodeIds.current].sort(), [...ids].sort())
      ) {
        onSelectionChange();
      }
      previousSelectedNodeIds.current = ids;
      updateSelectedNodesInMeta(ids);

      return ids;
    });
  };

  const onAddNodeToSingleSelection = (nodeId: NodeId) => {
    setSelectedNodeIds((state) => {
      if (!state.has(nodeId) || state.size > 1) {
        const ids = new Set([nodeId]);

        /*
         This is hack, cb fn in setter is called 2 times in react strict mode, this was causing
         onSelectionChange to be called twice
        */

        if (
          !equals([...previousSelectedNodeIds.current].sort(), [...ids].sort())
        ) {
          onSelectionChange();
        }

        previousSelectedNodeIds.current = ids;
        updateSelectedNodesInMeta(ids);

        return ids;
      }

      const ids = new Set(state).add(nodeId);
      updateSelectedNodesInMeta(ids);

      return ids;
    });
  };

  const onRemoveNodeFromSelection = (nodeId: NodeId) => {
    setSelectedNodeIds((state) => {
      const ids = new Set(state);

      ids.delete(nodeId);

      /*
       This is hack, cb fn in setter is called 2 times in react strict mode, this was causing
       onSelectionChange to be called twice
      */
      if (
        !equals([...previousSelectedNodeIds.current].sort(), [...ids].sort())
      ) {
        onSelectionChange();
      }

      previousSelectedNodeIds.current = ids;
      updateSelectedNodesInMeta(ids);

      return ids;
    });
  };

  return {
    nodes,
    edges,
    updateSelectedNodes,
    onClearSelection,
    onAddNodeToSelection,
    onAddNodeToSingleSelection,
    onRemoveNodeFromSelection,
    onNodeRightClick,
  };
};
