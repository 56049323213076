import type { ReactDiagram } from "gojs-react";
import graphLib from "graphlib";
import { isEmpty } from "lodash";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type {
  Edge,
  EdgeId,
  EdgeVariant,
  Node,
  NodeId,
  NodePositionsMap,
  NodeVariant,
  PointString,
} from "../widget/types";
import {
  getDisconnectedNodeIds,
  getEdgeVariant,
  getNodeVariant,
} from "./helpers";
import type {
  ContextMenuData,
  EdgeTransformed,
  NodeTransformed,
  TargetType,
} from "./types";
import { useNeighbours } from "./useNeighbours";
export interface UseDiagramConfig {
  nodes: Node[];
  edges: Edge[];

  hiddenNodeIds: NodeId[];
  removedNodeIds: NodeId[];
  selectedNodeId: NodeId;
  unmatchedNodeIds: NodeId[];
  nodePositions: NodePositionsMap;
  onNodePositionsChange: (nodePositions: NodePositionsMap) => void;
  onContextMenuPositionChange: (contextMenuData?: ContextMenuData) => void;

  removedEdgeIds: EdgeId[];
  selectedEdgeId: EdgeId;
  newEdges: Edge[];
  newEdgeFromNodeId: NodeId;
  onEdgeAdd: (edge: Edge) => void;

  onHiddenNodeIdsChange: (ids?: NodeId[]) => void;
  onRemovedNodeIdsChange: (ids?: NodeId[]) => void;
  onEdgesChange: (edges: Edge[]) => void;
  onNodesChange: (nodes: Node[]) => void;
  onSelectedNodeIdsChange: (selectedNodeIds: NodeId[]) => void;
  onSelectedEdgeIdChange: (edgeId?: EdgeId) => void;
  onRemovedEdgeIdsChange: (id: NodeId[]) => void;
  onIsGraphValidChange: (isValid: boolean) => void;
  onBackgroundClick: () => void;
  onNodeForNewEdgeChosen: (fromNode: NodeId) => void;
}

function useDebounce<F extends (...args: any[]) => void>(
  func: F,
  timeframe: number,
) {
  const timeoutIdRef = useRef<number | undefined>(undefined);

  return useCallback(
    (...args: Parameters<F>) => {
      if (timeoutIdRef.current !== undefined) {
        clearTimeout(timeoutIdRef.current);
      }

      timeoutIdRef.current = window.setTimeout(() => {
        func(...args);
      }, timeframe);
    },
    [func, timeframe],
  );
}

const SELECTION_DEBOUNCE_TIME = 400;

export const useDiagram = ({
  edges,
  hiddenNodeIds,
  newEdgeFromNodeId,
  newEdges,
  nodePositions,
  nodes,
  onBackgroundClick,
  onContextMenuPositionChange,
  onEdgeAdd,
  onEdgesChange,
  onHiddenNodeIdsChange,
  onIsGraphValidChange,
  onNodeForNewEdgeChosen,
  onNodePositionsChange,
  onNodesChange,
  onRemovedEdgeIdsChange,
  onRemovedNodeIdsChange,
  onSelectedEdgeIdChange,
  onSelectedNodeIdsChange,
  removedEdgeIds,
  removedNodeIds,
  selectedEdgeId,
  selectedNodeId,
  unmatchedNodeIds,
}: UseDiagramConfig) => {
  const ref = useRef<ReactDiagram>(null);

  const [contextMenuData, setContextMenuData] = useState<ContextMenuData>();
  const [isLoading, setIsLoading] = useState(false);
  const [circularNodeIds, setCircularNodeIds] = useState<NodeId[][]>([]);

  const selectedNodeIds = useMemo(() => [selectedNodeId], [selectedNodeId]);
  const nodePositionsMap = useMemo(
    () => new Map<NodeId, PointString>(Object.entries(nodePositions)),
    [nodePositions],
  );

  const removedEdgeIdsSet = useMemo(
    () => new Set(removedEdgeIds),
    [removedEdgeIds],
  );

  // Zoom to fit on nodes or edges change
  useEffect(() => {
    const diagram = ref.current?.getDiagram();
    diagram?.zoomToFit();
    diagram?.requestUpdate();
  }, [nodes, edges]);

  const { getPredecessors, getSuccessors } = useNeighbours([
    // leave only edges that were not removed
    ...edges.filter((edge) => !removedEdgeIdsSet.has(edge.id)),
    ...newEdges,
  ]);

  const nodesTransformed = useMemo(() => {
    const diagram = ref.current?.getDiagram();

    const model = diagram && JSON.parse(diagram.model.toJson());
    const edgesTransformed = model
      ? (model.linkDataArray as EdgeTransformed[])
      : [];

    const nodesTransformed = model
      ? (model.nodeDataArray as NodeTransformed[])
      : [];

    const disconnectedNodeIds = getDisconnectedNodeIds(
      nodesTransformed,
      edgesTransformed.filter((edge) => edge.visible),
    );
    const predecessors = getPredecessors(selectedNodeIds, [1, 1]);
    const successors = getSuccessors(selectedNodeIds, [1, 1]);

    const getPosition = (node: Node) => {
      if (nodePositionsMap.get(node.id)) {
        return nodePositionsMap.get(node.id);
      }

      if (node?.location) {
        return node.location as go.Point;
      }
    };

    return nodes.map((node) => {
      const transformedNode: NodeTransformed = {
        ...node,
        visible: !removedNodeIds.includes(node.id),
        partType: "node" as TargetType,
        variant: getNodeVariant(
          node.id,
          unmatchedNodeIds.includes(node.id)
            ? "unmatched-default"
            : "matched-default",
          selectedNodeIds,
          predecessors,
          successors,
          hiddenNodeIds,
          edgesTransformed,
          disconnectedNodeIds,
          nodes,
          newEdgeFromNodeId,
        ) as NodeVariant,
      };

      const location = getPosition(node);

      if (location) {
        transformedNode.location = location as go.Point;
      }

      return transformedNode;
    });
  }, [
    nodes,
    selectedNodeIds,
    removedNodeIds,
    hiddenNodeIds,
    removedNodeIds,
    newEdgeFromNodeId,
    newEdges,
    unmatchedNodeIds,
    hiddenNodeIds,
    selectedEdgeId,
    nodePositionsMap,
  ]);

  const edgesTransformed = useMemo(() => {
    const selectedNodes = nodesTransformed.filter((node) =>
      selectedNodeIds.includes(node.id),
    );

    return [...edges, ...newEdges].map((edge) => {
      return {
        ...edge,
        visible: !removedEdgeIdsSet.has(edge.id),
        partType: "edge" as TargetType,
        variant: getEdgeVariant(
          selectedEdgeId === edge.id,
          selectedNodes,
          nodesTransformed,
          edge.from,
          edge.to,
          circularNodeIds,
          newEdgeFromNodeId,
        ) as EdgeVariant,
        isSelected:
          selectedNodeIds.includes(edge.from) ||
          selectedNodeIds.includes(edge.to) ||
          edge.id === selectedEdgeId,
      };
    });
  }, [
    edges,
    selectedNodeIds,
    nodesTransformed,
    nodes,
    hiddenNodeIds,
    selectedEdgeId,
    removedEdgeIdsSet,
    newEdges,
    circularNodeIds,
  ]);

  const onZoomToSelectedPart = () => {
    const diagram = ref.current?.getDiagram();

    if (!diagram?.scale) {
      return;
    }

    if (
      // This will be changed once multi selection in Precedence Graph is merged
      selectedNodeIds.every((id) => id === "") &&
      !selectedEdgeId
    ) {
      diagram.commandHandler.zoomToFit();
      return;
    }

    // If edge is selected zoom to edge
    if (selectedEdgeId) {
      const edge = diagram?.findLinkForKey(selectedEdgeId);
      if (!edge) {
        return;
      }

      // Get the bounds of the edge in document coordinates
      const bounds = edge.actualBounds;

      // Zoom to part bounds
      diagram.zoomToRect(bounds);
      diagram.scale = 0.6;
      return;
    }

    // If node is selected zoom to node
    const part = diagram.findPartForKey(selectedNodeIds[0]);

    if (!part) {
      return;
    }

    const bounds = part.actualBounds;

    // Zoom to part bounds
    diagram.zoomToRect(bounds);
    diagram.scale = 0.6;
    return;
  };

  const onContextMenu = (contextMenuData?: ContextMenuData) => {
    setContextMenuData(contextMenuData);

    if (!contextMenuData) {
      return;
    }
    onContextMenuPositionChange(contextMenuData);
  };

  const onContextMenuClose = () => {
    onContextMenu(undefined);
  };

  const updateHiddenNodeIds = (ids: NodeId[]) => {
    onHiddenNodeIdsChange(ids);
  };

  const onHidePart = (targetId: NodeId) => {
    const newHiddenNodeIds = [...hiddenNodeIds, targetId];
    updateHiddenNodeIds(newHiddenNodeIds);
    onSelectedNodeIdsChange([]);
  };

  const onUnhidePart = (targetId: NodeId) => {
    const newHiddenNodeIds = hiddenNodeIds.filter((id) => id !== targetId);
    updateHiddenNodeIds(newHiddenNodeIds);
  };

  const updateRemovedNodeIds = (ids: NodeId[]) => {
    onRemovedNodeIdsChange(ids);
  };

  const onRemovePart = (targetId: NodeId) => {
    const newRemovedNodeIds = [...removedNodeIds, targetId];
    updateRemovedNodeIds(newRemovedNodeIds);
  };

  const updateSelectedNodeIds = (ids: NodeId[]) => {
    if (isEmpty(ids)) {
      onSelectedNodeIdsChange([]);
      return;
    }

    const recentlySelectedNodeId = ids[ids.length - 1];
    const newSelection = [recentlySelectedNodeId];

    onSelectedNodeIdsChange(newSelection);
  };
  const onNodeSelectionChange = (ids: NodeId[]) => {
    setContextMenuData(undefined);
    updateSelectedNodeIds(ids);
  };

  const onNodeSelectionChangeDebounced = useDebounce(
    onNodeSelectionChange,
    SELECTION_DEBOUNCE_TIME,
  );

  // To prevent the context menu from closing when the user right-clicks on a node
  const onContextMenuDebounced = useDebounce(
    onContextMenu,
    SELECTION_DEBOUNCE_TIME + 1,
  );

  const onEdgeSelectionChange = (id?: EdgeId) => {
    onSelectedEdgeIdChange(id);
  };

  const onRemoveEdge = (targetId: NodeId) => {
    const newRemovedEdgeIds = new Set([...removedEdgeIdsSet, targetId]);

    onRemovedEdgeIdsChange([...newRemovedEdgeIds]);

    setCircularNodeIds([]);
  };

  const onCreateNewEdge = (nodeId: NodeId) => {
    const isEdgeAlreadyExisting = [
      // leave only edges that were not deleted
      ...edgesTransformed.filter((edge) => edge.visible),
      ...newEdges,
    ].find((edge) => edge.from === newEdgeFromNodeId && edge.to === nodeId);

    if (isEdgeAlreadyExisting) {
      // TODO: clean this up. This logic should be in the widget
      onNodeForNewEdgeChosen(undefined as any);
      return;
    }

    if (!newEdgeFromNodeId) {
      return;
    }

    const id = `${newEdgeFromNodeId}-${nodeId}`;

    // if edge was deleted before, and now it's created with the same id remove it from deletedEdgeIds
    if (removedEdgeIdsSet.has(id)) {
      const newDeletedEdgeIds = new Set([...removedEdgeIdsSet]);
      newDeletedEdgeIds.delete(id);

      onRemovedEdgeIdsChange([...newDeletedEdgeIds]);
    }

    const newEdge = {
      id: id,
      from: newEdgeFromNodeId,
      to: nodeId,
      partType: "edge" as TargetType,
      visible: true,
      variant: "default" as EdgeVariant,
    };
    onEdgeAdd(newEdge);
  };

  const getGraphForValidation = useCallback(() => {
    const graph = new graphLib.Graph({ directed: true, multigraph: true });

    // Add nodes and edges to the graph
    nodesTransformed.forEach((node) => {
      if (node.visible) {
        graph.setNode(node.id as string, node.text);
        return;
      }

      graph.removeNode(node.id as string);
    });

    edgesTransformed.forEach((edge) => {
      if (edge.visible) {
        graph.setEdge(
          edge.from as string,
          edge.to as string,
          "",
          edge.id as string,
        );
        return;
      }

      graph.removeEdge(
        edge.from as string,
        edge.to as string,
        edge.id as string,
      );
    });

    edgesTransformed.forEach((edge) => {
      if (!edge.visible) {
        graph.removeEdge(edge.from as string, edge.to as string);
      }
    });

    return graph;
  }, [edgesTransformed, nodesTransformed]);

  const getIsGraphValid = () => {
    const hasDisconnectedNode = Boolean(
      nodesTransformed.some((node) => node.variant === "disconnected"),
    );

    const graph = getGraphForValidation();

    return graphLib.alg.isAcyclic(graph) && !hasDisconnectedNode;
  };
  const updateIsGraphValid = () => {
    onIsGraphValidChange(getIsGraphValid());
  };

  const onValidateGraph = useCallback(() => {
    const graph = getGraphForValidation();

    const isAcyclic = getIsGraphValid();

    if (isAcyclic) {
      setCircularNodeIds([]);
      return;
    }

    setCircularNodeIds(graphLib.alg.findCycles(graph));
  }, [
    nodesTransformed,
    edgesTransformed,
    newEdges,
    selectedEdgeId,
    selectedNodeIds,
  ]);

  const onBackgroundSingleClick = () => {
    onContextMenuClose();
    onNodeSelectionChangeDebounced([]);
    onBackgroundClick();
  };

  const onModelChange = () => {
    if (isLoading) {
      return;
    }
    const diagram = ref.current?.getDiagram();
    if (!diagram || !diagram?.model) return;

    const model = JSON.parse(diagram.model.toJson());
    const nodeDataArray = model.nodeDataArray as NodeTransformed[];
    const linkDataArray = model.linkDataArray as EdgeTransformed[];
    const newPositions: NodePositionsMap = {};

    // We don't want to update meta property if we don't have all locations generated.
    if (nodeDataArray.some((node) => !node.location)) {
      return;
    }

    diagram.nodes.each((node) => {
      if (!node.position.isReal()) {
        return;
      }
      newPositions[node.data.id] = `${node.position.x} ${node.position.y}`;
    });
    onNodePositionsChange(newPositions);

    onNodesChange(
      nodeDataArray.map((node) => ({
        id: node.id,
        text: node.text,
        location: node.location as go.Point,
      })),
    );
    onEdgesChange(
      linkDataArray.map((edge) => ({
        id: edge.id,
        from: edge.from,
        to: edge.to,
      })),
    );

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const onAutoLayout = () => {
    setIsLoading(true);

    // For some reason, isLoading state doesn't change without setTimeout
    setTimeout(() => {
      const diagram = ref.current?.getDiagram();
      if (!diagram) return;

      onNodePositionsChange({});
      diagram.layoutDiagram(true);
      onModelChange();
    }, 0);
  };

  useEffect(() => {
    setIsLoading(true);
    const diagram = ref.current?.getDiagram();

    if (!diagram) return;

    if (!diagram.nodes.all((n) => n.location.isReal())) {
      diagram.layoutDiagram(true);
      return;
    }

    setIsLoading(false);
  }, [nodes]);

  useEffect(() => {
    updateIsGraphValid();
  }, [edgesTransformed]);

  useEffect(() => {
    // Prevent appearing of default context menu on MS Edge on windows machine.
    const onContextMenu: EventListenerOrEventListenerObject = (event) => {
      event.preventDefault();
    };
    document.addEventListener("contextmenu", onContextMenu);

    setIsLoading(true);
    const diagram = ref.current?.getDiagram();
    if (!diagram) return;

    // Stop loading state on InitialLayoutCompleted.
    const onInitialLayoutCompleted = () => {
      setIsLoading(false);
    };

    diagram.addDiagramListener(
      "InitialLayoutCompleted",
      onInitialLayoutCompleted,
    );

    const onLayoutCompleted = () => {
      setIsLoading(false);
      diagram.commandHandler.zoomToFit();
    };

    diagram.addDiagramListener("LayoutCompleted", onLayoutCompleted);

    // Remove event listeners
    return () => {
      diagram.removeDiagramListener(
        "InitialLayoutCompleted",
        onInitialLayoutCompleted,
      );
      document.removeEventListener("contextmenu", onContextMenu);
    };
  }, []);

  useEffect(() => {
    onValidateGraph();
  }, [newEdges, removedEdgeIds, nodesTransformed]);

  return {
    ref,
    hiddenNodeIds,
    nodesTransformed,
    edgesTransformed,
    contextMenuData,
    onNodeSelectionChange: onNodeSelectionChangeDebounced,
    onContextMenu: onContextMenuDebounced,
    onHidePart,
    onUnhidePart,
    onRemovePart,
    onEdgeSelectionChange,
    onRemoveEdge,
    newEdgeStartNodeId: newEdgeFromNodeId,
    setNewEdgeStartNodeId: (nodeId: NodeId) => onNodeForNewEdgeChosen(nodeId),
    onCreateNewEdge,
    onZoomToSelectedPart,
    onBackgroundSingleClick,
    onModelChange,
    onAutoLayout,
    isLoading,
    selectedNodeIds,
  };
};
