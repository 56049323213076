import React from "react";

const DashedLineIcon = ({ strokeColor = "#596478" }) => (
  <svg
    data-testid="DashedLineIcon"
    fill="none"
    viewBox="0 0 25 2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H6V2H0V0Z" fill={strokeColor} />
    <path d="M9.5 0H15.5V2H9.5V0Z" fill={strokeColor} />
    <path d="M19 0H25V2H19V0Z" fill={strokeColor} />
  </svg>
);

export default DashedLineIcon;
