import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import type { ViewerContext3D } from "@spread-ai/softy-renderer-react";
import {
  NavigationCube,
  Viewer,
  createViewerContext3D,
} from "@spread-ai/softy-renderer-react";
import { isEmpty, isNil } from "lodash";
import styled from "styled-components";

import type { Marker, Pill } from "../widget/types";
import type {
  GeneralStyles,
  MarkersStyles,
  ModelStyles,
} from "../widget/styleConfig";

import { ViewerApi } from "./ViewerApi";
import type { NodeId, Group } from "./ViewerApi/types";

import { useMarkers } from "./markers/useMarkers";
import { buildMarkersList } from "./markers/helpers";

import { PillsOverlay } from "./pills/PillsOverlay";
import { usePills } from "./pills/usePills";
import { buildPillsList } from "./pills/helpers";

import Toolbar from "./Toolbar";

import { ContextMenu } from "./ContextMenu";
import { useContextMenu } from "./ContextMenu/useContextMenu";
import { compareArraysUnordered, type ShortRecursiveNode } from "./utils";
import { parseString, shortifyRecursiveNode } from "./utils";
import { Loader } from "../../../spread/components/Loader";
import { ProgressVariant } from "../../ProgressWidget/constants";
import type { SceneTreeLoadProgressReport } from "@spread-ai/softy-renderer-react/dist/types/model/ViewerContext3D";

export interface Collection {
  collectionId: string;
  groups: Group[];
}

interface RendererComponentProps {
  // Default
  widgetId: string;
  // General
  generalStyles: GeneralStyles;
  // Model
  modelSceneGraphUrl: string;
  modelStyles: ModelStyles;
  onModelLoaded: (sceneTree?: ShortRecursiveNode) => void;
  // Node selection
  onNodeSelectionChange: (selectedLeafNodesIds: string[]) => void;
  /** TODO In the future we need to think about changing this to something like defaultSelectedIds
   because it's not only nodes that can be selected but also groups.
  */
  defaultSelectedNodesIds: string[];
  // Hidden nodes
  hiddenNodesIds: string[];
  onHiddenNodesChange: (hiddenNodesIds: string[]) => void;
  // Markers
  markers: Marker[];
  markersStyle: MarkersStyles;
  defaultSelectedMarkerId: string;
  onMarkerSelected: (markerId: string) => void;
  // Pills
  pills: Pill[];
  // Context menu's elements visibility
  isContextMenuVisible: boolean;
  isHideVisible: boolean;
  isShowAllVisible: boolean;
  isShowOnlyVisible: boolean;
  // Loading
  isForcedLoading: boolean;
  isLoading: boolean;
  updateLoadingState: (isLoading: boolean) => void;
  // Progress bar
  progressBarFillColor: string;
  // Collections
  onSelectedGroupsChange: (selectedGroupsIds: string[]) => void;
  defaultActiveCollectionId?: string;
  defaultCollections: Collection[];
}
const PROGRESS_BAR_HEIGHT = 4;

const RendererComponent = (props: RendererComponentProps) => {
  const [viewerContext, setViewerContext] = useState<ViewerContext3D>();
  const [isModelAdded, setIsModelAdded] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const {
    defaultActiveCollectionId,
    defaultCollections,
    isForcedLoading,
    isLoading,
    progressBarFillColor,
    updateLoadingState,
  } = props;

  // Initialization of viewer context
  useEffect(() => {
    let _viewerContext: ViewerContext3D;

    createViewerContext3D({
      models: [],
      isHighQuality: false,
      timeouts: {
        // default
      },
    }).then((newViewerContext) => {
      _viewerContext = newViewerContext;
      setViewerContext(newViewerContext);
    });

    return () => {
      _viewerContext.destroy();
    };
  }, [props.widgetId]);

  // Initialization of ViewerApi
  const viewerApi = useMemo(() => {
    if (viewerContext) {
      return new ViewerApi(viewerContext);
    }
  }, [viewerContext]);

  // Context menu
  const { closeContextMenu, contextMenuData } = useContextMenu({
    viewerApi,
    enabled: isModelAdded && props.isContextMenuVisible,
  });

  const ghostedNodesIds = useMemo(() => {
    const nodes = props.modelStyles.ghostedMode.ghostedNodesIds;

    // There is a case in which Appsmith does not parse a string into an array. This is a workaround for that.
    if (typeof nodes === "string") {
      return parseString(nodes, [] as NodeId[]);
    }

    return nodes;
  }, [props.modelStyles.ghostedMode.ghostedNodesIds]);

  // Handling Ghosted nodes
  useEffect(() => {
    if (!viewerApi || !isModelAdded) return;

    const ghostedMode = props.modelStyles.ghostedMode;

    viewerApi.setEntireModelGhosted(ghostedMode.entireModel);

    if (!ghostedMode.entireModel) viewerApi.setGhostedNodes(ghostedNodesIds);
  }, [
    viewerApi,
    isModelAdded,
    props.modelStyles.ghostedMode.entireModel,
    ghostedNodesIds,
  ]);

  const onLoadingProgressChange = (progress?: SceneTreeLoadProgressReport) => {
    if (!progress) {
      setLoadingProgress(100);
      updateLoadingState(false);

      return;
    }
    const loadingProgress =
      (progress.filesLoadedSoFar / progress.totalFilesToLoad) * 100;

    setLoadingProgress(loadingProgress);
  };

  // Model rendering
  useEffect(() => {
    if (!viewerContext) return;

    if (props.modelSceneGraphUrl) {
      setIsModelAdded(false);
      updateLoadingState(true);
      viewerContext
        .drawSceneGraph(props.modelSceneGraphUrl, onLoadingProgressChange)
        .then((sceneTree) => {
          const shortSceneTree = sceneTree
            ? shortifyRecursiveNode(sceneTree)
            : undefined;
          props.onModelLoaded(shortSceneTree);

          // Indicating that model is added
          setIsModelAdded(true);
        });
    } else {
      viewerContext.reset();
    }
  }, [viewerContext, props.modelSceneGraphUrl]);

  // Markers
  const [selectedMarkerId, setSelectedMarkerId] = useState(
    props.defaultSelectedMarkerId,
  );

  const markers = useMemo(
    () =>
      buildMarkersList({
        markers: props.markers,
        selectedMarkerId,
        style: props.markersStyle,
      }),
    [
      props.markers,
      selectedMarkerId,
      props.markersStyle,
      // We need to re-render when the model changes, because the markers are
      // relative to the model and will be cleared when the model is changed.
      props.modelSceneGraphUrl,
    ],
  );

  const { selectedMarker } = useMarkers(viewerApi, isModelAdded, markers, [
    viewerContext,
    props.modelSceneGraphUrl,
  ]);

  // Updating selected marker by click on it (outgoing)
  useEffect(() => {
    if (selectedMarker) {
      setSelectedMarkerId(selectedMarker.id);
      props.onMarkerSelected(selectedMarker.id);
    }
  }, [selectedMarker]);

  // Updating selected marker by API (incoming)
  useEffect(() => {
    setSelectedMarkerId(props.defaultSelectedMarkerId.trim());
    // No need to call `props.onMarkerSelected` here, because it is incoming change
    // it was already called in widget/index.tsx
  }, [props.defaultSelectedMarkerId]);

  // Pills
  const pillsList = useMemo(() => buildPillsList(props.pills), [props.pills]);
  const pills = usePills(viewerApi, isModelAdded, pillsList, [
    viewerContext,
    props.modelSceneGraphUrl,
  ]);

  // Node selection listener (outcoming)
  useEffect(() => {
    if (!viewerApi || !isModelAdded) return;

    // Subscription
    const unsubscribe = viewerApi.addEventListener(
      "selectionchange",
      (selection) => {
        const selectedLeafNodesIds = selection.map((item) => item.id);
        props.onNodeSelectionChange(selectedLeafNodesIds);
      },
    );

    // Unsubscription
    return () => unsubscribe();
  }, [viewerApi, isModelAdded]);

  // Groups selection listener (outcoming)
  useEffect(() => {
    if (!viewerApi || !isModelAdded) return;

    // Subscription
    const unsubscribe = viewerApi.addEventListener(
      "groupsselectionchange",
      (selectedGroupsIds) => {
        props.onSelectedGroupsChange(selectedGroupsIds);
      },
    );

    // Unsubscription
    return () => unsubscribe();
  }, [viewerApi, isModelAdded]);

  // Creating defaultSelectedIdsRef prevents from infinite loops that is caused by meta updates, for some reason on page load
  // defaultSelectedNodesIds are updated few times with empty array, and it causes infinite loop.
  const defaultSelectedIdsRef = useRef<NodeId[]>([]);

  // Active collection id update / groups collections update (incoming)
  useEffect(() => {
    if (
      !viewerApi ||
      !isModelAdded ||
      !defaultActiveCollectionId ||
      !defaultCollections ||
      !defaultCollections.some((collection) => collection.groups)
    ) {
      return;
    }

    defaultCollections.map((collection) => {
      // Create collections
      viewerApi?.createCollection(collection.collectionId);

      // Create groups
      collection.groups.map((group) => {
        if (!group.id || !group.nodeIds || !collection.collectionId) {
          return;
        }
        viewerApi.createGroup(group.id, group.nodeIds, collection.collectionId);
      });
    });
    // Set active collection id if it exists in collections.
    if (
      defaultActiveCollectionId &&
      defaultCollections.every(
        (collection) => collection.groups && collection.groups.length,
      )
    ) {
      viewerApi.setActiveCollectionId(defaultActiveCollectionId);
    }
    // Collections might changed so remove all of them in clean up function and recreate
    return () => viewerApi.clearGroupsState();
  }, [viewerApi, isModelAdded, defaultActiveCollectionId, defaultCollections]);

  // Nodes / groups selection update (incoming)
  useEffect(() => {
    if (!viewerApi || !viewerContext || !isModelAdded) return;

    if (
      compareArraysUnordered(
        defaultSelectedIdsRef.current,
        props.defaultSelectedNodesIds,
      )
    ) {
      return;
    }

    defaultSelectedIdsRef.current = props.defaultSelectedNodesIds;

    const activeCollection = defaultCollections?.find(
      (collection) => collection.collectionId === defaultActiveCollectionId,
    );

    const { groups: selectedGroups, nodes: selectedNodes } =
      props.defaultSelectedNodesIds.reduce<{
        groups: string[];
        nodes: string[];
      }>(
        (groupedIds, id) => {
          const group = activeCollection?.groups?.find(
            (group) => group.id === id,
          );
          if (group) {
            return { ...groupedIds, groups: [...groupedIds.groups, group.id] };
          } else {
            return { ...groupedIds, nodes: [...groupedIds.nodes, id] };
          }
        },
        { groups: [], nodes: [] },
      );

    // Remove all selections of non group members and group members
    if (isEmpty(props.defaultSelectedNodesIds)) {
      viewerApi.setSelection([]);
      return;
    }

    // select nodes that are not members of any group
    if (selectedNodes.length) {
      viewerApi.setNonGroupsMemberSelection(selectedNodes);
    }

    // Select groups
    // Only one group was selected, replace previous selection with new one.
    if (selectedGroups && selectedGroups.length === 1 && activeCollection) {
      viewerApi.setGroupsSelection(selectedGroups);
      // More than one groups were selected, extend selection.
    } else if (selectedGroups.length > 1 && activeCollection) {
      viewerApi.extendSelection(selectedGroups);
    }
    // Clear selection of the nodes that don't belong to the selected groups
    if (!selectedNodes.length) {
      viewerContext.selectionClearNonMemberSelection();
    }

    // Clear selection of the groups that belong the selected groups
    if (!selectedGroups.length) {
      viewerContext.selectionClearGroupSelection();
    }
  }, [
    viewerContext,
    isModelAdded,
    defaultActiveCollectionId,
    props.defaultSelectedNodesIds,
    defaultCollections,
  ]);

  // Hidden nodes update (incoming)
  useEffect(() => {
    if (!viewerApi || !isModelAdded) return;

    viewerApi.setHiddenNodes(props.hiddenNodesIds, {
      committedByUser: true,
    });
  }, [viewerApi, isModelAdded, props.hiddenNodesIds]);

  const onFocus = useCallback(() => {
    if (!viewerContext) return;
    viewerContext.focusOnSelection();
  }, [viewerContext]);

  const onReset = useCallback(() => {
    if (!viewerContext) return;
    viewerContext.resetCamera();
  }, [viewerContext]);

  if (!viewerContext) return null;

  const NavigationCubeSection = props.generalStyles.hasNavigationCube && (
    <NavCubeContainer>
      <NavigationCube viewerContext={viewerContext} />
    </NavCubeContainer>
  );

  const PillsSection = !isNil(pills) && <PillsOverlay pillData={pills} />;

  const ToolbarSection = props.generalStyles.hasToolbar && (
    <Toolbar onFocus={onFocus} onReset={onReset} />
  );

  const ContextMenuSection = (
    <ContextMenu
      anchorPosition={contextMenuData?.anchorPosition}
      items={[
        {
          label: "Show only",
          onClick: (targetId) => {
            if (!viewerApi || !targetId) return;
            viewerApi?.showOnlyNode(targetId, { committedByUser: true });
            props.onHiddenNodesChange(viewerApi.getHiddenByUserNodes());
          },
          disabled: (targetId) => !targetId,
          isHidden: !props.isShowOnlyVisible,
        },
        {
          label: "Show all",
          onClick: () => {
            if (!viewerApi) return;
            viewerApi.unhideAllNodesHiddenByUser();
            props.onHiddenNodesChange([]);
          },
          disabled: !viewerApi?.isAnyNodeHiddenByUser(),
          isHidden: !props.isShowAllVisible,
        },
        {
          label: "Hide",
          onClick: (targetId) => {
            if (!viewerApi || !targetId) return;
            viewerApi.setNodeEnabled(targetId, false, {
              committedByUser: true,
            });
            props.onHiddenNodesChange(viewerApi.getHiddenByUserNodes());
          },
          disabled: (targetId) => !targetId,
          isHidden: !props.isHideVisible,
        },
        {
          label: "Zoom to fit",
          onClick: (targetId) => viewerApi?.zoomToFit(targetId),
        },
        {
          label: "Copy ID",
          onClick: (targetId) => {
            if (!targetId) return;
            navigator.clipboard.writeText(targetId);
          },
          disabled: (targetId) => !targetId,
        },
      ]}
      onClose={closeContextMenu}
      targetId={contextMenuData?.targetId}
    />
  );

  const getIsLoaderVisible = () => {
    return (isLoading && loadingProgress !== 100) || isForcedLoading;
  };

  const isLoaderVisible = getIsLoaderVisible();

  const LoaderSection = isLoaderVisible && (
    <LoaderContainer>
      <Loader
        borderRadius="0px"
        fillColor={progressBarFillColor}
        height={PROGRESS_BAR_HEIGHT}
        isVisible
        progressValue={loadingProgress}
        variant={
          isLoading
            ? ProgressVariant.DETERMINATE
            : ProgressVariant.INDETERMINATE
        }
      />
    </LoaderContainer>
  );

  return (
    <Container>
      <InnerContainer>
        {LoaderSection}
        <Viewer viewerContext={viewerContext} />
        {NavigationCubeSection}
        {PillsSection}
        {ToolbarSection}
      </InnerContainer>
      {ContextMenuSection}
    </Container>
  );
};

export default RendererComponent;

const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1000;
  cursor: not-allowed;
`;

const Container = styled.div`
  display: flex;
  overflow: visible;
  height: 100%;
  width: 100%;
`;

Container.defaultProps = {
  // There should be no native context menu on the renderer widget
  onContextMenu: (event: React.MouseEvent) => event.preventDefault(),
};

const InnerContainer = styled.div<{ cursor?: string }>`
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-width: 1px;
  cursor: ${({ cursor }) => cursor};
`;

const NavCubeContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;
