import { FieldType } from "components/editorComponents/ActionCreator/constants";
import type { SelectorField } from "components/editorComponents/ActionCreator/types";
import {
  RUN_FLOW_FLOW_ID_ARG_INDEX,
  RUN_FLOW_FLOW_ID_ARG_NAME,
  RUN_FLOW_PARAMETERS_ARG_NAME,
} from "spread/flows/editorAutocompleteDefinitions";

/**
 * Returns the UI fields used to create the `spread.flows.runFlow` action code.
 *
 * This function is used only to decide which fields to render in the action creator UI.
 *
 * This is not where the parameters are parsed/stringified to show as values of these fields:
 * that happens in the field configuration object's `getter` and `setter` functions.
 *
 * @see https://github.com/spread-ai/spread-appsmith/blob/ffb241ab7bddff66cd563cfdd51e9583f24605ba/app/client/src/components/editorComponents/ActionCreator/Field/FieldConfig.ts#L163-L188
 *
 * @pure
 * @returns {SelectorField[]} The 3 fields to render in the action creator UI:
 * - [0] Action selector field
 * - [1] Flow ID text field
 * - [2] Flow parameters object field
 */
export function getRunFlowActionCreationFields(
  getParentValue: ((changeValue: string) => string) | undefined,
  value: string,
) {
  const fields: SelectorField[] = [];

  fields.push({
    field: FieldType.ACTION_SELECTOR_FIELD,
    getParentValue,
    value,
  });

  fields.push({
    field: FieldType.ARGUMENT_KEY_VALUE_FIELD,
    getParentValue,
    label: RUN_FLOW_FLOW_ID_ARG_NAME,
    index: RUN_FLOW_FLOW_ID_ARG_INDEX,
    value: "", // not used for anything
  } as SelectorField);

  const parametersField: SelectorField = {
    // What field configuration to use.
    // All configs can be found at app/client/src/components/editorComponents/ActionCreator/Field/FieldConfig.ts
    field: FieldType.FLOW_PARAMETERS_OBJECT_FIELD,
    getParentValue,
    label: RUN_FLOW_PARAMETERS_ARG_NAME,
    // index: argument index is hard coded in the field configuration object's `setter` function.
    value: "", // not used for anything
  };

  fields.push(parametersField);

  return fields;
}
