// Debug: https://regex101.com/r/PqFyPy/4
const REFID_REGEX = /^([\w\d-/]*)(?:\*([\w\d-]*)(?:@([\w\d-]*))?)?$/i;
export function parseReferenceId(refId: string) {
  const [, component = null, connector = null, terminal = null] =
    refId.match(REFID_REGEX) ?? [];

  if (component === null) {
    throw new Error(`Could not parse reference ID "${refId}": unknown format.`);
  }

  return { component, connector, terminal };
}

export function createReferenceId(ids: {
  component: string;
  connector?: string | null;
  terminal?: string | null;
}) {
  if (ids.terminal) {
    if (!ids.connector) {
      throw new Error(
        `Cannot create terminal reference ID without its connector.`,
      );
    }

    return `${ids.component}*${ids.connector}@${ids.terminal}`;
  }

  if (ids.connector) {
    return `${ids.component}*${ids.connector}`;
  }

  return ids.component;
}
