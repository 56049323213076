import React from "react";

import type {
  AutoLayoutConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { WithMeta } from "widgets/MetaHOC";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";

import { WIDGET_TAGS } from "constants/WidgetConstants";
import RendererComponent, { type Collection } from "../component";
import type { ShortRecursiveNode } from "../component/utils";
import { propertyPaneContentConfig } from "./contentConfig";
import {
  propertyPaneStyleConfig,
  stylesheetConfig,
  type StyleProps,
} from "./styleConfig";
import type { Marker, Pill } from "./types";

import IconSVG from "../icon.svg";
import ThumbnailSVG from "../../spread_tmp_thumbnail.svg";

import { DefaultModelOption } from "components/propertyControls/spreadProperyControls/CadDomainModelSelector/constants";
import { generateTypeDef } from "utils/autocomplete/defCreatorUtils";

const defaultProps = {
  modelSceneGraphUrl: DefaultModelOption.value,
  markers: [
    {
      id: "marker1",
      label: "Unit 1",
      shape: "cube",
      position: { x: -3700, y: -1000, z: 1800 },
      digit: 1,
    },
    {
      id: "marker2",
      label: "Unit 2",
      shape: "sphere",
      position: { x: -3000, y: -2000, z: 1500 },
      digit: 2,
    },
  ],
  defaultSelectedMarkerId: "marker2",
  pills: [
    {
      id: "marker2",
      label: "Unit 2",
      position: { x: -3000, y: -2000, z: 1500 },
    },
  ],
  defaultSelectedNodesIds: [],
  defaultHiddenNodesIds: [],
  isShowOnlyVisible: true,
  isShowAllVisible: true,
  isHideVisible: true,
  isContextMenuVisible: true,
};
export interface RendererWidgetProps extends WidgetProps, WithMeta, StyleProps {
  // Model
  modelSceneGraphUrl: string;

  // Markers
  markers: Marker[];
  defaultSelectedMarkerId: string;

  // Pills
  pills: Pill[];

  // Node selection
  defaultSelectedNodesIds: string[];

  // Hidden nodes
  defaultHiddenNodesIds: string[];

  // Context menu's elements visibility
  isContextMenuVisible: boolean;
  isHideVisible: boolean;
  isShowAllVisible: boolean;
  isShowOnlyVisible: boolean;

  // Loading
  isLoading: boolean;
  isForcedLoading: boolean;

  /** Active group collection id */
  defaultActiveCollectionId: string;

  /** Collections of the groups */
  defaultCollections?: Collection[];
}

class RendererWidget extends BaseWidget<RendererWidgetProps, WidgetState> {
  static type = "RENDERER_WIDGET";

  static getConfig() {
    return {
      name: "Renderer", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      thumbnailSVG: ThumbnailSVG,
      tags: [WIDGET_TAGS.DISPLAY],
      searchTags: ["Model", "3D", "CAD", "Viewer"],
      needsMeta: true, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
    };
  }

  static getDefaults() {
    return {
      ...defaultProps,
      widgetName: "Renderer",
      rows: 40,
      columns: 40,
      version: 1,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    // TODO: add proper auto layout config
    return {};
  }

  onGroupsSelectionChange = (selectedGroupsIds: string[]) => {
    // Update selected groups ids
    this.props.updateWidgetMetaProperty(
      "selectedGroupsIds",
      selectedGroupsIds,
      // Execute event listeners
      {
        triggerPropertyName: "onSelectionChange",
        dynamicString: this.props.onSelectionChange,
        event: {
          type: EventType.ON_SELECTION_CHANGE,
        },
      },
    );
  };

  updateSelectedMarkerId = (selectedMarkerId: string) => {
    this.props.updateWidgetMetaProperty("selectedMarkerId", selectedMarkerId);
  };

  onNodeSelectionChange = (selectedLeafNodesIds: string[]) => {
    // Update selected leaf nodes ids
    this.props.updateWidgetMetaProperty(
      "selectedLeafNodesIds",
      selectedLeafNodesIds,
      // Execute event listeners
      {
        triggerPropertyName: "onSelectionChange",
        dynamicString: this.props.onSelectionChange,
        event: {
          type: EventType.ON_SELECTION_CHANGE,
        },
      },
    );
  };

  updateHiddenNodesIds = (hiddenNodesIds: string[]) => {
    this.props.updateWidgetMetaProperty("hiddenNodesIds", hiddenNodesIds);
  };

  onModelLoaded = (sceneTree?: ShortRecursiveNode) => {
    const sceneTreeArray = sceneTree ? [sceneTree] : [];
    this.props.updateWidgetMetaProperty("sceneTree", sceneTreeArray, {
      triggerPropertyName: "onModelLoaded",
      dynamicString: this.props.onModelLoaded,
      event: {
        type: EventType.ON_MODEL_LOADED,
      },
    });
  };

  updateLoadingState = (isLoading: boolean) => {
    this.props.updateWidgetMetaProperty("isLoading", isLoading);
  };

  componentDidMount(): void {
    const { defaultHiddenNodesIds, defaultSelectedMarkerId } = this.props;
    this.updateHiddenNodesIds(defaultHiddenNodesIds);
    this.updateSelectedMarkerId(defaultSelectedMarkerId);
  }

  componentDidUpdate(prevProps: RendererWidgetProps): void {
    const { defaultSelectedMarkerId } = this.props;

    if (prevProps.defaultSelectedMarkerId !== defaultSelectedMarkerId) {
      this.updateSelectedMarkerId(defaultSelectedMarkerId);
    }

    if (prevProps.defaultHiddenNodesIds !== this.props.defaultHiddenNodesIds) {
      this.updateHiddenNodesIds(this.props.defaultHiddenNodesIds);
    }
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return (widget: RendererWidgetProps) => ({
      "!doc": "Renderer widget is used to display 3D models.",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      modelSceneGraphUrl: generateTypeDef(widget.modelSceneGraphUrl),
      markers: generateTypeDef(widget.markers),
      selectedMarkerId: generateTypeDef(widget.defaultSelectedMarkerId),
      pills: generateTypeDef(widget.pills),
      selectedLeafNodesIds: {
        "!type": "[string]",
        "!doc": "The array of selected leaf nodes ids.",
      },
      hiddenNodesIds: generateTypeDef(widget.defaultHiddenNodesIds),
      /**
       * Important note:
       * Studio developers should check `isEntireModelGhosted` first.
       * If it is true, then they should ignore `ghostedNodesIds`.
       * If it is false, then they should use `ghostedNodesIds`.
       */
      ghostedNodesIds: generateTypeDef(
        widget.modelStyles.ghostedMode.ghostedNodesIds,
      ),
      isEntireModelGhosted: generateTypeDef(
        widget.modelStyles.ghostedMode.entireModel,
      ),
      sceneTree: {
        // It returns an array, not an object, because it is format `TreeWidget` expects
        "!type": "[object]",
        "!doc": "The scene tree of the rendered model.",
      },
      selectedGroupsIds: {
        "!type": "[string]",
        "!doc": "The array of selected groups IDs.",
      },
    });
  }

  static getDerivedPropertiesMap() {
    return {
      ghostedNodesIds: `{{this.modelStyles.ghostedMode.ghostedNodesIds}}`,
      isEntireModelGhosted: `{{this.modelStyles.ghostedMode.entireModel}}`,
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return stylesheetConfig;
  }

  static getPropertyPaneStyleConfig() {
    return propertyPaneStyleConfig;
  }

  static getPropertyPaneContentConfig() {
    return propertyPaneContentConfig;
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
        setEntireModelGhosted: {
          path: "modelStyles.ghostedMode.entireModel",
          type: "boolean",
        },
        setGhostedNodes: {
          path: "modelStyles.ghostedMode.ghostedNodesIds",
          type: "array",
        },
        /**
         * Important note:
         * The user also has an opportunity to hide nodes from the context menu.
         * Because of this there is one bug, for which no solution has been found yet.
         *
         * If you call `Renderer1.setHiddenNodes(["NODE_1"])`, then hide some node from the context menu,
         * then call `Renderer1.setHiddenNodes(["NODE_1"])` again, Appsmith will believe state has not changed
         * and will not update the widget.
         *
         * I tried to fix this by calling `this.props.updateWidgetMetaProperty("defaultHiddenNodesIds", hiddenNodesIds);`
         * when the user hides a node from the context menu, but it did not work.
         *
         * But a workaround has been found.
         *
         * If you always call `Renderer1.setHiddenNodes([])` before calling `Renderer1.setHiddenNodes(["NODE_1"])`, then the widget will be updated.
         * So the workaround for Studio developers is to always call `Renderer1.setHiddenNodes([]); Renderer1.setHiddenNodes(["NODE_1"]);`.
         *
         * This was discussed with @sbstnkll and decided to leave it at the level of a documented bug for now.
         * If you know the solution for it, please feel free to suggest one.
         *
         * It also applies to `setSelectedNodes` and `setSelectedMarker`, because they also may be changed from inside the widget.
         */
        setHiddenNodes: {
          path: "defaultHiddenNodesIds",
          type: "array",
        },
        setSelectedNodes: {
          path: "defaultSelectedNodesIds",
          type: "array",
        },
        setSelectedMarker: {
          path: "defaultSelectedMarkerId",
          type: "string",
        },
        setCollections: {
          path: "defaultCollections",
          type: "array",
        },
        setActiveCollectionId: {
          path: "defaultActiveCollectionId",
          type: "string",
        },
        setIsForcedLoading: {
          path: "isForcedLoading",
          type: "boolean",
        },
      },
    };
  }

  getWidgetView() {
    const {
      defaultActiveCollectionId,
      defaultCollections,
      isContextMenuVisible,
      isForcedLoading,
      isHideVisible,
      isLoading,
      isShowAllVisible,
      isShowOnlyVisible,
      progressBarFillColor,
    } = this.props;

    return (
      <RendererComponent
        defaultActiveCollectionId={defaultActiveCollectionId ?? ""}
        defaultCollections={defaultCollections ?? []}
        defaultSelectedMarkerId={this.props.defaultSelectedMarkerId}
        defaultSelectedNodesIds={this.props.defaultSelectedNodesIds}
        generalStyles={this.props.generalStyles}
        hiddenNodesIds={this.props.defaultHiddenNodesIds}
        /*
          We check if the values of isHideVisible, isShowAllVisible, isShowOnlyVisible,
          and isContextMenuVisible are undefined, as older widgets might not have these properties.
          By default, we want to show the context menu and all its elements in such cases
          */
        isContextMenuVisible={
          isContextMenuVisible === undefined ? true : isContextMenuVisible
        }
        isForcedLoading={isForcedLoading}
        isHideVisible={isHideVisible === undefined ? true : isHideVisible}
        isLoading={isLoading}
        isShowAllVisible={
          isShowAllVisible === undefined ? true : isShowAllVisible
        }
        isShowOnlyVisible={
          isShowOnlyVisible === undefined ? true : isShowOnlyVisible
        }
        markers={this.props.markers}
        markersStyle={this.props.markerStyles}
        modelSceneGraphUrl={this.props.modelSceneGraphUrl}
        modelStyles={this.props.modelStyles}
        onHiddenNodesChange={this.updateHiddenNodesIds}
        onMarkerSelected={this.updateSelectedMarkerId}
        onModelLoaded={this.onModelLoaded}
        onNodeSelectionChange={this.onNodeSelectionChange}
        onSelectedGroupsChange={this.onGroupsSelectionChange}
        pills={this.props.pills}
        progressBarFillColor={
          // Old widgets have progressBarFillColor equal undefined, we need some default value
          progressBarFillColor ? progressBarFillColor : "#553DE9"
        }
        updateLoadingState={this.updateLoadingState}
        widgetId={this.props.widgetId}
      />
    );
  }
}

export default RendererWidget;
