import type { WidgetProperties } from "../../../selectors/propertyPaneSelectors";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import { AutocompleteDataType } from "../../../utils/autocomplete/AutocompleteDataType";

import { ReduxActionTypes } from "@appsmith/constants/ReduxActionConstants";
import { selectedStepValidation } from "./index";

export const contentConfig = [
  {
    sectionName: "Data",
    children: [
      {
        propertyName: "stepsObj",
        isJSConvertible: false,
        label: "Steps",
        helpText: "Steps",
        controlType: "STEPS_INPUT",
        isBindProperty: false,
        isTriggerProperty: false,
        updateRelatedWidgetProperties: (
          propertyPath: string,
          propertyValue: string,
          props: WidgetProperties,
        ) => {
          const propertyPathSplit = propertyPath.split(".");
          const property = propertyPathSplit.pop();
          if (property === "label") {
            const itemId = propertyPathSplit.pop() || "";
            const item = props.stepsObj[itemId];
            if (item) {
              return [
                {
                  widgetId: item.widgetId,
                  updates: {
                    modify: {
                      stepName: propertyValue,
                    },
                  },
                },
              ];
            }
          }
          return [];
        },
        panelConfig: {
          editableTitle: true,
          titlePropertyName: "label",
          panelIdPropertyName: "id",
          updateHook: (
            props: any,
            propertyPath: string,
            propertyValue: string,
          ) => {
            return [
              {
                propertyPath,
                propertyValue,
              },
            ];
          },
          children: [
            {
              sectionName: "General",
              children: [
                {
                  propertyName: "isVisible",
                  label: "Visible",
                  helpText: "Controls the visibility of the tab",
                  controlType: "SWITCH",
                  useValidationMessage: true,
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: { type: ValidationTypes.BOOLEAN },
                },
                {
                  propertyName: "isOptional",
                  label: "Optional step",
                  helpText: "Defines if the step is optional",
                  controlType: "SWITCH",
                  useValidationMessage: true,
                  isJSConvertible: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: { type: ValidationTypes.BOOLEAN },
                },
                {
                  propertyName: "subTitle",
                  label: "Step subtitle",
                  helpText: "Short subtitle of the step",
                  controlType: "INPUT_TEXT",
                  useValidationMessage: true,
                  isBindProperty: true,
                  isTriggerProperty: false,
                  validation: { type: ValidationTypes.TEXT },
                },
              ],
            },
          ],
        },
      },
      {
        propertyName: "defaultStep",
        helpText: "Selects a step name specified by default",
        placeholderText: "Step 1",
        label: "Default step",
        controlType: "INPUT_TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.FUNCTION,
          params: {
            fn: selectedStepValidation,
            expected: {
              type: "Step Name (string)",
              example: "Step 1",
              autocompleteDataType: AutocompleteDataType.STRING,
            },
          },
        },
        dependencies: ["stepsObj", "steps"],
      },
    ],
  },
  {
    sectionName: "General",
    children: [
      {
        propertyName: "isVisible",
        label: "Visible",
        helpText: "Controls the visibility of the widget",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        helpText: "Enables scrolling for content inside the widget",
        propertyName: "shouldScrollContents",
        label: "Scroll contents",
        controlType: "SWITCH",
        isBindProperty: false,
        isTriggerProperty: false,
      },
      {
        propertyName: "shouldShowSteps",
        helpText:
          "Hides the steps so that different widgets can be displayed based on the default step",
        label: "Show steps",
        controlType: "SWITCH",
        isBindProperty: false,
        isTriggerProperty: false,
        postUpdateAction: ReduxActionTypes.CHECK_CONTAINERS_FOR_AUTO_HEIGHT,
      },
    ],
  },
  {
    sectionName: "Events",
    children: [
      {
        helpText: "Trigger on selected step change",
        propertyName: "onStepSelected",
        label: "onStepSelected",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
    ],
  },
];
