import { ReduxActionTypes } from "@appsmith/constants/ReduxActionConstants";
import { ValidationTypes } from "constants/WidgetValidation";
import { Validations } from "../../../utils/validation/common";

export const styleConfig = [
  {
    sectionName: "Color",
    children: [
      {
        helpText: "Use a html color name, HEX, RGB or RGBA value",
        propertyName: "backgroundColor",
        label: "Background color",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: Validations.COLOR,
      },
      {
        helpText: "Use a html color name, HEX, RGB or RGBA value",
        propertyName: "borderColor",
        label: "Border color",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: Validations.COLOR,
      },
    ],
  },
  {
    sectionName: "Border and shadow",
    children: [
      {
        helpText: "Enter value for border width",
        propertyName: "borderWidth",
        label: "Border width",
        placeholderText: "Enter value in px",
        controlType: "INPUT_TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.NUMBER },
        postUpdateAction: ReduxActionTypes.CHECK_CONTAINERS_FOR_AUTO_HEIGHT,
      },
      {
        helpText: "Rounds the corners of the widgets's outer border edge",
        propertyName: "borderRadius",
        label: "Border radius",
        controlType: "BORDER_RADIUS_OPTIONS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        helpText:
          "Enables you to cast a drop shadow from the frame of the widget",
        propertyName: "boxShadow",
        label: "Box shadow",
        controlType: "BOX_SHADOW_OPTIONS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
  {
    sectionName: "Layout",
    children: [
      {
        helpText: "Spacing between layers in the graph",
        propertyName: "layerSpacing",
        label: "Layer spacing",
        placeholderText: "150",
        controlType: "INPUT_TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.NUMBER,
          params: {
            natural: true,
            min: 1,
            max: 500,
          },
        },
      },
      {
        helpText: "Spacing between nodes in the graph",
        propertyName: "nodeSpacing",
        label: "Node spacing",
        placeholderText: "100",
        controlType: "INPUT_TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.NUMBER,
          params: {
            natural: true,
            min: 1,
            max: 500,
          },
        },
      },
      {
        helpText: "Spacing between nodes within a group",
        propertyName: "groupSpacing",
        label: "Group spacing",
        placeholderText: "20",
        controlType: "INPUT_TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.NUMBER,
          params: {
            natural: true,
            min: 1,
            max: 500,
          },
        },
      },
    ],
  },
];
