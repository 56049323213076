import { callEin, gql } from "spread";

export interface CadModelsResponse {
  cadModels: {
    items: {
      id: string;
      name: string;
      payloadURLs: string[];
    }[];
  };
}

export async function requestCadModels() {
  return callEin<CadModelsResponse>(gql`
    query Models {
      cadModels {
        items {
          id
          name
          payloadURLs
        }
      }
    }
  `);
}

export async function mockRequestCadModels() {
  return new Promise<CadModelsResponse>((resolve) => {
    setTimeout(() => {
      resolve({
        cadModels: {
          items: [
            {
              id: "816fe696-2c19-4b65-b470-3521e0e0fb1b",
              name: "CoffeeMaker",
              payloadURLs: [
                "https://local.spread.ai/api/download/cad-models/816fe696-2c19-4b65-b470-3521e0e0fb1b/scene_graph/1.binge",
                "https://local.spread.ai/api/download/cad-models/816fe696-2c19-4b65-b470-3521e0e0fb1b/scene_graph/model.binma",
                "https://local.spread.ai/api/download/cad-models/816fe696-2c19-4b65-b470-3521e0e0fb1b/scene_graph/scene_graph.json",
              ],
            },
          ],
        },
      });
    }, 1000);
  });
}
