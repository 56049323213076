import { equals, has, nth, pick, prop, uniq, uniqBy } from "ramda";
import type { ComponentType } from "../models/ElectricPart";
import { DiagramObjectCategories } from "../DiagramObjectCategories";
import type {
  AnnotatedConnection,
  AnnotatedSignalPathArray,
  AnnotatedTerminal,
  DiagramConnection,
  DiagramData,
  DiagramTerminal,
  TerminalOfConnection,
} from "./types";
import type { DiagramConnector, DiagramNode, DiagramPort } from "../index";
import { getPartTitle } from "../models/ElectricPart";
import type {
  ColorInfo,
  Connection,
  SignalPathArray,
  SignalPathItem,
  Terminal,
} from "../models/SignalPath";
import {
  generateSignalPathId,
  isConnection,
  isTerminal,
} from "../models/SignalPath";
import { isAnnotatedConnection, isAnnotatedTerminal } from "./types";

type DiagramObjectSelectionState = "neutral" | "highlighted" | "selected";
export const linkCrossSectionToPortIdSuffix = "-link-cross-section-to-port-id";

const getNodeCategory = (
  componentType: ComponentType,
  isStart: boolean,
  isEnd: boolean,
): DiagramObjectCategories => {
  if (isStart) {
    return DiagramObjectCategories.START_OBJECT;
  }
  if (isEnd) {
    return DiagramObjectCategories.END_OBJECT;
  }
  switch (componentType) {
    case "CuttingPoint":
    default:
      return DiagramObjectCategories.CUTTING_POINT;
  }
};
const getSelectionState = (
  isItemSelected: boolean,
  isContainingSignalPathSelected: boolean,
): DiagramObjectSelectionState => {
  if (isItemSelected) {
    return "selected";
  }

  if (isContainingSignalPathSelected) {
    return "highlighted";
  }

  return "neutral";
};

const getPorts = (terminals: AnnotatedTerminal[]): DiagramTerminal[] => {
  const allPorts: DiagramTerminal[] = terminals.map((terminal) => {
    const terminalName = `${terminal.terminalName}`;
    const isPortSelected = terminal.selectedTerminalId === terminal.id;
    const isContainingSignalPathSelected = terminals
      .filter((item) => item.id === terminal.id)
      .some((item) => item.isContainingSignalPathSelected);

    const port: DiagramTerminal = {
      terminalName,
      signalName: terminal.connection.signalName,
      id: terminal.id,
      metadata: {
        containingSignalPath: terminal.signalPath,
        signalPathItem: terminal.signalPathItem,
      },
      selectionState: getSelectionState(
        isPortSelected,
        isContainingSignalPathSelected,
      ),
      order: terminal.order,
      ...(!terminal.isStartItem &&
        !terminal.isEndItem && {
          linkCrossSectionToPort: `${terminal.id}${linkCrossSectionToPortIdSuffix}`,
        }),
      linkingPortName: terminal.id,
      linksWidth:
        terminal.numberOfConnections && terminal.numberOfConnections > 0
          ? terminal.numberOfConnections * 13
          : 10,
    };

    return port;
  });

  return uniqBy(prop("id"), allPorts);
};

const getNodes = (terminals: AnnotatedTerminal[]): DiagramNode[] => {
  const sortedTerminals = terminals.sort(
    (a: AnnotatedTerminal, b: AnnotatedTerminal) =>
      Number(a.isEndItem) - Number(b.isEndItem),
  );
  const ids = uniq(
    sortedTerminals.map((terminal) => terminal.componentReferenceId),
  );
  return ids.reduce((acc: DiagramNode[], id: string) => {
    const childTerminals = sortedTerminals.filter(
      (terminal) => terminal.componentReferenceId === id,
    );
    const connectors = uniq(
      childTerminals.map((terminal) => terminal.connectorName),
    );
    const firstChildTerminal = childTerminals[0];
    const category = getNodeCategory(
      firstChildTerminal.componentType,
      firstChildTerminal.isStartItem || false,
      firstChildTerminal.isEndItem || false,
    );
    const portsProperty =
      category === DiagramObjectCategories.START_OBJECT
        ? "portsBottom"
        : "portsTop";
    const mappedConnectors: DiagramConnector[] | undefined =
      category === DiagramObjectCategories.START_OBJECT ||
      category === DiagramObjectCategories.END_OBJECT
        ? connectors.map((connectorName, connectorIndex) => {
            const connectorTerminals = childTerminals.filter(
              (terminal) => terminal.connectorName === connectorName,
            );
            return {
              isFirst: connectorIndex === 0,
              connectorName,
              [portsProperty]: getPorts(connectorTerminals),
              selectionState: getSelectionState(
                firstChildTerminal.isComponentSelected,
                connectorTerminals.some(
                  (terminal) => terminal.isContainingSignalPathSelected,
                ),
              ),
            };
          })
        : undefined;
    const getCuttingPointPorts = (): {
      portsTop?: DiagramPort[];
      portsBottom?: DiagramPort[];
    } => {
      if (category !== DiagramObjectCategories.CUTTING_POINT) {
        return {};
      }
      const ports = getPorts(childTerminals);
      return {
        portsTop: ports,
        portsBottom: ports.map((port: DiagramPort) => ({
          ...port,
          linkingPortName: port.linkCrossSectionToPort || "",
        })),
      };
    };
    const terminalWithDiagnosisId = childTerminals.find(
      (terminal) => !!terminal.diagnosisId,
    );

    const node: DiagramNode = {
      id,
      terminalLabel: terminalWithDiagnosisId
        ? getPartTitle(terminalWithDiagnosisId)
        : id,
      terminalDescription: childTerminals.length
        ? firstChildTerminal.description
        : "",
      category,
      connectors: mappedConnectors,
      [portsProperty]: mappedConnectors
        ? mappedConnectors
            .map((connector) => connector[portsProperty])
            .reduce((portA = [], portB = []) => portA.concat(portB))
        : getPorts(childTerminals),
      ...getCuttingPointPorts(),
      selectionState: getSelectionState(
        firstChildTerminal.isComponentSelected,
        childTerminals.some(
          (terminal) => terminal.isContainingSignalPathSelected,
        ),
      ),
    };
    return [...acc, node];
  }, []);
};

const getColors = (colors: ColorInfo[]) => {
  return colors.map((color) => color.name.charAt(0)).join("/");
};

const isDuplicatedConnection = (
  diagramConnections: DiagramConnection[],
  diagramConnection: DiagramConnection,
) => {
  const getConnectionUniqueProperties = ({
    connectionDetails,
    from,
    fromPort,
    to,
    toPort,
  }: DiagramConnection) => ({
    from,
    to,
    connectionDetails,
    fromPort,
    toPort,
  });
  const mapped = diagramConnections.map((item) =>
    getConnectionUniqueProperties(item),
  );
  const reducedConnection = getConnectionUniqueProperties(diagramConnection);
  return Boolean(mapped.find((item) => equals(item, reducedConnection)));
};

const getLinks = (
  connections: AnnotatedConnection[],
  nodes: DiagramNode[],
): DiagramConnection[] => {
  return connections.reduce(
    (acc: DiagramConnection[], connection: AnnotatedConnection) => {
      const id = `${connection.id}`;
      // get ports
      const getNode = (connectionOrigin: "connectionFrom" | "connectionTo") => {
        return nodes.find(
          (node) =>
            node.id === connection[connectionOrigin]?.componentReferenceId,
        );
      };
      const nodeFromPort = getNode("connectionFrom");
      const fromPort = nodeFromPort?.[
        nodeFromPort.category === DiagramObjectCategories.START_OBJECT
          ? "portsBottom"
          : "portsTop"
      ]?.find((port) => port.id === connection.connectionFrom?.id);
      const toPort = getNode("connectionTo")?.portsTop?.find(
        (port) => port.id === connection.connectionTo?.id,
      );

      const colors = connection.colors.map((color) => color.hexCode);
      const isContainingSignalPathSelected = connections
        .filter((item) => item.id === connection.id)
        .some((item) => item.isContainingSignalPathSelected);

      const diagramConnection: DiagramConnection = {
        id: id.toString(),
        connectionId: connection.id,
        from: connection.connectionFrom?.componentReferenceId,
        to: connection.connectionTo?.componentReferenceId,
        connectionDetails: `${connection.id}    ${
          connection.crossSectionArea
        }    ${getColors(connection.colors)}`,
        fromPort: has("linkCrossSectionToPort")(fromPort)
          ? fromPort?.linkCrossSectionToPort
          : fromPort?.linkingPortName,
        toPort: toPort?.linkingPortName,
        colors,
        metadata: {
          signalPathItem: connection.signalPathItem,
          containingSignalPath: connection.signalPath,
        },
        selectionState: getSelectionState(
          connection.isConnectionSelected,
          isContainingSignalPathSelected,
        ),
      };
      const isDuplicated = isDuplicatedConnection(acc, diagramConnection);
      if (isDuplicated) {
        return acc;
      }
      return [...acc, diagramConnection].sort(
        (a: DiagramConnection, b: DiagramConnection) =>
          a.id.length - b.id.length,
      );
    },
    [],
  );
};

const getTerminalOfConnection = (
  terminalOfConnection?: TerminalOfConnection,
) => {
  return terminalOfConnection
    ? {
        componentReferenceId: terminalOfConnection.componentReferenceId,
        id: terminalOfConnection.id,
      }
    : undefined;
};

const mapSignalPaths = (
  signalPaths: SignalPathArray[],
  selectedSignalPathId: string | null,
  selectedSignalPathItem: SignalPathItem | null,
  selectedComponentRefId: string | null,
): { terminals: AnnotatedTerminal[]; connections: AnnotatedConnection[] } => {
  const startTerminals = signalPaths
    .map((signalPath) => nth(0, signalPath) || [])
    .flat();
  const startTerminalsComponentReferenceIds = startTerminals
    .filter(isTerminal)
    .map((terminal) => terminal.componentReferenceId);
  const annotatedSignalPaths: AnnotatedSignalPathArray[] = signalPaths.map(
    (path: SignalPathArray) => {
      const currentSignalPathId = generateSignalPathId(path);
      const isContainingSignalPathSelected =
        selectedSignalPathId === currentSignalPathId;

      return path.map((item: SignalPathItem, index: number) => {
        if (isTerminal(item)) {
          const selectedTerminal =
            selectedSignalPathItem && isTerminal(selectedSignalPathItem)
              ? selectedSignalPathItem
              : undefined;
          const isStartItem = startTerminalsComponentReferenceIds.includes(
            item.componentReferenceId,
          );
          const isEndItem = index + 1 === path.length;
          const connectionIndex = isEndItem ? index - 1 : index + 1;
          const connectionId = path[connectionIndex]?.id;
          const signalName =
            (
              path.find((findItem) => findItem.id === connectionId) as
                | Connection
                | undefined
            )?.signalName || "";
          const annotatedTerminal: AnnotatedTerminal = {
            ...item,
            signalPath: path,
            signalPathItem: item,
            isContainingSignalPathSelected,
            isComponentSelected:
              selectedComponentRefId === item.componentReferenceId,
            selectedTerminalId: selectedTerminal?.id,
            isStartItem,
            isEndItem,
            connection: {
              id: connectionId,
              signalName,
            },
            // every second signal path item is a terminal,
            // this formula gives their 1-based order
            order: index / 2 + 1,
            diagnosisId: item.diagnosisId,
          };

          return annotatedTerminal;
        }
        if (isConnection(item)) {
          const selectedConnection =
            selectedSignalPathItem && isConnection(selectedSignalPathItem)
              ? selectedSignalPathItem
              : undefined;
          const connectionFrom =
            index - 1 >= 0 ? (path[index - 1] as Terminal) : undefined;
          const connectionTo =
            index + 1 <= path.length
              ? (path[index + 1] as Terminal)
              : undefined;

          const annotatedConnection: AnnotatedConnection = {
            ...item,
            signalPath: path,
            signalPathItem: item,
            connectionFrom: getTerminalOfConnection(connectionFrom),
            connectionTo: getTerminalOfConnection(connectionTo),
            isContainingSignalPathSelected,
            isConnectionSelected: selectedConnection?.id === item.id,
          };

          return annotatedConnection;
        }
        return item;
      });
    },
  );
  const terminals: AnnotatedTerminal[] = annotatedSignalPaths
    .flat()
    .filter(isAnnotatedTerminal);
  const connections: AnnotatedConnection[] = annotatedSignalPaths
    .flat()
    .filter(isAnnotatedConnection);
  // add number of connections to terminals
  const uniqueConnections = uniqBy(
    pick(["connectionFrom", "connectionTo"]),
    connections,
  );
  const mappedTerminals = terminals.map((terminal: AnnotatedTerminal) => {
    const numberOfConnections = uniqueConnections.filter(
      (connection: AnnotatedConnection) =>
        connection.connectionFrom?.id === terminal.id,
    ).length;
    return { ...terminal, numberOfConnections };
  });
  return {
    terminals: mappedTerminals,
    connections,
  };
};

export const getDiagramData = (
  signalPaths: SignalPathArray[],
  selectedSignalPath: SignalPathArray | null,
  selectedSignalPathItem: SignalPathItem | null,
  selectedComponentRefId: string | null,
): DiagramData => {
  if (signalPaths.length === 0) {
    return { nodes: [], links: [] };
  }

  const selectedSignalPathId = selectedSignalPath
    ? generateSignalPathId(selectedSignalPath)
    : null;
  const { connections, terminals } = mapSignalPaths(
    signalPaths,
    selectedSignalPathId,
    selectedSignalPathItem,
    selectedComponentRefId,
  );
  const nodes = getNodes(terminals);
  const links = getLinks(connections, nodes);
  return { nodes, links };
};
