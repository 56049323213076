import React, { useMemo } from "react";

import styled from "styled-components";

import type { ClusterCards, ClusterData, Link } from "../types";
import { Cluster } from "./Cluster";
import { Links } from "./links";
import { buildGraph, depthFirstSearch } from "./linkedFilterUtils";
import type { ActionPayload } from "../widget/actionsAndCallbacks";
import type { LocalizedStrings } from "../widget/localizedStrings";

interface GuidedEventChainComponentProps {
  // General
  clusters: ClusterData[];
  clustersCards: ClusterCards;
  links: Link[];
  commitAction: (action: ActionPayload) => void;
  localizedStrings: LocalizedStrings;
  // Default
  widgetId: string;
  accentColor: string;
}

const GuidedEventChainComponent = (props: GuidedEventChainComponentProps) => {
  const { clustersByColumn, clustersById, defaultClustersFilterValues } =
    useMemo(
      () =>
        props.clusters.reduce(
          (acc, cluster) => {
            const column = cluster.column || 0;
            acc.clustersByColumn[column] = acc.clustersByColumn[column] || [];
            acc.clustersByColumn[column].push(cluster);
            acc.clustersById[cluster.id] = cluster;
            acc.defaultClustersFilterValues[cluster.id] = [];
            return acc;
          },
          {
            clustersByColumn: [] as ClusterData[][],
            clustersById: {} as Record<string, ClusterData>,
            defaultClustersFilterValues: {} as Record<string, string[]>,
          },
        ),
      [props.clusters],
    );

  const cardItemToColumnMap = useMemo(
    () =>
      Object.entries(props.clustersCards).reduce(
        (acc, [clusterId, cards]) => {
          cards.forEach((card) => {
            const cluster = clustersById[clusterId];

            // If cluster is not found, we will not be able to find column for this card
            if (!cluster) return acc;

            const items = card.items || [];
            items.forEach((item) => {
              acc[item.id] = cluster.column;
            });
          });
          return acc;
        },
        {} as Record<string, number>,
      ),
    [props.clustersCards, clustersById],
  );

  // #region Filter
  const [clustersFilterValues, setClustersFilterValues] = React.useState<
    Record<string, string[]>
  >(defaultClustersFilterValues);

  const allFilterValues = useMemo(
    () => Object.values(clustersFilterValues).flat(),
    [clustersFilterValues],
  );

  // TODO: find better name to describe that all chain is unfiltered, every card is visible
  const isUnfiltered = useMemo(
    () => allFilterValues.length === 0,
    [allFilterValues],
  );

  const linksGraph = useMemo(() => buildGraph(props.links), [props.links]);

  const linkedFilteredItems = useMemo(
    () => depthFirstSearch(linksGraph, allFilterValues),
    [linksGraph, allFilterValues],
  );

  const onFilterChange = React.useCallback(
    (clusterId: string) => (filterValues: string[]) => {
      setClustersFilterValues((prev) => ({
        ...prev,
        [clusterId]: filterValues,
      }));
    },
    [],
  );
  // #endregion Filter

  const linksRerenderDeps = useMemo(
    () => [
      props.links,
      props.clusters,
      props.clustersCards,
      clustersFilterValues,
    ],
    [props.links, props.clusters, props.clustersCards, clustersFilterValues],
  );

  return (
    <Container>
      <Row>
        {clustersByColumn.map((clusters, index) => (
          <Column key={index + 1}>
            {clusters.map((cluster) => (
              <Cluster
                accentColor={props.accentColor}
                cluster={cluster}
                clusterCards={props.clustersCards[cluster.id] || []}
                commitAction={props.commitAction}
                filterValues={clustersFilterValues[cluster.id] || []}
                isUnfiltered={isUnfiltered}
                key={cluster.id}
                linkedFilterValues={linkedFilteredItems}
                localizedStrings={props.localizedStrings}
                onFilterChange={onFilterChange(cluster.id)}
                widgetId={props.widgetId}
              />
            ))}
          </Column>
        ))}
      </Row>
      <Links
        cardItemToColumnMap={cardItemToColumnMap}
        deps={linksRerenderDeps}
        links={props.links}
        widgetId={props.widgetId}
      />
    </Container>
  );
};

export default GuidedEventChainComponent;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

const Column = styled.div`
  display: flex;
  width: 248px;
  flex-direction: column;
  gap: 24px;
`;

const Container = styled.div`
  cursor: auto;
  display: flex;
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%;
  // border-width: 1px;
`;
