import React from "react";
import styled from "styled-components";
import { Tooltip } from "design-system";
import type { LegendIcon } from "./IconComponent";
import { IconComponent } from "./IconComponent";

interface LegendItemProps {
  id: string;
  color: string;
  label: string;
  icon?: string;
  height: number;
  isSelected: boolean;
  onClick: (id: string) => void;
}

export interface LegendItem {
  id: string;
  color: string;
  label: string;
  icon?: string | LegendIcon;
}

export const LegendItemComponent: React.FC<LegendItemProps> = ({
  color,
  height,
  icon,
  id,
  isSelected,
  label,
  onClick,
}) => {
  return (
    <LegendItemContainer height={height} onClick={() => onClick(id)}>
      <IconContainer height={14} width={25}>
        <IconComponent
          icon={icon}
          isSelected={isSelected}
          strokeColor={color}
        />
      </IconContainer>

      <Tooltip content={label}>
        <Label isSelected={isSelected}>{label}</Label>
      </Tooltip>
    </LegendItemContainer>
  );
};

const LegendItemContainer = styled.div<{
  height: number;
}>`
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 5px;
  height: ${({ height }) => `${height}px`};
  min-height: ${({ height }) => `${height}px`};
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
`;

const IconContainer = styled.div<{
  width: number;
  height: number;
}>`
  flex-shrink: 0;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background-color: ${({ color }) => color};
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.span<{ isSelected: boolean }>`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: ${({ isSelected }) => (isSelected ? "#333333" : "#CCCCCC")};
`;
