import React from "react";

const FocusIcon = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M2.6 6.2C2.93 6.2 3.2 5.93 3.2 5.6V3.2H5.6C5.93 3.2 6.2 2.93 6.2 2.6C6.2 2.27 5.93 2 5.6 2H2.6C2.27 2 2 2.27 2 2.6V5.6C2 5.93 2.27 6.2 2.6 6.2ZM5.6 6.8C5.6 6.47 5.33 6.2 5 6.2C4.832 6.2 4.682 6.266 4.574 6.374L3.374 7.574C3.266 7.682 3.2 7.832 3.2 8C3.2 8.168 3.266 8.318 3.374 8.426L4.574 9.626C4.682 9.734 4.832 9.8 5 9.8C5.33 9.8 5.6 9.53 5.6 9.2C5.6 9.032 5.534 8.882 5.426 8.774L4.646 8L5.42 7.226C5.534 7.118 5.6 6.968 5.6 6.8ZM6.8 5.6C6.968 5.6 7.118 5.534 7.226 5.426L8 4.646L8.774 5.42C8.882 5.534 9.032 5.6 9.2 5.6C9.53 5.6 9.8 5.33 9.8 5C9.8 4.832 9.734 4.682 9.626 4.574L8.426 3.374C8.318 3.266 8.168 3.2 8 3.2C7.832 3.2 7.682 3.266 7.574 3.374L6.374 4.574C6.266 4.682 6.2 4.832 6.2 5C6.2 5.33 6.47 5.6 6.8 5.6ZM5.6 12.8H3.2V10.4C3.2 10.07 2.93 9.8 2.6 9.8C2.27 9.8 2 10.07 2 10.4V13.4C2 13.73 2.27 14 2.6 14H5.6C5.93 14 6.2 13.73 6.2 13.4C6.2 13.07 5.93 12.8 5.6 12.8ZM10.4 9.2C10.4 9.53 10.67 9.8 11 9.8C11.168 9.8 11.318 9.734 11.426 9.626L12.626 8.426C12.734 8.318 12.8 8.168 12.8 8C12.8 7.832 12.734 7.682 12.626 7.574L11.426 6.374C11.318 6.266 11.168 6.2 11 6.2C10.67 6.2 10.4 6.47 10.4 6.8C10.4 6.968 10.466 7.118 10.574 7.226L11.354 8L10.58 8.774C10.466 8.882 10.4 9.032 10.4 9.2ZM13.4 2H10.4C10.07 2 9.8 2.27 9.8 2.6C9.8 2.93 10.07 3.2 10.4 3.2H12.8V5.6C12.8 5.93 13.07 6.2 13.4 6.2C13.73 6.2 14 5.93 14 5.6V2.6C14 2.27 13.73 2 13.4 2ZM9.2 10.4C9.032 10.4 8.882 10.466 8.774 10.574L8 11.354L7.226 10.58C7.118 10.466 6.968 10.4 6.8 10.4C6.47 10.4 6.2 10.67 6.2 11C6.2 11.168 6.266 11.318 6.374 11.426L7.574 12.626C7.682 12.734 7.832 12.8 8 12.8C8.168 12.8 8.318 12.734 8.426 12.626L9.626 11.426C9.734 11.318 9.8 11.168 9.8 11C9.8 10.67 9.53 10.4 9.2 10.4ZM13.4 9.8C13.07 9.8 12.8 10.07 12.8 10.4V12.8H10.4C10.07 12.8 9.8 13.07 9.8 13.4C9.8 13.73 10.07 14 10.4 14H13.4C13.73 14 14 13.73 14 13.4V10.4C14 10.07 13.73 9.8 13.4 9.8Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default FocusIcon;
