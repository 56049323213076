import * as go from "gojs";

export const getAlignValue = (
  hierarchicalModeAlignUpperLeft: boolean,
  hierarchicalModeAlignUpperRight: boolean,
  hierarchicalModeAlignLowerLeft: boolean,
  hierarchicalModeAlignLowerRight: boolean,
) => {
  const align = [
    {
      checked: hierarchicalModeAlignUpperLeft,
      value: go.LayeredDigraphLayout.AlignUpperLeft,
    },
    {
      checked: hierarchicalModeAlignUpperRight,
      value: go.LayeredDigraphLayout.AlignUpperRight,
    },
    {
      checked: hierarchicalModeAlignLowerLeft,
      value: go.LayeredDigraphLayout.AlignLowerLeft,
    },
    {
      checked: hierarchicalModeAlignLowerRight,
      value: go.LayeredDigraphLayout.AlignLowerRight,
    },
  ];

  let alignOption = 0;
  for (let i = 0; i < align.length; i++) {
    if (align[i].checked) alignOption = alignOption | align[i].value;
  }

  return alignOption;
};
