/* eslint-disable no-param-reassign */
import * as go from "gojs";

import type {
  DiagramLink,
  DiagramNode,
  DiagramObjectSelectionState,
  DiagramPort,
} from "./index";
import type {
  Colors,
  DiagramEvents,
  Fonts,
  FontWeights,
} from "./makeInitDiagram";
import { registerToplessRectangleShape } from "./shapes/ToplessRectangle";
import { registerBottomlessRectangleShape } from "./shapes/BottomlessRectangle";
import { registerCuttingPointShape } from "./shapes/CuttingPoint";
import {
  makeMultiColorLinkBorderShape,
  makeMultiColorLinkDashShape,
  MultiColorLink,
} from "./MultiColorLink";

registerToplessRectangleShape();
registerBottomlessRectangleShape();
registerCuttingPointShape();

type NodePosition = "start" | "end";
interface PortContentPanelRenderingOptions {
  putSignalNameSecond: boolean;
  noSignalName: boolean;
  noMouseEvents: boolean;
  noMargin: boolean;
  isTransparent: boolean;
  withBottomMarginForPort: boolean;
}

type GoClickHandler = (event: go.InputEvent, object: go.GraphObject) => void;
function isStart(position: NodePosition): position is "start" {
  return position === "start";
}

const isShape = (object: go.GraphObject): object is go.Shape =>
  object instanceof go.Shape;

const isPanel = (object: go.GraphObject): object is go.Panel =>
  object instanceof go.Panel;

const isItemInEvidence = (objectData: DiagramPort | DiagramLink) =>
  objectData.selectionState !== "neutral";

const createStateColorSelector =
  (colorMap: Record<DiagramObjectSelectionState, string | null>) =>
  (state: DiagramObjectSelectionState) =>
    colorMap[state];

// Put nodes always in front of links
const Z_ORDERS = {
  link: 1,
  node: 2,
};

const getSizes = () => {
  // independent
  const connectorNameWidth = 40;
  const connectorNameRightOffset = 8;
  const spacingBetweenPorts = 100;

  const _startNodePortsPadding = {
    horizontal: 30,
    top: 26,
    bottom: 4,
  };

  const _endNodePortsPadding = {
    horizontal: 8,
    top: 0,
    bottom: 75,
  };

  /// borders
  const portsPanelBorderWidth = 4;

  // dependent
  const spacingBetweenConnectors =
    spacingBetweenPorts - (connectorNameWidth + connectorNameRightOffset * 2);

  const portsAndDescriptionPanelsHorizontalMargin = connectorNameWidth;

  const startNodeConnectorsContainerLeftMargin =
    portsAndDescriptionPanelsHorizontalMargin +
    portsPanelBorderWidth +
    _startNodePortsPadding.horizontal -
    (connectorNameWidth + connectorNameRightOffset * 2);

  const startNodePortsPadding = new go.Margin(
    _startNodePortsPadding.top,
    _startNodePortsPadding.horizontal,
    _startNodePortsPadding.bottom,
    _startNodePortsPadding.horizontal,
  );

  const endNodePortsPadding = new go.Margin(
    _endNodePortsPadding.top,
    _endNodePortsPadding.horizontal,
    _endNodePortsPadding.bottom,
    _endNodePortsPadding.horizontal,
  );

  return {
    connectorNameWidth,
    connectorNameRightOffset,
    spacingBetweenPorts,
    spacingBetweenConnectors,
    portsAndDescriptionPanelsHorizontalMargin,
    startNodeConnectorsContainerLeftMargin,
    portsPanelBorderWidth,
    startNodePortsPadding,
    endNodePortsPadding,
  } as const;
};

const SIZES = getSizes();

export const makeTemplates = (
  fonts: Fonts,
  fontWeights: FontWeights,
  colors: Colors,
  events: DiagramEvents,
  backgroundColor: string,
) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const $ = go.GraphObject.make;

  const onComponentClick: GoClickHandler = (event, object) => {
    const component = object.part?.data as DiagramNode;
    events.onComponentClick(component);
  };
  const onLinkClick: GoClickHandler = (event, object) => {
    const link = object.part?.data as DiagramLink;
    events.onConnectionClick(link);
  };

  const fontStyles = {
    body: `${fontWeights.regular} 14px ${fonts.openSans}`,
    bodyBold: `${fontWeights.bold} 14px ${fonts.openSans}`,
    bodySmall: `${fontWeights.regular} 11px ${fonts.openSans}`,
    bodySmallBold: `${fontWeights.bold} 11px ${fonts.openSans}`,
    buttonSmall: `${fontWeights.bold} 11px ${fonts.openSans}`,
  };

  const nodeShadowHoverInitialProps: Pick<
    go.Part,
    "isShadowed" | "shadowOffset" | "shadowColor" | "shadowBlur"
  > = {
    isShadowed: false,
    shadowOffset: new go.Point(0, 0),
    shadowColor: colors.diagram.shadow,
    shadowBlur: 8,
  };

  const setupPartShadowOnHover = (): Pick<
    go.Part,
    "mouseEnter" | "mouseLeave"
  > => ({
    mouseEnter(event, node) {
      if (node.part) {
        node.part.isShadowed = true;
      }
    },
    mouseLeave(event, node) {
      if (node.part) {
        node.part.isShadowed = false;
      }
    },
  });

  // Avoid triggering mouseEnter/mouseLeave events for some objects,
  // so they don't mess with the hover effects shadow of their parents.
  const disableMouseEventsProps: Pick<go.Panel, "pickable"> = {
    pickable: false,
  };

  const hideSelectionAdornment = {
    selectionAdornmentTemplate: $(go.Adornment),
  };

  const portFillColorMap: Record<DiagramObjectSelectionState, string> = {
    selected: colors.bgActive,
    highlighted: colors.bgHighlight,
    neutral: colors.tertiary.white,
  };

  const getPortFillColorFromState = createStateColorSelector(portFillColorMap);
  const getComponentStrokeColorFromState = createStateColorSelector({
    selected: colors.random.orangeHard,
    highlighted: colors.highlight,
    neutral: colors.lightGrey2,
  });
  const getLinkClickTargetStrokeColorFromState = createStateColorSelector({
    ...portFillColorMap,
    neutral: null,
  });

  const portIcon = $(
    go.Panel,
    "Vertical",
    new go.Binding("width", "linksWidth"),
    new go.Binding("portId", "linkingPortName"),
    { fromSpot: go.Spot.BottomSide },
    $(go.Shape, "Rectangle", {
      width: 8,
      height: 12,
      fill: colors.grey,
      strokeWidth: 0,
    }),
    $(go.Shape, {
      geometry: go.Geometry.parse("M 0,0 L 0,6 15,6 15,0", false),
      strokeWidth: 1,
      stroke: colors.grey,
    }),
    $(go.Shape, "Rectangle", {
      width: 1,
      height: 9,
      fill: colors.grey,
      strokeWidth: 0,
    }),
  );

  const reversePortIcon = $(
    go.Panel,
    "Vertical",
    new go.Binding("width", "linksWidth"),
    new go.Binding("portId", "linkingPortName"),
    {
      toSpot: go.Spot.TopSide,
      fromSpot: go.Spot.BottomSide,
    },
    $(go.Shape, "Rectangle", {
      width: 1,
      height: 9,
      fill: colors.grey,
      strokeWidth: 0,
    }),
    $(go.Shape, {
      geometry: go.Geometry.parse("M 0,6 L 0,0 15,0 15,6", false),
      strokeWidth: 1,
      stroke: colors.grey,
    }),
    $(go.Shape, "Rectangle", {
      width: 8,
      height: 12,
      fill: colors.grey,
      strokeWidth: 0,
    }),
  );

  const portIconProps = (parentPosition: NodePosition) => {
    if (isStart(parentPosition)) {
      return { fromSpot: go.Spot.BottomSide };
    }
    return { toSpot: go.Spot.TopSide };
  };

  const portContentPanel = (
    options: Partial<PortContentPanelRenderingOptions> = {},
  ) => {
    const defaultOptions: Required<PortContentPanelRenderingOptions> = {
      putSignalNameSecond: false,
      noSignalName: false,
      noMouseEvents: false,
      noMargin: false,
      isTransparent: false,
      withBottomMarginForPort: false,
    };

    const {
      isTransparent,
      noMargin,
      noMouseEvents,
      noSignalName,
      putSignalNameSecond,
      withBottomMarginForPort,
    } = {
      ...defaultOptions,
      ...options,
    };

    const templateBlocks = {
      signalName: $(
        go.TextBlock,
        {
          font: fontStyles.bodySmall,
          alignment: go.Spot.Center,
          visible: !noSignalName,
        },
        new go.Binding("text", "signalName"),
      ),
      terminalName: $(
        go.TextBlock,
        {
          font: fontStyles.bodySmallBold,
        },
        new go.Binding("text", "terminalName"),
      ),
    };

    const createHoverHandler = (stage: "mouseEnter" | "mouseLeave") => {
      const targetBackgroundColor: Record<typeof stage, string> = {
        mouseEnter: colors.tertiary.whiteDarken,
        mouseLeave: colors.tertiary.white,
      };

      return (e: go.InputEvent, panel: go.GraphObject) => {
        if (!isPanel(panel) || !panel.data) {
          return;
        }

        const port = panel.data as DiagramPort;
        if (!isItemInEvidence(port)) {
          const backgroundShape = panel.findObject(
            "PORT_BACKGROUND_SHAPE",
          ) as go.Shape;
          backgroundShape.fill = targetBackgroundColor[stage];
        }
      };
    };

    const hoverEventHandlers = noMouseEvents
      ? {}
      : {
          mouseEnter: createHoverHandler("mouseEnter"),
          mouseLeave: createHoverHandler("mouseLeave"),
        };

    const textContent = putSignalNameSecond
      ? [templateBlocks.terminalName, templateBlocks.signalName]
      : [templateBlocks.signalName, templateBlocks.terminalName];

    // with height: 0 the panel width is not calculated correctly,
    // so we:
    // - stack the port content panel on top of the port icon (with a parent Spot panel)
    // - use a height where the width is correctly calculated
    // - make port content transparent
    const transparentProps = { height: 20, opacity: 0 } as const;

    return $(
      go.Panel,
      "Spot",
      {
        ...hoverEventHandlers,
      },
      noMargin ? {} : { padding: new go.Margin(0, 10, 0, 10) },
      isTransparent ? transparentProps : {},
      $(
        go.Panel,
        "Auto",
        $(
          go.Shape,
          "RoundedRectangle",
          {
            name: "PORT_BACKGROUND_SHAPE",
            fill: colors.tertiary.white,
            strokeWidth: 1,
            stroke: colors.lightGrey3,
            shadowVisible: false,
          },
          new go.Binding("fill", "selectionState", getPortFillColorFromState),
        ),
        $(
          go.Panel,
          "Vertical",
          {
            margin: new go.Margin(4, 8, 4, 8),
            ...disableMouseEventsProps,
          },
          ...textContent,
        ),
      ),
      // Badge offset compensator
      $(go.Panel, "Auto", {
        alignment: go.Spot.TopLeft,
        alignmentFocus: go.Spot.Center,
        width: 20,
        height: 20,
      }),
      $(
        go.Panel,
        "Auto",
        {
          alignment: go.Spot.TopRight,
          alignmentFocus: go.Spot.Center,
          width: 20,
          height: 20,
        },
        new go.Binding("opacity", "selectionState", (state) =>
          state !== "neutral" ? 1 : 0,
        ),
        $(
          go.Shape,
          "Ellipse",
          {
            fill: colors.highlight,
            stroke: null,
          },
          new go.Binding("fill", "selectionState", (state) =>
            state === "selected" ? colors.random.orangeHard : colors.highlight,
          ),
        ),
        $(
          go.TextBlock,
          {
            font: fontStyles.buttonSmall,
            alignment: go.Spot.Center,
            stretch: go.GraphObject.Fill,
            textAlign: "center",
          },
          new go.Binding("text", "order"),
          new go.Binding("stroke", "selectionState", (state) =>
            state === "selected" ? colors.white : colors.black,
          ),
        ),
      ),
      ...(withBottomMarginForPort
        ? [
            $(
              go.Panel,
              { alignment: go.Spot.BottomCenter },
              $(
                go.Shape,
                {
                  strokeWidth: 0,
                  height: 0,
                  toSpot: go.Spot.TopSide,
                  margin: new go.Margin(20, 0, 0, 0),
                },
                new go.Binding("portId", "linkingPortName"),
                new go.Binding("width", "linksWidth"),
              ),
            ),
          ]
        : []),
    );
  };

  const connectorName = () =>
    $(
      go.TextBlock,
      {
        alignment: go.Spot.LeftCenter,
        alignmentFocus: new go.Spot(1, 0.5, SIZES.connectorNameRightOffset, 0),
        width: SIZES.connectorNameWidth,
        font: fontStyles.body,
        textAlign: "right",
      },
      new go.Binding("text", "connectorName"),
    );

  const connectorTemplate = (parentPosition: NodePosition) =>
    $(
      go.Panel,
      "Spot",
      new go.Binding("margin", "isFirst", (isFirst) => {
        return new go.Margin(
          0,
          0,
          0,
          isFirst ? 0 : SIZES.spacingBetweenConnectors,
        );
      }),
      $(
        go.Panel,
        "Auto",
        $(
          go.Shape,
          isStart(parentPosition) ? "ToplessRectangle" : "BottomlessRectangle",
          {
            fill: colors.tertiary.white,
            strokeWidth: 2,
            stroke: colors.lightGrey2,
            alignment: go.Spot.Center,
            shadowVisible: true,
          },
          new go.Binding(
            "stroke",
            "selectionState",
            getComponentStrokeColorFromState,
          ),
        ),
        $(
          go.Panel,
          "Horizontal",
          new go.Binding(
            "itemArray",
            isStart(parentPosition) ? "portsBottom" : "portsTop",
          ),
          {
            itemTemplate: $(
              go.Panel,
              "Spot",
              { ...portIconProps(parentPosition) },
              portContentPanel({
                isTransparent: true,
                noMouseEvents: true,
              }),
              (isStart(parentPosition)
                ? portIcon
                : reversePortIcon
              ).copyTemplate(),
            ),
          },
        ),
      ),
      connectorName(),
    );

  const portTemplate = (parentPosition: NodePosition) =>
    $(
      go.Panel,
      "Auto",
      new go.Binding("padding", "isFirst", (isFirst) => {
        return new go.Margin(0, 0, 0, isFirst ? 0 : SIZES.spacingBetweenPorts);
      }),
      $(
        go.Panel,
        "Horizontal",
        new go.Binding(
          "itemArray",
          isStart(parentPosition) ? "portsBottom" : "portsTop",
        ),
        {
          itemTemplate: portContentPanel({
            putSignalNameSecond: !isStart(parentPosition),
          }),
        },
      ),
    );

  const makeElectricComponentNode = (position: NodePosition) => {
    const templateBlocks = {
      descriptionPanel: $(
        go.Panel,
        "Vertical",
        {
          alignment: go.Spot.Left,
          ...disableMouseEventsProps,
        },
        $(
          go.TextBlock,
          {
            font: fontStyles.bodyBold,
            textAlign: "left",
            alignment: go.Spot.LeftCenter,
            shadowVisible: false,
          },
          new go.Binding("text", "terminalLabel").makeTwoWay(),
        ),
        $(
          go.TextBlock,
          {
            font: fontStyles.body,
            alignment: go.Spot.LeftCenter,
            shadowVisible: false,
          },
          new go.Binding("text", "terminalDescription").makeTwoWay(),
        ),
      ),
      portsPanel: $(
        go.Panel,
        "Auto",
        {
          alignment: go.Spot.LeftCenter,
          shadowVisible: true,
        },
        $(
          go.Shape,
          "RoundedRectangle",
          {
            fill: colors.tertiary.white,
            strokeWidth: SIZES.portsPanelBorderWidth,
            stroke: colors.lightGrey2,
          },
          new go.Binding(
            "stroke",
            "selectionState",
            getComponentStrokeColorFromState,
          ),
        ),
        $(
          go.Panel,
          "Horizontal",
          {
            padding: isStart(position)
              ? SIZES.startNodePortsPadding
              : SIZES.endNodePortsPadding,
          },
          new go.Binding("itemArray", "connectors"),
          { itemTemplate: portTemplate(position) },
        ),
      ),
      connectorsContainer: $(
        go.Panel,
        { alignment: go.Spot.Left },
        isStart(position)
          ? {
              margin: new go.Margin(
                0,
                0,
                0,
                SIZES.startNodeConnectorsContainerLeftMargin,
              ),
            }
          : {},
        $(go.Panel, "Horizontal", new go.Binding("itemArray", "connectors"), {
          itemTemplate: connectorTemplate(position),
        }),
      ),
    };

    const portsAndDescriptionPanels = $(
      go.Panel,
      "Vertical",
      {
        alignment: go.Spot.Left,
        padding: new go.Margin(
          0,
          SIZES.portsAndDescriptionPanelsHorizontalMargin,
          0,
          SIZES.portsAndDescriptionPanelsHorizontalMargin,
        ),
      },
      isStart(position)
        ? [templateBlocks.descriptionPanel, templateBlocks.portsPanel]
        : [templateBlocks.portsPanel, templateBlocks.descriptionPanel],
    );

    return $(
      go.Node,
      "Vertical",
      {
        ...hideSelectionAdornment,
        zOrder: Z_ORDERS.node,
        ...nodeShadowHoverInitialProps,
        cursor: "pointer",
        shadowVisible: true,
        click: onComponentClick,
        ...setupPartShadowOnHover(),
      },
      isStart(position)
        ? [portsAndDescriptionPanels, templateBlocks.connectorsContainer]
        : [templateBlocks.connectorsContainer, portsAndDescriptionPanels],
    );
  };

  const startNodeTemplate = makeElectricComponentNode("start");
  const endNodeTemplate = makeElectricComponentNode("end");

  const cuttingPointTemplate = $(
    go.Node,
    "Horizontal",
    {
      ...hideSelectionAdornment,
      zOrder: Z_ORDERS.node,
      ...nodeShadowHoverInitialProps,
    },
    $(
      go.TextBlock,
      {
        font: fontStyles.bodyBold,
        angle: -90,
        alignment: go.Spot.LeftCenter,
        shadowVisible: false,
      },
      new go.Binding("text", "terminalLabel").makeTwoWay(),
    ),
    $(
      go.TextBlock,
      {
        font: fontStyles.body,
        angle: -90,
        alignment: go.Spot.BottomLeft,
        shadowVisible: false,
      },
      new go.Binding("text", "terminalDescription").makeTwoWay(),
    ),

    $(
      go.Panel,
      "Vertical",
      {
        margin: new go.Margin(0, 10, 0, 10),
        cursor: "pointer",
        click: onComponentClick,
      },
      $(go.Panel, "Horizontal", new go.Binding("itemArray", "portsTop"), {
        itemTemplate: portContentPanel({
          noSignalName: true,
          noMargin: true,
          withBottomMarginForPort: true,
        }),
      }),
      $(
        go.Panel,
        "Auto",
        $(
          go.Shape,
          "CuttingPoint",
          {
            fill: colors.tertiary.white,
            strokeWidth: 2,
            shadowVisible: true,
            ...setupPartShadowOnHover(),
          },
          new go.Binding(
            "stroke",
            "selectionState",
            getComponentStrokeColorFromState,
          ),
        ),
        $(
          go.Panel,
          "Horizontal",
          { ...disableMouseEventsProps },
          new go.Binding("itemArray", "portsBottom"),
          {
            itemTemplate: $(
              go.Panel,
              "Spot",
              portContentPanel({
                isTransparent: true,
                noMouseEvents: true,
                noSignalName: true,
                noMargin: true,
              }),
              reversePortIcon.copyTemplate(),
            ),
          },
        ),
      ),
    ),
  );

  const createLinkLabelBgBrush = (color: string) =>
    $(go.Brush, "Radial", {
      0: `${color}`,
      0.7: `${color}`,
      1: "rgba(248, 248, 249, 0)",
    });
  const setLinkLabelBackgroundColor = (
    rootLinkPanel: go.Panel | null,
    color: string,
  ) => {
    const labelBackground = rootLinkPanel?.findObject("LINK_LABEL_BACKGROUND");
    if (labelBackground && labelBackground instanceof go.Shape) {
      labelBackground.fill = createLinkLabelBgBrush(color);
    }
  };
  const linkTemplate = $(
    MultiColorLink,
    {
      routing: go.Link.Orthogonal,
      curve: go.Link.JumpGap,
      // TODO inspect why after gojs update this causes problems but probably midAngle is read only??? https://gojs.net/latest/api/symbols/Link.html#midAngle
      // midAngle: 30,
      // angle: 30,
      zOrder: Z_ORDERS.link,
      ...hideSelectionAdornment,
      toEndSegmentLength: 50,
      fromEndSegmentLength: 150,
    },
    $(
      go.Shape,
      {
        isPanelMain: true,
        stroke: null,
        strokeWidth: 24,
        cursor: "pointer",
        mouseEnter(e: go.InputEvent, object: go.GraphObject) {
          const link = object.part?.data as DiagramLink;
          if (!isItemInEvidence(link) && isShape(object)) {
            object.stroke = colors.lightGrey3;
            setLinkLabelBackgroundColor(object.panel, colors.lightGrey3);
          }
        },
        mouseLeave(e: go.InputEvent, object: go.GraphObject) {
          const link = object.part?.data as DiagramLink;
          if (!isItemInEvidence(link) && isShape(object)) {
            object.stroke = null;
            setLinkLabelBackgroundColor(object.panel, backgroundColor);
          }
        },
        click: onLinkClick,
      },
      new go.Binding(
        "stroke",
        "selectionState",
        getLinkClickTargetStrokeColorFromState,
      ),
    ),
    makeMultiColorLinkBorderShape(colors.lightGrey2),
    // Max colors supported: 2
    makeMultiColorLinkDashShape(),
    makeMultiColorLinkDashShape(),
    // Add as much makeMultiColorLinkDashShape(), as maximum colors should be supported
    $(
      go.Panel,
      "Auto",
      {
        segmentOffset: new go.Point(NaN, 0),
        segmentOrientation: go.Link.OrientUpright,
        segmentIndex: 0,
        ...disableMouseEventsProps,
      },
      $(
        go.Shape, // the label background, which becomes transparent around the edges
        {
          name: "LINK_LABEL_BACKGROUND",
          fill: createLinkLabelBgBrush(backgroundColor),
          stroke: null,
        },
        new go.Binding(
          "fill",
          "selectionState",
          (state: DiagramObjectSelectionState, object: go.GraphObject) => {
            const link = object.part?.data as DiagramLink;
            const color = getLinkClickTargetStrokeColorFromState(state);

            if (isItemInEvidence(link) && color) {
              return createLinkLabelBgBrush(color);
            }

            return createLinkLabelBgBrush(backgroundColor);
          },
        ),
      ),
      $(go.TextBlock, "left", new go.Binding("text", "connectionDetails"), {
        font: `${fontWeights.regular} 11px ${fonts.openSans}`,
        margin: new go.Margin(0, 20, 0, 20),
      }),
    ),
  );

  return {
    linkTemplate,
    startNodeTemplate,
    cuttingPointTemplate,
    endNodeTemplate,
  };
};
