import React from "react";
export const Focus: React.FC = () => {
  return (
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M0.6 4.2C0.93 4.2 1.2 3.93 1.2 3.6V1.2H3.6C3.93 1.2 4.2 0.93 4.2 0.6C4.2 0.27 3.93 0 3.6 0H0.6C0.27 0 0 0.27 0 0.6V3.6C0 3.93 0.27 4.2 0.6 4.2ZM3.6 4.8C3.6 4.47 3.33 4.2 3 4.2C2.832 4.2 2.682 4.266 2.574 4.374L1.374 5.574C1.266 5.682 1.2 5.832 1.2 6C1.2 6.168 1.266 6.318 1.374 6.426L2.574 7.626C2.682 7.734 2.832 7.8 3 7.8C3.33 7.8 3.6 7.53 3.6 7.2C3.6 7.032 3.534 6.882 3.426 6.774L2.646 6L3.42 5.226C3.534 5.118 3.6 4.968 3.6 4.8ZM4.8 3.6C4.968 3.6 5.118 3.534 5.226 3.426L6 2.646L6.774 3.42C6.882 3.534 7.032 3.6 7.2 3.6C7.53 3.6 7.8 3.33 7.8 3C7.8 2.832 7.734 2.682 7.626 2.574L6.426 1.374C6.318 1.266 6.168 1.2 6 1.2C5.832 1.2 5.682 1.266 5.574 1.374L4.374 2.574C4.266 2.682 4.2 2.832 4.2 3C4.2 3.33 4.47 3.6 4.8 3.6ZM3.6 10.8H1.2V8.4C1.2 8.07 0.93 7.8 0.6 7.8C0.27 7.8 0 8.07 0 8.4V11.4C0 11.73 0.27 12 0.6 12H3.6C3.93 12 4.2 11.73 4.2 11.4C4.2 11.07 3.93 10.8 3.6 10.8ZM8.4 7.2C8.4 7.53 8.67 7.8 9 7.8C9.168 7.8 9.318 7.734 9.426 7.626L10.626 6.426C10.734 6.318 10.8 6.168 10.8 6C10.8 5.832 10.734 5.682 10.626 5.574L9.426 4.374C9.318 4.266 9.168 4.2 9 4.2C8.67 4.2 8.4 4.47 8.4 4.8C8.4 4.968 8.466 5.118 8.574 5.226L9.354 6L8.58 6.774C8.466 6.882 8.4 7.032 8.4 7.2ZM11.4 0H8.4C8.07 0 7.8 0.27 7.8 0.6C7.8 0.93 8.07 1.2 8.4 1.2H10.8V3.6C10.8 3.93 11.07 4.2 11.4 4.2C11.73 4.2 12 3.93 12 3.6V0.6C12 0.27 11.73 0 11.4 0ZM7.2 8.4C7.032 8.4 6.882 8.466 6.774 8.574L6 9.354L5.226 8.58C5.118 8.466 4.968 8.4 4.8 8.4C4.47 8.4 4.2 8.67 4.2 9C4.2 9.168 4.266 9.318 4.374 9.426L5.574 10.626C5.682 10.734 5.832 10.8 6 10.8C6.168 10.8 6.318 10.734 6.426 10.626L7.626 9.426C7.734 9.318 7.8 9.168 7.8 9C7.8 8.67 7.53 8.4 7.2 8.4ZM11.4 7.8C11.07 7.8 10.8 8.07 10.8 8.4V10.8H8.4C8.07 10.8 7.8 11.07 7.8 11.4C7.8 11.73 8.07 12 8.4 12H11.4C11.73 12 12 11.73 12 11.4V8.4C12 8.07 11.73 7.8 11.4 7.8Z"
        fill="#485162"
        fillRule="evenodd"
      />
    </svg>
  );
};

Focus.displayName = "Focus";
