import get from "lodash/get";
import React from "react";
import {
  BADGE_COLORS_BY_NAME,
  DEFAULT_BADGE_COLOR_NAME,
} from "widgets/SpreadBadgeWidget/constants";
import type { SpreadBadgeWidgetProps } from "widgets/SpreadBadgeWidget/widget";
import type { PropertyUpdates } from "WidgetProvider/constants";
import type {
  PropertyPaneConfig,
  PropertyPaneControlConfig,
} from "../../../constants/PropertyControlConstants";
import { ValidationTypes } from "../../../constants/WidgetValidation";
import { BadgeColorName } from "../types";
import { CustomColorCircle, SolidColorCircle } from "./ColorCircle";

/**
 * Get a property path for a property based on another path on the same level.
 *
 * This is useful for updating a property for a column in the Table widget.
 *
 * @example
 * getSiblingPropertyPath("first.second.third", "fourth") //> "first.second.fourth"
 * getSiblingPropertyPath("noBasePath", "siblingProperty") //> "siblingProperty"
 */
export function getSiblingPropertyPath(
  propertyPath: string,
  siblingPropertyName: string,
) {
  const properties = propertyPath.split(".");
  const isNestedProperty = properties.length > 1;
  if (isNestedProperty) {
    return `${properties.slice(0, -1).join(".")}.${siblingPropertyName}`;
  }

  return siblingPropertyName;
}

const BADGE_PRESETS_OPTIONS = [
  {
    label: "Neutral",
    value: BadgeColorName.NEUTRAL,
    leftElement: (
      <SolidColorCircle color={BADGE_COLORS_BY_NAME[BadgeColorName.NEUTRAL]} />
    ),
  },
  {
    label: "Danger",
    value: BadgeColorName.DANGER,
    leftElement: (
      <SolidColorCircle color={BADGE_COLORS_BY_NAME[BadgeColorName.DANGER]} />
    ),
  },
  {
    label: "Warning",
    value: BadgeColorName.WARNING,
    leftElement: (
      <SolidColorCircle color={BADGE_COLORS_BY_NAME[BadgeColorName.WARNING]} />
    ),
  },
  {
    label: "Success",
    value: BadgeColorName.SUCCESS,
    leftElement: (
      <SolidColorCircle color={BADGE_COLORS_BY_NAME[BadgeColorName.SUCCESS]} />
    ),
  },
  {
    label: "Custom",
    value: BadgeColorName.CUSTOM,
    leftElement: <CustomColorCircle />,
  },
];

export const BADGE_STYLE_CONFIG = {
  badgeColorName: {
    propertyName: "badgeColorName",
    controlType: "DROP_DOWN",
    label: "Badge color",
    helpText: "Sets the background color of the badge",
    isBindProperty: true,
    isTriggerProperty: false,
    validation: { type: ValidationTypes.TEXT },
    options: BADGE_PRESETS_OPTIONS,
    defaultValue: DEFAULT_BADGE_COLOR_NAME,
    updateHook(
      props: SpreadBadgeWidgetProps,
      propertyPath,
      propertyValue: BadgeColorName,
    ) {
      const propertiesToUpdate: PropertyUpdates[] = [
        { propertyPath, propertyValue },
      ];

      if (propertyValue !== BadgeColorName.CUSTOM) {
        propertiesToUpdate.push({
          propertyPath: getSiblingPropertyPath(propertyPath, "badgeColor"),
          propertyValue: BADGE_COLORS_BY_NAME[propertyValue],
        });
      }

      return propertiesToUpdate;
    },
  } as PropertyPaneControlConfig,
  badgeColor: {
    propertyName: "badgeColor",
    controlType: "COLOR_PICKER",
    label: "Custom color",
    helpText: "Sets a custom background color for the badge",
    isBindProperty: true,
    isJSConvertible: true,
    isTriggerProperty: false,
    validation: { type: ValidationTypes.TEXT },
    dependencies: ["badgeColorName"],
    hidden(props: SpreadBadgeWidgetProps, propertyPath) {
      const badgeColorNamePropertyPath = getSiblingPropertyPath(
        propertyPath,
        "badgeColorName",
      );
      return get(props, badgeColorNamePropertyPath) !== BadgeColorName.CUSTOM;
    },
  } as PropertyPaneControlConfig,
};

export const propertyPaneStyleConfig: PropertyPaneConfig[] = [
  {
    sectionName: "Color",
    children: [
      BADGE_STYLE_CONFIG.badgeColorName,
      BADGE_STYLE_CONFIG.badgeColor,
    ],
  },
];
