import { LAUNCHER_PATH } from "spread/urls";

export const RUN_FLOW_FLOW_ID_ARG_NAME = "id";
export const RUN_FLOW_FLOW_ID_ARG_INDEX = 0;
export const RUN_FLOW_PARAMETERS_ARG_NAME = "parameters";
export const RUN_FLOW_PARAMETERS_ARG_INDEX = 1;

export const SPREAD_GLOBAL_FUNCTIONS = {
  "!name": "SPREAD/flows",
  "!define": {
    FlowsApi: {
      runFlow: {
        "!type": `fn(${RUN_FLOW_FLOW_ID_ARG_NAME}: string, ${RUN_FLOW_PARAMETERS_ARG_NAME}?: +Object) -> +Promise[:t=?]`,
        "!url": `${LAUNCHER_PATH}docs/using-flows/managing-flows.html#running-flows`,
        "!doc":
          "Run a flow by its ID. It returns a Promise that resolves with the flow execution result payload.",
      },
    },
  },
  spread: {
    flows: {
      "!type": "+FlowsApi",
      "!doc": "APIs to interact with SPREAD Flows.",
    },
  },
};
