import React from "react";
import { Select, Option } from "design-system";

import { useCadModelsOptions } from "./useCadModelsOptions";
import { DEFAULT_MODEL_OPTIONS } from "./constants";

interface CadDomainModelSelectorProps {
  onSelect: (value: string) => void;
  value: string;
}

export function CadDomainModelSelector({
  onSelect,
  value,
}: CadDomainModelSelectorProps) {
  const { loading, options } = useCadModelsOptions({
    defaultOptions: DEFAULT_MODEL_OPTIONS,
  });

  return (
    <Select
      isLoading={loading}
      onSelect={onSelect}
      placeholder="Select a model"
      value={value}
    >
      {options.map((option) => (
        <Option key={option.id} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
}
