import { filter, flatten, pluck } from "ramda";

import type { TreeNode, TreeNodeId } from "./Node";
import type { TreeNodeIdMap } from "./types";

export type ChildrenIdsMap = TreeNodeIdMap;

interface TraverseResult {
  id: TreeNodeId;
  hasChildren: boolean;
  children: TreeNodeId[];
}

const isLeaf = (traverseResult: TraverseResult) => !traverseResult.hasChildren;

const hasChildren = (node: TreeNode) => Boolean(node.children?.length);

const getChildIds = (traverseResult: TraverseResult[], includeAll: boolean) => {
  const results = includeAll ? traverseResult : filter(isLeaf)(traverseResult);
  const ownChildIds = pluck("id")(results);

  return ownChildIds.concat(flatten(traverseResult.map((id) => id.children)));
};

const traverse = (node: TreeNode, map: TreeNodeIdMap, includeAll: boolean) => {
  const traverseResult =
    node.children?.map((childNode) => {
      if (hasChildren(childNode)) {
        const innerTraverseResult = traverse(childNode, map, includeAll);
        const innerChildren = getChildIds(innerTraverseResult, includeAll);

        // eslint-disable-next-line no-param-reassign
        map[childNode.id] = innerChildren;
        return {
          id: childNode.id,
          hasChildren: hasChildren(childNode),
          children: innerChildren,
        };
      }

      return {
        id: childNode.id,
        hasChildren: hasChildren(childNode),
        children: [],
      };
    }) || [];

  if (hasChildren(node)) {
    // eslint-disable-next-line no-param-reassign
    map[node.id] = getChildIds(traverseResult, includeAll);
  }

  return traverseResult;
};

export const createIdToLeafIdsMap = (
  tree: TreeNode,
  includeAll = false,
): ChildrenIdsMap => {
  const map: ChildrenIdsMap = {};
  const traverseResult = traverse(tree, map, includeAll);

  map[tree.id] = getChildIds(traverseResult, includeAll);

  return map;
};
