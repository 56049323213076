import { ValidationTypes } from "../../../constants/WidgetValidation";
import type { TreeProps } from "../components/Tree";
import React from "react";
export const propertyPaneContentConfig = [
  {
    sectionName: "Data",
    children: [
      {
        helpText: "Allows users to select multiple nodes. Id must be unique",
        propertyName: "sourceData",
        label: "Source Data",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter tree nodes values",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.NESTED_OBJECT_ARRAY,
          params: {
            unique: ["id"],
            default: [],
            children: {
              type: ValidationTypes.OBJECT,
              params: {
                allowedKeys: [
                  {
                    name: "style",
                    type: ValidationTypes.OBJECT,
                    params: {
                      allowedKeys: [
                        {
                          name: "iconUrl",
                          type: ValidationTypes.TEXT,
                          params: {
                            default: "",
                          },
                        },
                        {
                          name: "opacity",
                          type: ValidationTypes.NUMBER,
                          params: {
                            default: "",
                          },
                        },
                      ],
                    },
                  },
                  {
                    name: "label",
                    type: ValidationTypes.TEXT,
                    params: {
                      default: "",
                      required: true,
                    },
                  },
                  {
                    name: "id",
                    type: ValidationTypes.TEXT,
                    params: {
                      default: "",
                    },
                  },
                  {
                    name: "children",
                    type: ValidationTypes.ARRAY,
                    required: false,
                    params: {
                      children: {
                        type: ValidationTypes.OBJECT,
                        params: {
                          allowedKeys: [
                            {
                              name: "label",
                              type: ValidationTypes.TEXT,
                              params: {
                                default: "",
                                required: true,
                              },
                            },
                            {
                              name: "id",
                              type: ValidationTypes.TEXT,
                              params: {
                                default: "",
                              },
                            },
                          ],
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
        },
      },
      {
        propertyName: "defaultSelectedIds",
        helpText: "Allows user do define array of default selected node IDs",
        label: "Selected node IDs",
        controlType: "INPUT_TEXT",
        placeholderText: '["6c947224-b7ae-4f39-978f-ebd351b1946e"]',
        inputType: "ARRAY",
        defaultValue: [],
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            children: {
              type: ValidationTypes.TEXT,
            },
          },
        },
      },
    ],
  },
  {
    sectionName: "General",
    children: [
      {
        propertyName: "selectionType",
        label: "Selection type",
        helperText: (
          <div>
            <ul>
              <br />
              <li>
                <strong>Default: </strong> Allows users to independently select
                either parent or child nodes using single selection.
              </li>
              <br />
              <li>
                <strong>Controlled: </strong> Users can select multiple nodes.
                Selecting or deselecting a parent node automatically updates all
                its child nodes. This mode includes visual feedback with
                checkboxes, indicating the selection status of all nodes
              </li>
              <br />
              <li>
                <strong>Directory: </strong> Enables users to select multiple
                parent and/or child nodes independently using the Ctrl key
                (Windows) or Command key (Mac).
              </li>
            </ul>
          </div>
        ),
        controlType: "TREE_MODE_CONTROL",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
        },
        defaultValue: "default",
      },
      {
        propertyName: "isParentNodeSelectable",
        label: "Parent node selection",
        helpText: "Allows selection of the parent node",
        controlType: "SWITCH",
        defaultValue: false,
        isBindProperty: true,
        isTriggerProperty: false,
        isJSConvertible: true,
        validation: { type: ValidationTypes.BOOLEAN },
        hidden: (props: TreeProps) => {
          return props.selectionType !== "default";
        },
        dependencies: ["selectionType"],
      },
      {
        helpText: "Search term of the tree",
        propertyName: "searchTerm",
        label: "Search term",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter search term",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
  {
    sectionName: "Events",
    children: [
      {
        helpText: "Is triggered on node selection change",
        propertyName: "onSelectionChange",
        label: "onSelectionChange",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
    ],
  },
];
