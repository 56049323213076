import React from "react";
import styled from "styled-components";

const Circle = styled.div<{ background: string }>`
  width: 90%;
  height: 90%;
  background: ${(props) => props.background};
  border-radius: 50%;
`;

const CircleContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .rc-select-selection-item & {
    width: 18px;
    height: 18px;
  }
`;

export const SolidColorCircle: React.FC<{ color: string }> = (props) => {
  return (
    <CircleContainer>
      <Circle background={props.color} />
    </CircleContainer>
  );
};

export const CustomColorCircle: React.FC = () => {
  return (
    <CircleContainer>
      <Circle background="repeating-conic-gradient(#000000 0% 25%, #ffffff 0% 50%) 50% / 10px 10px" />
    </CircleContainer>
  );
};
