import MatchedDefault from "assets/svg/spread/precedenceGraph/matched-default.svg";
import UnmatchedDefault from "assets/svg/spread/precedenceGraph/unmatched-default.svg";
import MatchedEnabled from "assets/svg/spread/precedenceGraph/matched-enabled.svg";
import UnmatchedEnabled from "assets/svg/spread/precedenceGraph/unmatched-enabled.svg";
import MatchedActive from "assets/svg/spread/precedenceGraph/matched-active.svg";
import UnmatchedActive from "assets/svg/spread/precedenceGraph/unmatched-active.svg";
import MatchedHidden from "assets/svg/spread/precedenceGraph/matched-hidden.svg";
import UnmatchedHidden from "assets/svg/spread/precedenceGraph/unmatched-hidden.svg";
import type {
  Edge,
  EdgeVariant,
  Node,
  NodeId,
  NodeVariant,
} from "../widget/types";
import type { EdgeTransformed, NodeTransformed } from "./types";
import { isEmpty } from "lodash";

interface EdgeStyle {
  stroke: string;
  strokeWidth: number;
  strokeDashArray?: number[];
}

export const edgeVariantStyleMap: Record<EdgeVariant, EdgeStyle> = {
  default: { stroke: "#E0DEDE", strokeWidth: 2 },
  "default-dashed": {
    stroke: "#E0DEDE",
    strokeWidth: 2,
  },
  active: { stroke: "#F86A2B", strokeWidth: 2 },
  cyclic: { stroke: "#B91F07", strokeWidth: 2 },
};

interface NodeStyle {
  icon: string;
  labelStroke: string;
  background: string;
}

export const nodeVariantStyleMap: Record<NodeVariant, NodeStyle> = {
  "matched-default": {
    icon: MatchedDefault,
    labelStroke: "#716E6E",
    background: "#F4F4F5",
  },
  "matched-enabled": {
    icon: MatchedEnabled,
    labelStroke: "#090707",
    background: "#485162",
  },
  "matched-active": {
    icon: MatchedActive,
    labelStroke: "#090707",
    background: "#F86A2B",
  },
  "matched-hidden": {
    icon: MatchedHidden,
    labelStroke: "#A9A7A7",
    background: "#F4F4F5",
  },
  "unmatched-default": {
    icon: UnmatchedDefault,
    labelStroke: "#716E6E",
    background: "#F4F4F5",
  },
  "unmatched-enabled": {
    icon: UnmatchedEnabled,
    labelStroke: "#090707",
    background: "#F4F4F5",
  },
  "unmatched-active": {
    icon: UnmatchedActive,
    labelStroke: "#090707",
    background: "#F4F4F5",
  },
  "unmatched-hidden": {
    icon: UnmatchedHidden,
    labelStroke: "#A9A7A7",
    background: "#F4F4F5",
  },
  disconnected: {
    icon: MatchedActive,
    labelStroke: "#B91F07",
    background: "#B91F07",
  },
};
const transformNodeVariantToActive = (currentVariant: NodeVariant) => {
  if (
    currentVariant === "matched-default" ||
    currentVariant === "matched-enabled" ||
    currentVariant === "matched-hidden"
  ) {
    return "matched-active";
  }
  if (
    currentVariant === "unmatched-default" ||
    currentVariant === "unmatched-enabled" ||
    currentVariant === "unmatched-hidden"
  ) {
    return "unmatched-active";
  }
  return currentVariant;
};

const transformCurrentVariantToEnabledNodeVariant = (
  currentVariant: NodeVariant,
) => {
  return currentVariant.startsWith("matched")
    ? "matched-enabled"
    : "unmatched-enabled";
};

export const getNodeVariant = (
  nodeId: NodeId,
  currentVariant: NodeVariant,
  selectedNodeIds: NodeId[],
  predecessorsNodeIds: NodeId[],
  successorsNodeIds: NodeId[],
  hiddenNodeIds: NodeId[],
  edges: EdgeTransformed[],
  disconnectedNodeIds: Set<NodeId>,
  nodes: Node[],
  newEdgeStartNodeId?: NodeId,
) => {
  // If node is selected or new edge starts from edge - transform to active variant.
  if (selectedNodeIds.includes(nodeId) || newEdgeStartNodeId === nodeId) {
    return transformNodeVariantToActive(currentVariant);
  }

  if (
    disconnectedNodeIds.size > 0 &&
    (!edges
      .filter((edge) => edge.visible)
      .find((edge) => edge.from === nodeId || edge.to === nodeId) ||
      disconnectedNodeIds.has(nodeId)) &&
    !hiddenNodeIds.includes(nodeId) &&
    nodes.length > 1
  ) {
    return "disconnected";
  }

  // If new edge addition process initialized change all nodes that are not start edge node to enabled variant.
  if (newEdgeStartNodeId) {
    return currentVariant;
  }

  // If node is predecessor or successor of selected node - transform to enabled variant.
  if ([...predecessorsNodeIds, ...successorsNodeIds].includes(nodeId)) {
    return transformCurrentVariantToEnabledNodeVariant(currentVariant);
  }

  // If node is hidden - transform to hidden variant.
  if (hiddenNodeIds.includes(nodeId)) {
    return currentVariant.startsWith("matched")
      ? "matched-hidden"
      : "unmatched-hidden";
  }

  return currentVariant;
};

export const getEdgeVariant = (
  isEdgeSelected: boolean,
  selectedNodes: NodeTransformed[],
  nodes: NodeTransformed[],
  fromNodeId: NodeId,
  toNodeId: NodeId,
  circularNodeIds: NodeId[][],
  newEdgeStartNodeId?: NodeId,
) => {
  const isAttachedNodeSelected = selectedNodes.find(
    (node) => node.id === fromNodeId || node.id === toNodeId,
  );
  if ((isAttachedNodeSelected || isEdgeSelected) && !newEdgeStartNodeId) {
    return "active";
  }

  if (
    nodes.find(
      (node) =>
        (node.id === fromNodeId || node.id === toNodeId) &&
        node.variant === "disconnected",
    )
  ) {
    return "cyclic";
  }

  if (!isEmpty(circularNodeIds)) {
    const isNodeInCycle = circularNodeIds.some(
      (nodeIds) => nodeIds.includes(fromNodeId) && nodeIds.includes(toNodeId),
    );
    if (isNodeInCycle) {
      return "cyclic";
    }
  }

  const isAttachedNodeHidden = nodes.find(
    (node) =>
      (node.id === fromNodeId || node.id === toNodeId) &&
      node.variant.endsWith("hidden"),
  );
  if (isAttachedNodeHidden) {
    return "default-dashed";
  }

  return "default";
};

const findConnectedNodes = (nodes: Node[], edges: Edge[]): NodeId[][] => {
  const graph = new Map<NodeId, NodeId[]>();
  const visited = new Set<NodeId>();
  const nodesGroupsIds: NodeId[][] = [];

  // Initialize graph
  nodes.forEach((node) => {
    graph.set(node.id, []);
  });

  // Populate graph with edges
  edges.forEach((edge) => {
    if (graph.has(edge.from) && graph.has(edge.to)) {
      graph.get(edge.from)?.push(edge.to);
      graph.get(edge.to)?.push(edge.from); // For undirected graph, add reverse edge too
    }
  });

  const traverseNodes = (nodeId: NodeId, nodeIds: NodeId[]) => {
    visited.add(nodeId);
    nodeIds.push(nodeId);
    const neighbors = graph.get(nodeId) || [];
    neighbors.forEach((neighbor) => {
      if (!visited.has(neighbor)) {
        traverseNodes(neighbor, nodeIds);
      }
    });
  };

  // Find connected components
  nodes.forEach((node) => {
    if (!visited.has(node.id)) {
      const ids: NodeId[] = [];
      traverseNodes(node.id, ids);
      nodesGroupsIds.push(ids);
    }
  });

  return nodesGroupsIds;
};

export function removeLongestArray<T>(arrays: T[][]): T[][] {
  // Find the length of the longest array
  const maxLength = Math.max(...arrays.map((arr) => arr.length));

  // Filter out all arrays that have the longest length
  return arrays.filter((arr) => arr.length !== maxLength);
}
export function getDisconnectedNodeIds(
  nodes: NodeTransformed[],
  edges: EdgeTransformed[],
) {
  const groups = findConnectedNodes(
    nodes.filter((node) => node.visible),
    edges.filter((edge) => edge.visible),
  );

  if (!edges.length) {
    return new Set(nodes.map((node) => node.id));
  }

  const getDisconnectedIds = () => {
    if (groups.length === 1 || !groups) {
      return [];
    }

    return removeLongestArray(groups).flat();
  };

  return new Set(getDisconnectedIds());
}
