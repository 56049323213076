import React from "react";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { Edge, Node } from "../component";
import { GraphComponent } from "../component";
import type { Stylesheet } from "../../../entities/AppTheming";
import { getAppsmithConfigs } from "@appsmith/configs";
import {
  GraphWidgetHierarchicalModeDirection,
  GraphWidgetHierarchicalModeLayering,
  GraphWidgetMode,
} from "./types";
import { getAlignValue } from "./helpers";
import type {
  AutoLayoutConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import { styleConfig } from "./styleConfig";
import { contentConfig } from "./contentConfig";
import { EventType } from "../../../constants/AppsmithActionConstants/ActionConstants";
import type { NodeId } from "../component/makeInitDiagram";
import { WIDGET_TAGS } from "constants/WidgetConstants";

import IconSVG from "../icon.svg";
import ThumbnailSVG from "../../spread_tmp_thumbnail.svg";

export interface GraphWidgetProps extends WidgetProps {
  nodes: Node[];
  edges: Edge[];
  nodeFigure: "Circle" | "RoundedRectangle";

  nodeTextColor: string;
  nodeColor: string;
  edgeColor: string;
  backgroundColor: string;
  selectedNodeBackgroundColor: string;
  nodeBorderColor: string;
  selectedNodeBorderColor: string;

  nodeDiameter: number;
  nodeTextSize: number;
  nodeBorderSize: number;
  selectedNodeBorderSize: number;

  defaultSelectedNodes: NodeId[];
  hiddenNodeIds: NodeId[];

  mode: GraphWidgetMode;
  hierarchicalModeDirection: GraphWidgetHierarchicalModeDirection;
  hierarchicalModeLayering: GraphWidgetHierarchicalModeLayering;
  hierarchicalModeLayerSpacing: string;
  hierarchicalModeColumnSpacing: string;
  hierarchicalModeAlignUpperLeft: boolean;
  hierarchicalModeAlignUpperRight: boolean;
  hierarchicalModeAlignLowerLeft: boolean;
  hierarchicalModeAlignLowerRight: boolean;

  onSelectionChange: string;
  onRightClick: string;
}

const defaultProps = {
  edges: [
    {
      id: "0",
      from: "0",
      to: "2",
      label: "made of",
    },
    {
      id: "1",
      from: "1",
      to: "2",
      label: "made of",
    },
  ],
  nodes: [
    {
      id: "0",
      label: "rim",
      color: "#89fbac",
    },
    {
      id: "1",
      label: "body",
      color: "#85f5df",
    },
    {
      id: "2",
      label: "steel",
      color: "#b188a3",
    },
  ],
  defaultSelectedNodes: [],
  hiddenNodeIds: [],
};

const defaultStyleProps = {
  // General
  nodeFigure: "Circle",
  // Mode related props
  mode: GraphWidgetMode.ForceDirected,
  hierarchicalModeDirection: GraphWidgetHierarchicalModeDirection.Right,
  hierarchicalModeLayering:
    GraphWidgetHierarchicalModeLayering.OptimalLinkLength,
  hierarchicalModeLayerSpacing: "75", // should be a string to be parsed as int
  hierarchicalModeColumnSpacing: "75", // should be a string to be parsed as int
  hierarchicalModeAlignUpperLeft: true,
  hierarchicalModeAlignUpperRight: true,
  hierarchicalModeAlignLowerLeft: true,
  hierarchicalModeAlignLowerRight: true,
  // Sizes and colors
  edgeColor: "#000000",
  selectedNodeBorderSize: 2,
  selectedNodeBorderColor: "#4ade80",
  nodeBorderSize: 0,
  nodeBorderColor: "#000000",
  nodeDiameter: 60,
  nodeTextSize: 10,
  hasToolbar: false,
};

class GraphWidget extends BaseWidget<GraphWidgetProps, WidgetState> {
  static type = "GRAPH_WIDGET";

  static getConfig() {
    return {
      name: "Graph", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      thumbnailSVG: ThumbnailSVG,
      tags: [WIDGET_TAGS.DISPLAY],
      needsMeta: true, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
      searchTags: ["percent"],
    };
  }

  static getDefaults() {
    return {
      ...defaultProps,
      ...defaultStyleProps,
      widgetName: "Graph",
      rows: 40,
      columns: 40,
      version: 1,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    // TODO: add proper auto layout config
    return {};
  }

  constructor(props: GraphWidgetProps) {
    super(props);
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      selectedNodes: {
        "!type": "[Node]",
        "!doc": "The array of selected nodes of the graph",
      },
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      selectedNodes: `{{this.selectedNodes}}`,
    };
  }
  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedNodes: undefined,
    };
  }

  updateSelectedNodesInMeta = (selectedNodesIds: Set<NodeId>) => {
    this.props.updateWidgetMetaProperty(
      "selectedNodes",
      [...selectedNodesIds]
        .map((nodeId) => this.props.nodes.find((node) => node.id == nodeId))
        .filter((node) => node != null),
    );
  };

  static getStylesheetConfig(): Stylesheet {
    return {
      nodeTextColor: "{{appsmith.theme.colors.backgroundColor}}",
      backgroundColor: "{{appsmith.theme.colors.backgroundColor}}",
      selectedNodeBackgroundColor: "{{appsmith.theme.colors.primaryColor}}",
    };
  }

  static getPropertyPaneContentConfig() {
    return contentConfig;
  }

  static getPropertyPaneStyleConfig() {
    return styleConfig;
  }

  onSelectionChange = () => {
    super.executeAction({
      triggerPropertyName: "onSelectionChange",
      dynamicString: this.props.onSelectionChange,
      event: {
        type: EventType.ON_SELECTION_CHANGE,
      },
    });
  };

  onRightClick = () => {
    super.executeAction({
      triggerPropertyName: "onRightClick",
      dynamicString: this.props.onRightClick,
      event: {
        type: EventType.ON_RIGHT_CLICK,
      },
    });
  };

  getWidgetView() {
    const {
      hierarchicalModeAlignLowerLeft,
      hierarchicalModeAlignLowerRight,
      hierarchicalModeAlignUpperLeft,
      hierarchicalModeAlignUpperRight,
      hierarchicalModeColumnSpacing,
      hierarchicalModeDirection,
      hierarchicalModeLayering,
      hierarchicalModeLayerSpacing,
      mode,
    } = this.props;

    const modeRelatedProperties = {
      mode,
      hierarchicalModeAlignOption: getAlignValue(
        hierarchicalModeAlignLowerLeft,
        hierarchicalModeAlignLowerRight,
        hierarchicalModeAlignUpperLeft,
        hierarchicalModeAlignUpperRight,
      ),
      hierarchicalModeColumnSpacing: parseInt(
        hierarchicalModeColumnSpacing,
        10,
      ),
      hierarchicalModeDirection,
      hierarchicalModeLayering,
      hierarchicalModeLayerSpacing: parseInt(hierarchicalModeLayerSpacing, 10),
    };

    return (
      <GraphComponent
        backgroundColor={this.props.backgroundColor}
        defaultSelectedNodes={this.props.defaultSelectedNodes}
        edgeColor={this.props.edgeColor}
        gojsLicenseKey={getAppsmithConfigs().gojsLicenseKey}
        graph={{
          nodes: this.props.nodes || [],
          edges: this.props.edges || [],
        }}
        hasToolbar={this.props.hasToolbar}
        hiddenNodeIds={this.props.hiddenNodeIds}
        nodeBorderColor={this.props.nodeBorderColor}
        nodeBorderSize={this.props.nodeBorderSize}
        nodeColor={this.props.nodeColor}
        nodeDiameter={this.props.nodeDiameter}
        nodeFigure={this.props.nodeFigure}
        nodeTextColor={this.props.nodeTextColor}
        nodeTextSize={this.props.nodeTextSize}
        onRightClick={this.onRightClick}
        onSelectionChange={this.onSelectionChange}
        selectedNodeBackgroundColor={this.props.selectedNodeBackgroundColor}
        selectedNodeBorderColor={this.props.selectedNodeBorderColor}
        selectedNodeBorderSize={this.props.selectedNodeBorderSize}
        updateSelectedNodesInMeta={this.updateSelectedNodesInMeta}
        {...modeRelatedProperties}
      />
    );
  }
}

export default GraphWidget;
