import type { WidgetProps } from "widgets/BaseWidget";

import { ReduxActionTypes } from "@appsmith/constants/ReduxActionConstants";
import type { AppState } from "@appsmith/reducers";
import type { WidgetResize } from "actions/pageActions";
import { renderAppsmithCanvas } from "layoutSystems/CanvasFactory";
import { Positioning } from "layoutSystems/common/utils/constants";
import { compact, map, sortBy } from "lodash";
import { sort } from "ramda";
import type { ReactNode } from "react";
import React from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import { getSnappedGrid } from "sagas/WidgetOperationUtils";
import { getWidgets } from "sagas/selectors";
import type { WithMeta } from "widgets/MetaHOC";
import type {
  ContainerPropsExtension,
  ContentArea,
  ContentResizeConfig,
} from "widgets/SpreadSplitContainerWidget/types";
import { getAreaPositioning } from "widgets/SpreadSplitContainerWidget/widget/layouting";

/** Placeholder type until we understand what exactly is a child widget's type. */
interface ChildWidgetConfiguration {
  widgetId: string;
  parentId?: string;
}
type ChildContainerWidgetProps = WidgetProps & ContainerPropsExtension;

export interface SpreadSplitContainerWidgetProps<T extends WidgetProps>
  extends WidgetProps,
    WithMeta,
    PropsFromRedux {
  isLeftAreaVisible?: boolean;
  isMiddleAreaVisible?: boolean;
  isRightAreaVisible?: boolean;
  children?: T[];
}

class SpreadSplitContainerWidget extends React.PureComponent<
  SpreadSplitContainerWidgetProps<any>
> {
  constructor(props: SpreadSplitContainerWidget["props"]) {
    super(props);
    this.renderChildWidget = this.renderChildWidget.bind(this);
  }

  componentDidMount(): void {
    this.updateVisibleAreasSizes();
  }

  componentDidUpdate(prevProps: SpreadSplitContainerWidget["props"]) {
    if (
      prevProps.bottomRow !== this.props.bottomRow ||
      prevProps.topRow !== this.props.topRow
    ) {
      this.widgetDidMoveVertically();
    }

    if (
      prevProps.isLeftAreaVisible !== this.props.isLeftAreaVisible ||
      prevProps.isMiddleAreaVisible !== this.props.isMiddleAreaVisible ||
      prevProps.isRightAreaVisible !== this.props.isRightAreaVisible
    ) {
      this.updateVisibleAreasSizes();
    }
  }

  private updateVisibleAreasSizes = () => {
    const visibleAreas = this.getVisibleContentAreasNames();
    const contentAreas = this.getAllContentAreas();
    const positioning = getAreaPositioning(visibleAreas);

    const resizeOperations = visibleAreas.map((area) =>
      getResizeOperationForContentArea(contentAreas[area], positioning[area]),
    );

    this.props.resizeWidgets(resizeOperations);
  };

  private widgetDidMoveVertically = () => {
    const contentAreas = this.getAllContentAreas();

    const childContainerBottomRow =
      this.props.bottomRow - this.props.topRow - 1;

    const resizeOperations = [
      getResizeOperationForContentArea(contentAreas.left, {
        bottomRow: childContainerBottomRow,
      }),
      getResizeOperationForContentArea(contentAreas.middle, {
        bottomRow: childContainerBottomRow,
      }),
      getResizeOperationForContentArea(contentAreas.right, {
        bottomRow: childContainerBottomRow,
      }),
    ];

    this.props.resizeWidgets(resizeOperations);
  };

  getSnapSpaces = () => {
    const { componentWidth } = this.props;
    const { snapGrid } = getSnappedGrid(this.props, componentWidth);

    return snapGrid;
  };

  renderChildren = () => {
    return map(
      // sort by row so stacking context is correct
      // TODO(abhinav): This is hacky. The stacking context should increase for widgets rendered top to bottom, always.
      // Figure out a way in which the stacking context is consistent.
      this.props.positioning !== Positioning.Fixed
        ? this.props.children
        : sortBy(compact(this.props.children), (child) => child.topRow),
      this.renderChildWidget,
    );
  };

  getChildCanvas = (): WidgetProps & {
    children?: ChildWidgetConfiguration[];
  } => {
    const canvas = compact(this.props.children).find(
      (child: WidgetProps) => child.type === "CANVAS_WIDGET",
    );

    if (!canvas) {
      throw new Error("Canvas widget is missing!");
    }

    return canvas;
  };

  getAllContentAreas = (): Record<ContentArea, ChildContainerWidgetProps> => {
    const childContainers = Object.values(this.props.widgets).filter(
      (widget) => widget.parentId === this.getChildCanvas().widgetId,
    ) as ChildContainerWidgetProps[];
    const [left, middle, right] = sort(
      (a, b) => a.splitContainerAreaIndex - b.splitContainerAreaIndex,
      childContainers,
    );

    return { left, middle, right };
  };

  getVisibleContentAreasNames = (): ContentArea[] => {
    const visibleAreas: ContentArea[] = compact([
      this.props.isLeftAreaVisible && "left",
      this.props.isMiddleAreaVisible && "middle",
      this.props.isRightAreaVisible && "right",
    ]);
    return visibleAreas;
  };

  getVisibleContentAreasCanvasChildren = (): Record<
    ContentArea,
    ChildWidgetConfiguration
  > => {
    const visibleAreas = this.getVisibleContentAreasNames();
    const contentAreas = this.getAllContentAreas();
    const canvasChildren = this.getChildCanvas().children ?? [];

    return visibleAreas.reduce(
      (acc, area) => {
        const canvasChild = canvasChildren.find(
          (child) => child.widgetId === contentAreas[area]?.widgetId,
        );
        if (!canvasChild) {
          return acc;
        }

        return { ...acc, [area]: canvasChild };
      },
      {} as Record<ContentArea, ChildWidgetConfiguration>,
    );
  };

  renderChildWidget = () => {
    try {
      const canvas = this.getChildCanvas() as WidgetProps;

      const { componentHeight, componentWidth } = this.props;

      const canvasCopy = { ...canvas };
      canvasCopy.parentId = this.props.widgetId;
      canvasCopy.children = Object.values(
        this.getVisibleContentAreasCanvasChildren(),
      );

      canvasCopy.minHeight = componentHeight;
      canvasCopy.rightColumn = componentWidth;
      canvasCopy.bottomRow = componentHeight; // TODO: check auto-height behaviour

      canvasCopy.positioning = Positioning.Fixed;
      canvasCopy.useAutoLayout = this.props.positioning
        ? this.props.positioning === Positioning.Vertical
        : false;

      return renderAppsmithCanvas(canvasCopy);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        `[SplitPanel: ${this.props.widgetName}] Could not render child.`,
        e,
      );
    }

    return null;
  };

  render(): ReactNode {
    return this.renderChildWidget();
  }
}

function getResizeOperationForContentArea(
  area: ChildContainerWidgetProps,
  newSizes: ContentResizeConfig,
) {
  return {
    widgetId: area.widgetId,
    parentId: area.parentId ?? "0",
    // TODO: find out where these come from
    // Guess: https://github.com/spread-ai/spread-appsmith/blob/196642b84b215e4996125b6e9e22f6fb84d06c14/app/client/src/sagas/WidgetOperationUtils.ts#L773
    snapColumnSpace: 5.532085418701172,
    snapRowSpace: 10,
    bottomRow: newSizes.bottomRow,
    leftColumn: newSizes.leftColumn,
    rightColumn: newSizes.rightColumn,
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  resizeWidgets: (payload: WidgetResize[]) => {
    dispatch({
      type: ReduxActionTypes.RESIZE_MULTIPLE_WIDGETS,
      payload,
    });
  },
});

const mapStateToProps = (state: AppState) => {
  return {
    widgets: getWidgets(state),
  };
};

const connectToStore = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connectToStore>;
const SpreadSplitContainerWidgetConnected = connectToStore(
  SpreadSplitContainerWidget as any,
);

export { SpreadSplitContainerWidgetConnected };
