import type { ReactNode } from "react";
import React from "react";
import type { IconName } from "@blueprintjs/icons";
import { BaseButton } from "components/designSystems/appsmith/BaseButton";
import type { BaseButtonProps } from "components/designSystems/appsmith/BaseButton";
import { StyledButton } from "widgets/IconButtonWidget/component";
import styled from "styled-components";
import { Tooltip } from "design-system";
import { TAILWIND_COLORS } from "constants/ThemeConstants";
import { ButtonBorderRadiusTypes } from "components/propertyControls/ButtonBorderRadiusControl";

interface actionButtonProps {
  stateActionButton: boolean;
}

interface ContainerChildrenStateProps {
  iconName?: IconName;
  title?: string;
  description?: string;
  children?: ReactNode;
  actionButton?: BaseButtonProps & actionButtonProps;
  isError: boolean;
  iconFillColor?: string;
  tooltipContent?: string;
  containerHeight: number;
}

interface StateContainerWrapperProps {
  height: number;
}
interface StateTitleProps {
  isError: boolean;
}

const StateContainerWrapper = styled.div<StateContainerWrapperProps>`
  height: ${(props) => (props.height ? props.height + "px" : "100%")};
`;

const StateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 60px;
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  gap: 20px;
  &:last-child {
    margin-bottom: -20px;
  }
`;

const StateTitle = styled.span<StateTitleProps>`
  font-family: Open Sans;
  font-weight: bold;
  font-size: 1rem;
  color: ${(props) => (props.isError ? TAILWIND_COLORS.red[500] : "#485162")};
  width: 100%;
`;

const StateDescription = styled.span`
  font-size: 0.875rem;
  font-family: Open Sans;
  color: #231f20;
  overflow: scroll;
  scrollbar-width: none;
`;

const ActionButtonContainer = styled.div`
  width: 90px;
  height: 32px;
  font-family: Open Sans;
`;

const ContainerState = (props: ContainerChildrenStateProps) => {
  const {
    actionButton,
    children,
    containerHeight,
    description,
    iconFillColor,
    iconName,
    isError,
    title,
    tooltipContent,
  } = props;

  const actionButtonContent = (
    <ActionButtonContainer>
      <BaseButton
        {...actionButton}
        borderRadius={ButtonBorderRadiusTypes.ROUNDED}
      />
    </ActionButtonContainer>
  );
  return (
    <StateContainerWrapper height={containerHeight}>
      <StateContainer>
        {iconName && containerHeight > 200 && (
          <div className="flex justify-center">
            <StyledButton
              buttonColor={iconFillColor}
              buttonVariant={"PRIMARY"}
              dimension={40}
              disabled={false}
              hasOnClickAction={false}
              icon={iconName || "error"}
            />
          </div>
        )}
        <StateTitle isError={isError}>{title}</StateTitle>
        {description && containerHeight > 250 && (
          <StateDescription>{description}</StateDescription>
        )}
        {children && <div className="w-full">{children}</div>}
        {actionButton?.stateActionButton &&
          (tooltipContent ? (
            <Tooltip content={tooltipContent}>{actionButtonContent}</Tooltip>
          ) : (
            actionButtonContent
          ))}
      </StateContainer>
    </StateContainerWrapper>
  );
};

export default ContainerState;
