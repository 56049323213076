import * as go from "gojs";

const getPosition = (level: number, direction: number) => {
  let x;
  let y;

  switch (direction) {
    case 0: // Right
      x = level * 200;
      break;
    case 180: // Left
      x = -level * 200;
      break;
    case 90: // Down
      y = level * 200;
      break;
    case 270: // Up
      y = -level * 200;
      break;
  }

  return { x, y };
};

export class HierarchicalDigramLayout extends go.LayeredDigraphLayout {
  commitLayout() {
    super.commitLayout();
    const nodes =
      this.network?.vertexes.filter((v) => Boolean(v.node)) ||
      new go.Set<go.LayoutVertex>();

    nodes.toArray().forEach((n) => {
      // If "level" is specified on the nodes, we want to position them hierarchically.
      // Nodes with the same level should be aligned on the same line (horizontally or vertically, depending on the direction)

      if (!n.node) {
        // just a typeguard, this will never happen because of the filter above
        return;
      }

      const level = n.node.data.level;
      if (typeof level === "number") {
        // Overwrite either x or y position
        const { x, y } = getPosition(level, this.direction);
        n.node.moveTo(x || n.node.position.x, y || n.node.position.y);
      }
    });
  }
}
