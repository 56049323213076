export const GRAPH_STYLES = {
  width: "100%",
  height: "100%",
  background: "white",
};

export const DASH_WIDTH = 5;
export const NUMBER_OF_COLORS = 2;
export const WHITE_COLOR = "#FFFFFF";
export const SELECTED_EDGE_Z_ORDER = 1000;
export const DEFAULT_EDGE_Z_ORDER = 1;

export const PROGRESS_BAR_HEIGHT = 4;
