import React from "react";

import { ContextMenu } from "./ContextMenu";
import type { NodeId } from "../../widget/types";
import type { ContextMenuData } from "../types";
interface PrecedenceGraphContextMenu {
  contextMenuData?: ContextMenuData;
  onHidePart: (targetId: NodeId) => void;
  onUnhidePart: (targetId: NodeId) => void;
  onRemovePart: (targetId: NodeId) => void;
  onRemoveEdge: (targetId: NodeId) => void;
  setNewEdgeStartNodeId: (nodeId: NodeId) => void;
  hiddenNodeIds: NodeId[];
  onClose: () => void;
  newEdgeStartNodeId?: NodeId;
  onCreateNewEdge: (targetId: NodeId) => void;
  onAddNode: () => void;
  onAutoLayout: () => void;
  selectedNodeIds: NodeId[];
  onUnmatchedNodeRemove: () => void;
}

export const PrecedenceGraphContextMenu: React.FC<
  PrecedenceGraphContextMenu
> = ({
  contextMenuData,
  hiddenNodeIds,
  newEdgeStartNodeId,
  onAddNode,
  onAutoLayout,
  onClose,
  onCreateNewEdge,
  onHidePart,
  onRemoveEdge,
  onUnhidePart,
  onUnmatchedNodeRemove,
  selectedNodeIds,
  setNewEdgeStartNodeId,
}) => {
  const isPartMatched = Boolean(
    contextMenuData?.variant?.startsWith("matched"),
  );
  // Items that you see when you right-click on the node
  const nodeItems = [
    {
      label: "Add connection",
      onClick: (targetId?: NodeId) => {
        if (!targetId) return;
        setNewEdgeStartNodeId(targetId);
      },
      isHidden: (targetId?: NodeId) => !targetId || Boolean(newEdgeStartNodeId),
    },
    {
      label: "Select as ending node",
      onClick: (targetId?: NodeId) => {
        if (!targetId) return;
        onCreateNewEdge(targetId);
      },
      isHidden: (targetId?: NodeId) =>
        // Don't show if right-click on the starting node in edge creation mode
        !targetId || newEdgeStartNodeId === targetId || !newEdgeStartNodeId,
    },
    {
      label: "Hide part",
      onClick: (targetId?: NodeId) => {
        if (!targetId) return;
        onHidePart(targetId);
      },
      isHidden: (targetId?: NodeId) =>
        !targetId ||
        hiddenNodeIds.includes(targetId) ||
        Boolean(newEdgeStartNodeId),
    },
    {
      label: "Unhide part",
      onClick: (targetId?: NodeId) => {
        if (!targetId) return;
        onUnhidePart(targetId);
      },
      isHidden: (targetId?: NodeId) =>
        !targetId ||
        !hiddenNodeIds.includes(targetId) ||
        Boolean(newEdgeStartNodeId),
    },
    {
      label: "Add node",
      onClick: () => {
        onAddNode();
      },
      isHidden: (targetId?: NodeId) =>
        !targetId ||
        Boolean(newEdgeStartNodeId) ||
        selectedNodeIds.length === 0 ||
        !isPartMatched,
    },
    {
      label: "Remove part",
      onClick: (targetId?: NodeId) => {
        if (!targetId) return;
        onUnmatchedNodeRemove();
      },
      isHidden: (targetId?: NodeId) =>
        !targetId || Boolean(newEdgeStartNodeId) || isPartMatched,
    },
  ];
  // Items that you see when you right-click on the edge
  const edgeItems = [
    {
      label: "Delete edge",
      onClick: (targetId?: NodeId) => {
        if (!targetId) return;
        onRemoveEdge(targetId);
      },
      isHidden: (targetId?: NodeId) => !targetId,
    },
  ];

  const backgroundItems = [
    {
      label: "Auto layout",
      onClick: () => onAutoLayout(),
    },
    {
      label: "Add node",
      onClick: () => {
        onAddNode();
      },
    },
  ];

  const getContextMenuItems = () => {
    if (contextMenuData?.targetPartType === "edge") {
      return edgeItems;
    }

    if (contextMenuData?.targetPartType === "node") {
      return nodeItems;
    }

    return backgroundItems;
  };

  return (
    <ContextMenu
      anchorPosition={contextMenuData?.anchorPosition}
      items={getContextMenuItems()}
      onClose={onClose}
      targetId={contextMenuData?.targetId}
    />
  );
};
