import React from "react";
import { ZoomInIcon } from "./ZoomInIcon";
import { ZoomOutIcon } from "./ZoomOutIcon";
import { Focus } from "./Focus";
import styled from "styled-components";
import { Tooltip } from "../Tooltip";
import { Z_INDEX } from "../constants";

interface ToolbarProps {
  onZoomOut: () => void;
  onZoomToSelectedPart: () => void;
  onZoomIn: () => void;
}
export const Toolbar: React.FC<ToolbarProps> = ({
  onZoomIn,
  onZoomOut,
  onZoomToSelectedPart,
}) => {
  return (
    <Container>
      <Button onClick={onZoomToSelectedPart}>
        <Tooltip content="Focus">
          <Focus />
        </Tooltip>
      </Button>
      <Button onClick={onZoomIn}>
        <Tooltip content="Zoom in">
          <ZoomInIcon />
        </Tooltip>
      </Button>
      <Button onClick={onZoomOut}>
        <Tooltip content="Zoom out">
          <ZoomOutIcon />
        </Tooltip>
      </Button>
    </Container>
  );
};

Toolbar.displayName = "Toolbar";

const Button = styled.button`
  /* Layout */
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;

  padding-top: 10px;
  border-radius: 6px;
  color: #485162;

  &:hover {
    background: rgba(89, 100, 120, 0.08);
  }
`;

const Container = styled.div`
  box-shadow: 0 1px 3px 0 #0000001a;
  position: absolute;
  width: 32px;
  border-radius: 6px;
  bottom: 8px;
  right: 8px;
  z-index: ${Z_INDEX.TOOLBAR};
  cursor: pointer;
  background-color: white;
`;
