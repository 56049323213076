import React from "react";
import type { Link } from "../../types";
import Xarrow, { type xarrowPropsType } from "react-xarrows";

interface ArrowProps {
  // Widget ID is used to create unique IDs for each widget instance
  widgetId: string;
  link: Link;
  direction: "fromLeftToRight" | "fromRightToLeft";
}

const ARROW_CONFIG: Partial<xarrowPropsType> = {
  color: "#858282",
  curveness: 0.4,
  headSize: 6,
  path: "smooth",
  strokeWidth: 1,
  // In order to show arrows under cards, but above cluster background
  divContainerStyle: { zIndex: 1 },
};

// TODO: adjust dashness together with Ricardo
const DASHNESS_CONFIG: xarrowPropsType["dashness"] = {
  strokeLen: 4,
  nonStrokeLen: 4,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Arrow = ({ direction, link, widgetId }: ArrowProps) => {
  const dynamicProps: xarrowPropsType = {
    start: `${widgetId}${link.from}`,
    startAnchor: direction === "fromLeftToRight" ? "right" : "left",
    end: `${widgetId}${link.to}`,
    endAnchor: direction === "fromLeftToRight" ? "left" : "right",
    dashness: link.dashed ? DASHNESS_CONFIG : undefined,
  };

  return <Xarrow {...ARROW_CONFIG} {...dynamicProps} />;
};
