import React from "react";

import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import type {
  AutoLayoutConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";

import GuidedEventChainComponent from "../component";
import { propertyPaneStyleConfig, stylesheetConfig } from "./styleConfig";
import { propertyPaneContentConfig } from "./contentConfig";
import type { ClusterCards, ClusterData, Link } from "../types";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import { actionToCallbackMap, type ActionPayload } from "./actionsAndCallbacks";

import { defaultValues } from "../defaultValues";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../../spread_tmp_thumbnail.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import type { LocalizedStrings } from "./localizedStrings";

export interface GuidedEventChainWidgetProps extends WidgetProps {
  clusters: ClusterData[];
  clustersCards: ClusterCards;
  links: Link[];
  localizedStrings: LocalizedStrings;
}

class GuidedEventChainWidget extends BaseWidget<
  GuidedEventChainWidgetProps,
  WidgetState
> {
  static type = "GUIDED_EVENT_CHAIN_WIDGET";

  static getConfig() {
    return {
      name: "Guided Event Chain", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      thumbnailSVG: ThumbnailSVG,
      tags: [WIDGET_TAGS.BETA],
      needsMeta: true, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
    };
  }

  static getDefaults() {
    return {
      ...defaultValues,
      widgetName: "GuidedEventChain",
      rows: 40,
      columns: 40,
      version: 1,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    // TODO: add proper auto layout config
    return {};
  }

  componentDidMount(): void {
    // Set default last action to empty object
    this.updateLastAction();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidUpdate(prevProps: GuidedEventChainWidgetProps): void {
    //
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (widget: GuidedEventChainWidgetProps) => ({
      lastAction: {
        "!type": "{}", // TODO: after upstream PR is merged, check if there is types feature for object
        "!doc": "Payload of last action triggered",
      },
    });
  }

  static getDerivedPropertiesMap() {
    return {
      //
    };
  }

  static getStylesheetConfig(): Stylesheet {
    return stylesheetConfig;
  }

  static getPropertyPaneStyleConfig() {
    return propertyPaneStyleConfig;
  }

  static getPropertyPaneContentConfig() {
    return propertyPaneContentConfig;
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        //
      },
    };
  }

  private updateLastAction = (action?: ActionPayload) => {
    this.props.updateWidgetMetaProperty("lastAction", action || {});
  };

  private triggerActionCallback = (action: ActionPayload) => {
    const callback = actionToCallbackMap[action.type];
    this.executeAction({
      triggerPropertyName: callback,
      dynamicString: this.props[callback],
      event: {
        type: EventType.ON_CLICK,
      },
    });
  };

  commitAction = (action: ActionPayload) => {
    this.updateLastAction(action);
    this.triggerActionCallback(action);
  };

  getWidgetView() {
    return (
      <GuidedEventChainComponent
        accentColor={this.props.accentColor}
        clusters={this.props.clusters}
        clustersCards={this.props.clustersCards}
        commitAction={this.commitAction}
        links={this.props.links}
        localizedStrings={this.props.localizedStrings}
        widgetId={this.props.widgetId}
      />
    );
  }
}

export default GuidedEventChainWidget;
