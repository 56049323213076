import React, { type ReactElement } from "react";
import { Tooltip as TooltipComponent } from "design-system";

export interface TooltipProps {
  children: ReactElement;
  content: string;
}

export const InfoTooltip: React.FC<TooltipProps> = ({ children, content }) => (
  <TooltipComponent
    content={content}
    // TODO: adjust tooltip delay with Ricardo
    mouseEnterDelay={0.2}
    mouseLeaveDelay={0}
    placement="top"
  >
    {children}
  </TooltipComponent>
);
