import React from "react";

import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import type {
  AutocompletionDefinitions,
  AutoLayoutConfig,
} from "WidgetProvider/constants";

import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import BaseWidget from "widgets/BaseWidget";
import { getComplementaryGrayscaleColor } from "widgets/WidgetUtils";

import { contentConfig } from "./contentConfig";
import { defaultProps } from "./defaultDataProps";
import { styleConfig } from "./styleConfig";
import type { Edge, Node } from "./types";
import { TopologyViewerComponent } from "../component";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../../spread_tmp_thumbnail.svg";
import type { DerivedPropertiesMap } from "../../../WidgetProvider/factory";

interface SpreadTopologyViewerWidgetProps extends WidgetProps {
  defaultNodes: Node[];
  defaultEdges: Edge[];
  isVisible: boolean;
  hasToolbar: boolean;
  highlightMode: string;

  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  borderRadius: string;
  boxShadow: string;

  groupSpacing: number;
  layerSpacing: number;
  nodeSpacing: number;

  defaultSelectedNodeIds: (string | number)[];
  onSelectionChange: string;
}

class SpreadTopologyViewerWidget extends BaseWidget<
  SpreadTopologyViewerWidgetProps,
  WidgetState
> {
  static type = "SPREAD_TOPOLOGY_VIEWER_WIDGET";

  static getConfig() {
    return {
      name: "Topology Viewer",
      iconSVG: IconSVG,
      thumbnailSVG: ThumbnailSVG,
      searchTags: ["fam", "fahrzeugarchitekturmappe"],
      tags: [WIDGET_TAGS.DISPLAY],
      needsMeta: true,
      isCanvas: false,
    };
  }

  static getDefaults() {
    return {
      widgetName: "TopologyViewer",
      rows: 50,
      columns: 50,
      version: 1,
      backgroundColor: "#FFFFFF",
      borderWidth: 0,
      groupSpacing: 20,
      layerSpacing: 150,
      nodeSpacing: 100,
      highlightMode: "root",
      ...defaultProps,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {};
  }

  static getStylesheetConfig(): Stylesheet {
    return {
      progressBarFillColor: "{{appsmith.theme.colors.primaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "none",
    };
  }

  static getPropertyPaneContentConfig() {
    return contentConfig;
  }

  static getPropertyPaneStyleConfig() {
    return styleConfig;
  }

  constructor(props: SpreadTopologyViewerWidgetProps) {
    super(props);
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      selectedNodeIds: {
        "!type": "[string]",
        "!doc": "The selected node ids of the topology viewer",
      },
      nodes: {
        "!type": "?",
        "!doc": "Current nodes of the topology viewer",
      },
      edges: {
        "!type": "?",
        "!doc": "Current edges of the topology viewer",
      },
      isVisible: {
        "!type": "bool",
        "!doc": "Boolean value indicating if the widget is in visible state",
      },
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      selectedNodeIds: `{{this.selectedNodeIds}}`,
    };
  }

  static getMetaPropertiesMap() {
    return {
      edges: undefined,
      nodes: undefined,
      selectedNodeIds: undefined,
    };
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setSelectedNodeIds: {
          path: "defaultSelectedNodeIds",
          type: "array",
        },
      },
    };
  }

  updateSelectedNodeIds = (ids: string[]) => {
    this.props.updateWidgetMetaProperty("selectedNodeIds", ids, {
      triggerPropertyName: "onSelectionChange",
      dynamicString: this.props.onSelectionChange,
      event: {
        type: EventType.ON_SELECTION_CHANGE,
      },
    });
  };

  updateEdgesMeta = (edges: Edge[]) => {
    this.props.updateWidgetMetaProperty("edges", edges);
  };

  updateNodesMeta = (nodes: Node[]) => {
    this.props.updateWidgetMetaProperty("nodes", nodes);
  };

  addDefaultEdgeAttributes = (edges: Edge[], backgroundColor: string) => {
    let textColor = "#000000";
    if (backgroundColor) {
      textColor = getComplementaryGrayscaleColor(backgroundColor);
    }
    return edges.map((edge) => ({
      ...edge,
      fromSide: edge.fromSide === undefined ? "bottom" : edge.fromSide,
      toSide: edge.toSide === undefined ? "left" : edge.toSide,
      labelVisible: edge.labelVisible !== undefined ? edge.labelVisible : true,
      bidirect: edge.bidirect || false,
      textColor: textColor,
    }));
  };

  addDefaultNodeAttributes = (nodes: Node[], backgroundColor: string) => {
    return nodes.map((node) => {
      let textColor = "#000000";
      if (node.isGroup && backgroundColor) {
        textColor = getComplementaryGrayscaleColor(backgroundColor);
      } else if (node.color) {
        textColor = getComplementaryGrayscaleColor(node.color);
      }
      return {
        ...node,
        textColor: textColor,
      };
    });
  };

  getWidgetView() {
    const {
      backgroundColor,
      borderColor,
      borderRadius,
      borderWidth,
      boxShadow,
      defaultEdges,
      defaultNodes,
      defaultSelectedNodeIds,
      groupSpacing,
      hasToolbar,
      highlightMode,
      layerSpacing,
      nodeSpacing,
    } = this.props;

    const edgesWithDefaults = this.addDefaultEdgeAttributes(
      defaultEdges,
      backgroundColor,
    );
    const nodesWithDefaults = this.addDefaultNodeAttributes(
      defaultNodes,
      backgroundColor,
    );

    return (
      <TopologyViewerComponent
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        boxShadow={boxShadow}
        defaultSelectedNodeIds={defaultSelectedNodeIds}
        edges={edgesWithDefaults}
        groupSpacing={groupSpacing}
        hasToolbar={hasToolbar}
        highlightMode={highlightMode}
        layerSpacing={layerSpacing}
        nodeSpacing={nodeSpacing}
        nodes={nodesWithDefaults}
        onEdgesChange={this.updateEdgesMeta}
        onNodeIdsSelectionChange={this.updateSelectedNodeIds}
        onNodesChange={this.updateNodesMeta}
      />
    );
  }
}

export default SpreadTopologyViewerWidget;
