export interface ModelOption {
  id: string;
  label: string;
  value: string;
}

export const DefaultModelOption: ModelOption = {
  id: "demo_model",
  label: "Demo Model (default)",
  value: "/controls-assets/cad-domain-model-selector/demo_model",
};

export const DEFAULT_MODEL_OPTIONS: ModelOption[] = [DefaultModelOption];
