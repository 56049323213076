import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import { RenderModes } from "constants/WidgetConstants";
import React from "react";
import type { CellProps as ReactTableCellProps } from "react-table";
import type {
  CellLayoutProperties,
  ColumnProperties,
} from "widgets/TableWidgetV2/component/Constants";
import type {
  OnColumnEventArgs,
  TableWidgetProps,
} from "widgets/TableWidgetV2/constants";
import { BadgeCell } from "./BadgeCell";

type RequiredTableWidgetProps = Required<TableWidgetProps>;
export type TablePropsWithRequiredCompactMode = TableWidgetProps &
  Pick<RequiredTableWidgetProps, "compactMode">;

/**
 * Called to render `BadgeCell` into the table widget.
 */
export function renderBadgeCell(props: {
  tableProps: TablePropsWithRequiredCompactMode;
  cellProps: ReactTableCellProps<any>;
  appsmithCellProperties: CellLayoutProperties;
  appsmithColumnProperties: ColumnProperties;
  isHidden: boolean;
  onColumnEvent(event: OnColumnEventArgs): void;
}) {
  const {
    appsmithCellProperties,
    appsmithColumnProperties,
    cellProps,
    isHidden,
    onColumnEvent,
    tableProps,
  } = props;

  return (
    <BadgeCell
      allowCellWrapping={appsmithCellProperties.allowCellWrapping}
      cellBackground={appsmithCellProperties.cellBackground}
      columnActions={[
        {
          badgeColor:
            appsmithCellProperties.badgeColor ?? tableProps.primaryColor,
          id: appsmithColumnProperties.id,
          label: cellProps.cell.value,
          dynamicTrigger: appsmithColumnProperties.onClick || "",
        },
      ]}
      compactMode={tableProps.compactMode}
      fontStyle={appsmithCellProperties.fontStyle}
      horizontalAlignment={appsmithCellProperties.horizontalAlignment}
      isCellDisabled={appsmithCellProperties.isCellDisabled}
      isCellVisible={appsmithCellProperties.isCellVisible ?? true}
      isHidden={isHidden}
      isOnPage={tableProps.renderMode === RenderModes.PAGE}
      onCommandClick={(action: string, onComplete: () => void) =>
        onColumnEvent({
          rowIndex: cellProps.row.index,
          action,
          onComplete,
          triggerPropertyName: "onClick",
          eventType: EventType.ON_CLICK,
        })
      }
      textColor={appsmithCellProperties.textColor}
      textSize={appsmithCellProperties.textSize}
      verticalAlignment={appsmithCellProperties.verticalAlignment}
    />
  );
}
