import { ValidationTypes } from "../../../constants/WidgetValidation";
import {
  GraphWidgetHierarchicalModeDirection,
  GraphWidgetHierarchicalModeLayering,
  GraphWidgetMode,
} from "./types";
import { Validations } from "../../../utils/validation/common";
import type { GraphWidgetProps } from "./index";

export const styleConfig = [
  {
    sectionName: "General",
    children: [
      {
        propertyName: "nodeFigure",
        label: "Node figure",
        helpText: "Node geometrical figure",
        controlType: "DROP_DOWN",
        defaultValue: "circle",
        options: [
          {
            label: "Circle",
            value: "Circle",
          },
          {
            label: "Rounded rectangle",
            value: "RoundedRectangle",
          },
        ],
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "hasToolbar",
        label: "Toolbar",
        helpText: "Toolbar helping in interactions with diagram",
        controlType: "SWITCH",
        defaultValue: false,
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
    ],
  },
  {
    sectionName: "Mode related properties",
    children: [
      {
        helpText: "Sets the mode of the widget",
        propertyName: "mode",
        label: "Mode",
        controlType: "LABEL_ALIGNMENT_OPTIONS",
        options: [
          { label: "Force directed", value: GraphWidgetMode.ForceDirected },
          { label: "Layered", value: GraphWidgetMode.Layered },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        helpText: "Sets the direction of the graph",
        propertyName: "hierarchicalModeDirection",
        label: "Direction",
        controlType: "LABEL_ALIGNMENT_OPTIONS",
        options: [
          {
            label: "Right",
            value: GraphWidgetHierarchicalModeDirection.Right,
          },
          {
            label: "Down",
            value: GraphWidgetHierarchicalModeDirection.Down,
          },
          {
            label: "Left",
            value: GraphWidgetHierarchicalModeDirection.Left,
          },
          { label: "Up", value: GraphWidgetHierarchicalModeDirection.Up },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (props: GraphWidgetProps) =>
          props.mode !== GraphWidgetMode.Layered,
        dependencies: ["mode"],
      },
      {
        helpText:
          "Sets the layering of the graph. It influences how the nodes are arranged. More info about each option can be found at: https://gojs.net/latest/api/symbols/LayeredDigraphLayering.html",
        propertyName: "hierarchicalModeLayering",
        label: "Layering",
        helperText: (props: GraphWidgetProps) => {
          if (
            props.hierarchicalModeLayering ===
            GraphWidgetHierarchicalModeLayering.Hierarchical
          ) {
            return "Hierarchical layering requires a 'level' property (a number) to be defined in each node data object. Nodes with the same 'level' value will be placed on the same layer.";
          }

          return undefined;
        },
        controlType: "DROP_DOWN",
        options: [
          {
            label: "Optimal Link Length",
            value: GraphWidgetHierarchicalModeLayering.OptimalLinkLength,
          },
          {
            // This option doesn't work well when we organise nodes by hierarchy - nodes tend to overlap
            label: "Longest Path Source",
            value: GraphWidgetHierarchicalModeLayering.LongestPathSource,
          },
          {
            label: "Longest Path Sink",
            value: GraphWidgetHierarchicalModeLayering.LongestPathSink,
          },
          {
            label: "Hierarchical",
            value: GraphWidgetHierarchicalModeLayering.Hierarchical,
          },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        hidden: (props: GraphWidgetProps) =>
          props.mode !== GraphWidgetMode.Layered,
        dependencies: ["mode"],
      },
      {
        helpText: "Sets the spacing between layers",
        propertyName: "hierarchicalModeLayerSpacing",
        label: "Layer Spacing",
        placeholderText: "Enter integer value",
        controlType: "NUMERIC_INPUT",
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.NUMBER },
        hidden: (props: GraphWidgetProps) =>
          props.mode !== GraphWidgetMode.Layered,
        dependencies: ["mode"],
      },
      {
        helpText: "Sets the spacing between columns",
        propertyName: "hierarchicalModeColumnSpacing",
        label: "Column Spacing",
        placeholderText: "Enter integer value",
        controlType: "NUMERIC_INPUT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.NUMBER,
        },
        hidden: (props: GraphWidgetProps) =>
          props.mode !== GraphWidgetMode.Layered,
        dependencies: ["mode"],
      },
      {
        sectionName: "Align",
        hidden: (props: GraphWidgetProps) =>
          props.mode !== GraphWidgetMode.Layered,
        dependencies: ["mode"],
        children: [
          {
            propertyName: "hierarchicalModeAlignUpperLeft",
            label: "Upper Left",
            helpText: "Hierarchical upper left alignment",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "hierarchicalModeAlignUpperRight",
            label: "Upper Right",
            helpText: "Hierarchical upper right alignment",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "hierarchicalModeAlignLowerLeft",
            label: "Lower Left",
            helpText: "Hierarchical lower left alignment",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "hierarchicalModeAlignLowerRight",
            label: "Lower Right",
            helpText: "Hierarchical lower right alignment",
            controlType: "SWITCH",
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
    ],
  },
  {
    sectionName: "Size",
    children: [
      {
        helpText: "Node diameter",
        propertyName: "nodeDiameter",
        label: "Node diameter",
        placeholderText: "Enter integer value",
        controlType: "NUMERIC_INPUT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.NUMBER,
        },
      },
      {
        helpText: "Node text size",
        propertyName: "nodeTextSize",
        label: "Node text size in pt",
        placeholderText: "Enter integer value",
        controlType: "NUMERIC_INPUT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.NUMBER,
        },
      },
      {
        helpText: "Node border size",
        propertyName: "nodeBorderSize",
        label: "Node border size",
        placeholderText: "Enter integer value",
        controlType: "NUMERIC_INPUT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.NUMBER,
        },
      },
      {
        helpText: "Selected node border size",
        propertyName: "selectedNodeBorderSize",
        label: "Selected node border size",
        placeholderText: "Enter integer value",
        controlType: "NUMERIC_INPUT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.NUMBER,
        },
      },
    ],
  },
  {
    sectionName: "Color",
    children: [
      {
        propertyName: "backgroundColor",
        label: "Background color",
        helpText: "Background color",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: Validations.COLOR,
      },
      {
        propertyName: "nodeTextColor",
        label: "Node Text Color",
        helpText: "Controls the color of the text displayed inside nodes",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: Validations.COLOR,
      },
      {
        propertyName: "nodeColor",
        label: "Node Color",
        helpText: "Background node color",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        defaultValue: undefined,
        validation: Validations.COLOR,
      },
      {
        propertyName: "nodeBorderColor",
        label: "Node border color",
        helpText: "Node border color",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: Validations.COLOR,
      },
      {
        propertyName: "edgeColor",
        label: "Edge Color",
        helpText: "Edge color",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: Validations.COLOR,
      },
      {
        propertyName: "selectedNodeBackgroundColor",
        label: "Selected node background color",
        helpText: "Controls the background color of selected nodes",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: Validations.COLOR,
      },
      {
        propertyName: "selectedNodeBorderColor",
        label: "Selected node border color",
        helpText: "Selected node border color",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: Validations.COLOR,
      },
    ],
  },
];
