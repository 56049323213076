const defaultNodes = [
  {
    id: "Components/1",
    text: "Gateway1_ECU",
    color: "#9FD6DD",
    type: "gateway",
  },
  {
    id: "Components/2",
    text: "Receiver1_ECU",
    color: "#86efac",
    type: "receiver",
    group: 4,
  },
  {
    id: "Components/3",
    text: "Emitter_ECU",
    color: "#DD9FCF",
    type: "emitter",
  },
  {
    id: "Components/4",
    text: "Grouped1_ECU",
    color: "#86efac",
    type: "receiver",
    group: 2,
  },
  {
    id: "Components/6",
    text: "Grouped2_ECU",
    color: "#86efac",
    type: "receiver",
    group: 2,
  },
  {
    id: "Components/7",
    text: "Grouped3_ECU",
    color: "#86efac",
    type: "receiver",
    group: 2,
  },
  {
    id: "Components/12",
    text: "Grouped4_ECU",
    color: "#86efac",
    type: "receiver",
    group: 2,
  },
  {
    id: "Components/13",
    text: "Grouped5_ECU",
    color: "#86efac",
    type: "receiver",
    group: 5,
  },
  {
    id: "Components/14",
    text: "Grouped6_ECU",
    color: "#86efac",
    type: "receiver",
    group: 5,
  },
  {
    id: "Components/15",
    text: "Gateway2_ECU",
    color: "#9FD6DD",
    type: "gateway",
  },
  {
    id: "Components/16",
    text: "Grouped7_ECU",
    color: "#86efac",
    type: "receiver",
    group: 7,
  },
  {
    id: "Components/17",
    text: "Grouped8_ECU",
    color: "#86efac",
    type: "receiver",
    group: 7,
  },
  {
    id: "Components/18",
    text: "Grouped9_ECU",
    color: "#86efac",
    type: "receiver",
    group: 7,
  },
  {
    id: "Components/19",
    text: "Receiver2_ECU",
    color: "#86efac",
    type: "receiver",
    group: 9,
  },
  // Group with background color
  {
    id: 2,
    isGroup: true,
    orientation: "vertical",
    color: "#D9D9D9",
    text: "Receiver Group",
  },
  // Vertical groups
  {
    id: 4,
    isGroup: true,
    orientation: "vertical",
  },
  {
    id: 5,
    isGroup: true,
    orientation: "vertical",
  },
  {
    id: 7,
    isGroup: true,
    orientation: "vertical",
  },
  {
    id: 9,
    isGroup: true,
    orientation: "vertical",
  },
];

const defaultEdges = [
  {
    id: "Components/1_Components/2",
    from: "Components/1",
    to: "Components/2",
    color: "#c084fc",
    label: "VLAN_Connect",
    labelVisible: true,
  },
  {
    id: "Components/3_Components/4",
    from: "Components/3",
    to: "Components/4",
    color: "#c084fc",
    label: "VLAN_FAS",
    labelVisible: false,
  },
  {
    id: "Components/3_Components/5",
    from: "Components/3",
    to: "Components/5",
    color: "#c084fc",
    label: "VLAN_FAS",
    labelVisible: false,
  },
  {
    id: "Components/3_Components/6",
    from: "Components/3",
    to: "Components/6",
    color: "#c084fc",
    label: "VLAN_FAS",
    labelVisible: false,
  },
  {
    id: "Components/3_Components/7",
    from: "Components/3",
    to: "Components/7",
    color: "#c084fc",
    label: "VLAN_FAS",
    labelVisible: false,
  },
  {
    id: "Components/3_Components/1",
    from: "Components/3",
    to: "Components/1",
    color: "#c084fc",
    label: "VLAN_FAS",
    labelVisible: false,
  },
  {
    id: "Components/3_Components/12",
    from: "Components/3",
    to: "Components/12",
    color: "#c084fc",
    label: "VLAN_FAS",
    labelVisible: true,
  },
  {
    id: "Components/1_Components/13",
    from: "Components/1",
    to: "Components/13",
    color: "#c084fc",
    label: "VLAN_Cockpit",
    labelVisible: false,
  },
  {
    id: "Components/1_Components/14",
    from: "Components/1",
    to: "Components/14",
    color: "#c084fc",
    label: "VLAN_Cockpit",
    labelVisible: true,
  },
  {
    id: "Components/15_Components/16",
    from: "Components/15",
    to: "Components/16",
    color: "#c084fc",
    label: "VLAN_Komfort",
    labelVisible: false,
  },
  {
    id: "Components/15_Components/17",
    from: "Components/15",
    to: "Components/17",
    color: "#c084fc",
    label: "VLAN_Komfort",
    labelVisible: false,
  },
  {
    id: "Components/15_Components/18",
    from: "Components/15",
    to: "Components/18",
    color: "#c084fc",
    label: "VLAN_Komfort",
    labelVisible: false,
  },
  // Bidirect link
  {
    id: "Components/15_Components/1",
    from: "Components/15",
    to: "Components/1",
    color: "#c084fc",
    label: "VLAN_Komfort",
    labelVisible: true,
    bidirect: true,
    fromSide: "bottom",
    toSide: "top",
  },
  {
    id: "Components/1_Components/16",
    from: "Components/1",
    to: "Components/16",
    color: "#c084fc",
    label: "VLAN_Komfort",
    labelVisible: false,
  },
  {
    id: "Components/1_Components/17",
    from: "Components/1",
    to: "Components/17",
    color: "#c084fc",
    label: "VLAN_Komfort",
    labelVisible: false,
  },
  {
    id: "Components/1_Components/18",
    from: "Components/1",
    to: "Components/18",
    color: "#c084fc",
    label: "VLAN_Komfort",
    labelVisible: false,
  },
  {
    id: "Components/15_Components/19",
    from: "Components/15",
    to: "Components/19",
    color: "#f472b6",
    label: "CANFD05",
    labelVisible: true,
  },
];

export const defaultProps = {
  defaultNodes,
  defaultEdges,
};
