import * as go from "gojs";
import { DASH_WIDTH, NUMBER_OF_COLORS } from "./constants";

export class MultiColorLink extends go.Link {
  makeGeometry() {
    const geo = super.makeGeometry();

    const paths: go.Shape[] = []; // find all path Shapes
    this.elements.each((elt) => {
      if (elt.isPanelMain && elt instanceof go.Shape) {
        paths.push(elt);
      }
    });

    for (let i = 0; i < paths.length; i++) {
      // go through all path Shapes
      const shape = paths[i];
      if (i < NUMBER_OF_COLORS) {
        shape.visible = true; // make sure this Shape can be seen
        if (i > 0) {
          // and a stroke dash array so that it only draws the needed fraction
          shape.strokeDashArray = [
            0, // first dash is width-less, because we need to start with space to show the first color which is not dashed
            i * DASH_WIDTH, // space between previous colors dashes and first dash of this color
            DASH_WIDTH, // width of dash
            (NUMBER_OF_COLORS - 1 - i) * DASH_WIDTH, // space after current color dash
          ];
        }
      }
    }

    return geo;
  }
}
