import { ValidationTypes } from "../../../constants/WidgetValidation";
import { ICON_REGEX } from "./validations";

export const contentConfig = [
  {
    sectionName: "General",
    children: [
      {
        helpText: "Defines legend entries.",
        propertyName: "entries",
        label: "Entries",
        controlType: "INPUT_TEXT",
        placeholderText: '[{ "id": "1", "label": "wheel" }]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            children: {
              type: ValidationTypes.OBJECT,
              params: {
                required: true,
                allowedKeys: [
                  {
                    name: "id",
                    type: ValidationTypes.TEXT,
                    params: {
                      default: "1",
                      requiredKey: true,
                    },
                  },
                  {
                    name: "label",
                    type: ValidationTypes.TEXT,
                    params: {
                      default: "first",
                      required: true,
                    },
                  },
                  {
                    name: "icon",
                    type: ValidationTypes.TEXT,
                    params: {
                      regex: ICON_REGEX,
                    },
                  },
                  {
                    name: "color",
                    type: ValidationTypes.TEXT,
                    params: {
                      default: "#553DE9",
                    },
                  },
                ],
              },
            },
          },
        },
      },
      {
        helpText:
          "Defines the orientation of the legend. If set to 'Auto', the orientation will be chosen based on the widget's dimensions, selecting the most suitable option for its size.",
        propertyName: "orientation",
        label: "Orientation",
        controlType: "ICON_TABS",
        fullWidth: true,
        options: [
          { label: "Auto", value: "auto" },
          { label: "Horizontal", value: "horizontal" },
          { label: "Vertical", value: "vertical" },
        ],
        defaultValue: "auto",
        isBindProperty: true,
        isTriggerProperty: false,
        isJSConvertible: true,
        validation: { type: ValidationTypes.TEXT },
      },

      {
        helpText: "Defines disabled entries ids of the legend.",
        propertyName: "defaultDisabledIds",
        label: "Disabled entries ids",
        controlType: "INPUT_TEXT",
        placeholderText: '["1"]',
        inputType: "ARRAY",
        defaultValue: ["1", "2", "3"],
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            children: {
              type: ValidationTypes.TEXT,
            },
          },
        },
      },
    ],
  },
  {
    sectionName: "Events",
    children: [
      {
        helpText:
          "Function that will be fired on legend disabled entries change.",
        propertyName: "onDisabledEntriesChange",
        label: "onDisabledEntriesChange",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
    ],
  },
];
