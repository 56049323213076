import React from "react";
export const ZoomOutIcon: React.FC = () => {
  return (
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M6.6 4.2H3C2.67 4.2 2.4 4.47 2.4 4.8C2.4 5.13 2.67 5.4 3 5.4H6.6C6.93 5.4 7.2 5.13 7.2 4.8C7.2 4.47 6.93 4.2 6.6 4.2ZM11.736 10.464L8.772 7.5C9.294 6.726 9.6 5.802 9.6 4.8C9.6 2.148 7.452 0 4.8 0C2.148 0 0 2.148 0 4.8C0 7.452 2.148 9.6 4.8 9.6C5.802 9.6 6.726 9.294 7.5 8.772L10.464 11.736C10.626 11.898 10.854 12 11.1 12C11.598 12 12 11.598 12 11.1C12 10.854 11.898 10.626 11.736 10.464ZM4.8 8.4C2.814 8.4 1.2 6.786 1.2 4.8C1.2 2.814 2.814 1.2 4.8 1.2C6.786 1.2 8.4 2.814 8.4 4.8C8.4 6.786 6.786 8.4 4.8 8.4Z"
        fill="#485162"
        fillRule="evenodd"
      />
    </svg>
  );
};

ZoomOutIcon.displayName = "ZoomOutIcon";
