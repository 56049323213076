import { useEffect, useRef, useState } from "react";

import type { ViewerApi } from "../ViewerApi";

import { patchMarkers } from "./patchMarkers";
import { getRootNodeIdFromShape } from "./nodeOperations";
import { updateMarkerProperties } from "./updateMarkerProperties";
import { MARKER_ID, MARKER_URL } from "./constants";
import type { Marker, SpreadRenderedMarker } from "./types";

function removeNodes(viewer: ViewerApi, nodeIds: string[]) {
  void viewer.removeNode(nodeIds);
}

async function renderMarker<GMarkerMetadata>(
  viewer: ViewerApi,
  markerToRender: Marker<GMarkerMetadata>,
) {
  const isInitialMarkerModelExists = viewer.isNodeExists(MARKER_ID);
  if (!isInitialMarkerModelExists) {
    // If the model is not loaded, we load it and disable it.
    const baseUrl = window.location.origin;
    const markerUrl = `${baseUrl}${MARKER_URL}`;
    await viewer.addModel(markerUrl);
    viewer.setNodeEnabled(MARKER_ID, false);
  }
  const markerNode = await viewer.duplicateSubtree(MARKER_ID);
  if (!markerNode) {
    throw new Error("Could not duplicate marker node");
  }
  const nodeId = markerNode.id;
  const renderedMarker: SpreadRenderedMarker<GMarkerMetadata> = {
    ...markerToRender,
    nodeId,
  };
  void updateMarkerProperties(viewer, renderedMarker);
  return renderedMarker;
}

async function findMarkerFromNodeId<GMarkerMetadata>(
  viewer: ViewerApi | undefined,
  renderedMarkers: SpreadRenderedMarker<GMarkerMetadata>[],
  selectedNodeId: string,
): Promise<SpreadRenderedMarker<GMarkerMetadata> | null> {
  if (renderedMarkers.length === 0 || !viewer) {
    return null;
  }

  const possibleMarkerRootNodeId = await getRootNodeIdFromShape(
    viewer,
    selectedNodeId,
  );
  if (possibleMarkerRootNodeId === null) {
    return null;
  }

  const isSelectedNodeId = (marker: SpreadRenderedMarker<GMarkerMetadata>) =>
    marker.nodeId === possibleMarkerRootNodeId;
  const selectedMarker = renderedMarkers.find(isSelectedNodeId);
  if (!selectedMarker) {
    return null;
  }

  return selectedMarker;
}

interface ContextMenuData<GMarkerMetadata> {
  marker: SpreadRenderedMarker<GMarkerMetadata>;
  top: number;
  left: number;
}

export function useMarkers<GMarkerMetadata>(
  viewer: ViewerApi | undefined,
  isModelAdded: boolean,
  markers: Marker<GMarkerMetadata>[],
  resetDependencies: unknown[],
) {
  // We store the pending markers becuase we add the markers asynchronously
  // and we need to wait for all the markers to be added before we can update the markers.
  // Otherwise we may see more markers than we should.
  const pendingMarkers = useRef<
    Set<Promise<SpreadRenderedMarker<GMarkerMetadata>>>
  >(new Set());

  const [renderedMarkers, setRenderedMarkers] = useState<
    SpreadRenderedMarker<GMarkerMetadata>[]
  >([]);
  const [selectedMarker, setSelectedMarker] =
    useState<SpreadRenderedMarker<GMarkerMetadata> | null>(null);
  const [contextMenu, setContextMenu] =
    useState<ContextMenuData<GMarkerMetadata> | null>(null);

  useEffect(() => {
    pendingMarkers.current.clear();
    setRenderedMarkers([]);
  }, resetDependencies);

  useEffect(() => {
    if (!viewer || !isModelAdded) {
      return;
    }

    // We wait for pending markers to be added before we update the markers,
    // so we always provide correct `previousMarkers` to the patchMarkers function.
    // Otherwise we may see more markers than we should.
    void Promise.all(pendingMarkers.current).then(() => {
      const previousMarkers = renderedMarkers;

      void patchMarkers(previousMarkers, markers, {
        addMarker: async (markerToRender) => {
          // Here we add promise to the pending markers set.
          // When marker is added, we remove the promise from the set.
          const promise: Promise<SpreadRenderedMarker<GMarkerMetadata>> =
            new Promise((resolve) => {
              void renderMarker(viewer, markerToRender).then((marker) => {
                pendingMarkers.current.delete(promise);
                resolve(marker);
              });
            });
          pendingMarkers.current.add(promise);
          return promise;
        },
        updateMarker: (updatedMarker) => {
          return void updateMarkerProperties(viewer, updatedMarker);
        },
        removeMarker: (markerToRemove) => {
          return removeNodes(viewer, [markerToRemove.nodeId]);
        },
      }).then((newMarkers) => {
        setRenderedMarkers(newMarkers);
      });
    });
    // NOTE: do not add `renderedMarkers` to the dependency list to avoid infinite loop.
  }, [markers, viewer, isModelAdded]);

  useEffect(() => {
    if (renderedMarkers.length === 0 || !viewer) {
      return;
    }

    const removeNodeClickHandler = viewer.addEventListener(
      "nodeclick",
      ({ pointerInfo, targetNode }) => {
        // prevent selecting on right click of node
        if (pointerInfo.button === "right") {
          return;
        }
        void findMarkerFromNodeId(viewer, renderedMarkers, targetNode.id).then(
          (selectedRenderedMarker) => {
            if (selectedRenderedMarker !== null) {
              setSelectedMarker(selectedRenderedMarker);
            }
          },
        );
      },
    );

    const removeContextMenuHandler = viewer.addEventListener(
      "requestcontextmenu",
      ({ targetNode, x, y }) => {
        const targetID = targetNode?.id;
        if (!targetID) {
          return;
        }
        const leftPos = x;
        const topPos = y;
        void findMarkerFromNodeId(viewer, renderedMarkers, targetID).then(
          (selectedRenderedMarker) => {
            if (selectedRenderedMarker !== null) {
              setContextMenu({
                marker: selectedRenderedMarker,
                left: leftPos,
                top: topPos,
              });
            }
          },
        );
      },
    );

    return () => {
      removeNodeClickHandler();
      removeContextMenuHandler();
    };
  }, [viewer, renderedMarkers]);

  useEffect(
    () => () => {
      if (viewer && renderedMarkers.length > 0) {
        const nodeIds = renderedMarkers.map((marker) => marker.nodeId);
        removeNodes(viewer, nodeIds);

        setRenderedMarkers([]);
      }
    },
    [],
  );

  return {
    markers: renderedMarkers,
    selectedMarker,
    contextMenu,
  };
}
