import { ValidationTypes } from "../../../constants/WidgetValidation";

export const contentConfig = [
  {
    sectionName: "General",
    children: [
      {
        helpText: "Defines edges of the graph.",
        propertyName: "edges",
        label: "Edges",
        controlType: "INPUT_TEXT",
        placeholderText:
          '[{ "id": "1", "from": "1", "to": "2", "label": "has" }]',
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            unique: ["id"],
            children: {
              type: ValidationTypes.OBJECT,
              params: {
                required: true,
                allowedKeys: [
                  {
                    name: "id",
                    type: ValidationTypes.TEXT || ValidationTypes.NUMBER,
                    params: {
                      default: "1",
                      requiredKey: true,
                    },
                  },
                  {
                    name: "from",
                    type: ValidationTypes.TEXT || ValidationTypes.NUMBER,
                    params: {
                      default: "",
                      requiredKey: true,
                    },
                  },
                  {
                    name: "to",
                    type: ValidationTypes.TEXT || ValidationTypes.NUMBER,
                    params: {
                      default: "",
                      requiredKey: true,
                    },
                  },
                  {
                    name: "label",
                    type: ValidationTypes.TEXT,
                    params: {
                      default: "has",
                    },
                  },
                ],
              },
            },
          },
        },
      },
      {
        helpText: "Defines nodes of the graph.",
        propertyName: "nodes",
        label: "Nodes",
        controlType: "INPUT_TEXT",
        placeholderText: '[{ "id": "1", "label": "wheel" }]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            unique: ["id"],
            children: {
              type: ValidationTypes.OBJECT,
              params: {
                required: true,
                allowedKeys: [
                  {
                    name: "id",
                    type: ValidationTypes.TEXT || ValidationTypes.NUMBER,
                    params: {
                      default: "1",
                      requiredKey: true,
                    },
                  },
                  {
                    name: "label",
                    type: ValidationTypes.TEXT,
                    params: {
                      default: "first",
                      required: true,
                    },
                  },
                  {
                    name: "color",
                    type: ValidationTypes.TEXT,
                    params: {
                      default: "#553DE9",
                    },
                  },
                ],
              },
            },
          },
        },
      },
      {
        helpText: "Defines selected nodes indices of the graph.",
        propertyName: "defaultSelectedNodes",
        label: "Selected nodes",
        controlType: "INPUT_TEXT",
        placeholderText: '["1"]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            default: [],
            children: {
              type: ValidationTypes.TEXT || ValidationTypes.NUMBER,
            },
          },
        },
      },
      {
        helpText: "Defines nodes that are hidden in the graph.",
        propertyName: "hiddenNodeIds",
        label: "Hidden node ids",
        controlType: "INPUT_TEXT",
        placeholderText: '["1"]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            default: [],
            children: {
              type: ValidationTypes.TEXT || ValidationTypes.NUMBER,
            },
          },
        },
      },
    ],
  },
  {
    sectionName: "Events",
    children: [
      {
        helpText: "function that will be fired on graph node selection change",
        propertyName: "onSelectionChange",
        label: "onSelectionChange",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
      {
        helpText: "function that will be fired on graph element right click",
        propertyName: "onRightClick",
        label: "onRightClick",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
    ],
  },
];
