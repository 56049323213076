import React from "react";
import { Pill } from "./Pill";

import type { PillData } from "./types";

interface PillsOverlayProps {
  pillData: PillData[] | null;
}

export const PillsOverlay = ({ pillData }: PillsOverlayProps) => {
  if (!pillData) {
    return null;
  }

  return (
    <>
      {pillData.map((pillData) => (
        <Pill key={pillData.id} pillData={pillData} />
      ))}
    </>
  );
};
