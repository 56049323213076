import * as go from "gojs";

import type { ThemeType } from "./theme";
import { FlatLayeredDigraphLayout } from "./FlatLayeredDigraphLayout";
import { makeTemplates } from "./makeTemplates";
import { makeInitOverview } from "./Overview";
import type { DiagramLink, DiagramNode } from ".";

export type Fonts = ThemeType["fonts"];
export type FontWeights = ThemeType["fontWeights"];
export type Colors = ThemeType["colors"];

export type OnComponentClickHandler = (componentNodeData: DiagramNode) => void;

export interface DiagramEvents {
  onComponentClick: OnComponentClickHandler;
  onConnectionClick: OnConnectionClickHandler;
}

export interface OnConnectionClickHandler<GLinkMetadata = unknown> {
  (linkData: DiagramLink<GLinkMetadata>): void;
}
export const makeInitDiagram =
  (
    fonts: Fonts,
    fontWeights: FontWeights,
    colors: Colors,
    events: DiagramEvents,
    overviewId: string,
    gojsLicenseKey: string,
    backgroundColor = colors.tertiary.whiteDarken,
  ) =>
  () => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const $ = go.GraphObject.make;

    go.Diagram.licenseKey = gojsLicenseKey || "";

    const diagram = go.GraphObject.make(go.Diagram, {
      layout: $(FlatLayeredDigraphLayout, {
        setsPortSpots: false,
        direction: 90,
        layerSpacing: 230,
        linkSpacing: 40, // Undocumented prop https://forum.nwoods.com/t/how-to-control-spacing-between-vertical-line-link/5462
      }),
      model: $(go.GraphLinksModel, {
        nodeKeyProperty: "id",
        linkKeyProperty: "id",
        linkFromPortIdProperty: "fromPort",
        linkToPortIdProperty: "toPort",
        nodeCategoryProperty: "category",
      }),
      padding: new go.Margin(200, 50, 180, 50),
    });
    const templateMap = new go.Map<string, go.Node>();
    const {
      cuttingPointTemplate,
      endNodeTemplate,
      linkTemplate,
      startNodeTemplate,
    } = makeTemplates(fonts, fontWeights, colors, events, backgroundColor);

    templateMap.add("cuttingPoint", cuttingPointTemplate);
    templateMap.add("endObject", endNodeTemplate);
    templateMap.add("", startNodeTemplate);

    diagram.nodeTemplateMap = templateMap;
    diagram.nodeTemplate = startNodeTemplate;
    diagram.linkTemplate = linkTemplate;

    makeInitOverview(diagram, colors.random.orangeHard, overviewId);

    return diagram;
  };
