import * as go from "gojs";

export const registerBottomlessRectangleShape = () => {
  go.Shape.defineFigureGenerator(
    "BottomlessRectangle",
    function (shape, width, height) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const strokeWidth = shape?.strokeWidth || 2;

      const offset = strokeWidth / 2;

      const minY = -offset;
      const maxY = height + offset;

      const minX = 0;
      const maxX = width;

      const cornerSize = strokeWidth;

      const path = new go.PathFigure(minX, maxY)
        .add(new go.PathSegment(go.PathSegment.Line, minX, minY + cornerSize))
        .add(
          new go.PathSegment(
            go.PathSegment.Arc,
            180,
            90,
            minX + cornerSize,
            minY + cornerSize,
            cornerSize,
            cornerSize,
          ),
        )
        .add(new go.PathSegment(go.PathSegment.Line, maxX - cornerSize, minY))
        .add(
          new go.PathSegment(
            go.PathSegment.Arc,
            270,
            90,
            maxX - cornerSize,
            minY + cornerSize,
            cornerSize,
            cornerSize,
          ),
        )
        .add(new go.PathSegment(go.PathSegment.Line, maxX, maxY));

      const geo = new go.Geometry();
      geo.add(path);

      geo.spot1 = new go.Spot(0, 0, -(strokeWidth + offset), 0);
      geo.spot2 = new go.Spot(1, 1, strokeWidth + offset, offset);
      geo.normalize();

      return geo;
    },
  );
};
