import { useEffect, useMemo, useState } from "react";
import type { ViewerApi } from "../ViewerApi";
import type { RecursiveNode } from "@spread-ai/softy-renderer-react";
import type { MouseButton } from "@spread-ai/softy-renderer-react/dist/types/model/interfaces/ViewerWithClickableNodesAndBackground";

interface ContextMenuData {
  targetId?: string;
  anchorPosition: { top: number; left: number };
}

interface UseContextMenuArgs {
  viewerApi: ViewerApi | undefined;
  enabled: boolean;
}

export const useContextMenu = ({ enabled, viewerApi }: UseContextMenuArgs) => {
  const [contextMenuData, setContextMenuData] = useState<ContextMenuData>();

  const closeContextMenu = () => {
    setContextMenuData(undefined);
  };

  // Click outside of context menu or request context menu on another node closes renderer widget context menu
  useEffect(() => {
    if (!contextMenuData) return;

    window.addEventListener("click", closeContextMenu);
    window.addEventListener("contextmenu", closeContextMenu);
    window.addEventListener("touchend", closeContextMenu);
    return () => {
      window.removeEventListener("click", closeContextMenu);
      window.removeEventListener("contextmenu", closeContextMenu);
      window.removeEventListener("touchend", closeContextMenu);
    };
  }, [contextMenuData]);

  /**
   * White list of node ids that can be clicked on with passing targetId to context menu.
   *
   * This is needed to limit context menu content on nodes that are not the part of current model,
   * i.e. utility nodes of other widget features, etc.
   */
  const whiteListedNodeIds = useMemo(() => {
    if (!viewerApi || !enabled) return;

    const modelsSubtree = viewerApi.getViewer().getModelsSubRoot();

    if (!modelsSubtree) return;

    return getIdsOfNodesInSubtree(modelsSubtree);
  }, [viewerApi, enabled]);

  // Request context menu listener
  useEffect(() => {
    if (!viewerApi || !whiteListedNodeIds) return;

    const removeContextMenuListener = viewerApi.addEventListener(
      "requestcontextmenu",
      ({ targetNode, x, y }) => {
        const targetId = getTargetNodeId(whiteListedNodeIds, targetNode);
        const anchorPosition = { top: y, left: x };
        setContextMenuData({ targetId, anchorPosition });
      },
    );

    return () => {
      removeContextMenuListener();
    };
  }, [viewerApi, whiteListedNodeIds]);

  // Close context menu event listeners
  useEffect(() => {
    if (!viewerApi) return;

    const clickHandler = (event: { pointerInfo: { button: MouseButton } }) => {
      // to prevent closing context menu right after opening it
      if (event.pointerInfo.button !== "right") {
        closeContextMenu();
      }
    };

    const removeBackgroundClickListener = viewerApi?.addEventListener(
      "backgroundclick",
      clickHandler,
    );

    const removeNodeClickListener = viewerApi?.addEventListener(
      "nodeclick",
      clickHandler,
    );

    const removeViewChangeListener = viewerApi?.addEventListener(
      "viewchange",
      () => {
        closeContextMenu();
      },
    );

    return () => {
      removeBackgroundClickListener();
      removeNodeClickListener();
      removeViewChangeListener();
    };
  }, [viewerApi]);

  return { contextMenuData, closeContextMenu };
};

const getIdsOfNodesInSubtree = (node: RecursiveNode): Set<string> => {
  const ids = new Set<string>();
  ids.add(node.id);

  if (node.children) {
    node.children.forEach((child) => {
      getIdsOfNodesInSubtree(child).forEach((id) => ids.add(id));
    });
  }

  return ids;
};

/**
 * Returns id of the target node.
 * Return undefined if click on background or on node that is not in white list.
 */
const getTargetNodeId = (
  whiteListedNodeIds: Set<string>,
  targetNode?: RecursiveNode,
): string | undefined => {
  // If click on background we should return undefined
  if (!targetNode) return undefined;

  // If click on node that is not in white list, we should return undefined
  if (!whiteListedNodeIds?.has(targetNode.id)) return undefined;

  // Otherwise we should return id of the clicked node
  return targetNode.id;
};
