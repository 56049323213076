import * as go from "gojs";
import { getDiagramLayout } from "./layout";
import { isNil } from "lodash";
import type {
  GraphWidgetHierarchicalModeDirection,
  GraphWidgetHierarchicalModeLayering,
  GraphWidgetMode,
} from "../widget/types";

export type NodeId = string | number;

export interface InitDiagramProps {
  gojsLicenseKey?: string;

  onAddNodeToSelection?: (nodeId: NodeId) => void;
  onRemoveNodeFromSelection?: (nodeId: NodeId) => void;
  onClearSelection?: () => void;

  mode: GraphWidgetMode;
  hierarchicalModeDirection: GraphWidgetHierarchicalModeDirection;
  hierarchicalModeLayering: GraphWidgetHierarchicalModeLayering;
  hierarchicalModeLayerSpacing: number;
  hierarchicalModeColumnSpacing: number;
  hierarchicalModeAlignOption: number;

  onRightClick: (nodeId: number | string) => void;
  onAddNodeToSingleSelection: (nodeId: NodeId) => void;
}

function isPart(node: go.GraphObject): node is go.Part {
  return node instanceof go.Part;
}
export const makeInitDiagram = (props: InitDiagramProps) => () => {
  return initDiagram(props);
};

const initDiagram = (props: InitDiagramProps): go.Diagram => {
  const $ = go.GraphObject.make;
  const layout = getDiagramLayout(
    props.mode,
    props.hierarchicalModeDirection,
    props.hierarchicalModeLayering,
    props.hierarchicalModeLayerSpacing,
    props.hierarchicalModeColumnSpacing,
    props.hierarchicalModeAlignOption,
  );
  go.Diagram.licenseKey = props.gojsLicenseKey || "";
  const diagram = $(go.Diagram, {
    initialAutoScale: go.Diagram.Uniform,
    layout,
    model: $(go.GraphLinksModel, {
      nodeKeyProperty: "id",
      linkKeyProperty: "id",
    }),
    // do an extra layout at the end of a move
    SelectionMoved: (e) => e.diagram.layout.invalidateLayout(),
    BackgroundSingleClicked: () => {
      props.onClearSelection?.();
    },
  });

  diagram.nodeTemplate = $(
    go.Node,
    "Auto",
    {
      // node tooltip
      toolTip: $("ToolTip", $(go.TextBlock, new go.Binding("text", "label"))),
      click: (e, node) => {
        if (
          isNil(props.onAddNodeToSelection) ||
          isNil(props.onClearSelection) ||
          isNil(props.onRemoveNodeFromSelection)
        ) {
          return;
        }

        if (!isPart(node)) return;

        const nodeId = node.key as NodeId;
        const isCtrlPressed = e.diagram.lastInput.control;
        const isMetaPressed = e.diagram.lastInput.meta;
        if (isCtrlPressed || isMetaPressed) {
          if (node.isSelected) {
            props.onAddNodeToSelection?.(nodeId);
          } else {
            props.onRemoveNodeFromSelection?.(nodeId);
          }
        } else {
          props.onAddNodeToSingleSelection?.(nodeId);
        }
      },
      contextClick: (_, node) => {
        if (!isPart(node)) return;

        const nodeId = node.key as NodeId;

        props.onRightClick?.(nodeId);
      },
    },
    // node shape settings
    $(
      go.Shape,
      new go.Binding("fill", "color"),
      new go.Binding("width", "nodeDiameter").ofModel(),
      new go.Binding("height", "nodeDiameter").ofModel(),
      new go.Binding("figure", "nodeFigure").ofModel(),
      new go.Binding("strokeWidth", "nodeBorderSize").ofModel(),
      new go.Binding("stroke", "nodeBorderColor").ofModel(),
    ),
    // node text settings
    $(
      go.TextBlock,
      {
        textAlign: "center",
        overflow: go.TextBlock.OverflowEllipsis,
        maxLines: 3,
      },
      new go.Binding("text", "label"),
      new go.Binding("stroke", "nodeTextColor").ofModel(),
      new go.Binding("width", "nodeTextWidth").ofModel(),
      new go.Binding("font", "nodeFont").ofModel(),
    ),
    // selected node settings
    {
      selectionAdornmentTemplate: $(
        go.Adornment,
        "Auto",
        $(
          go.Shape,
          { fill: null },
          new go.Binding("figure", "nodeFigure").ofModel(),
          new go.Binding("width", "nodeDiameter").ofModel(),
          new go.Binding("height", "nodeDiameter").ofModel(),
          new go.Binding("stroke", "selectedNodeBorderColor").ofModel(),
          new go.Binding("strokeWidth", "selectedNodeBorderSize").ofModel(),
        ),
        $(go.Placeholder),
      ),
    },
  );

  diagram.linkTemplate = $(
    go.Link,
    // edge shape settings
    {
      layerName: "Background",
      click: (_, node) => {
        if (!isPart(node)) return;

        props.onClearSelection?.();
      },
    },
    $(go.Shape, new go.Binding("stroke", "edgeColor").ofModel()),
    $(
      go.Shape, // the arrowhead
      {
        toArrow: "OpenTriangle",
      },
      new go.Binding("stroke", "edgeColor").ofModel(),
    ),
    // edge label settings
    $(
      go.Panel,
      "Auto",
      $(
        go.Shape,
        {
          stroke: null,
        },
        new go.Binding("fill", "labelBackground"),
      ),
      $(
        go.TextBlock,
        {
          textAlign: "center",
          font: "10pt helvetica, arial, sans-serif",
          margin: 4,
        },
        new go.Binding("stroke", "edgeColor"),
        new go.Binding("text", "label"),
      ),
    ),
  );

  return diagram;
};
