import { mat4 } from "gl-matrix";
import { isNil } from "lodash";

import type { ViewerApi } from "../ViewerApi";

import type { SpreadRenderedMarker } from "./types";

export function isSupportedMarkerNumber(
  markerNumber?: number,
): markerNumber is number {
  if (markerNumber === undefined || markerNumber === 0 || markerNumber > 9) {
    return false;
  }

  return true;
}

import { getShapeNodeIdsFromModel } from "./nodeOperations";

export async function updateMarkerProperties(
  viewer: ViewerApi,
  marker: SpreadRenderedMarker,
) {
  const {
    coordinates,
    isEnabled,
    label,
    nodeId,
    number,
    numberColor,
    scale: scaleFactor,
    shape,
    shapeColor,
  } = marker;

  // Nodes are loaded after we set the "enabled" property to true.
  // After the first load, the model is cached for every subsequent load (no matter the shape).
  const shapeNodeIdsResult = await getShapeNodeIdsFromModel(
    viewer,
    nodeId,
    shape,
  );

  if (!shapeNodeIdsResult) {
    return null;
  }
  const targetShapeContainerNode = shapeNodeIdsResult.containerNode;
  const allNodeIds = shapeNodeIdsResult.nodeIds;

  const shapeNodeId = allNodeIds.shape;
  const numbersNodeIds = [
    allNodeIds.digit1,
    allNodeIds.digit2,
    allNodeIds.digit3,
    allNodeIds.digit4,
    allNodeIds.digit5,
    allNodeIds.digit6,
    allNodeIds.digit7,
    allNodeIds.digit8,
    allNodeIds.digit9,
  ];

  // Coordinates should be multiplied by 1000 to be in the same scale as the model.
  // However, it should be done in the higher level, not here.
  // So pills also have proper coordinates.
  /*
    const coordinatesMultiplier = 1000;
    const coordinatesMultiplied = coordinates.map(
        coordinate => coordinate * coordinatesMultiplier
    );
  */
  // It's ConAn case, so it seems we don't need to multiply the coordinates in SpreadStudio.
  // Anyway, we should double check it with real data first.

  const position = mat4.translate(
    mat4.create(),
    mat4.create(),
    new Float32Array(coordinates),
  );

  const scale = mat4.scale(
    mat4.create(),
    position,
    new Float32Array([scaleFactor, scaleFactor, scaleFactor]),
  );

  const shapesContainerNode = viewer.getNodeChildren(nodeId)?.[0];
  if (isNil(shapesContainerNode)) {
    throw new Error("Marker node has no children");
    // should be unreachable, but we do it for type safety
  }

  const allShapesContainersIds = viewer.getNodeChildren(shapesContainerNode);
  if (isNil(allShapesContainersIds)) {
    throw new Error("Shapes container node has no children");
    // should be unreachable, but we do it for type safety
  }

  allShapesContainersIds.forEach((shapeContainerNodeId) => {
    const isTargetShape = shapeContainerNodeId === targetShapeContainerNode.id;
    void viewer.setNodeEnabled(shapeContainerNodeId, isTargetShape);
  });

  void viewer.setNodeLocalTransform(nodeId, scale);

  void viewer.setNodeSelectable(shapeNodeId, false); // Disable selection
  void viewer.setNodeEnabled(shapeNodeId, isEnabled);
  void viewer.setNodeLabel(shapeNodeId, label);
  void viewer.setNodeColor(shapeNodeId, shapeColor);

  numbersNodeIds.forEach((numberNodeId) => {
    void viewer.setNodeEnabled(numberNodeId, false);
  });

  if (isSupportedMarkerNumber(number)) {
    const numberNodeId = numbersNodeIds[number - 1];
    void viewer.setNodeClickable(numberNodeId, false);
    void viewer.setNodeEnabled(numberNodeId, isEnabled);
    void viewer.setNodeColor(numberNodeId, numberColor);
  }
}
