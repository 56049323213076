import type {
  RecursiveNode,
  ViewerContext3D,
} from "@spread-ai/softy-renderer-react";
import type {
  BackgroundClickListener,
  NodeClickListener,
} from "@spread-ai/softy-renderer-react/dist/types/model/interfaces/ViewerWithClickableNodesAndBackground";
import type { ContextMenuRequestListener } from "@spread-ai/softy-renderer-react/dist/types/model/interfaces/ViewerWithContextMenu";
import type { ViewProjectionMatrixUpdateListener } from "@spread-ai/softy-renderer-react/dist/types/model/interfaces/ViewerWithControlableCamera";
import type { ModelAddedListener } from "@spread-ai/softy-renderer-react/dist/types/model/interfaces/ViewerWithModelsAddition";
import type { SelectedNodesUpdateListener } from "@spread-ai/softy-renderer-react/dist/types/model/interfaces/ViewerWithSelectableNodes";
import { isNil } from "lodash";
import { sort } from "ramda";

import type { FlatFourByFourMatrix, NodeId } from "./types";

type SelectedGroupsChangeListener = (groupIds: string[]) => void;

interface ViewerEventHandlerMap {
  nodeclick: NodeClickListener;
  selectionchange: SelectedNodesUpdateListener;
  backgroundclick: BackgroundClickListener;
  requestcontextmenu: ContextMenuRequestListener;
  viewchange: ViewProjectionMatrixUpdateListener;
  modeladded: ModelAddedListener;
  groupsselectionchange: SelectedGroupsChangeListener;
}

/**
 * Generic options applicable to all methods of ViewerApi
 */
interface GenericOptions {
  /**
   * If true, the change will be considered as committed by user,
   * if false - as committed by system (markers logic, etc.)
   */
  committedByUser?: boolean;
}

const sortNodesById = sort<RecursiveNode>((a, b) => a.id.localeCompare(b.id));

export class ViewerApi {
  constructor(private viewerContext: ViewerContext3D) {}

  getViewer(): ViewerContext3D {
    return this.viewerContext;
  }

  removeNode(nodeId: NodeId | NodeId[]) {
    if (Array.isArray(nodeId)) {
      return this.viewerContext.removeNodes(nodeId);
    }
    return this.viewerContext.removeNode(nodeId);
  }

  isNodeExists(nodeId: NodeId) {
    return this.viewerContext.isNodeExists(nodeId);
  }

  async addModel(modelUrl: string) {
    return this.viewerContext.addModel(modelUrl);
  }

  /**
   * IDs of nodes that are hidden by user
   */
  hiddenNodesByUser = new Set<string>();

  getHiddenByUserNodes(): NodeId[] {
    return [...this.hiddenNodesByUser];
  }

  setNodeEnabled(
    nodeId: NodeId | NodeId[],
    enabled: boolean,
    options?: GenericOptions,
  ) {
    // handle array of node ids
    if (Array.isArray(nodeId)) {
      nodeId.forEach((id) => this.setNodeEnabled(id, enabled, options));
      return;
    }

    // handle single node id
    this.viewerContext.setNodeVisible(nodeId, enabled);

    if (options?.committedByUser && !enabled) {
      // if node is hidden by user, add it to the list of hidden nodes
      this.hiddenNodesByUser.add(nodeId);
    } else {
      // if node is shown (regardless of who did it), remove it from the list of hidden nodes
      this.hiddenNodesByUser.delete(nodeId);
    }
  }

  setHiddenNodes(nodes: NodeId[], options?: GenericOptions) {
    // First, disable hiding for all nodes that are hidden but not in the list (anymore)
    [...this.hiddenNodesByUser]
      .filter((nodeId) => !nodes.includes(nodeId))
      .forEach((nodeId) => {
        this.setNodeEnabled(nodeId, true, options);
      });

    // Hide all nodes that are in the list
    this.setNodeEnabled(nodes, false, options);
  }

  isAnyNodeHiddenByUser() {
    return this.hiddenNodesByUser.size > 0;
  }

  unhideAllNodesHiddenByUser() {
    this.hiddenNodesByUser.forEach((nodeId) => {
      this.setNodeEnabled(nodeId, true);
    });
    this.hiddenNodesByUser.clear();
  }

  /**
   * Hides all nodes of initial model and shows only node with given id
   */
  showOnlyNode(nodeId: NodeId, options?: GenericOptions) {
    const initialModelId = this.viewerContext.getInitialModelId();
    if (initialModelId) this.setNodeEnabled(initialModelId, false, options);
    this.setNodeEnabled(nodeId, true, options);
  }

  async duplicateSubtree(nodeId: NodeId, targetParentId?: NodeId) {
    const newSubtree = await this.viewerContext.duplicateSubtree(
      nodeId,
      targetParentId,
    );
    return newSubtree;
  }

  addEventListener = <GEventName extends keyof ViewerEventHandlerMap>(
    eventName: GEventName,
    handler: ViewerEventHandlerMap[GEventName],
  ) => {
    if (eventName === "groupsselectionchange") {
      const handlerId = this.viewerContext.addSelectedGroupsUpdateListener(
        handler as SelectedGroupsChangeListener,
      );

      const removeListener = () => {
        this.viewerContext.removeSelectedGroupsUpdateListener(handlerId);
      };

      return removeListener;
    }

    if (eventName === "selectionchange") {
      // This is a workaround for the fact that the selection change event
      // is fired even when the selection did not really change.
      // ie. setting the selected nodes to the same value as before
      // still triggers the event, even though it shouldn't.
      // TODO: Fix renderer to not fire the event when the selection did not effectively change.
      let previousSelectedNodesSorted: RecursiveNode[] | undefined = undefined;
      const onSelectionChange: SelectedNodesUpdateListener = (
        newSelectedNodes,
      ) => {
        // using sort + JSON.stringify() to compare the arrays
        // as it's much faster than Ramda's symmetricDifference()
        const newSelectedNodesSorted = sortNodesById(newSelectedNodes);
        const didSelectionReallyChange =
          previousSelectedNodesSorted === undefined ||
          JSON.stringify(previousSelectedNodesSorted) !==
            JSON.stringify(newSelectedNodesSorted);

        if (didSelectionReallyChange) {
          previousSelectedNodesSorted = newSelectedNodesSorted;
          (handler as SelectedNodesUpdateListener)(newSelectedNodes);
        }
      };

      const handlerId =
        this.viewerContext.addSelectedNodesUpdateListener(onSelectionChange);

      const removeListener = () => {
        this.viewerContext.removeSelectedNodesUpdateListener(handlerId);
      };

      return removeListener;
    }

    if (eventName === "viewchange") {
      const handlerId =
        this.viewerContext.addViewProjectionMatrixUpdateListener(
          handler as ViewProjectionMatrixUpdateListener,
        );

      const removeListener = () => {
        this.viewerContext.removeViewProjectionMatrixUpdateListener(handlerId);
      };

      return removeListener;
    }

    if (eventName === "backgroundclick") {
      const handlerId = this.viewerContext.addBackgroundClickListener(
        handler as BackgroundClickListener,
      );

      const removeListener = () => {
        this.viewerContext.removeBackgroundClickListener(handlerId);
      };

      return removeListener;
    }

    if (eventName === "nodeclick") {
      const handlerId = this.viewerContext.addNodeClickListener(
        handler as NodeClickListener,
      );

      const removeListener = () => {
        this.viewerContext.removeNodeClickListener(handlerId);
      };

      return removeListener;
    }

    if (eventName === "requestcontextmenu") {
      const handlerId = this.viewerContext.addContextMenuRequestListener(
        handler as ContextMenuRequestListener,
      );

      const removeListener = () => {
        this.viewerContext.removeContextMenuRequestListener(handlerId);
      };

      return removeListener;
    }

    if (eventName === "modeladded") {
      const handlerId = this.viewerContext.addModelAddedListener(
        handler as ModelAddedListener,
      );

      const removeListener = () => {
        this.viewerContext.removeModelAddedListener(handlerId);
      };

      return removeListener;
    }

    throw new Error(
      `addEventListener() Error: Unknown event name: ${eventName}`,
    );
  };

  getNodeParent = (nodeId: NodeId) => {
    return this.viewerContext.getNodeParent(nodeId);
  };

  getNodeChildren(nodeId: NodeId) {
    return this.viewerContext.getNodeChildren(nodeId);
  }

  getSubtree(nodeId: NodeId) {
    return this.viewerContext.getSubtree(nodeId);
  }

  setNodeLocalTransform(
    nodeId: NodeId | NodeId[],
    transform: Float32Array | FlatFourByFourMatrix,
  ) {
    if (Array.isArray(nodeId)) {
      return nodeId.map((id) =>
        this.viewerContext.setNodeLocalTransform(id, transform),
      );
    }
    return this.viewerContext.setNodeLocalTransform(nodeId, transform);
  }

  setNodeLabel(nodeId: NodeId | NodeId[], label: string) {
    if (Array.isArray(nodeId)) {
      return nodeId.map((id) => this.viewerContext.setNodeLabel(id, label));
    }
    return this.viewerContext.setNodeLabel(nodeId, label);
  }

  setNodeColor(nodeId: NodeId | NodeId[], color: string) {
    if (Array.isArray(nodeId)) {
      return nodeId.map((id) => this.viewerContext.setNodeColor(id, color));
    }
    return this.viewerContext.setNodeColor(nodeId, color);
  }

  setNodeClickable(nodeId: NodeId | NodeId[], pickable: boolean) {
    if (Array.isArray(nodeId)) {
      nodeId.map((id) => this.viewerContext.setNodeClickable(id, pickable));
      return;
    }
    return this.viewerContext.setNodeClickable(nodeId, pickable);
  }

  setNodeSelectable(nodeId: NodeId | NodeId[], selectable: boolean) {
    if (Array.isArray(nodeId)) {
      nodeId.forEach((id) =>
        this.viewerContext.setNodeSelectable(id, selectable),
      );
      return;
    }
    return this.viewerContext.setNodeSelectable(nodeId, selectable);
  }

  projectPointToCanvas(coordinates: number[]) {
    const canvasCoords = this.viewerContext.projectPointToCanvas({
      x: coordinates[0],
      y: coordinates[1],
      z: coordinates[2],
    }) || { x: 0, y: 0 };

    return { left: canvasCoords.x, top: canvasCoords.y } as const;
  }

  zoomToFit(nodes?: NodeId | NodeId[]) {
    const focusTarget = nodes ? (Array.isArray(nodes) ? nodes : [nodes]) : [];
    this.viewerContext.focusOnNodes(focusTarget);
  }

  /**
   * IDs of nodes that are ghosted
   */
  ghostedNodes = new Set<string>();

  private setNodeGhosted(nodeId: NodeId, enabled: boolean) {
    if (enabled) {
      this.ghostedNodes.add(nodeId);
    } else {
      this.ghostedNodes.delete(nodeId);
    }

    this.viewerContext.setNodeXray(nodeId, enabled);
    this.viewerContext.setNodeClickable(nodeId, !enabled);
  }

  setEntireModelGhosted(enabled: boolean) {
    const id = this.viewerContext.getInitialModelId();

    if (isNil(id)) return;

    this.setNodeGhosted(id, enabled);
  }

  setGhostedNodes(nodes: NodeId[]) {
    // First, disable ghosting for all nodes that are ghosted but not in the list (anymore)
    [...this.ghostedNodes]
      .filter((nodeId) => !nodes.includes(nodeId))
      .forEach((nodeId) => {
        this.setNodeGhosted(nodeId, false);
      });

    // Enable ghosting for all nodes that are in the list
    for (const nodeId of nodes) {
      this.setNodeGhosted(nodeId, true);
    }
  }

  // Collections methods
  setActiveCollectionId(collectionId: string) {
    this.viewerContext.selectionSetActiveCollection(collectionId);
  }

  createCollection(collectionId: string) {
    this.viewerContext.selectionCreateCollection(collectionId);
  }

  createGroup(id: string, nodeIds: string[], collectionId: string) {
    this.viewerContext.selectionCreateGroup(id, nodeIds, collectionId);
  }

  extendSelection(nodeIds: string[]) {
    this.viewerContext.selectionExtendSelection(nodeIds);
  }

  // remove: active collection id, created collections, created groups, selected groups
  clearGroupsState() {
    this.viewerContext.selectionClearState();
  }

  setSelection(nodeIds: string[]) {
    this.viewerContext.selectionSetSelection(nodeIds);
  }

  setNonGroupsMemberSelection(nodeIds: string[]) {
    this.viewerContext.selectionSetNonMemberSelection(nodeIds);
  }

  setGroupsSelection(nodeIds: string[]) {
    this.viewerContext.selectionSetGroupSelection(nodeIds);
  }
}
