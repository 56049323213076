/**
 * Extracts the flowId from the runFlow argument value.
 *  - If the argument is a string, it returns the string without quotes.
 *  - If the argument is not a string (eg. a variable), it returns null.
 *
 * @example
 * ```ts
 * getFlowIdFromRunFlowArgument('"A_FLOW_ID"'); //> "A_FLOW_ID"
 * getFlowIdFromRunFlowArgument("'A_FLOW_ID'"); //> "A_FLOW_ID"
 * getFlowIdFromRunFlowArgument("Input1.text"); //> null
 * ```
 *
 * @param flowIdArgValue `flowId` as returned by `getRunFlowArguments`.
 */
export function getFlowIdFromRunFlowArgument(flowIdArgValue: string) {
  const trimmedFlowIdArgValue = flowIdArgValue.trim();
  // checks if it starts and ends with a quote (single or double) with a regex
  const isQuoted = /^["'].*["']$/.test(trimmedFlowIdArgValue);
  if (isQuoted) {
    return trimmedFlowIdArgValue.slice(1, -1);
  }

  return null;
}
