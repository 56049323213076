import * as go from "gojs";

export const changeLinkStyle = (
  diagram: go.Diagram,
  link: go.Link,
  width: number = 2,
  color: string | null = null,
  labelVisible: boolean | null = null,
  overrideOriginal: boolean = false,
) => {
  if (link.path) {
    link.path.strokeWidth = width;
    // Store the original color if not already stored
    if (!link.data.originalStroke || overrideOriginal) {
      link.data.originalStroke = link.path.stroke;
    }
    link.path.stroke = color || link.data.originalStroke;
  }
  // Find and style the arrow
  const arrow = link.findObject("ARROW_TO") as go.Shape;
  if (arrow) {
    arrow.fill = color || link.data.originalStroke;
  }
  // Find and style the bidirectional arrow
  const arrowFrom = link.findObject("ARROW_FROM") as go.Shape;
  if (arrowFrom) {
    arrowFrom.fill = color || link.data.originalStroke;
  }
  // Find and style the label
  link.elements.each((part) => {
    if (part instanceof go.TextBlock) {
      if (link.data.originalLabelVisible === undefined || overrideOriginal) {
        link.data.originalLabelVisible = link.data.labelVisible;
      }
      const labelVisibleRes =
        labelVisible !== null ? labelVisible : link.data.originalLabelVisible;
      diagram.model.setDataProperty(link.data, "labelVisible", labelVisibleRes);
    }
  });
};

export const changeNodeStyle = (
  diagram: go.Diagram,
  node: go.Node,
  opacity: number = 1,
  color: string | null = null,
  overrideOriginal: boolean = false,
) => {
  const shape = node.findObject("SHAPE") as go.Shape;
  if (shape) {
    // Store the original color if not already stored
    if (!node.data.originalFill || overrideOriginal) {
      node.data.originalFill = shape.fill;
    }
    shape.fill = color || node.data.originalFill;
  }
  node.opacity = opacity; // reset opacity
};

export function highlightInboundConnections(
  diagram: go.Diagram,
  node: go.Node,
  visitedNodes = new Set(),
) {
  visitedNodes.add(node);
  node.findLinksConnected().each((link) => {
    if (link.toNode === node || link.data.bidirect) {
      // Highlight the inbound link (including the label)
      changeLinkStyle(diagram, link, 4, null, true);
      // Highlight the node at the other end of the link
      const nextNode = link.toNode === node ? link.fromNode : link.toNode;
      if (nextNode && !visitedNodes.has(nextNode)) {
        changeNodeStyle(diagram, nextNode, 1);
        // Recursively highlight inbound connections of the fromNode
        highlightInboundConnections(diagram, nextNode, visitedNodes);
      }
    }
  });
}

export function resetDiagramStyles(
  diagram: go.Diagram,
  overrideOriginal = false,
) {
  diagram.nodes.each((node) => {
    changeNodeStyle(diagram, node, 1, null, overrideOriginal);
  });
  diagram.links.each((link) => {
    changeLinkStyle(diagram, link, 2, null, null, overrideOriginal);
  });
}

// Highlight links connected to the selected nodes
export function handleNodeSelection(diagram: go.Diagram) {
  const selectedNodes = diagram.selection.filter(
    (part) => part instanceof go.Node,
  );

  const highlightMode = diagram.model.modelData.highlightMode;

  diagram.startTransaction("highlight links");
  // If there are no selected nodes, reset default styles
  if (selectedNodes.count === 0 || highlightMode === "none") {
    // Reset all node styles to default
    resetDiagramStyles(diagram);
    diagram.commitTransaction("highlight links");
    return;
  }

  if (highlightMode === "neighbors" || highlightMode === "root") {
    // Grey out all nodes and links
    diagram.nodes.each((node) => {
      changeNodeStyle(diagram, node, 0.5, "lightgray");
    });
    diagram.links.each((link) => {
      changeLinkStyle(diagram, link, 2, "lightgray", false);
    });

    // Highlight links connected to all selected nodes
    selectedNodes.each((selectedPart) => {
      if (selectedPart instanceof go.Node) {
        // Highlight selected node
        changeNodeStyle(diagram, selectedPart, 1);
        selectedPart.findLinksConnected().each((link: go.Link) => {
          // Highlight links connected to the selected node
          changeLinkStyle(diagram, link, 4, null, true);
          // Highlight the connected nodes
          link.fromNode && changeNodeStyle(diagram, link.fromNode, 1);
          link.toNode && changeNodeStyle(diagram, link.toNode, 1);
        });
        if (highlightMode === "root") {
          // Highlight inbound connections recursively to the "root"
          highlightInboundConnections(diagram, selectedPart);
        }
      }
    });
  }

  diagram.commitTransaction("highlight links");
}
