import React from "react";

interface IconProps {
  color?: string;
  size?: number;
}

export const EditIcon = ({ color = "#858282", size = 24 }: IconProps) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 25"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_48_14900)">
      <path
        clipRule="evenodd"
        d="M8.43749 13.8172L10.29 15.6697L15.3075 10.6522L13.4625 8.79225L8.43749 13.8172ZM6.74249 17.3647L9.63749 16.3222L7.79249 14.4922L6.74249 17.3647ZM15.93 6.86475C15.57 6.86475 15.24 7.01475 15 7.24725L13.92 8.32725L15.7725 10.1797L16.8525 9.09975C17.0925 8.85975 17.235 8.53725 17.235 8.16975C17.2425 7.45725 16.6575 6.86475 15.93 6.86475Z"
        fill={color}
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_48_14900">
        <rect
          fill="white"
          height="12"
          transform="translate(6 6.12207)"
          width="12"
        />
      </clipPath>
    </defs>
  </svg>
);

export const DeleteIcon = ({ color = "#EF4444", size = 24 }: IconProps) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 25"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_48_14906)">
      <path
        clipRule="evenodd"
        d="M16.8675 9.11475H7.11749C6.90749 9.11475 6.74249 9.27975 6.74249 9.48975C6.74249 9.69975 6.90749 9.86475 7.11749 9.86475H7.49249V17.3647C7.49249 17.7772 7.82999 18.1147 8.24249 18.1147H15.7425C16.155 18.1147 16.4925 17.7772 16.4925 17.3647V9.86475H16.8675C17.0775 9.86475 17.2425 9.69975 17.2425 9.48975C17.2425 9.27975 17.0775 9.11475 16.8675 9.11475ZM10.4925 15.8647C10.4925 16.2772 10.155 16.6147 9.74249 16.6147C9.32999 16.6147 8.99249 16.2772 8.99249 15.8647V11.3647C8.99249 10.9522 9.32999 10.6147 9.74249 10.6147C10.155 10.6147 10.4925 10.9522 10.4925 11.3647V15.8647ZM12.7425 15.8647C12.7425 16.2772 12.405 16.6147 11.9925 16.6147C11.58 16.6147 11.2425 16.2772 11.2425 15.8647V11.3647C11.2425 10.9522 11.58 10.6147 11.9925 10.6147C12.405 10.6147 12.7425 10.9522 12.7425 11.3647V15.8647ZM14.9925 15.8647C14.9925 16.2772 14.655 16.6147 14.2425 16.6147C13.83 16.6147 13.4925 16.2772 13.4925 15.8647V11.3647C13.4925 10.9522 13.83 10.6147 14.2425 10.6147C14.655 10.6147 14.9925 10.9522 14.9925 11.3647V15.8647ZM16.4925 6.86475H13.4925C13.4925 6.45225 13.155 6.11475 12.7425 6.11475H11.2425C10.83 6.11475 10.4925 6.45225 10.4925 6.86475H7.49249C7.07999 6.86475 6.74249 7.20225 6.74249 7.61475V8.36475H17.2425V7.61475C17.2425 7.20225 16.905 6.86475 16.4925 6.86475Z"
        fill={color}
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_48_14906">
        <rect
          fill="white"
          height="12"
          transform="translate(6 6.12207)"
          width="12"
        />
      </clipPath>
    </defs>
  </svg>
);

export const AddIcon = ({ color = "#858282", size = 24 }: IconProps) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 25"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M15.75 11.3721H12.75V8.37207C12.75 7.95957 12.4125 7.62207 12 7.62207C11.5875 7.62207 11.25 7.95957 11.25 8.37207V11.3721H8.25C7.8375 11.3721 7.5 11.7096 7.5 12.1221C7.5 12.5346 7.8375 12.8721 8.25 12.8721H11.25V15.8721C11.25 16.2846 11.5875 16.6221 12 16.6221C12.4125 16.6221 12.75 16.2846 12.75 15.8721V12.8721H15.75C16.1625 12.8721 16.5 12.5346 16.5 12.1221C16.5 11.7096 16.1625 11.3721 15.75 11.3721Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
