import React from "react";

import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";

import type {
  AutoLayoutConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import { EventType } from "constants/AppsmithActionConstants/ActionConstants";
import { RenderModes, WIDGET_TAGS } from "constants/WidgetConstants";
import type { SetterConfig } from "entities/AppTheming";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import type { BadgeComponentProps } from "../component";
import BadgeComponent from "../component";
import { propertyPaneContentConfig } from "./contentConfig";
import { propertyPaneStyleConfig } from "./styleConfig";

import type { WidgetFeatures } from "utils/WidgetFeatures";
import { DEFAULT_BADGE_COLOR, DEFAULT_BADGE_COLOR_NAME } from "../constants";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../../spread_tmp_thumbnail.svg";

type ForwardedBadgeComponentProps = Omit<BadgeComponentProps, "onClick">;
export interface SpreadBadgeWidgetProps
  extends WidgetProps,
    ForwardedBadgeComponentProps {
  onClick?: string;
}

class SpreadBadgeWidget extends BaseWidget<
  SpreadBadgeWidgetProps,
  WidgetState
> {
  static type = "SPREAD_BADGE_WIDGET";

  static getConfig() {
    return {
      name: "Badge", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      tags: [WIDGET_TAGS.DISPLAY],
      thumbnailSVG: ThumbnailSVG,
      needsMeta: false, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
      searchTags: ["label", "tag"],
    };
  }

  static getDefaults() {
    return {
      widgetName: "Badge",
      rows: 4,
      columns: 7,
      version: 1,
      label: "Danger",
      badgeColorName: DEFAULT_BADGE_COLOR_NAME,
      badgeColor: DEFAULT_BADGE_COLOR,
      isVisible: true,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    // TODO: add proper auto layout config
    return {
      autoDimension: {
        height: false,
        width: false,
      },
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "25px",
              minHeight: "25px",
            };
          },
        },
      ],
      disableResizeHandles: {
        vertical: true,
        horizontal: false,
      },
    };
  }

  static getFeatures(): WidgetFeatures | null {
    return {
      dynamicHeight: {
        sectionIndex: 1, // Index of the property pane "General" section
        active: false,
      },
    };
  }

  static getPropertyPaneContentConfig() {
    return propertyPaneContentConfig;
  }

  static getPropertyPaneStyleConfig() {
    return propertyPaneStyleConfig;
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      label: {
        "!type": "string",
        "!doc": "Returns the value of the badge's label property.",
      },
    };
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setColor: {
          path: "badgeColor",
          type: "string",
        },
        setLabel: {
          path: "label",
          type: "string",
        },
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
      },
    };
  }

  onBadgeClick = () => {
    if (!this.props.onClick) {
      return;
    }

    super.executeAction({
      triggerPropertyName: "onClick",
      dynamicString: this.props.onClick,
      event: {
        type: EventType.ON_CLICK,
      },
    });
  };

  getWidgetView() {
    const { badgeColor, label, onClick, renderMode } = this.props;
    const hasOnClickHandler = !!onClick;

    return (
      <BadgeComponent
        badgeColor={badgeColor}
        isFullWidth
        isOnPage={renderMode === RenderModes.PAGE}
        label={label}
        onClick={hasOnClickHandler ? this.onBadgeClick : undefined}
      />
    );
  }
}

export default SpreadBadgeWidget;
