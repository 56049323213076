import type {
  Connection,
  SignalPathArray,
  SignalPathItem,
  Terminal,
} from "../models/SignalPath";
import type { DiagramLink, DiagramNode, DiagramPort } from "../index";
import { isConnection, isTerminal } from "../models/SignalPath";

export type TerminalOfConnection = Partial<
  Pick<Terminal, "id" | "componentReferenceId">
>;

export interface DiagramObjectMetadata<GItem extends SignalPathItem> {
  containingSignalPath: SignalPathArray;
  signalPathItem: GItem;
}
export type DiagramPortMetadata = DiagramObjectMetadata<Terminal>;
export type DiagramLinkMetadata = DiagramObjectMetadata<Connection>;

export type DiagramTerminal = DiagramPort<DiagramPortMetadata>;
export type DiagramConnection = DiagramLink<DiagramLinkMetadata>;

export interface DiagramData {
  nodes: DiagramNode[];
  links: DiagramConnection[];
}

export type AnnotatedTerminal = Terminal & {
  connection: Pick<Connection, "id" | "signalName">;
  isStartItem: boolean;
  isEndItem: boolean;
  numberOfConnections?: number;
  isContainingSignalPathSelected: boolean;
  isComponentSelected: boolean;
  selectedTerminalId: string | undefined;
  signalPath: SignalPathArray;
  signalPathItem: Terminal;
  order: number;
};

export type AnnotatedConnection = Connection & {
  connectionFrom?: TerminalOfConnection;
  connectionTo?: TerminalOfConnection;
  isContainingSignalPathSelected: boolean;
  isConnectionSelected: boolean;
  signalPath: SignalPathArray;
  signalPathItem: Connection;
};

export type AnnotatedSignalPathArray = (
  | AnnotatedConnection
  | AnnotatedTerminal
)[];

export function isAnnotatedTerminal(
  terminal: AnnotatedSignalPathArray[number],
): terminal is AnnotatedTerminal {
  return isTerminal(terminal) && "isComponentSelected" in terminal;
}

export function isAnnotatedConnection(
  connection: AnnotatedSignalPathArray[number],
): connection is AnnotatedConnection {
  return isConnection(connection) && "isConnectionSelected" in connection;
}
