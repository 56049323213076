import { Position } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import Interweave from "interweave";
import React from "react";

import styled, { createGlobalStyle } from "styled-components";

// Note: tooltip styles are same as for ButtonWidget

const ToolTipWrapper = styled.div`
  height: 100%;
  && .bp3-popover2-target {
    height: 100%;
    width: 100%;
    & > div {
      height: 100%;
    }
  }
`;

const TooltipStyles = createGlobalStyle`
  .btnTooltipContainer {
    .bp3-popover2-content {
      max-width: 350px;
      overflow-wrap: anywhere;
      padding: 10px 12px;
      border-radius: 0px;
    }
  }
`;

export const Tooltip: React.FC<{ content: string }> = ({
  children,
  content,
}) => (
  <ToolTipWrapper>
    <TooltipStyles />
    <Popover2
      autoFocus={false}
      content={<Interweave content={content} />}
      hoverOpenDelay={200}
      interactionKind="hover"
      portalClassName="btnTooltipContainer"
      position={Position.TOP}
    >
      {children}
    </Popover2>
  </ToolTipWrapper>
);
