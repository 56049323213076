import React from "react";

import type { ColumnAction } from "components/propertyControls/ColumnActionSelectorControl";
import { noop } from "lodash";
import type { BadgeComponentProps } from "widgets/SpreadBadgeWidget/component";
import BadgeComponent from "widgets/SpreadBadgeWidget/component";
import type { BaseCellComponentProps } from "widgets/TableWidgetV2/component/Constants";
import type { RenderActionProps } from "widgets/TableWidgetV2/component/cellComponents/ButtonCell";
import { CellWrapper } from "../../../TableStyledWrappers";
import type { BadgeCellProperties } from "./constants";

type BadgeColumnAction = ColumnAction & Required<BadgeCellProperties>;

interface BadgeCellProps extends BaseCellComponentProps {
  columnActions: BadgeColumnAction[];
  isOnPage: BadgeComponentProps["isOnPage"];
  onCommandClick: RenderActionProps["onCommandClick"];
}

export function BadgeCell(props: BadgeCellProps) {
  const action = props.columnActions[0];
  const onBadgeClick: React.MouseEventHandler | undefined = Boolean(
    action.dynamicTrigger,
  )
    ? (event) => {
        event.stopPropagation();
        props.onCommandClick(action.dynamicTrigger, noop);
      }
    : undefined;

  return (
    <CellWrapper
      allowCellWrapping={props.allowCellWrapping}
      cellBackground={props.cellBackground}
      compactMode={props.compactMode}
      fontStyle={props.fontStyle}
      horizontalAlignment={props.horizontalAlignment}
      isCellDisabled={props.isCellDisabled}
      isCellVisible={props.isCellVisible}
      isHidden={props.isHidden}
      textColor={props.textColor}
      textSize={props.textSize}
      verticalAlignment={props.verticalAlignment}
    >
      <BadgeComponent
        badgeColor={action.badgeColor}
        isFullWidth={false}
        isOnPage={props.isOnPage}
        label={action.label ?? ""}
        onClick={onBadgeClick}
      />
    </CellWrapper>
  );
}
