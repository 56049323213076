// #region @spread
import { CONTAINER_STATUS } from "../constants";
import type { ContainerWidgetProps } from "widgets/ContainerWidget/widget";
import type { WidgetProps } from "widgets/BaseWidget";
import { IS_SPREAD_STUDIO } from "spread";

interface ContainerStatusProps
  extends Pick<
    ContainerWidgetProps<WidgetProps>,
    | "isErrorStateVisible"
    | "isEmptyStateVisible"
    | "isLoading"
    | "isLoadingStateVisible"
  > {}

export const getContainerStatus = (props: ContainerStatusProps) => {
  const {
    isEmptyStateVisible,
    isErrorStateVisible,
    isLoading,
    isLoadingStateVisible,
  } = props;
  const isContainerLoading =
    isLoading || (isLoadingStateVisible && IS_SPREAD_STUDIO);
  if (isErrorStateVisible) return CONTAINER_STATUS.ERROR;
  else if (isContainerLoading) return CONTAINER_STATUS.LOADING;
  else if (isEmptyStateVisible) return CONTAINER_STATUS.EMPTY;
  else return CONTAINER_STATUS.HAS_CHILDREN;
};
// #endregion
