import React from "react";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../../spread_tmp_thumbnail.svg";
import { WIDGET_TAGS } from "../../../constants/WidgetConstants";
import { defaultProps } from "./defaultProps";
import type {
  AutocompletionDefinitions,
  AutoLayoutConfig,
} from "../../../WidgetProvider/constants";
import type { DerivedPropertiesMap } from "../../../WidgetProvider/factory";
import { EventType } from "../../../constants/AppsmithActionConstants/ActionConstants";
import type { LegendOrientationType } from "spread/components/LegendComponent/Legend";
import { Legend } from "spread/components/LegendComponent/Legend";
import { contentConfig } from "./contentConfig";
import { styleConfig } from "./styleConfig";
import type { LegendItem } from "../../../spread/components/LegendComponent/LegendItem";
import { WidgetContainerDiff } from "../../WidgetUtils";

function getOrientation({
  componentHeight,
  componentWidth,
  orientation,
}: {
  componentHeight: number;
  componentWidth: number;
  orientation: LegendOrientationType;
}) {
  if (orientation === "auto") {
    return componentHeight > componentWidth ? "vertical" : "horizontal";
  }

  return orientation;
}
class SpreadLegendWidget extends BaseWidget<
  SpreadLegendWidgetProps,
  WidgetState
> {
  static type = "SPREAD_LEGEND_WIDGET";

  static getConfig() {
    return {
      name: "Legend",
      iconSVG: IconSVG,
      thumbnailSVG: ThumbnailSVG,
      tags: [WIDGET_TAGS.BETA],
      needsMeta: true,
      isCanvas: false,
      searchTags: ["key", "caption", "graph", "chart", "diagram"],
    };
  }

  static getDefaults() {
    return {
      widgetName: "Legend",
      rows: 20,
      columns: 10,
      version: 1,
      ...defaultProps,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    return {};
  }

  static getPropertyPaneContentConfig() {
    return contentConfig;
  }

  static getPropertyPaneStyleConfig() {
    return styleConfig;
  }

  constructor(props: SpreadLegendWidgetProps) {
    super(props);
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      disabledEntries: {
        "!type": "[?]",
        "!doc": "The enabled entries of the legend",
      },
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {
      disabledEntries: `{{this.disabledEntries}}`,
    };
  }

  static getMetaPropertiesMap(): MetaProperties {
    return {
      disabledEntries: [],
    };
  }

  updateDisabledEntries = (ids: string[]) => {
    this.props.updateWidgetMetaProperty(
      "disabledEntries",
      ids.map((id) => this.props.entries.find((item) => item.id === id)),
      {
        triggerPropertyName: "onDisabledEntriesChange",
        dynamicString: this.props.onDisabledEntriesChange,
        event: {
          type: EventType.ON_DISABLED_ENTRIES_CHANGE,
        },
      },
    );
  };

  getWidgetView() {
    const { defaultDisabledIds = [], entries = [], orientation } = this.props;
    const { componentHeight, componentWidth } = this.props;

    // Style properties
    const {
      backgroundColor,
      borderColor,
      borderRadius,
      borderWidth,
      boxShadow,
    } = this.props;

    return (
      <Legend
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        boxShadow={boxShadow}
        defaultDisabledIds={defaultDisabledIds}
        entries={entries}
        itemHeight={16}
        onDisabledEntriesChange={this.updateDisabledEntries}
        orientation={getOrientation({
          componentHeight,
          componentWidth,
          orientation,
        })}
        width={componentWidth - WidgetContainerDiff - 10}
      />
    );
  }
}

interface SpreadLegendWidgetProps extends WidgetProps, MetaProperties {
  defaultDisabledIds: string[];
  onDisabledEntriesChange: string;
  backgroundColor: string;
  borderColor: string;
  borderRadius: string;
  borderWidth: string;
  boxShadow: string;
  entries: LegendItem[];
}

interface MetaProperties {
  disabledEntries: string[];
}
export default SpreadLegendWidget;
