import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import MultiTreeSelectComponent from "widgets/MultiSelectTreeWidget/component";

import type { CardData, ClusterData } from "../types";
import { Card } from "./Card";
import { AddIcon } from "./icons";
import { IconButton } from "./IconButton";
import type { ActionPayload } from "../widget/actionsAndCallbacks";
import type { LocalizedStrings } from "../widget/localizedStrings";
import { InfoTooltip } from "./tooltips/InfoTooltip";

interface ClusterProps {
  cluster: ClusterData;
  clusterCards: CardData[];
  filterValues: string[];
  isUnfiltered: boolean;
  linkedFilterValues: Set<string>;
  onFilterChange: (filterValues: string[]) => void;
  commitAction: (action: ActionPayload) => void;
  widgetId: string;
  accentColor: string;
  localizedStrings: LocalizedStrings;
}

export const Cluster = ({
  accentColor,
  cluster,
  clusterCards,
  commitAction,
  filterValues,
  isUnfiltered,
  linkedFilterValues,
  localizedStrings,
  onFilterChange,
  widgetId,
}: ClusterProps) => {
  const filterOptions = clusterCards.map((card) => ({
    label: card.title,
    value: card.id,
    children: (card.items || []).map((item) => ({
      label: item.title,
      value: item.id,
    })),
  }));

  const cardsIds = useMemo(
    () => new Set(clusterCards.map((card) => card.id)),
    [clusterCards],
  );

  const onFilterChangeWithouthCards = useCallback(
    (values: string[] = []) => {
      const filteredValues = values.filter((value) => !cardsIds.has(value));
      onFilterChange(filteredValues);
    },
    [cardsIds],
  );

  const onAddOnClusterClick = () => {
    commitAction({
      type: "cluster:add",
      target: {
        clusterId: cluster.id,
      },
    });
  };

  return (
    <ClusterContainer
      $borderColor={cluster.borderColor}
      $color={cluster.color}
      key={cluster.id}
    >
      <ClusterTitle>{cluster.title}</ClusterTitle>

      {/* TODO: investigate loading speed */}
      {/* TODO: investigate all props */}
      <FilterRow>
        <FilterContainer>
          <MultiTreeSelectComponent
            accentColor={accentColor}
            allowClear
            borderRadius="6px"
            compactMode={false} // TODO: investigate
            disabled={filterOptions.length === 0}
            dropDownWidth={100}
            dropdownStyle={{
              zIndex: 100,
            }}
            expandAll={false}
            isFilterable
            isValid
            labelText=""
            loading={false}
            mode="SHOW_ALL"
            onChange={(values) =>
              onFilterChangeWithouthCards(values as string[])
            }
            options={filterOptions}
            placeholder={localizedStrings.linkedFilterPlaceholder}
            value={filterValues}
            widgetId={widgetId}
            width={206}
          />
        </FilterContainer>
        <InfoTooltip content={localizedStrings.clusterAddButtonTooltip}>
          <IconButton onClick={onAddOnClusterClick} size={32}>
            <AddIcon color="#485162" size={32} />
          </IconButton>
        </InfoTooltip>
      </FilterRow>

      {clusterCards.map((card) => {
        const isCardVisible = isUnfiltered
          ? true
          : card.items.some((item) => linkedFilterValues.has(item.id));
        if (!isCardVisible) return null;

        return (
          <CardContainer key={card.id}>
            <Card
              clusterId={cluster.id}
              commitAction={commitAction}
              data={card}
              isUnfiltered={isUnfiltered}
              linkedFilterValues={linkedFilterValues}
              localizedStrings={localizedStrings}
              widgetId={widgetId}
            />
          </CardContainer>
        );
      })}
    </ClusterContainer>
  );
};

const ClusterContainer = styled.div<{ $color: string; $borderColor: string }>`
  border-radius: 6px;
  border: 1px solid ${(props) => props.$borderColor};
  background: ${(props) => props.$color};
  padding: 24px;
`;

const ClusterTitle = styled.p`
  color: #090707;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07px;
  margin-bottom: 6px;
`;

const CardContainer = styled.div`
  border-top: 1px solid #e0dede;
  margin-top: 24px;
  padding-top: 24px;
`;

const FilterRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;

const FilterContainer = styled.div`
  flex: 1;
  max-width: 160px;
`;
