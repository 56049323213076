import {
  getAST,
  isIdentifierNode,
  isObjectExpression,
  isPropertyNode,
  isLiteralNode,
} from "@shared/ast";
import { attachCommentsToAst } from "@shared/ast/src";
import { findRootCallExpression } from "@shared/ast/src/actionCreator";
import { sanitizeScript } from "@shared/ast/src/utils";
import type { Comment, Node } from "acorn";
import { generate } from "astring";

const FLOW_ID_ARGUMENT_INDEX = 0;
const FLOW_PARAMETERS_ARGUMENT_INDEX = 1;

/**
 * Extracts the flowId and parameters from a `spread.flows.runFlow` function call.
 * All values are stringified code.
 *
 * @example
 * ```ts
 * const code = `spread.flows.runFlow("A_FLOW_ID", { lang: "de", includeTime: true, timestamp: undefined })`;
 * const { flowId, parameters } = getRunFlowArguments(`{{${code}}}`, 2);
 * //> flowId = "\"A_FLOW_ID\""
 * //> parameters = { lang: "\"de\"", includeTime: "true", timestamp: "undefined" }
 * ```
 *
 * @param value The code, with mustache syntax.
 * @param evaluationVersion Appsmith code evaluation version. Currently 2.
 * @returns An object with the flowId and parameters extracted from the given code.
 */
export function getRunFlowArguments(
  value: string,
  evaluationVersion: number,
): { flowId: string; parameters: Record<string, string> } {
  let ast: Node = { end: 0, start: 0, type: "" };
  const commentArray: Array<Comment> = [];
  let astWithComments;
  try {
    const sanitizedScript = sanitizeScript(value, evaluationVersion);
    ast = getAST(sanitizedScript, {
      locations: true,
      ranges: true,
      onComment: commentArray,
    });
    astWithComments = attachCommentsToAst(ast, commentArray);
  } catch (error) {
    return {
      flowId: "",
      parameters: {},
    };
  }

  const rootCallExpression = findRootCallExpression(astWithComments);

  const args = rootCallExpression.arguments || [];

  if (args.length === 0) {
    return {
      flowId: "",
      parameters: {},
    };
  }

  const flowId = generate(args[FLOW_ID_ARGUMENT_INDEX]);

  const flowParametersArgument = args[FLOW_PARAMETERS_ARGUMENT_INDEX];
  if (!flowParametersArgument) {
    return { flowId, parameters: {} };
  }

  const parameters: Record<string, string> = {};

  if (isObjectExpression(flowParametersArgument)) {
    for (const property of flowParametersArgument.properties) {
      if (!isPropertyNode(property)) {
        continue;
      }

      if (isIdentifierNode(property.key)) {
        parameters[property.key.name] = generate(property.value);
      } else if (
        isLiteralNode(property.key) &&
        typeof property.key.value === "string"
      ) {
        parameters[property.key.value] = generate(property.value);
      }
    }
  }

  return { flowId, parameters };
}
