import * as go from "gojs";

export const nodeTemplate = () => {
  const $ = go.GraphObject.make;

  const createPortPanel = (
    alignment: go.Spot,
    orientation: string,
    bindingName: string,
  ) => {
    const margin =
      orientation === "Horizontal" ? new go.Margin(0, 8) : new go.Margin(4, 0);
    return $(
      go.Panel,
      orientation,
      {
        alignment: alignment,
        alignmentFocus: alignment,
        fromLinkable: true,
        toLinkable: true,
        fromLinkableDuplicates: true,
        toLinkableDuplicates: true,
      },
      new go.Binding("itemArray", bindingName),
      {
        itemTemplate: $(
          go.Panel,
          {
            fromLinkable: true,
            toLinkable: true,
            fromSpot: alignment,
            toSpot: alignment,
            portId: "", // Default to use itemArray index as portId
            margin: margin, // Add margin to create space between ports
          },
          new go.Binding("portId", "portId"),
          $(
            go.Shape,
            "Circle",
            {
              desiredSize: new go.Size(8, 8),
              fill: "transparent",
              stroke: null,
            },
            new go.Binding("fill", "color"), // Bind the fill property to the color of the link
          ),
        ),
      },
    );
  };

  const regularNode = $(
    go.Node,
    "Auto",
    {
      selectionAdorned: true,
      zOrder: 10,
      movable: true, // Allow the node to be moved
    },
    $(
      go.Shape,
      "Rectangle",
      {
        name: "SHAPE",
        stroke: null, // No border
        desiredSize: new go.Size(171, 47), // Fixed size
        fill: "lightblue", // Default color
      },
      new go.Binding("fill", "color").makeTwoWay(), // Bind the fill property to the color in node data, with a default
    ),
    $(
      go.TextBlock,
      {
        margin: new go.Margin(0, 8, 0, 20), // Left and right margin of 8px
        textAlign: "left", // Align text to the left
        verticalAlignment: go.Spot.Center,
        alignment: go.Spot.Left, // Align text to the left
        alignmentFocus: go.Spot.Left, // Ensure text alignment focus is left
        stroke: "#000000", // Default color
        font: "12pt Inter", // Font style
        wrap: go.TextBlock.None, // Disable wrapping
        overflow: go.TextBlock.OverflowEllipsis, // Ellipsis for overflow text
        maxSize: new go.Size(135, NaN), // Truncate text if it exceeds the node width
      },
      new go.Binding("text", "text"),
      new go.Binding("stroke", "textColor"),
    ),
    // Create port panels for each side of the node
    createPortPanel(go.Spot.Left, "Vertical", "leftPorts"),
    createPortPanel(go.Spot.Right, "Vertical", "rightPorts"),
    createPortPanel(go.Spot.Top, "Horizontal", "topPorts"),
    createPortPanel(go.Spot.Bottom, "Horizontal", "bottomPorts"),
    {
      toolTip: $(
        "ToolTip",
        $(
          go.TextBlock,
          { margin: 4 },
          new go.Binding("text", "text"), // Show the full text in the tooltip
        ),
      ),
    },
  );

  return { regularNode };
};
