export const localizedStrings = [
  // Cluster
  {
    id: "linkedFilterPlaceholder",
    name: "Linked Filter Placeholder",
    description: "Placeholder for the linked filter on the cluster",
    defaultMessage: "Linked Filter",
  },
  {
    id: "clusterAddButtonTooltip",
    name: "Cluster Add Button Tooltip",
    description: "Tooltip for the add button on the cluster",
    defaultMessage: "Add",
  },
  // Card
  {
    id: "cardEditButtonTooltip",
    name: "Card Edit Button Tooltip",
    description: "Tooltip for the edit button on the card",
    defaultMessage: "Edit",
  },
  {
    id: "cardAddButtonTooltip",
    name: "Card Add Button Tooltip",
    description: "Tooltip for the add button on the card",
    defaultMessage: "Add",
  },
  {
    id: "cardDeleteButtonTooltip",
    name: "Card Delete Button Tooltip",
    description: "Tooltip for the delete button on the card",
    defaultMessage: "Delete",
  },
  // Card item
  {
    id: "cardItemEditButtonTooltip",
    name: "Card Item Edit Button Tooltip",
    description: "Tooltip for the edit button on the card item",
    defaultMessage: "Edit",
  },
  {
    id: "cardItemDeleteButtonTooltip",
    name: "Card Item Delete Button Tooltip",
    description: "Tooltip for the delete button on the card item",
    defaultMessage: "Delete",
  },
] as const;

type LocalizationStringEntry = (typeof localizedStrings)[number];

export type LocalizedStrings = {
  [K in LocalizationStringEntry as K["id"]]: string;
};

export const defaultLocalizedStrings = localizedStrings.reduce(
  (acc, localizationString) => ({
    ...acc,
    [localizationString.id]: localizationString.defaultMessage,
  }),
  {} as LocalizedStrings,
);
