import { ValidationTypes } from "../../../constants/WidgetValidation";

export const propertyPaneStyleConfig = [
  {
    sectionName: "Tree style",
    children: [
      {
        propertyName: "rowHeight",
        label: "Height of each row",
        helpText: "Sets height of the tree row",
        isBindProperty: true,
        isTriggerProperty: false,
        controlType: "INPUT_TEXT",
        validation: { type: ValidationTypes.NUMBER },
      },
      {
        propertyName: "rowBackgroundAccentColor",
        helpText: "Sets the background color of the tree row",
        label: "Row background color",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
  {
    sectionName: "Checkbox",
    children: [
      {
        propertyName: "checkboxAccentColor",
        helpText: "Sets the checked state color of the checkbox",
        label: "Accent color",
        controlType: "COLOR_PICKER",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "checkboxBorderRadius",
        label: "Border radius",
        helpText: "Rounds the corners of the icon button's outer border edge",
        controlType: "BORDER_RADIUS_OPTIONS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
];
