import { vec3 } from "gl-matrix";
import type {
  Component,
  Connection,
  Connector,
  SignalPathArray,
  SignalPathEntitiesMap,
  Terminal,
} from "../types";
import { createReferenceId } from "./referenceId";
import type { ConnectorReferenceId } from "../../ElectricPart";
import { isTerminal } from "../guards";

function getCenterCoordinates(allCoordinates: number[][]) {
  if (allCoordinates.length === 0) {
    return null;
  }

  const finalCoordinate = vec3.create();
  for (const coordinates of allCoordinates) {
    vec3.add(finalCoordinate, finalCoordinate, new Float32Array(coordinates));
  }

  vec3.divide(
    finalCoordinate,
    finalCoordinate,
    new Float32Array([
      allCoordinates.length,
      allCoordinates.length,
      allCoordinates.length,
    ]),
  );

  return [...finalCoordinate];
}

export function getComponentFromTerminal(
  terminal: Pick<
    Terminal,
    | "componentReferenceId"
    | "componentType"
    | "description"
    | "coordinates"
    | "diagnosisId"
  >,
): Component {
  return {
    referenceId: terminal.componentReferenceId,
    type: terminal.componentType,
    description: terminal.description,
    coordinates: getCenterCoordinates(terminal.coordinates) ?? undefined,
    diagnosisId: terminal.diagnosisId,
  };
}

export function getConnectorFromTerminal(terminal: Terminal): Connector {
  return {
    referenceId: createReferenceId({
      component: terminal.componentReferenceId,
      connector: terminal.connectorName,
    }) as ConnectorReferenceId,
    componentReferenceId: terminal.componentReferenceId,
    name: terminal.connectorName,
  };
}

export function collectSignalPathEntitiesFromList(
  signalPaths: SignalPathArray[],
): SignalPathEntitiesMap {
  const items = signalPaths.flat();
  const components: Record<string, Component> = {};
  const connectors: Record<string, Connector> = {};
  const terminals: Record<string, Terminal> = {};
  const connections: Record<string, Connection> = {};

  const addIfNotThere = (
    map: Record<string, unknown>,
    id: string,
    value: unknown,
  ) => {
    if (!(id in map)) {
      map[id] = value;
    }
  };

  items.forEach((signalPathItem) => {
    if (isTerminal(signalPathItem)) {
      addIfNotThere(
        components,
        signalPathItem.componentReferenceId,
        getComponentFromTerminal(signalPathItem),
      );

      const connector = getConnectorFromTerminal(signalPathItem);
      addIfNotThere(connectors, connector.referenceId, connector);

      addIfNotThere(terminals, signalPathItem.id, signalPathItem);
    } else {
      addIfNotThere(connections, signalPathItem.id, signalPathItem);
    }
  });

  return { components, connectors, terminals, connections };
}
