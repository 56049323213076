import React from "react";
import styled from "styled-components";
import type { CardData } from "../types";
import { AddIcon, DeleteIcon, EditIcon } from "./icons";
import { InfoTooltip } from "./tooltips/InfoTooltip";
import { AlertTooltip } from "./tooltips/AlertTooltip";
import { IconButton } from "./IconButton";
import type { ActionPayload } from "../widget/actionsAndCallbacks";
import type { LocalizedStrings } from "../widget/localizedStrings";

interface CardProps {
  data: CardData;
  isUnfiltered: boolean;
  linkedFilterValues: Set<string>;
  commitAction: (action: ActionPayload) => void;
  clusterId: string;
  localizedStrings: LocalizedStrings;
  // Widget ID is used to create unique IDs for each widget instance
  widgetId: string;
}

export const Card = ({
  clusterId,
  commitAction,
  data,
  isUnfiltered,
  linkedFilterValues,
  localizedStrings,
  widgetId,
}: CardProps) => {
  const card = data;

  const cardTarget = {
    clusterId,
    cardId: card.id,
  };

  const onEditOnCardClick = () => {
    commitAction({
      type: "card:edit",
      target: cardTarget,
    });
  };

  const onAddOnCardClick = () => {
    commitAction({
      type: "card:add",
      target: cardTarget,
    });
  };

  const onDeleteOnCardClick = () => {
    commitAction({
      type: "card:delete",
      target: cardTarget,
    });
  };

  return (
    <CardContainer isAlert={card.isAlert}>
      <CardHeader>
        <CardTitle isAlert={card.isAlert}>{card.title}</CardTitle>
        <CardSubtitle>{card.subtitle}</CardSubtitle>
      </CardHeader>
      <CardItemsContainer>
        {card.items?.map((item) => {
          const isVisible = isUnfiltered || linkedFilterValues.has(item.id);
          if (!isVisible) return null;

          const cardItemTarget = {
            clusterId,
            cardId: card.id,
            cardItemId: item.id,
          };

          const onEditOnCardItemClick = () => {
            commitAction({
              type: "cardItem:edit",
              target: cardItemTarget,
            });
          };

          const onDeleteOnCardItemClick = () => {
            commitAction({
              type: "cardItem:delete",
              target: cardItemTarget,
            });
          };

          const id = `${widgetId}${item.id}`;
          return (
            <CardItem id={id} isAlert={item.isAlert} key={id}>
              <AlertTooltip content={item.alertMessage} enabled={item.isAlert}>
                <CardItemLabel isAlert={item.isAlert}>
                  {item.title}
                </CardItemLabel>
              </AlertTooltip>
              <HiddenButtonsContainer isAlert={item.isAlert}>
                <InfoTooltip
                  content={localizedStrings.cardItemEditButtonTooltip}
                >
                  <IconButton
                    onClick={onEditOnCardItemClick}
                    red={item.isAlert}
                  >
                    <EditIcon color={item.isAlert ? "#ef4444" : undefined} />
                  </IconButton>
                </InfoTooltip>
                <InfoTooltip
                  content={localizedStrings.cardItemDeleteButtonTooltip}
                >
                  <IconButton onClick={onDeleteOnCardItemClick} red>
                    <DeleteIcon />
                  </IconButton>
                </InfoTooltip>
              </HiddenButtonsContainer>
            </CardItem>
          );
        })}
      </CardItemsContainer>
      <BottomIconBarContainer>
        <InfoTooltip content={localizedStrings.cardEditButtonTooltip}>
          <IconButton onClick={onEditOnCardClick}>
            <EditIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip content={localizedStrings.cardAddButtonTooltip}>
          <IconButton onClick={onAddOnCardClick}>
            <AddIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip content={localizedStrings.cardDeleteButtonTooltip}>
          <IconButton onClick={onDeleteOnCardClick} red>
            <DeleteIcon />
          </IconButton>
        </InfoTooltip>
      </BottomIconBarContainer>
    </CardContainer>
  );
};

interface IsAlertProps {
  isAlert?: boolean;
}

const CardContainer = styled.div<IsAlertProps>`
  // Layout
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  // In order to show arrows under cards, but above cluster background
  z-index: 2;
  position: relative;

  // Style
  border-radius: 6px;
  border: 1px solid ${(props) => (props.isAlert ? "#ef4444" : "#e0dede")};
  background: #fff;
`;

const CardHeader = styled.div`
  padding: 0px 12px;
  width: 100%; // Important for ellipsis
`;

const CardTitle = styled.div<IsAlertProps>`
  color: ${(props) => (props.isAlert ? "#ef4444" : "#10b981")};
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 4px;
  word-wrap: break-word;
`;

const CardSubtitle = styled.div`
  color: #090707;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  word-wrap: break-word;
`;

const CardItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const CardItem = styled.div<IsAlertProps>`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  min-height: 32px;

  // Important for HiddenButtonsContainer, to be 100% height
  position: relative;

  ${(props) => props.isAlert && `background: rgba(239, 68, 68, 0.10);`}

  border-top: 1px solid ${(props) => (props.isAlert ? "#ef4444" : "#e0dede")};

  &:last-of-type {
    border-bottom: 1px solid
      ${(props) => (props.isAlert ? "#ef4444" : "#e0dede")};
  }

  &:not(:last-of-type) {
    ${(props) => props.isAlert && `border-bottom: 1px solid #ef4444;`}
  }
`;

const CardItemLabel = styled.div<IsAlertProps>`
  // Layout
  flex: 1 0 0;

  // Style
  color: ${(props) => (props.isAlert ? "#ef4444" : "#716e6e")};
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  word-wrap: break-word;
`;

const HiddenButtonsContainer = styled.div<IsAlertProps>`
  background-color: ${(props) => (props.isAlert ? "#feeced" : "#ffffff")};

  // Overlay on right side
  position: absolute;
  right: 0px;

  padding: 0 12px;
  min-height: 30px;
  height: 100%;
  gap: 4px;
  align-items: center;

  // Hidden by default, show on hover
  display: none;

  ${CardItem}:hover & {
    display: flex;
  }
`;

const BottomIconBarContainer = styled.div`
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;
