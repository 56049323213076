import React from "react";
import styled from "styled-components";
import {
  generateClassName,
  getCanvasClassName,
} from "../../../utils/generators";
import { scrollCSS } from "../../WidgetUtils";
import { StepContainer } from "./StepContainer";
import type { ComponentProps } from "../../BaseComponent";

interface StepperProps extends ComponentProps {
  selectedStepIndex: number;
  borderColor: string;
  borderWidth: string;
  borderRadius: string;
  boxShadow: string;
  children: React.ReactNode;
  shouldScrollContents?: boolean;
  $noScroll: boolean;
  onStepChange: (stepWidgetId: string) => void;
  steps: Array<{
    id: string;
    widgetId: string;
    isVisible?: boolean;
    isOptional?: boolean;
    title: string;
    subTitle?: string;
  }>;
  selectedStepWidgetId: string;
  isSelectedStepCompleted: boolean;
  shouldShowSteps: boolean;
}

export const Stepper: React.FC<StepperProps> = ({
  $noScroll,
  borderColor,
  borderRadius,
  borderWidth,
  boxShadow,
  children,
  isSelectedStepCompleted,
  onStepChange,
  selectedStepIndex,
  selectedStepWidgetId,
  shouldScrollContents,
  shouldShowSteps,
  steps,
  widgetId,
}) => {
  const onSelectedStepChange = (id: string) => {
    onStepChange(id);
  };

  return (
    <StepperContent
      borderColor={borderColor}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      boxShadow={boxShadow}
    >
      {shouldShowSteps && (
        <StepsContainer>
          {steps.map((step, index) => {
            const isActive = selectedStepWidgetId === step.widgetId;
            const isAccessible = Boolean(
              index <= selectedStepIndex ||
                (index > 0 &&
                  steps[index - 1]?.isOptional &&
                  selectedStepIndex === index - 1),
            );
            const isCompleted =
              index < selectedStepIndex ||
              (isSelectedStepCompleted && index === selectedStepIndex);

            const onClick =
              isAccessible || isCompleted
                ? () => onSelectedStepChange(step.widgetId)
                : undefined;

            return (
              <StepContainer
                isAccessible={isAccessible}
                isActive={isActive}
                isCompleted={isCompleted}
                key={index}
                onClick={onClick}
                {...step}
              />
            );
          })}
        </StepsContainer>
      )}

      <ScrollCanvas
        $shouldScrollContents={!!shouldScrollContents && !$noScroll}
        className={`${
          shouldScrollContents ? getCanvasClassName() : ""
        } ${generateClassName(widgetId)}`}
      >
        {children}
      </ScrollCanvas>
    </StepperContent>
  );
};

const StepperContent = styled.div<{
  borderColor: string;
  borderRadius: string;
  borderWidth: string;
  boxShadow: string;
}>`
  height: 100%;
  background: white;
  border: ${({ borderColor, borderWidth }) =>
    `${borderWidth}px solid ${borderColor}`};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border-radius: ${({ borderRadius }) => borderRadius};
`;
const ScrollCanvas = styled.div<{ $shouldScrollContents: boolean }>`
  overflow: hidden;
  ${(props) => (props.$shouldScrollContents ? scrollCSS : ``)}
  width: 100%;
`;

const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1px solid #e0dede;
`;
