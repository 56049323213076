import type { PropertyPaneConfig } from "constants/PropertyControlConstants";
import {
  CLUSTERS_ARRAY_VALIDATION,
  CLUSTERS_CARDS_VALIDATION,
  LINKS_ARRAY_VALIDATION,
} from "./validations";
import { callbacksDict } from "./actionsAndCallbacks";
import { localizedStrings } from "./localizedStrings";
import { ValidationTypes } from "../../../constants/WidgetValidation";

export const propertyPaneContentConfig: PropertyPaneConfig[] = [
  {
    sectionName: "General",
    children: [
      {
        propertyName: "clusters",
        label: "Clusters",
        helpText: "Defines clusters.",
        controlType: "INPUT_TEXT",
        placeholderText:
          '[{"id": "cluster1", "title": "Cluster 1", "color": "#F0FDFA", "borderColor": "#5EEAD4", "column": 0}]',
        isBindProperty: true,
        isTriggerProperty: false,
        validation: CLUSTERS_ARRAY_VALIDATION,
      },
      {
        propertyName: "clustersCards",
        label: "Clusters Cards",
        helpText: "Defines clusters cards.",
        controlType: "INPUT_TEXT",
        placeholderText:
          '{"cluster1": [{"id": "card1", "title": "Card 1", "subtitle": "Card 1 subtitle", "items": [{"id": "item1", "title": "Item 1"}]}]}',
        isBindProperty: true,
        isTriggerProperty: false,
        validation: CLUSTERS_CARDS_VALIDATION,
      },
      {
        propertyName: "links",
        label: "Links",
        helpText: "Defines links.",
        controlType: "INPUT_TEXT",
        placeholderText: '[{"from": "item1", "to": "item2", "dashed": true}]',
        isBindProperty: true,
        isTriggerProperty: false,
        validation: LINKS_ARRAY_VALIDATION,
      },
    ],
  },
  {
    sectionName: "Callbacks",
    children: callbacksDict.map((callback) => ({
      propertyName: callback.name,
      label: callback.name,
      helpText: `Triggers an action when ${callback.triggersActionWhen}`,
      controlType: "ACTION_SELECTOR",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: true,
    })),
  },
  {
    sectionName: "Localized strings",
    children: localizedStrings.map((localizationString) => ({
      propertyName: `localizedStrings.${localizationString.id}`,
      label: localizationString.name,
      helpText: localizationString.description,
      controlType: "INPUT_TEXT",
      placeholderText: localizationString.defaultMessage,
      validation: { type: ValidationTypes.TEXT },
      isBindProperty: true,
      isTriggerProperty: false,
    })),
  },
];
