export enum FontFamily {
  PRIMARY = "DM Sans, Arial, sans-serif",
  SECONDARY = "Open Sans, sans-serif",
}

export const DEFAULT_FONT_SIZE = 16;

const getDefaultFontSize = (defaultFontSize: number): number => {
  const elements = document.getElementsByTagName("html");

  const element = elements[0] as HTMLElement | undefined;

  if (element) {
    const style = window.getComputedStyle(element);
    const fontSizeString = style.fontSize.split("px")[0];
    try {
      return parseInt(fontSizeString, 10) || defaultFontSize;
    } catch (e) {
      return defaultFontSize;
    }
  }
  return defaultFontSize;
};
export const USER_FONT_SIZE: number = getDefaultFontSize(DEFAULT_FONT_SIZE);
export const HTML_FONT_SIZE = USER_FONT_SIZE * (62.5 / 100);

export const pixelToRem = (pixels: number): string =>
  `${(pixels / HTML_FONT_SIZE).toFixed(2)}rem`;

const fontSizes = {
  bigHeading: pixelToRem(64),
  h1: pixelToRem(42.72),
  h2: pixelToRem(34.18),
  h3: pixelToRem(27.32),
  h4: pixelToRem(17.5),
  h5: pixelToRem(17.5),
  body: pixelToRem(14),
  small: pixelToRem(11.2),
  tiny: pixelToRem(8.69),
};

const fontWeights = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
};

export const theme = {
  fonts: {
    dmSans: FontFamily.PRIMARY,
    openSans: FontFamily.SECONDARY,
  },
  colors: {
    primary: {
      dark: "#e56844",
      main: "#ff9678",
      light: "#ffb496",
      lighter: "#ffdcce",
      lighterTransparent: "rgba(255, 220, 206, 0.8)",
    },
    secondary: {
      dark: "#303d55",
      main: "#485162",
      light: "#7f8591",
      lighter: "#bbbfc5",
      lightest: "#dadadb",
    },
    tertiary: {
      dark: "#c6b193",
      main: "#ebe5dc",
      whiteDarken: "#f8f8f9",
      white: "#ffffff",
      whiteTransparent: "rgba(255,255,255,.95)",
    },
    text: {
      dark: "#111d3e",
      main: "#586178",
      light: "#888E9f",
      lighter: "#cfd2d8",
      white: "#fffefd",
    },
    green: {
      main: "#05c273",
      light: "#d1e7de",
      lighter: "#e6f2ed",
    },
    red: {
      dark: "#980125",
      main: "#be022f",
      light: "#CF264E",
      lighter: "#f7e8ec",
    },
    blue: {
      dark: "#0057a8",
      main: "#017aea",
      light: "#bddaf4",
      lighter: "#f4faff",
    },
    yellow: {
      dark: "#e2a403",
      main: "#fcc12b",
      light: "#FDDA81",
      lighter: "#fef0cd",
    },
    random: {
      // source of these hex values is unknown
      ced4da: "#ced4da",
      orangeHard: "#FF7659",
      yellowRegular: "#FCC536",
    },
    bgActive: "rgba(255, 220, 206, 0.8)",
    bgHighlight: "rgba(255, 251, 153, 0.8)",
    bgHover: "rgba(248, 248, 249, 0.8)",
    highlight: "#FFF500",
    grey: "#485162",
    mediumGrey: "#535a69",
    hardGrey: "#303d55",
    orangeHard: "#303d55",
    lightGrey3: "#ececec",
    lightGrey3Transparent: "rgba(236, 236, 236, 0.8)",
    lightGrey2: "#bbbfc5",
    lightGrey: "#7f8591",
    success: "#46b634",
    black: "#000000",
    black2: "#1c1c1c",
    white: "#ffffff",
    purple: "#6C00D9",
    offWhite: "#F8F8F9",
    viewer: {
      ghost: "00000010",
      lightGhost: "00000005",
      referencePart: "ffdd00",
      relatedParts: {
        1: "05C273",
        2: "017AEA",
        3: "FCC536",
      },
      blockedPart: "be022f",
      blockingPart: "eabdc8",
      original: null,
      selected: "FF673C",
      signalPathObjects: {
        neutral: "303D55",
        highlighted: "FCC12B",
        selected: "FF673C",
        numberHighlighted: "000000",
        numberSelected: "FFFFFF",
      },
    } as const,
    diagram: {
      shadow: "rgba(72, 81, 98, 0.5)",
    } as const,
  },
  shadow: {
    five: "0px 2px 2px rgba(17, 29, 62, 0.15), 0px 3px 1px rgba(17, 29, 62, 0.1), 0px 1px 5px rgba(17, 29, 62, 0.2)",
    navigation:
      "0px 0px 8px 1px rgba(72, 81, 98, 0.1), 0px 8px 24px rgba(72, 81, 98, 0.1)",
    regular:
      "0px 8px 24px 0px rgba(72, 81, 98, 0.1), 0px 0px 8px 1px rgba(72, 81, 98, 0.1)",
    hover:
      "0px 16px 32px 0px rgba(72, 81, 98, 0.1), 0px 0px 8px 1px rgba(72, 81, 98, 0.1)",
    drag: "0px 0px 8px 1px rgba(72, 81, 98, 0.1), 0px 32px 40px rgba(72, 81, 98, 0.1)",
    topBar: "0px 4px 8px rgba(72, 81, 98, 0.1)",
    alert:
      "0px 16px 32px 0px rgba(72,81,98,0.1),0px 0px 8px 1px rgba(72,81,98,0.1)",
    connectivityAnalyser: {
      // The visual regression tool uses Chrome 89 to test the UI components.
      // Before Chrome 94, outline does not follow the shape of border-radius.
      // The same applies to many other browsers: https://developer.mozilla.org/en-US/docs/Web/CSS/outline#browser_compatibility
      // Using box-shadow for greater browser compatibility.
      pillOutline: `0px 0px 0px 1px #ececec`,
    },
  },
  zIdx: {
    card: 8,
    headerCard: 9,
    toolbar: 10,
    infoBox: 11,
    removalPositionOverlay: 12,
    drawer: 12,
    autocomplete: 13,
    progress: 1101,
    drawerButton: 1102,
    threeDotsMenu: 1102,
    wideDemountSequenceOverView: 1103,
    blockingOverlay: 1103,
    alert: 1104,
    sidenav: 3,
    topBar: 14,
    connectivityAnalyser_shadowBox: 1,
    background: -1,
    diagramOverview: 3,
  },
  textStyles: {
    label: {
      fontFamily: FontFamily.PRIMARY,
      fontSize: fontSizes.body,
      fontWeight: fontWeights.medium,
      lineHeight: "1.5em",
    } as const,
    labelLight: {
      fontFamily: FontFamily.PRIMARY,
      fontSize: fontSizes.body,
      fontWeight: fontWeights.regular,
      lineHeight: "1.5em",
    } as const,
    bigHeading: {
      fontFamily: FontFamily.PRIMARY,
      fontSize: fontSizes.bigHeading,
      fontWeight: fontWeights.bold,
      letterSpacing: "0.03em",
      lineHeight: "5.6rem",
    },
    h1: {
      fontFamily: FontFamily.PRIMARY,
      fontSize: fontSizes.h1,
      fontWeight: fontWeights.bold,
      letterSpacing: "0.03em",
      lineHeight: "5.6rem",
    } as const,
    h2: {
      fontFamily: FontFamily.PRIMARY,
      fontSize: fontSizes.h2,
      fontWeight: fontWeights.medium,
      lineHeight: "4.5rem",
    } as const,
    h3: {
      fontFamily: FontFamily.PRIMARY,
      fontSize: fontSizes.h3,
      fontWeight: fontWeights.bold,
      letterSpacing: "0.02em",
      lineHeight: "3.6rem",
    } as const,
    h4: {
      fontFamily: FontFamily.PRIMARY,
      fontSize: fontSizes.h4,
      fontWeight: fontWeights.bold,
      letterSpacing: "0.1em",
      lineHeight: "2.3rem",
      textTransform: "uppercase",
    } as const,
    h5: {
      fontFamily: FontFamily.PRIMARY,
      fontSize: fontSizes.h5,
      fontWeight: fontWeights.bold,
      lineHeight: "2.3rem",
    } as const,
    body: {
      fontFamily: FontFamily.SECONDARY,
      fontSize: fontSizes.body,
      fontWeight: fontWeights.regular,
      lineHeight: "1.9rem",
    } as const,
    bodyBold: {
      fontFamily: FontFamily.SECONDARY,
      fontSize: fontSizes.body,
      fontWeight: fontWeights.bold,
      lineHeight: "1.9rem",
    } as const,
    bodySmallBold: {
      fontFamily: FontFamily.SECONDARY,
      fontSize: fontSizes.small,
      fontWeight: fontWeights.bold,
      lineHeight: pixelToRem(15.25),
    } as const,
    bodySmallItalic: {
      fontFamily: FontFamily.SECONDARY,
      fontSize: fontSizes.small,
      fontWeight: fontWeights.regular,
      fontStyle: "italic",
      lineHeight: pixelToRem(15.25),
    } as const,
    button: {
      fontFamily: FontFamily.SECONDARY,
      fontSize: fontSizes.body,
      fontWeight: fontWeights.bold,
      lineHeight: "2rem",
      textTransform: "uppercase",
    } as const,
    small: {
      fontFamily: FontFamily.SECONDARY,
      fontSize: fontSizes.small,
      fontWeight: fontWeights.regular,
      lineHeight: "1.5rem",
    } as const,
    smallBold: {
      fontFamily: FontFamily.SECONDARY,
      fontSize: fontSizes.small,
      fontWeight: fontWeights.bold,
      lineHeight: "1.5rem",
      letterSpacing: "0.03em",
    } as const,
    tiny: {
      fontFamily: FontFamily.SECONDARY,
      fontSize: fontSizes.tiny,
      fontWeight: fontWeights.semiBold,
      letterSpacing: "0.1em",
      lineHeight: "1.2rem",
    } as const,
    buttonSmall: {
      fontFamily: FontFamily.SECONDARY,
      fontSize: fontSizes.small,
      fontWeight: fontWeights.bold,
      letterSpacing: "0.03em",
      lineHeight: "1.7rem",
      textTransform: "uppercase",
    } as const,
    buttonTiny: {
      fontFamily: FontFamily.SECONDARY,
      fontSize: fontSizes.tiny,
      fontWeight: fontWeights.extraBold,
      letterSpacing: "0.03em",
      lineHeight: "1.2rem",
      textTransform: "uppercase",
    } as const,
    navigation: {
      fontFamily: FontFamily.PRIMARY,
      fontSize: fontSizes.body,
      fontWeight: fontWeights.medium,
      lineHeight: "1.8rem",
    } as const,
    navigationSmall: {
      fontFamily: FontFamily.PRIMARY,
      fontSize: fontSizes.small,
      fontWeight: fontWeights.medium,
      lineHeight: "1.5rem",
    } as const,
    inputText: {
      fontFamily: FontFamily.PRIMARY,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "18px",
    } as const,
  },
  fontWeights,
  fontSizes,
} as const;

export type ThemeType = typeof theme;

type Join<GValue1, GValue2> = GValue1 extends string | number
  ? GValue2 extends string | number
    ? `${GValue1}${"" extends GValue2 ? "" : "."}${GValue2}`
    : never
  : never;

// Recursion Abort. Only iterates through 10 levels to prevent the compiler of going nuts
type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[],
];

export type Paths<GObject, GRecursionCutoff extends number = 10> = [
  GRecursionCutoff,
] extends [never]
  ? never
  : GObject extends object
    ? {
        [K in keyof GObject]-?: K extends string | number
          ? `${K}` | Join<K, Paths<GObject[K], Prev[GRecursionCutoff]>>
          : never;
      }[keyof GObject]
    : "";

export type Globals =
  | "-moz-initial"
  | "inherit"
  | "initial"
  | "revert"
  | "revert-layer"
  | "unset";

type ThemeLiteralColors = Paths<typeof theme.colors>;
export type ThemeColor = ThemeLiteralColors | Globals | "transparent";
