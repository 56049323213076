import * as go from "gojs";

export const registerCuttingPointShape = () => {
  go.Shape.defineFigureGenerator(
    "CuttingPoint",
    function (shape, width, height) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const strokeWidth = shape?.strokeWidth || 2;
      const offset = strokeWidth / 2;

      const minY = -offset;
      const maxY = height + offset;
      const midY = (maxY - minY) / 2 + minY;

      const minX = 0;
      const maxX = width;

      // Gap between top path and bottom path
      const gap = 2 * strokeWidth;

      const cornerSize = strokeWidth;

      const path = new go.PathFigure(minX + gap, midY)
        .add(
          new go.PathSegment(
            go.PathSegment.Line,
            minX + gap,
            minY + cornerSize,
          ),
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Arc,
            180,
            90,
            minX + gap + cornerSize,
            minY + cornerSize,
            cornerSize,
            cornerSize,
          ),
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Line,
            maxX - gap - cornerSize,
            minY,
          ),
        )
        .add(
          new go.PathSegment(
            go.PathSegment.Arc,
            270,
            90,
            maxX - gap - cornerSize,
            minY + cornerSize,
            cornerSize,
            cornerSize,
          ),
        )
        .add(new go.PathSegment(go.PathSegment.Line, maxX - gap, midY))
        .add(new go.PathSegment(go.PathSegment.Move, maxX, midY))
        .add(new go.PathSegment(go.PathSegment.Line, maxX, maxY - cornerSize))
        .add(
          new go.PathSegment(
            go.PathSegment.Arc,
            0,
            90,
            maxX - cornerSize,
            maxY - cornerSize,
            cornerSize,
            cornerSize,
          ),
        )
        .add(new go.PathSegment(go.PathSegment.Line, minX + cornerSize, maxY))
        .add(
          new go.PathSegment(
            go.PathSegment.Arc,
            90,
            90,
            minX + cornerSize,
            maxY - cornerSize,
            cornerSize,
            cornerSize,
          ),
        )
        .add(new go.PathSegment(go.PathSegment.Line, minX, midY));

      const geo = new go.Geometry();
      geo.add(path);

      return geo;
    },
  );
};
