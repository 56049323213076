import type { DiagramLink, DiagramNode } from "./index";
import { useMemo, useState } from "react";
import type { NodeId } from "../../GraphWidget/component/makeInitDiagram";

interface UseDiagramDataProps {
  diagramNodes: DiagramNode[];
  diagramLinks: DiagramLink[];
  onSelectedConnectionIdChanged: (nodeId: NodeId) => void;
  onSelectedTerminalIdChanged: (nodeId: NodeId) => void;
}

function updateObjectById(
  arr: DiagramNode[] | DiagramLink[],
  id: NodeId,
  updatedProperties: Partial<DiagramNode | DiagramLink>,
) {
  const result = [...arr];
  const index = result.findIndex((obj) => obj.id === id);
  if (index !== -1) {
    result[index] = { ...result[index], ...updatedProperties };
  }

  return result;
}

export const useDiagramData = ({
  diagramLinks,
  diagramNodes,
  onSelectedConnectionIdChanged,
  onSelectedTerminalIdChanged,
}: UseDiagramDataProps) => {
  const [selectedNodeId, setSelectedNodeId] = useState<NodeId | undefined>(
    undefined,
  );
  const [selectedLinkId, setSelectedLinkId] = useState<NodeId | undefined>(
    undefined,
  );

  const onNodeSelectionChange = (nodeId: string) => {
    setSelectedNodeId(nodeId);
    setSelectedLinkId(undefined);
    onSelectedTerminalIdChanged(nodeId);
  };
  const onLinkSelectionChange = (nodeId: string) => {
    setSelectedLinkId(nodeId);
    setSelectedNodeId(undefined);
    onSelectedConnectionIdChanged(nodeId);
  };

  const nodes = useMemo(() => {
    return selectedNodeId
      ? updateObjectById(diagramNodes, selectedNodeId, {
          selectionState: "selected",
        })
      : diagramNodes;
  }, [selectedNodeId, diagramNodes]);

  const links = useMemo(() => {
    return selectedLinkId
      ? updateObjectById(diagramLinks, selectedLinkId, {
          selectionState: "selected",
        })
      : diagramLinks;
  }, [selectedLinkId, diagramLinks]);

  return {
    nodes,
    links,
    onNodeSelectionChange,
    onLinkSelectionChange,
  };
};
