import React from "react";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { NodeId } from "../components";
import { Diagram } from "../components";
import { getDiagramData } from "../components/context";
import type {
  PathContainer,
  SignalPathConnection,
  SignalPathTerminal,
} from "../components/models/SignalPath";
import { expandRawSignalPaths } from "../components/models/SignalPath";
import { ValidationTypes } from "../../../constants/WidgetValidation";

import IconSVG from "../icon.svg";
import ThumbnailSVG from "../../spread_tmp_thumbnail.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import type {
  AutocompletionDefinitions,
  AutoLayoutConfig,
} from "WidgetProvider/constants";

const defaultProps = {
  connections: [
    {
      colors: [
        {
          hexCode: "#A52A2A",
          name: "Braun",
        },
      ],
      crossSectionArea: 4,
      id: "143007",
      origin: "Modul",
      partNumber: "4U2_765_123",
      segmentFiles: [
        "file:///file-server-data/shared/data/wire-segments/5ee562e0-420d-4f7e-ac37-db5f77999bb8/Segment.obj",
      ],
      signalName: "X.00",
      wireLength: 702.09,
      wireType: "individual wire",
    },
    {
      colors: [],
      crossSectionArea: 0,
      id: "174c2373-2990-45ac-b6bb-1455a08dea94",
      origin: "",
      partNumber: "",
      segmentFiles: [],
      signalName: "X.00",
      wireLength: 0,
      wireType: "internal",
    },
    {
      colors: [
        {
          hexCode: "#A52A2A",
          name: "Braun",
        },
      ],
      crossSectionArea: 0.35,
      id: "143017",
      origin: "Modul",
      partNumber: "4U2_765_123",
      segmentFiles: [
        "file:///file-server-data/shared/data/wire-segments/76b27180-02c8-4e7d-b191-3429d34a41af/Segment.obj",
      ],
      signalName: "X.00",
      wireLength: 588.77,
      wireType: "individual wire",
    },
  ],
  paths: [
    {
      path: [
        "11*1@A1",
        "143007",
        "MT8*7@A9",
        "174c2373-2990-45ac-b6bb-1455a08dea94",
        "MT8*7@C1",
        "143017",
        "86*1@1",
      ],
    },
  ],
  terminals: [
    {
      componentReferenceId: "11",
      componentType: "GroundPoint",
      connectionSignalName: "X.00",
      connectorColor: null,
      connectorName: "1",
      connectorNameAdditional: "XT4_B2_QQ",
      connectorPartNumber: "7T1_123_876_N",
      contactSurface: "sn",
      coordinates: [
        {
          coordinate: [0.022623482, -0.36798117, 0.5339945],
        },
      ],
      description: "Massepunkt Batteriekasten [KL2]",
      id: "11*1@A1",
      partNumber: "7T1_123_876_N",
      terminalName: "A1",
    },
    {
      componentReferenceId: "JK_VM0_T56",
      componentType: "Other",
      connectionSignalName: "X.00",
      connectorColor: {
        hexCode: "#000000",
        name: "Schwarz",
      },
      connectorName: "7",
      connectorPartNumber: "2P9_123_765_F",
      contactSurface: "sn",
      coordinates: [
        {
          coordinate: [0.00475274, -0.19127359000000002, 0.38409344],
        },
      ],
      description: "Bremskraftverstärker",
      id: "MT8*7@A9",
      partNumber: "H_202_812_97",
      terminalName: "A9",
    },
    {
      componentReferenceId: "MT8",
      componentType: "Other",
      connectionSignalName: "X.00",
      connectorColor: {
        hexCode: "#000000",
        name: "Schwarz",
      },
      connectorName: "7",
      connectorPartNumber: "3Y5_123_876",
      contactSurface: "sn",
      coordinates: [
        {
          coordinate: [0.00475274, -0.19127359000000002, 0.38409344],
        },
      ],
      description: "Bremskraftverstärker",
      id: "MT8*7@C1",
      partNumber: "B_102_777_08",
      terminalName: "C1",
    },
    {
      componentReferenceId: "86",
      componentType: "Splice",
      connectionSignalName: "X.00",
      connectorColor: null,
      connectorName: "1",
      connectorNameAdditional: "JK_VM0_T56",
      connectorPartNumber: "SPLICE",
      contactSurface: "",
      coordinates: [
        {
          coordinate: [-0.06929952, -0.39842413, 0.43523267],
        },
      ],
      description: "Masseverbindung 1 im Leitungsstrang hinten",
      id: "86*1@1",
      partNumber: "",
      terminalName: "1",
    },
  ],
};

const defaultStyleProps = {
  hasMiniMap: true,
  scale: "0.5",
  hasToolbar: false,
};

export interface WiringHarnessWidgetProps extends WidgetProps {
  terminals: SignalPathTerminal[];
  connections: SignalPathConnection[];
  paths: PathContainer[];

  hasMiniMap: boolean;
  scale: string;
  hasToolbar: boolean;
}

class WiringHarnessWidget extends BaseWidget<
  WiringHarnessWidgetProps,
  WidgetState
> {
  static type = "SPREAD_WIRING_HARNESS_WIDGET";

  static getConfig() {
    return {
      name: "Wiring Harness", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      thumbnailSVG: ThumbnailSVG,
      tags: [WIDGET_TAGS.DISPLAY],
      needsMeta: true, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
    };
  }

  static getDefaults() {
    return {
      ...defaultProps,
      ...defaultStyleProps,
      widgetName: "WiringHarness",
      rows: 60,
      columns: 60,
      version: 1,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    // TODO: add proper auto layout config
    return {};
  }

  constructor(props: WiringHarnessWidgetProps) {
    super(props);
  }

  getDiagramData() {
    return getDiagramData(
      expandRawSignalPaths({
        terminals: this.props.terminals,
        paths: this.props.paths,
        connections: this.props.connections,
      }).signalPaths,
      null,
      null,
      null,
    );
  }

  static getPropertyPaneStyleConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            propertyName: "hasMiniMap",
            label: "Minimap",
            helpText: "Mini preview of the diagram",
            controlType: "SWITCH",
            defaultValue: true,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "hasToolbar",
            label: "Toolbar",
            helpText: "Toolbar helping in interactions with diagram",
            controlType: "SWITCH",
            defaultValue: false,
            isBindProperty: false,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
          {
            propertyName: "scale",
            label: "Diagram scale",
            helpText: "Scale for diagram visualization",
            placeholderText: "Enter positive number",
            controlType: "INPUT_TEXT",
            defaultValue: "1",
            isBindProperty: true,
            isTriggerProperty: true,
          },
        ],
      },
    ];
  }

  static getPropertyPaneContentConfig() {
    return [
      {
        sectionName: "General",
        children: [
          {
            helperText: "Defines connections of the diagram.",
            propertyName: "connections",
            label: "Connections",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                unique: ["id"],
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    allowedKeys: [
                      {
                        name: "color",
                        type: ValidationTypes.ARRAY,
                        children: {
                          type: ValidationTypes.OBJECT,
                          params: {
                            children: {
                              type: ValidationTypes.OBJECT,
                              params: {
                                allowedKeys: [
                                  {
                                    name: "hexCode",
                                    type: ValidationTypes.TEXT,
                                  },
                                  {
                                    name: "name",
                                    type: ValidationTypes.TEXT,
                                  },
                                ],
                              },
                            },
                          },
                        },
                      },
                      {
                        name: "crossSectionArea",
                        type: ValidationTypes.NUMBER,
                      },
                      {
                        name: "id",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "origin",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "partNumber",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "segmentFiles",
                        type: ValidationTypes.ARRAY,
                        params: {
                          children: {
                            type: ValidationTypes.TEXT,
                          },
                        },
                      },
                      {
                        name: "signalName",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "wireLength",
                        type: ValidationTypes.NUMBER,
                      },
                      {
                        name: "wireType",
                        type: ValidationTypes.TEXT,
                      },
                    ],
                  },
                },
              },
            },
          },

          {
            helperText: "Defines paths of the diagram.",
            propertyName: "paths",
            label: "Paths",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    allowedKeys: [
                      {
                        name: "path",
                        type: ValidationTypes.ARRAY,
                        params: {
                          children: { type: ValidationTypes.TEXT },
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
          {
            helperText: "Defines terminals of the diagram.",
            propertyName: "terminals",
            label: "Terminals",
            controlType: "INPUT_TEXT",
            isBindProperty: true,
            isTriggerProperty: false,
            validation: {
              type: ValidationTypes.ARRAY,
              params: {
                unique: ["id"],
                children: {
                  type: ValidationTypes.OBJECT,
                  params: {
                    allowedKeys: [
                      {
                        name: "componentReferenceId",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "componentType",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "connectionSignalName",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "diagnosisId",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "fuseValue",
                        type: ValidationTypes.NUMBER,
                      },
                      {
                        name: "connectorColor",
                        type: ValidationTypes.OBJECT,
                        params: {
                          allowedKeys: [
                            {
                              name: "hexCode",
                              type: ValidationTypes.TEXT,
                            },
                            {
                              name: "name",
                              type: ValidationTypes.TEXT,
                            },
                          ],
                        },
                      },
                      {
                        name: "connectorName",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "connectorNameAdditional",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "connectorPartNumber",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "contactSurface",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "coordinates",
                        type: ValidationTypes.ARRAY,
                        params: {
                          children: {
                            type: ValidationTypes.OBJECT,
                            params: {
                              allowedKeys: [
                                {
                                  name: "coordinate",
                                  type: ValidationTypes.ARRAY,
                                  params: {
                                    children: { type: ValidationTypes.TEXT },
                                  },
                                },
                              ],
                            },
                          },
                        },
                      },
                      {
                        name: "description",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "id",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "partNumber",
                        type: ValidationTypes.TEXT,
                      },
                      {
                        name: "terminalName",
                        type: ValidationTypes.TEXT,
                      },
                    ],
                  },
                },
              },
            },
          },
        ],
      },
    ];
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      selectedTerminalId: {
        "!type": "string",
        "!doc": "Selected terminal id",
      },
      selectedConnectionId: {
        "!type": "string",
        "!doc": "Selected connection id",
      },
    };
  }

  onSelectedTerminalIdChanged = (nodeId: NodeId) => {
    this.props.updateWidgetMetaProperty("selectedTerminalId", nodeId);
    this.props.updateWidgetMetaProperty("selectedConnectionId", undefined);
  };

  onSelectedConnectionIdChanged = (nodeId: NodeId) => {
    this.props.updateWidgetMetaProperty("selectedConnectionId", nodeId);
    this.props.updateWidgetMetaProperty("selectedTerminalId", undefined);
  };

  getWidgetView() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Diagram
          hasMiniMap={this.props.hasMiniMap}
          hasToolbar={this.props.hasToolbar}
          linkDataArray={this.getDiagramData().links}
          nodeDataArray={this.getDiagramData().nodes}
          onSelectedConnectionIdChanged={this.onSelectedConnectionIdChanged}
          onSelectedTerminalIdChanged={this.onSelectedTerminalIdChanged}
          scale={parseFloat(this.props.scale)}
        />
      </div>
    );
  }
}

export default WiringHarnessWidget;
