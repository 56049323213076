import type { CadDomainModelSelectorProps } from "./CadDomainModelSelector";
import CadDomainModelSelector from "./CadDomainModelSelector";
import StepsControl from "./StepsControl";
import Base64ImagePickerControl from "./Base64ImagePickerControl";
import type { Base64ImagePickerControlProps } from "./Base64ImagePickerControl";
import TreeModeControl, { type TreeModeControlProps } from "./TreeModeControl";

export const SpreadPropertyControls = {
  StepsControl,
  CadDomainModelSelector,
  Base64ImagePickerControl,
  TreeModeControl,
};

export type SpreadPropertyControlProps =
  | CadDomainModelSelectorProps
  | Base64ImagePickerControlProps
  | TreeModeControlProps;
