import React, { useEffect, useMemo, useState } from "react";

import type { Link } from "../../types";
import { Arrow } from "./Arrow";

interface LinksProps {
  links: Link[];
  cardItemToColumnMap: Record<string, number>;
  widgetId: string;
  deps?: unknown[];
}

export const Links = ({
  cardItemToColumnMap,
  deps,
  links: linksProp,
  widgetId,
}: LinksProps) => {
  const [links, setLinks] = useState<Link[]>([]);

  // TODO: rethink data flows to reduce recomputations and reduce complexity
  const listOfItemIDs = useMemo(
    () => new Set(Object.keys(cardItemToColumnMap)),
    [cardItemToColumnMap],
  );

  /**
   * Force rerender of links when links or deps change.
   */
  useEffect(() => {
    setLinks([]);
    setTimeout(() => {
      setLinks(linksProp);
    }, 0);
  }, [linksProp, deps]);

  return (
    <>
      {links.map((link) => {
        // Skip links that have no valid source or target
        if (!listOfItemIDs.has(link.from) || !listOfItemIDs.has(link.to))
          return null;

        // Unique ID for each link
        const linkId = `${widgetId}-${link.from}-${link.to}`;

        // Determine direction of the arrow
        const fromColumn = cardItemToColumnMap[link.from];
        const toColumn = cardItemToColumnMap[link.to];

        // Unexpected behavior, shall we document that links between items in the same column are not supported?
        const isSameColumn = fromColumn === toColumn;
        if (isSameColumn) return null;

        const direction =
          fromColumn < toColumn ? "fromLeftToRight" : "fromRightToLeft";

        return (
          <Arrow
            direction={direction}
            key={linkId}
            link={link}
            widgetId={widgetId}
          />
        );
      })}
    </>
  );
};
