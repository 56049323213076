import * as go from "gojs";

// eslint-disable-next-line @typescript-eslint/unbound-method
const $ = go.GraphObject.make;

const DASH_WIDTH = 8;
const LINK_WIDTH = 2;
const BORDERED_LINK_WIDTH = 3;
const WHITE_COLOR = "#FFFFFF";

/**
 * Multi-color link for GoJS.
 *
 * The colors must be passed with the data in the `colors` parameter as an array of strings.
 *
 * To make it work, add to link template following shapes:
 * - `makeMultiColorLinkBorderShape()`
 * - as much `makeMultiColorLinkDashShape()` as maximal amount of colors should be supported.
 *
 * The implementation of the class is inspired by an example from the library website
 * Reference: https://gojs.net/latest/samples/multiColorLinks.html
 */
export class MultiColorLink extends go.Link {
  makeGeometry() {
    const geo = super.makeGeometry();
    const colors = (this.data.colors || []) as string[];

    // if there is no colors, return normal link
    if (colors.length < 1) {
      return geo;
    }

    const paths: go.Shape[] = [];

    // find all shapes with isPanelMain=true related to MultiColorLink
    this.elements.each((element) => {
      if (
        element.isPanelMain &&
        element instanceof go.Shape &&
        element.name.includes("MultiColorLink")
      ) {
        paths.push(element);
      }
    });

    // The first shape reserved for border
    const border = paths.shift() as go.Shape;

    /**
     * The presence of white color is the only condition for showing a border
     * https://solveit.atlassian.net/wiki/spaces/PROD/pages/916947065/Connection+and+Wire+Colors
     */
    const hasBorder = colors.some((color) => color === WHITE_COLOR);

    /**
     * if a border should be shown, increase the strokeWidth
     * it doesn't break link selection logic, unlike changing border.visible
     */
    if (hasBorder) {
      border.strokeWidth = BORDERED_LINK_WIDTH;
    }

    /**
     * Determine the number of colors that will be shown
     * if you see less colors than in provided data, add more `makeMultiColorLinkDashShape()` to link template
     */
    const numColors = Math.min(colors.length, paths.length);

    // if there is no numColors, return normal link
    if (numColors < 1) {
      return geo;
    }

    for (let i = 0; i < numColors; i++) {
      const shape = paths[i];

      // make shape visible and assign color to it
      shape.visible = true;
      shape.stroke = colors[i];

      // add dashes starting from the second color
      if (i > 0) {
        // this strokeDashArray adjusts the appearance of the dashes so that they go in order (supports as much colors as `numColors`)
        shape.strokeDashArray = [
          0, // first dash is widthless, because we need to start with space to show the first color which is not dashed
          i * DASH_WIDTH, // space between previous colors dashes and first dash of this color
          DASH_WIDTH, // width of dash
          (numColors - 1 - i) * DASH_WIDTH, // space after current color dash
        ];
      }
    }
    return geo;
  }
}

export const makeMultiColorLinkBorderShape = (stroke: string) =>
  $(go.Shape, {
    name: "MultiColorLinkBorder",
    isPanelMain: true,
    stroke,
    strokeWidth: LINK_WIDTH, // by default
    pickable: false,
  });

export const makeMultiColorLinkDashShape = () =>
  $(go.Shape, {
    name: "MultiColorLinkDash",
    isPanelMain: true,
    strokeWidth: LINK_WIDTH,
    visible: false, // by default
    pickable: false,
  });
