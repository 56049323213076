import React from "react";
interface ArrowRightProps {
  width?: number;
  height?: number;
}
export const ArrowRight: React.FC<ArrowRightProps> = ({
  height = "12",
  width = "8",
}) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 8 12"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7.17985 5.39143L2.037 0.248571C1.88272 0.0942857 1.66843 0 1.42843 0C0.957003 0 0.571289 0.385714 0.571289 0.857143C0.571289 1.09714 0.665575 1.31143 0.81986 1.46571L5.36271 6L0.828432 10.5343C0.665575 10.6886 0.571289 10.9029 0.571289 11.1429C0.571289 11.6143 0.957003 12 1.42843 12C1.66843 12 1.88272 11.9057 2.037 11.7514L7.17985 6.60857C7.33414 6.45429 7.42842 6.24 7.42842 6C7.42842 5.76 7.33414 5.54571 7.17985 5.39143Z"
        fill="#090707"
        fillRule="evenodd"
      />
    </svg>
  );
};
