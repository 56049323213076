import { SpreadFunction } from "components/editorComponents/ActionCreator/constants";
import type { TreeDropdownOption } from "design-system-old";
import type { EnabledFlow } from "spread/flows/types";

export interface AppsmithAction {
  label: string;
  value: string;
  children?: TreeDropdownOption[];
}

export interface SpreadRunFlowDropdownOption extends TreeDropdownOption {
  args: [flowId: string, flowParameters: EnabledFlow["parameters"]];
}

export const EMPTY_DROPDOWN_OPTION: SpreadRunFlowDropdownOption = {
  /**
   * Uses BlueprintJS icons
   * Available icons: https://blueprintjs.com/docs/versions/3/#icons
   */
  icon: "play",
  label: "Execute flow by ID",
  value: `${SpreadFunction.runFlow}(""`,
  args: ["", []],
  type: SpreadFunction.runFlow,
};

export function findExecuteAFlowAction(
  actionList: AppsmithAction[],
): TreeDropdownOption | undefined {
  return actionList.find((action) => action.value === SpreadFunction.runFlow);
}

export function generateFlowsActionList(
  enabledFlows: EnabledFlow[],
): SpreadRunFlowDropdownOption[] {
  if (!enabledFlows || enabledFlows.length === 0) {
    return [EMPTY_DROPDOWN_OPTION];
  }

  return enabledFlows.map((flow) => {
    const option: SpreadRunFlowDropdownOption = {
      label: flow.name,
      // The dropdown option is selected if the actual code starts with this value (!!!)
      // app/client/src/components/editorComponents/ActionCreator/viewComponents/Action/ActionSelector.tsx:202
      value: `${SpreadFunction.runFlow}("${flow.id}"`,
      args: [flow.id, flow.parameters],

      // this is connected to app/client/src/components/editorComponents/ActionCreator/FieldGroup/FieldGroupConfig.ts
      type: SpreadFunction.runFlow,
    };

    return option;
  });
}
