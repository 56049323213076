import React from "react";

const LineIcon = ({ strokeColor = "#596478" }) => (
  <svg
    data-testid="LineIcon"
    fill="none"
    viewBox="0 0 25 2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill={strokeColor} height="2" width="25" />
  </svg>
);

export default LineIcon;
