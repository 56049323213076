import React from "react";

const ResetIcon = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M5.33335 5.33334C5.33335 5.70001 5.03335 6.00001 4.66669 6.00001H2.00002C1.63335 6.00001 1.33335 5.70001 1.33335 5.33334V2.66668C1.33335 2.30001 1.63335 2.00001 2.00002 2.00001C2.36669 2.00001 2.66669 2.30001 2.66669 2.66668V4.03334C3.88002 2.40001 5.80669 1.33334 8.00002 1.33334C11.68 1.33334 14.6667 4.32001 14.6667 8.00001C14.6667 11.68 11.68 14.6667 8.00002 14.6667C4.32002 14.6667 1.33335 11.68 1.33335 8.00001C1.33335 7.63334 1.63335 7.33334 2.00002 7.33334C2.36669 7.33334 2.66669 7.63334 2.66669 8.00001C2.66669 10.9467 5.05335 13.3333 8.00002 13.3333C10.9467 13.3333 13.3334 10.9467 13.3334 8.00001C13.3334 5.05334 10.9467 2.66668 8.00002 2.66668C6.31335 2.66668 4.82002 3.44668 3.84002 4.66668H4.66669C5.03335 4.66668 5.33335 4.96668 5.33335 5.33334Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default ResetIcon;
