import type { EnabledFlow } from "../types";
import { getFlowIdFromRunFlowArgument } from "./getFlowIdFromRunFlowArgument";
import { getRunFlowArguments } from "./getRunFlowArguments";

const SELECT_FLOW_TEXT = "Select flow";

/**
 * Returns the text shown in the action creator UI for the `spread.flows.runFlow` action.
 *
 * @example
 * ```ts
 * const flows = [{
 *  id: "FLOW_1",
 *  name: "My cool flow",
 *  parameters: [{ name: "param1" }],
 * }]
 * getRunFlowActionHeading(flows, 'spread.flows.runFlow("FLOW_1", { param1: "value1" })', { codeEvaluationVersion: 2, minimal: false });
 * //> "My cool flow { param1: "value1" }"
 * ```
 */
export function getRunFlowActionHeading(
  enabledFlows: EnabledFlow[],
  runFlowCode: string,
  {
    codeEvaluationVersion,
    minimal,
  }: {
    codeEvaluationVersion: number;
    minimal: boolean;
  },
) {
  const { flowId, parameters } = getRunFlowArguments(
    runFlowCode,
    codeEvaluationVersion,
  );

  if (
    !flowId ||
    // is flowId just an empty string?
    ['""', "''", "``"].includes((flowId ?? "").replaceAll(" ", ""))
  ) {
    return SELECT_FLOW_TEXT;
  }

  const flowName = getFlowNameFromIdArgument(enabledFlows, flowId);

  if (minimal) {
    return flowName;
  }
  const stringifiedFlowParams = stringifyFlowParameters(parameters);
  return `${flowName}${stringifiedFlowParams ? ` ${stringifiedFlowParams}` : ""}`;
}

function getFlowNameFromIdArgument(
  enabledFlows: EnabledFlow[],
  flowId: string,
) {
  const parsedFlowId = getFlowIdFromRunFlowArgument(flowId);

  if (parsedFlowId === null) return `Flow ID: ${flowId}`;

  const flow = enabledFlows.find((flow) => flow.id === parsedFlowId);
  if (!flow) return `Unpublished flow (${flowId})`;

  return flow.name;
}

/**
 * Stringifies the flow parameters object to look like code, excluding any undefined values.
 * @example
 * ```ts
 * stringifyFlowParameters({ lang: "\"de\"", includeTime: "true", timestamp: "undefined" });
 * //> "{ lang: "de", includeTime: true }"
 * ```
 *
 * @param flowParams The flow parameters object as returned by `getRunFlowArguments`.
 */
function stringifyFlowParameters(flowParams: Record<string, string>) {
  if (Object.keys(flowParams).length === 0) return "";

  const flowParamsString = `${Object.entries(flowParams).reduce(
    (paramsStr, [paramName, paramValue]) => {
      if (paramValue === undefined || paramValue === "undefined") {
        return paramsStr;
      }

      return `${paramsStr}${paramsStr ? ", " : ""}${paramName}: ${paramValue}`;
    },
    "",
  )}`;

  // in case all values are undefined
  if (!flowParamsString) return "";

  return `{ ${flowParamsString} }`;
}
