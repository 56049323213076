import type {
  AutoLayoutConfig,
  AutocompletionDefinitions,
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import React from "react";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { WithMeta } from "widgets/MetaHOC";
import { EventType } from "../../../constants/AppsmithActionConstants/ActionConstants";
import type { SetterConfig, Stylesheet } from "../../../entities/AppTheming";
import { WidgetContainerDiff } from "../../WidgetUtils";
import type { TreeNode, TreeNodeId } from "../components/Node";
import { Tree } from "../components/Tree";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../../spread_tmp_thumbnail.svg";
import { propertyPaneContentConfig } from "./propertyConfig";
import { propertyPaneStyleConfig } from "./styleConfig";
import {
  type ChildrenIdsMap,
  createIdToLeafIdsMap,
} from "../components/createIdToLeafIdsMap";
import { INTERNAL_TREE_ROOT } from "../components/constants";
import { SPREAD_TREE_STYLESHEET_CONFIG } from "../constants";

export type TreeSelectionType = "default" | "controlled" | "directory";

export interface SpreadTreeWidgetProps extends WidgetProps, WithMeta {
  sourceData?: TreeNode[];
  searchTerm: string;
  selectedLeafNodesIds?: string[];
  defaultSelectedIds?: string[];
  fillColor: string;
  selectionType: TreeSelectionType;
}

const defaultProps: Partial<SpreadTreeWidgetProps> = {
  sourceData: [
    {
      id: "1",
      label: "Germany",
      children: [
        {
          id: "2",
          label: "Baden-Württemberg",
          children: [
            {
              id: "3",
              label: "Alb-Donau-Kreis",
              children: [
                { id: "4", label: "Albeck" },
                { id: "5", label: "Schnürpflingen" },
              ],
            },
            {
              id: "6",
              label: "Schwäbisch Hall",
              children: [{ id: "7", label: "Gaildorf" }],
            },
          ],
        },
      ],
    },
  ],
};

const defaultStyleProps = {
  treeHeight: "600",
  searchTerm: "",
  treeWidth: "242",
  defaultSelectedIds: [],
  selectedLeafNodesIds: [],
  rowHeight: 32,
  isParentNodeSelectable: false,
  selectionType: "controlled",
};

export const PROGRESS_BAR_HEIGHT = 4;

function areSetsEqual<T>(set1: Set<T>, set2: Set<T>): boolean {
  // Check every element of set1 in set2
  for (const item of set1) {
    if (!set2.has(item)) {
      return false; // If an item in set1 is not in set2, sets are not equal
    }
  }

  // If we made it here, all elements match
  return true;
}

class SpreadTreeWidget extends BaseWidget<SpreadTreeWidgetProps, WidgetState> {
  childrenIdsMap: ChildrenIdsMap;
  static type = "SPREAD_TREE_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return {
      name: "Tree",
      iconSVG: IconSVG,
      thumbnailSVG: ThumbnailSVG,
      tags: [WIDGET_TAGS.DISPLAY],
      needsMeta: true,
      isCanvas: false,
    };
  }

  static getDefaults(): WidgetDefaultProps {
    return {
      ...defaultProps,
      ...defaultStyleProps,
      widgetName: "Tree",
      rows: 60,
      columns: 15,
      version: 1,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    // TODO: add proper auto layout config
    return {};
  }

  constructor(props: SpreadTreeWidgetProps) {
    super(props);
    this.childrenIdsMap = {};
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      selectedLeafNodesIds: {
        "!type": "[string]",
        "!doc": "The array of selected nodes ids of the tree",
      },
      selectedIds: {
        "!type": "[string]",
        "!doc":
          "The array of all selected ids of the tree, including both leaf and parent nodes.",
      },
    };
  }

  static getPropertyPaneContentConfig() {
    return propertyPaneContentConfig;
  }

  static getPropertyPaneStyleConfig() {
    return propertyPaneStyleConfig;
  }

  static getStylesheetConfig(): Stylesheet {
    return SPREAD_TREE_STYLESHEET_CONFIG;
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setSelectedIds: {
          path: "defaultSelectedIds",
          type: "array<string>",
        },
        setIsForcedLoading: {
          path: "isForcedLoading",
          type: "boolean",
        },
      },
    };
  }

  componentDidMount() {
    this.createChildrenIdsMap();
    this.updateSelectedLeafNodeIds(
      this.props.defaultSelectedIds
        ? this.filterParentIds(this.props.defaultSelectedIds)
        : [],
    );

    this.updateSelectedIds(this.getSelectedIds());
  }

  componentDidUpdate(prevProps: SpreadTreeWidgetProps) {
    if (prevProps.sourceData !== this.props.sourceData) {
      this.createChildrenIdsMap();
    }

    if (
      prevProps.defaultSelectedIds?.length !==
        this.props.defaultSelectedIds?.length ||
      !areSetsEqual(
        new Set(prevProps.defaultSelectedIds),
        new Set(this.props.defaultSelectedIds),
      )
    ) {
      this.updateSelectedLeafNodeIds(
        this.props.defaultSelectedIds
          ? this.filterParentIds(this.props.defaultSelectedIds)
          : [],
      );

      this.updateSelectedIds(this.getSelectedIds());
    }
  }

  getSelectedIds = () => {
    if (
      this.props.isParentNodeSelectable ||
      this.props.selectionType === "directory"
    ) {
      return this.props.defaultSelectedIds ?? [];
    }

    return this.filterParentIds(this.props.defaultSelectedIds ?? []);
  };
  createChildrenIdsMap = () => {
    this.childrenIdsMap = createIdToLeafIdsMap({
      id: INTERNAL_TREE_ROOT,
      label: "",
      children: this.props.sourceData,
    });
  };

  filterParentIds = (ids: TreeNodeId[]) => {
    return ids.filter((id) => {
      return !(
        this.childrenIdsMap &&
        id in this.childrenIdsMap &&
        this.childrenIdsMap[id]!.length > 0
      );
    });
  };

  updateSelectedLeafNodeIds = (ids?: TreeNodeId[]) => {
    this.props.updateWidgetMetaProperty("selectedLeafNodesIds", ids);
  };

  updateSelectedIds = (ids?: TreeNodeId[]) => {
    this.props.updateWidgetMetaProperty("selectedIds", ids);
  };

  // In the future we should have only selectedIds
  onLeafNodesIdsSelectionChange = (value: string[]) => {
    this.props.updateWidgetMetaProperty("selectedLeafNodesIds", value, {
      triggerPropertyName: "onSelectionChange",
      dynamicString: this.props.onSelectionChange,
      event: {
        type: EventType.ON_SELECTION_CHANGE,
      },
    });
  };

  getWidgetView() {
    const { componentHeight, componentWidth } = this.props;

    // Style props
    const { checkboxAccentColor, checkboxBorderRadius, progressBarFillColor } =
      this.props;

    const {
      defaultSelectedIds,
      isForcedLoading,
      isParentNodeSelectable,
      rowBackgroundAccentColor,
      rowHeight,
      searchTerm,
      selectedIds,
      selectedLeafNodesIds,

      selectionType = "default",
      sourceData,
    } = this.props;

    return (
      <Tree
        checkboxAccentColor={checkboxAccentColor}
        checkboxBorderRadius={checkboxBorderRadius}
        childrenIdsMap={this.childrenIdsMap}
        defaultSelectedIds={defaultSelectedIds ?? []}
        filterParentIds={this.filterParentIds}
        height={componentHeight - WidgetContainerDiff - PROGRESS_BAR_HEIGHT}
        isLoading={isForcedLoading}
        isParentNodeSelectable={isParentNodeSelectable}
        onLeafNodesIdsSelectionChange={this.onLeafNodesIdsSelectionChange}
        onSelectedIdsChange={this.updateSelectedIds}
        progressBarFillColor={
          // Old widgets have progressBarFillColor equal undefined
          progressBarFillColor ? progressBarFillColor : "#553DE9"
        }
        rowBackgroundAccentColor={rowBackgroundAccentColor}
        rowHeight={rowHeight}
        searchTerm={searchTerm}
        selectedIds={selectedIds ?? []}
        selectedLeafNodesIds={selectedLeafNodesIds ?? []}
        selectionType={selectionType}
        sourceData={sourceData ?? []}
        width={componentWidth - WidgetContainerDiff}
      />
    );
  }
}

export default SpreadTreeWidget;
