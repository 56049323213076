import React from "react";

const LineCircleIcon = ({ strokeColor = "#596478" }) => (
  <svg
    data-testid="LineCircleIcon"
    fill="none"
    viewBox="0 0 25 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M19.9291 8C19.4441 11.3926 16.5266 14 13 14C9.47351 14 6.55615 11.3923 6.07092 8H0V6H6.07092C6.55591 2.60742 9.47339 0 13 0C16.5265 0 19.4438 2.6077 19.9291 6H25V8H19.9291ZM18 7C18 9.76141 15.7615 12 13 12C10.2385 12 8 9.76141 8 7C8 4.23859 10.2385 2 13 2C15.7615 2 18 4.23859 18 7Z"
      fill={strokeColor}
      fillRule="evenodd"
    />
  </svg>
);

export default LineCircleIcon;
