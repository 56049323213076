import React from "react";
import styled from "styled-components";

interface ContextMenuItem {
  label: string;
  onClick: (targetId?: string) => void;
  disabled?: boolean | ((targetId?: string) => boolean);
  isHidden?: boolean;
}

interface ContextMenuProps {
  targetId?: string;
  anchorPosition?: { top: number; left: number };
  items: ContextMenuItem[];
  onClose: () => void;
}

export const ContextMenu = (props: ContextMenuProps) => {
  const { anchorPosition, items, onClose, targetId } = props;

  const isVisible = !!anchorPosition;

  if (!isVisible) return null;

  return (
    <Container {...anchorPosition}>
      {items.map((item) => {
        const disabled =
          typeof item.disabled === "function"
            ? item.disabled(targetId)
            : item.disabled;

        if (item?.isHidden) {
          return;
        }

        return (
          <MenuItem
            disabled={disabled}
            key={item.label}
            onClick={() => {
              item.onClick(targetId);
              onClose();
            }}
          >
            {item.label}
          </MenuItem>
        );
      })}
    </Container>
  );
};

const Container = styled.div<{
  top: number;
  left: number;
}>`
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 6px;
  border: 1px solid #e0dede;
  background: #fff;

  /* Overlay */
  box-shadow:
    0px 4px 6px 0px rgba(0, 0, 0, 0.05),
    0px 10px 15px 0px rgba(0, 0, 0, 0.1);

  overflow: hidden;
`;

const MenuItem = styled.button`
  display: flex;
  padding: 6px 12px;
  align-self: stretch;
  min-width: 135px;
  white-space: nowrap;
  cursor: pointer;

  /* Font */
  color: #090707;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.07px;

  /* Colors from MenuButtonWidget */

  &:disabled {
    background-color: transparent;
    color: rgba(92, 112, 128, 0.6);
    cursor: not-allowed;
  }

  &:active {
    background-color: #e6e6e6 !important;
  }

  &:hover:not([disabled]) {
    background-color: rgba(167, 182, 194, 0.3);
  }
`;
