import { prop } from "ramda";
import type {
  ColorInfo,
  Component,
  SignalPathArray,
  SignalPathItem,
  Terminal,
} from "../types";
import { isTerminal } from "../guards";

export function generateSignalPathId(signalPathArray: SignalPathArray): string {
  return signalPathArray.map(prop("id")).join("->");
}

export function getConnectionColorNames(colors: ColorInfo[]): string {
  return colors.map(prop("name")).join(" / ") || "-";
}

export function getComponentTitle(
  component: Pick<Component, "referenceId" | "diagnosisId">,
): string {
  return component.diagnosisId
    ? `${component.referenceId} | ${component.diagnosisId}`
    : component.referenceId;
}

export function isItemInSignalPath(
  signalPath: SignalPathArray | null,
  item: SignalPathItem | null,
): boolean {
  if (!signalPath || !item) {
    return false;
  }

  const foundItem = signalPath.find((signalPathItem: SignalPathItem) => {
    return signalPathItem.id === item.id;
  });

  return foundItem !== undefined;
}

export function findSignalPathItemToSelectByRefId(
  componentRefId: string | null,
  selectedSignalPath: SignalPathArray | null,
  signalPathList: SignalPathArray[],
) {
  if (!componentRefId) {
    return {
      signalPath: null,
      signalPathItem: null,
    };
  }

  if (selectedSignalPath) {
    const itemInSelectedSignalPath = selectedSignalPath.find(
      (pathItem) =>
        isTerminal(pathItem) &&
        pathItem.componentReferenceId === componentRefId,
    );

    if (itemInSelectedSignalPath) {
      return {
        signalPath: selectedSignalPath,
        signalPathItem: itemInSelectedSignalPath,
      };
    }
  }

  const result: {
    signalPath: SignalPathArray | null;
    signalPathItem: SignalPathItem | null;
  } = {
    signalPath: null,
    signalPathItem: null,
  };

  if (!signalPathList.length) {
    return result;
  }

  for (let i = 0; i < signalPathList.length; i++) {
    const currentSignalPath = signalPathList[i];
    const itemInCurrentSignalPath = currentSignalPath.find(
      (item) =>
        isTerminal(item) && item.componentReferenceId === componentRefId,
    );
    if (itemInCurrentSignalPath) {
      if (!result.signalPathItem) {
        result.signalPathItem = itemInCurrentSignalPath;
        result.signalPath = currentSignalPath;
      } else {
        result.signalPathItem = null;
        result.signalPath = null;
        break;
      }
    }
  }

  return result;
}

export function sortTerminals(
  terminals: Terminal[],
  byProperty: "terminalName" | "connectorName",
) {
  return terminals.sort((a, b) => {
    return a[byProperty].localeCompare(b[byProperty], undefined, {
      numeric: true,
      sensitivity: "base",
    });
  });
}
