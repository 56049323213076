import { TAILWIND_COLORS } from "constants/ThemeConstants";
import { BadgeColorName } from "./types";

// This file contains common constants which can be used across the widget configuration file (index.ts), widget and component folders.

export const BADGE_COLORS_BY_NAME = {
  [BadgeColorName.NEUTRAL]: TAILWIND_COLORS.gray[300],
  [BadgeColorName.DANGER]: TAILWIND_COLORS.red[300],
  [BadgeColorName.WARNING]: TAILWIND_COLORS.yellow[300],
  [BadgeColorName.SUCCESS]: TAILWIND_COLORS.green[300],
};

export const DEFAULT_BADGE_COLOR_NAME: BadgeColorName = BadgeColorName.DANGER;
export const DEFAULT_BADGE_COLOR =
  BADGE_COLORS_BY_NAME[DEFAULT_BADGE_COLOR_NAME];
