import React, { useRef, useEffect } from "react";

import { getAppsmithConfigs } from "@appsmith/configs";
import { ReactDiagram } from "gojs-react";
import styled from "styled-components";

import { groupTemplate } from "./groupTemplate";
import { makeInitGraph } from "./makeInitGraph";
import { useDiagram } from "./useDiagram";
import type { Edge, Node } from "../widget/types";
import { Toolbar } from "../../../components/Toolbar/Toolbar";

export interface TopologyViewerComponentProps {
  // Data props
  nodes: Node[];
  edges: Edge[];
  defaultSelectedNodeIds?: (string | number)[];
  // Feature props
  hasToolbar: boolean;
  highlightMode: string;
  // Style props
  backgroundColor: string;
  borderColor: string;
  borderRadius: string;
  borderWidth: number;
  boxShadow: string;
  // Layout props
  groupSpacing: number;
  layerSpacing: number;
  nodeSpacing: number;
  // Callback props
  onNodeIdsSelectionChange: (ids: string[]) => void;
  onEdgesChange: (edges: Edge[]) => void;
  onNodesChange: (nodes: Node[]) => void;
  onDiagramInitialized?: (diagram: go.Diagram) => void;
}

// Styled container for the topology viewer
const TopologyContainer = styled.div<{
  borderColor: string;
  borderRadius: string;
  borderWidth: number;
  boxShadow: string;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: ${({ boxShadow }) => boxShadow};
  box-sizing: border-box;
  border: ${({ borderColor, borderWidth }) =>
    `${borderWidth}px solid ${borderColor}`};
`;

export const TopologyViewerComponent: React.FC<
  TopologyViewerComponentProps
> = ({
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  boxShadow,
  defaultSelectedNodeIds,
  edges,
  groupSpacing,
  hasToolbar,
  highlightMode,
  layerSpacing,
  nodes,
  nodeSpacing,
  onDiagramInitialized,
  onEdgesChange,
  onNodeIdsSelectionChange,
  onNodesChange,
}) => {
  const { onModelChange, ref } = useDiagram({
    edges,
    nodes,
    onEdgesChange,
    onNodesChange,
  });

  const diagramRef = useRef<go.Diagram | null>(null);

  const initialNodesRef = useRef<Node[]>(nodes);
  const initialEdgesRef = useRef<Edge[]>(edges);

  // Zoom to fit the diagram when using the toolbar
  const zoomToFit = () => {
    const diagram = diagramRef.current;
    if (!diagram || !diagram.scale) {
      return;
    }
    diagram.commandHandler.zoomToFit();
  };

  // Apply layout settings when they change
  useEffect(() => {
    if (diagramRef.current) {
      const diagram = diagramRef.current;
      // Update layout settings
      diagram.startTransaction("update layout");
      (diagram.layout as go.TreeLayout).layerSpacing = layerSpacing;
      (diagram.layout as go.TreeLayout).nodeSpacing = nodeSpacing;
      diagram.groupTemplate = groupTemplate(groupSpacing);
      diagram.commitTransaction("update layout");

      diagram.layoutDiagram(true);
    }
  }, [layerSpacing, nodeSpacing, groupSpacing]);

  useEffect(() => {
    if (diagramRef.current) {
      const diagram = diagramRef.current;
      // Update highlight mode
      diagram.startTransaction("update highlight mode");
      diagram.model.modelData.highlightMode = highlightMode;
      diagram.commitTransaction("update highlight mode");
    }
  }, [highlightMode]);

  useEffect(() => {
    if (diagramRef.current && onDiagramInitialized) {
      onDiagramInitialized(diagramRef.current);
    }
  }, [onDiagramInitialized]);

  useEffect(() => {
    if (diagramRef.current && defaultSelectedNodeIds) {
      // Update selected nodes
      const diagram = diagramRef.current;
      diagram.startTransaction("update selected nodes");
      diagram.clearSelection();
      diagram.selectCollection(
        defaultSelectedNodeIds
          .map((id) => diagram.findNodeForKey(id))
          .filter((node) => node !== null) as go.Part[],
      );
      diagram.commitTransaction("update selected nodes");
    }
  }, [defaultSelectedNodeIds]);

  return (
    <TopologyContainer
      borderColor={borderColor}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      boxShadow={boxShadow}
    >
      <ReactDiagram
        divClassName="topology-viewer"
        initDiagram={() => {
          diagramRef.current = makeInitGraph({
            eventHandlers: {
              onNodeIdsSelectionChange,
            },
            gojsLicenseKey: getAppsmithConfigs().gojsLicenseKey,
            groupSpacing: groupSpacing,
            highlightMode: highlightMode,
            layerSpacing: layerSpacing,
            nodeSpacing: nodeSpacing,
          });
          return diagramRef.current;
        }}
        linkDataArray={initialEdgesRef.current}
        nodeDataArray={initialNodesRef.current}
        onModelChange={onModelChange}
        ref={ref}
        style={{
          width: "100%",
          height: "100%",
          background: backgroundColor,
        }}
      />
      {hasToolbar && (
        <Toolbar
          onZoomIn={() => {
            diagramRef.current?.commandHandler.increaseZoom();
          }}
          onZoomOut={() => {
            diagramRef.current?.commandHandler.decreaseZoom();
          }}
          onZoomToSelectedPart={zoomToFit}
        />
      )}
    </TopologyContainer>
  );
};
