import type { CadModelsResponse } from "./api";
import type { ModelOption } from "./constants";

function removeLastSegmentFromURL(url: string) {
  const lastSlashIndex = url.lastIndexOf("/");
  if (lastSlashIndex !== -1) {
    return url.substring(0, lastSlashIndex);
  }
  return url;
}

export function convertCadModelsResponseIntoOptions(
  cadModelsResponse: CadModelsResponse,
) {
  return cadModelsResponse.cadModels.items.reduce((acc, item) => {
    if (!item.payloadURLs.length || !item.name.length) {
      return acc;
    }

    return [
      ...acc,
      {
        id: item.id,
        label: item.name,
        value: removeLastSegmentFromURL(item.payloadURLs[0]),
      },
    ];
  }, [] as ModelOption[]);
}
