import * as go from "gojs";

import { nodeTemplate } from "./nodeTemplate";
import { linkTemplate } from "./linkTemplate";
import type { NodeId } from "../widget/types";
import type { Events } from "./types";
import { head } from "ramda";

interface MakeInitGraphConfig {
  onNodeSelectionChange: (nodeIds: NodeId[]) => void;
  onEdgeSelectionChange: (edgeId?: NodeId) => void;
  events: Events;
  gojsLicenseKey: string;
}

export const makeInitGraph =
  ({
    events,
    gojsLicenseKey,
    onEdgeSelectionChange,
    onNodeSelectionChange,
  }: MakeInitGraphConfig) =>
  () => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const $ = go.GraphObject.make;

    go.Diagram.licenseKey = gojsLicenseKey || "";

    const diagram = $(go.Diagram, {
      initialAutoScale: go.Diagram.Uniform,
      "undoManager.isEnabled": true, // must be set to allow for model change listening
      ChangedSelection: function () {
        const selectedParts = diagram.selection.toArray();
        const selectedNodes: string[] = [];
        const selectedEdges: string[] = [];

        selectedParts.forEach((part) => {
          if (part.data?.partType === "node") {
            selectedNodes.push(part.data.id);
          }
          if (part.data?.partType === "edge") {
            selectedEdges.push(part.data.id);
          }
        });

        onEdgeSelectionChange(
          selectedEdges.length && !selectedNodes.length
            ? head(selectedEdges)
            : undefined,
        );

        onNodeSelectionChange(selectedNodes);
      },
      // In edge creation mode, if I click on the background, I want to remove newEdgeStartNodeId.
      BackgroundContextClicked: () => {
        events.onContextMenu();
      },
      BackgroundSingleClicked: () => events.onBackgroundSingleClick(),
      model: $(go.GraphLinksModel, {
        nodeKeyProperty: "id",
        linkKeyProperty: "id",
      }),
      layout: $(go.LayeredDigraphLayout, {
        isInitial: false,
        isOngoing: false,
      }),
      InitialLayoutCompleted: (e) => {
        // if not all Nodes have real locations, force a layout to happen
        if (!e.diagram.nodes.all((n) => n.location.isReal())) {
          e.diagram.layoutDiagram(true);
          e.diagram.zoomToFit();
        }
      },

      contextClick: (event: go.InputEvent) => {
        const clientX = event.viewPoint.x;
        const clientY = event.viewPoint.y;

        events.onContextMenu({
          targetPartType: "background",
          anchorPosition: { left: clientX, top: clientY },
          documentPoint: event?.documentPoint,
          targetId: undefined,
        });
      },
    });

    diagram.commandHandler.doKeyDown = function () {
      return;
    };

    diagram.toolManager.contextMenuTool.defaultTouchContextMenu = null;

    diagram.nodeTemplate = nodeTemplate(events);
    diagram.linkTemplate = linkTemplate(events);

    return diagram;
  };
