import {
  LEFT_AREA_COLUMNS,
  MIDDLE_AREA_COLUMNS,
  RIGHT_AREA_COLUMNS,
  WIDGET_DEFAULT_COLS,
} from "widgets/SpreadSplitContainerWidget/constants";
import type {
  ContentArea,
  ContentResizeConfig,
} from "widgets/SpreadSplitContainerWidget/types";

export function getAreaPositioning(
  visibleAreas: ContentArea[],
): Record<ContentArea, ContentResizeConfig & { visible: boolean }> {
  const leftAreaVisible = visibleAreas.includes("left");
  const middleAreaVisible = visibleAreas.includes("middle");
  const rightAreaVisible = visibleAreas.includes("right");

  let cursor = 0;
  const DEFAULT_POSITION = { visible: false, leftColumn: -1, rightColumn: -1 };
  const positioning = {
    left: { ...DEFAULT_POSITION, visible: leftAreaVisible },
    middle: { ...DEFAULT_POSITION, visible: middleAreaVisible },
    right: { ...DEFAULT_POSITION, visible: rightAreaVisible },
  };

  if (leftAreaVisible && middleAreaVisible && rightAreaVisible) {
    positioning.left.leftColumn = cursor;
    positioning.left.rightColumn = cursor += LEFT_AREA_COLUMNS;
    positioning.middle.leftColumn = cursor;
    positioning.middle.rightColumn = cursor += MIDDLE_AREA_COLUMNS;
    positioning.right.leftColumn = cursor;
    positioning.right.rightColumn = cursor += RIGHT_AREA_COLUMNS;
  } else if (leftAreaVisible && middleAreaVisible) {
    positioning.left.leftColumn = cursor;
    positioning.left.rightColumn = cursor += LEFT_AREA_COLUMNS;
    positioning.middle.leftColumn = cursor;
    positioning.middle.rightColumn = cursor +=
      MIDDLE_AREA_COLUMNS + RIGHT_AREA_COLUMNS;
  } else if (leftAreaVisible && rightAreaVisible) {
    positioning.left.leftColumn = cursor;
    positioning.left.rightColumn = cursor += LEFT_AREA_COLUMNS;
    positioning.right.leftColumn = cursor;
    positioning.right.rightColumn = cursor +=
      MIDDLE_AREA_COLUMNS + RIGHT_AREA_COLUMNS;
  } else if (middleAreaVisible && rightAreaVisible) {
    positioning.middle.leftColumn = cursor;
    positioning.middle.rightColumn = cursor +=
      MIDDLE_AREA_COLUMNS + LEFT_AREA_COLUMNS;
    positioning.right.leftColumn = cursor;
    positioning.right.rightColumn = cursor += RIGHT_AREA_COLUMNS;
  } else if (leftAreaVisible) {
    positioning.left.leftColumn = cursor;
    positioning.left.rightColumn = cursor += WIDGET_DEFAULT_COLS;
  } else if (middleAreaVisible) {
    positioning.middle.leftColumn = cursor;
    positioning.middle.rightColumn = cursor += WIDGET_DEFAULT_COLS;
  } else if (rightAreaVisible) {
    positioning.right.leftColumn = cursor;
    positioning.right.rightColumn = cursor += WIDGET_DEFAULT_COLS;
  }

  return positioning;
}
