import React from "react";

const SquareIcon = ({ strokeColor = "#596478" }) => (
  <svg
    data-testid="SquareIcon"
    fill="none"
    viewBox="0 0 25 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill={strokeColor} height="14" rx="4" width="25" />
  </svg>
);

export default SquareIcon;
