import React, { useEffect, useRef, useState } from "react";

import { ReactDiagram } from "gojs-react";

import "./styles.css";

import { theme } from "./theme";
import type { DiagramObjectCategories } from "./DiagramObjectCategories";

import { makeInitDiagram } from "./makeInitDiagram";
import { Overview } from "./Overview";
import { isNaN, isNil, isNumber } from "lodash";

import { Toolbar } from "./Toolbar";
import { useDiagramData } from "./useDiagramData";
import { getAppsmithConfigs } from "@appsmith/configs";

export type DiagramObjectSelectionState =
  | "neutral"
  | "highlighted"
  | "selected";

export interface WithMetadata<GMetadata = unknown> {
  metadata: GMetadata;
}

export interface DiagramPort<GPortMetadata = unknown>
  extends WithMetadata<GPortMetadata> {
  signalName?: string;
  terminalName: string;
  linkingPortName: string;
  id: string;
  linkCrossSectionToPort?: string;
  linksWidth?: number;
  selectionState: DiagramObjectSelectionState;
  order: number;
}

export interface DiagramConnector {
  connectorName?: string;
  portsBottom?: DiagramPort[];
  portsTop?: DiagramPort[];
  selectionState: DiagramObjectSelectionState;
}

interface DiagramObjectBase {
  category?: DiagramObjectCategories;
  id: string;
  terminalLabel: string;
  terminalDescription?: string;
  connectors?: DiagramConnector[];
  selectionState: DiagramObjectSelectionState;
}

export interface DiagramCuttingPoint extends DiagramObjectBase {
  category: DiagramObjectCategories.CUTTING_POINT;
  portsTop?: DiagramPort[];
  portsBottom?: DiagramPort[];
}

export interface DiagramTargetObject extends DiagramObjectBase {
  category:
    | DiagramObjectCategories.START_OBJECT
    | DiagramObjectCategories.END_OBJECT;
  portsBottom?: DiagramPort[];
  portsTop?: DiagramPort[];
}

export type DiagramNode = DiagramCuttingPoint | DiagramTargetObject;

export interface DiagramLink<GLinkMetadata = unknown>
  extends WithMetadata<GLinkMetadata> {
  id: string;
  from?: DiagramObjectBase["id"];
  to?: DiagramObjectBase["id"];
  fromPort?: DiagramPort["terminalName"];
  toPort?: DiagramPort["terminalName"];
  connectionDetails?: string;
  description?: string;
  colors?: string[];
  connectionId?: string;
  selectionState: DiagramObjectSelectionState;
}

export interface DiagramProps {
  nodeDataArray: DiagramNode[];
  linkDataArray: DiagramLink[];

  hasMiniMap: boolean;
  scale: number;
  hasToolbar: boolean;
  onSelectedConnectionIdChanged: (nodeId: NodeId) => void;
  onSelectedTerminalIdChanged: (nodeId: NodeId) => void;
}

export type NodeId = string | number;

export const OVERVIEW_ID = "diagram-overview";

export const Diagram: React.FC<DiagramProps> = React.memo(
  ({
    hasMiniMap,
    hasToolbar,
    linkDataArray,
    nodeDataArray,
    onSelectedConnectionIdChanged,
    onSelectedTerminalIdChanged,
    scale,
  }) => {
    const diagramRef = useRef<ReactDiagram>(null);

    const [toolbarZoom, setToolbarZoom] = useState(scale);

    const { links, nodes, onLinkSelectionChange, onNodeSelectionChange } =
      useDiagramData({
        diagramLinks: linkDataArray,
        diagramNodes: nodeDataArray,
        onSelectedConnectionIdChanged,
        onSelectedTerminalIdChanged,
      });

    const zoomChangeHandler = (value: number) => {
      const diagram = diagramRef.current?.getDiagram();
      if (isNil(diagram) || toolbarZoom + value <= 0) {
        return;
      }

      setToolbarZoom(toolbarZoom + value);
      diagram.commandHandler.resetZoom(toolbarZoom);
    };

    useEffect(() => {
      const diagram = diagramRef.current?.getDiagram();
      if (isNil(diagram)) {
        return;
      }

      if (isNumber(scale) && !isNaN(scale) && scale > 0) {
        diagram.commandHandler.resetZoom(scale);
        setToolbarZoom(scale);
      }
    }, [scale]);

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <ReactDiagram
          divClassName="spread-012312u3-diagram-component"
          initDiagram={makeInitDiagram(
            theme.fonts,
            theme.fontWeights,
            theme.colors,
            {
              onComponentClick: (componentNodeData: DiagramNode) =>
                onNodeSelectionChange(componentNodeData.id),
              onConnectionClick: (linkData: DiagramLink) =>
                onLinkSelectionChange(linkData.id),
            },
            OVERVIEW_ID,
            getAppsmithConfigs().gojsLicenseKey,
          )}
          linkDataArray={links}
          nodeDataArray={nodes}
          ref={diagramRef}
        />

        <Overview hasMiniMap={hasMiniMap} overviewId={OVERVIEW_ID} />

        {hasToolbar && <Toolbar onZoomChange={zoomChangeHandler} />}
      </div>
    );
  },
);

Diagram.displayName = "Diagram";
