import type { PropertyPaneConfig } from "constants/PropertyControlConstants";
import { ValidationTypes } from "../../../constants/WidgetValidation";

export const propertyPaneContentConfig: PropertyPaneConfig[] = [
  {
    sectionName: "Layout",
    children: [
      {
        propertyName: "isLeftAreaVisible",
        label: "Left container",
        helpText: "Whether the container on the left is visible",
        controlType: "SWITCH",
        isBindProperty: true,
        isTriggerProperty: false,
        isJSConvertible: true,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "isMiddleAreaVisible",
        label: "Show middle container",
        helpText: "Whether the container on the middle is visible",
        controlType: "SWITCH",
        isBindProperty: true,
        isTriggerProperty: false,
        isJSConvertible: true,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "isRightAreaVisible",
        label: "Right container",
        helpText: "Whether the container on the right is visible",
        controlType: "SWITCH",
        isBindProperty: true,
        isTriggerProperty: false,
        isJSConvertible: true,
        validation: { type: ValidationTypes.BOOLEAN },
      },
    ],
  },
  {
    sectionName: "General",
    children: [
      {
        propertyName: "isVisible",
        helpText: "Controls the visibility of the widget",
        label: "Visible",
        controlType: "SWITCH",
        isBindProperty: true,
        isTriggerProperty: false,
        isJSConvertible: true,
        validation: { type: ValidationTypes.BOOLEAN },
      },
    ],
  },
];
