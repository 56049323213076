import { DefaultModelOption } from "components/propertyControls/spreadProperyControls/CadDomainModelSelector/constants";
import { ValidationTypes } from "constants/WidgetValidation";

import {
  GROUPS_COLLECTIONS_VALIDATION_CONFIG,
  IDS_ARRAY_VALIDATION,
  MARKERS_VALIDATION,
  PILLS_VALIDATION,
  UUID_REGEX,
} from "./validations";
import type { RendererWidgetProps } from "./index";

export const propertyPaneContentConfig = [
  {
    sectionName: "General",
    children: [
      {
        propertyName: "modelSceneGraphUrl",
        label: "Model",
        helpText: "Defines URL to model scene graph directory.",
        controlType: "CAD_DOMAIN_MODEL_SELECTOR",
        placeholderText: DefaultModelOption.value,
        isBindProperty: true,
        isTriggerProperty: false,
        isJSConvertible: true,
        validation: {
          type: ValidationTypes.TEXT,
        },
      },
      {
        propertyName: "onModelLoaded",
        label: "onModelLoaded",
        helpText:
          "Triggers an action when model is loaded and sceneTree is ready",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
      {
        propertyName: "defaultSelectedNodesIds",
        label: "Selected nodes IDs",
        helpText:
          "Defines default selected nodes and groups. Group IDs take precedence over node IDs.",
        controlType: "INPUT_TEXT",
        placeholderText: '["6c947224-b7ae-4f39-978f-ebd351b1946e"]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: IDS_ARRAY_VALIDATION,
      },
      {
        propertyName: "onSelectionChange",
        label: "onSelectionChange",
        helpText: "Triggers an action when node selection changes",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
      {
        propertyName: "defaultHiddenNodesIds",
        label: "Hidden nodes IDs",
        helpText: "Defines default hidden nodes.",
        controlType: "INPUT_TEXT",
        placeholderText: '["6c947224-b7ae-4f39-978f-ebd351b1946e"]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: IDS_ARRAY_VALIDATION,
      },
      {
        propertyName: "markers",
        label: "Markers",
        helpText: "Defines a list of markers.",
        controlType: "INPUT_TEXT",
        placeholderText:
          '[{"id": "marker1", "shape": "cube", "position": {"x": 0, "y": 0, "z": 0}, "digit": 1}]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: MARKERS_VALIDATION,
      },
      {
        propertyName: "defaultSelectedMarkerId",
        label: "Selected marker ID",
        helpText: "Defines selected marker ID.",
        controlType: "INPUT_TEXT",
        placeholderText: "marker1",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
        },
      },
      {
        propertyName: "pills",
        label: "Pills",
        helpText: "Defines a list of pills.",
        controlType: "INPUT_TEXT",
        placeholderText:
          '[{"id": "pill1", "label": "Pill 1", "position": {"x": 0, "y": 0, "z": 0}}]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: PILLS_VALIDATION,
      },
      {
        propertyName: "isVisible",
        label: "Visible",
        helpText: "Controls the visibility of the widget",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        sectionName: "Context menu",
        children: [
          {
            propertyName: "isContextMenuVisible",
            label: "Show context menu",
            helpText: "Controls the visibility of context menu",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
          },
        ],
      },
      {
        sectionName: "Context menu actions",
        children: [
          {
            propertyName: "isShowOnlyVisible",
            label: "Show only",
            helpText:
              "Controls the visibility of 'Show only' in the context menu",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
            hidden: (props: RendererWidgetProps) => !props.isContextMenuVisible,
            dependencies: ["isContextMenuVisible"],
          },
          {
            propertyName: "isShowAllVisible",
            label: "Show all",
            helpText:
              "Controls the visibility of 'Show all' in the context menu",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
            hidden: (props: RendererWidgetProps) => !props.isContextMenuVisible,
            dependencies: ["isContextMenuVisible"],
          },
          {
            propertyName: "isHideVisible",
            label: "Hidden",
            helpText: "Controls the visibility of 'Hide' in the context menu",
            controlType: "SWITCH",
            isJSConvertible: true,
            isBindProperty: true,
            isTriggerProperty: false,
            validation: { type: ValidationTypes.BOOLEAN },
            hidden: (props: RendererWidgetProps) => !props.isContextMenuVisible,
            dependencies: ["isContextMenuVisible"],
          },
        ],
      },
    ],
  },
  {
    sectionName: "Grouping",
    children: [
      {
        propertyName: "defaultActiveCollectionId",
        label: "Active collection ID",
        helpText:
          "Specifies the active collection. Only when a collection is active that its groups and nodes can be selected.",
        controlType: "INPUT_TEXT",
        placeholderText: "COLLECTION_ID",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            regex: UUID_REGEX,
          },
        },
      },
      {
        helpText:
          "Defines collections, which encompass both node groups and individual nodes. When selecting nodes in the renderer, nodes within a group are treated as a single entity. Collection and group IDs must adhere to the hyphenated UUID format.",
        propertyName: "defaultCollections",
        label: "Collections",
        controlType: "INPUT_TEXT",
        placeholderText:
          '[{ "collectionId": "UUID", "groups": [{"id":"UUID", nodeIds: ["UUID"] }]',
        isBindProperty: true,
        isTriggerProperty: false,
        validation: GROUPS_COLLECTIONS_VALIDATION_CONFIG,
      },
    ],
  },
];
