import { ValidationTypes } from "../../../constants/WidgetValidation";

export const propertyPaneStyleConfig = [
  {
    sectionName: "General",
    children: [
      {
        propertyName: "hasToolbar",
        label: "Toolbar",
        helpText: "Toolbar helping in interactions with graph",
        controlType: "SWITCH",
        defaultValue: true,
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
    ],
  },
];
