import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../../spread_tmp_thumbnail.svg";

import type {
  AutoLayoutConfig,
  AutocompletionDefinitions,
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import { WIDGET_TAGS } from "constants/WidgetConstants";
import type { SetterConfig } from "entities/AppTheming";
import type { ReactNode } from "react";
import React from "react";
import { WIDGET_DEFAULTS } from "widgets/SpreadSplitContainerWidget/constants";
import type { SpreadSplitContainerWidgetProps } from "widgets/SpreadSplitContainerWidget/widget/SpreadSplitContainerWidgetConnected";
import { SpreadSplitContainerWidgetConnected } from "widgets/SpreadSplitContainerWidget/widget/SpreadSplitContainerWidgetConnected";
import { propertyPaneContentConfig } from "widgets/SpreadSplitContainerWidget/widget/contentConfig";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";

/**
 * Class containing the widget configuration.
 * The widget itself is implemented in `SpreadSplitContainerWidget`.
 * `SpreadSplitContainerWidget` is a separate class so it can be connected to the store.
 */
export default class SpreadSplitContainerWidgetShell extends BaseWidget<
  SpreadSplitContainerWidgetProps<WidgetProps>,
  WidgetState
> {
  static type = "SPREAD_SPLIT_CONTAINER_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return {
      name: "Split Container", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      thumbnailSVG: ThumbnailSVG,
      tags: [WIDGET_TAGS.BETA],
      searchTags: [WIDGET_TAGS.LAYOUT, "Resizable Split Views", "resize"],
      needsMeta: true, // Defines if this widget adds any meta properties
      isCanvas: true, // Defines if this widget has a canvas within in which we can drop other widgets
    };
  }

  static getDefaults(): WidgetDefaultProps {
    return WIDGET_DEFAULTS;
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    // TODO: add proper auto layout config
    return {};
  }

  static getPropertyPaneContentConfig() {
    return propertyPaneContentConfig;
  }

  static getPropertyPaneStyleConfig() {
    return [];
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, any> {
    return {};
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      ...Object.fromEntries(
        Object.entries(this.getMetaPropertiesMap()).map(
          ([metaProperty, metaPropertyDefaulValue]) => [
            metaProperty,
            typeof metaPropertyDefaulValue,
          ],
        ),
      ),
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      isLeftAreaVisible: "boolean",
      isMiddleAreaVisible: "boolean",
      isRightAreaVisible: "boolean",
    };
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setLeftAreaVisible: {
          path: "isLeftAreaVisible",
          type: "boolean",
        },
        setMiddleAreaVisible: {
          path: "isMiddleAreaVisible",
          type: "boolean",
        },
        setRightAreaVisible: {
          path: "isRightAreaVisible",
          type: "boolean",
        },
      },
    };
  }

  getWidgetView(): ReactNode {
    return <SpreadSplitContainerWidgetConnected {...this.props} />;
  }
}
