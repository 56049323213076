import { ValidationTypes } from "../../../constants/WidgetValidation";

const NODE_ID_VALIDATION_TYPE = ValidationTypes.TEXT || ValidationTypes.NUMBER;

const EDGES_VALIDATION_CONFIG = {
  type: ValidationTypes.ARRAY,
  params: {
    unique: ["id"],
    children: {
      type: ValidationTypes.OBJECT,
      params: {
        required: true,
        allowedKeys: [
          {
            name: "id",
            type: NODE_ID_VALIDATION_TYPE,
            params: {
              requiredKey: true,
            },
          },
          {
            name: "from",
            type: NODE_ID_VALIDATION_TYPE,
            params: {
              requiredKey: true,
            },
          },
          {
            name: "to",
            type: NODE_ID_VALIDATION_TYPE,
            params: {
              requiredKey: true,
            },
          },
        ],
      },
    },
  },
};
const NODES_VALIDATION_CONFIG = {
  type: ValidationTypes.ARRAY,
  params: {
    unique: ["id"],
    children: {
      type: ValidationTypes.OBJECT,
      params: {
        required: true,
        allowedKeys: [
          {
            name: "id",
            type: NODE_ID_VALIDATION_TYPE,
            params: {
              requiredKey: true,
            },
          },
          {
            name: "text",
            type: ValidationTypes.TEXT,
            params: {
              requiredKey: true,
            },
          },
          {
            name: "location",
            type: ValidationTypes.TEXT,
            params: {
              requiredKey: false,
            },
          },
        ],
      },
    },
  },
};
export const contentConfig = [
  {
    sectionName: "General",
    children: [
      {
        helpText: "Defines edges of the precedence graph.",
        propertyName: "edges",
        label: "Edges",
        controlType: "INPUT_TEXT",
        placeholderText:
          '[{ "id": "1", "from": "1", "to": "2", "variant": "active" }]',
        isBindProperty: true,
        isTriggerProperty: false,
        validation: EDGES_VALIDATION_CONFIG,
      },
      {
        helpText: "Defines nodes of the precedence graph.",
        propertyName: "nodes",
        label: "Nodes",
        controlType: "INPUT_TEXT",
        placeholderText:
          '[{ "id": "1", "text": "wheel", "variant": "matched-default" }]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: NODES_VALIDATION_CONFIG,
      },
      {
        helpText: "Defines selected node id of the precedence graph.",
        propertyName: "defaultSelectedNodeId",
        label: "Selected node id",
        controlType: "INPUT_TEXT",
        placeholderText: "1",
        inputType: "TEXT",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
        },
      },
      {
        helpText: "Defines hidden node ids of the precedence graph.",
        propertyName: "defaultHiddenNodeIds",
        label: "Hidden node ids",
        controlType: "INPUT_TEXT",
        placeholderText: '["1"]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            default: [],
            children: {
              type: NODE_ID_VALIDATION_TYPE,
            },
          },
        },
      },
      {
        helpText: "Defines removed node ids of the precedence graph.",
        propertyName: "defaultRemovedNodeIds",
        label: "Removed node ids",
        controlType: "INPUT_TEXT",
        placeholderText: '["1"]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            default: [],
            children: {
              type: NODE_ID_VALIDATION_TYPE,
            },
          },
        },
      },
      {
        helpText: "Defines removed edge ids of the precedence graph.",
        propertyName: "defaultRemovedEdgeIds",
        label: "Removed edge ids",
        controlType: "INPUT_TEXT",
        placeholderText: '["1"]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            default: [],
            children: {
              type: NODE_ID_VALIDATION_TYPE,
            },
          },
        },
      },
      {
        helpText: "Defines unmatched node ids of the precedence graph.",
        propertyName: "defaultUnmatchedNodeIds",
        label: "Unmatched node ids",
        controlType: "INPUT_TEXT",
        placeholderText: '["1"]',
        inputType: "ARRAY",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.ARRAY,
          params: {
            default: [],
            children: {
              type: NODE_ID_VALIDATION_TYPE,
            },
          },
        },
      },
    ],
  },
  {
    sectionName: "Events",
    children: [
      {
        helpText:
          "Action called when 'Add node' in context menu will be selected",
        propertyName: "onAddNode",
        label: "onAddNode",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
      {
        helpText: "Action called on node click",
        propertyName: "onNodeClick",
        label: "onNodeClick",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
      {
        helpText: "Action called on selected node ids change",
        propertyName: "onSelectedNodeIdsChange",
        label: "onSelectedNodeIdsChange",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
      {
        helpText: "Action called on background click of the graph",
        propertyName: "onBackgroundClick",
        label: "onBackgroundClick",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
      {
        helpText:
          "Action called when 'Remove node' in context menu will be selected",
        propertyName: "onUnmatchedNodeRemove",
        label: "onUnmatchedNodeRemove",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
    ],
  },
];
