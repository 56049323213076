// #region @spread
export const CONTAINER_LOADING_STATE_DEFAULT_DATA = {
  title: "Data loading",
  iconName: "data-connection",
};

export const CONTAINER_EMPTY_STATE_DEFAULT_DATA = {
  title: "Empty state title",
  description: "Empty state description",
  actionButtonLabel: "Button",
  iconName: "document",
};

export const CONTAINER_ERROR_STATE_DEFAULT_DATA = {
  title: "Error state title",
  description: "Error state description",
  actionButtonLabel: "Button",
  iconName: "error",
};

export const enum CONTAINER_STATUS {
  LOADING = "loading",
  ERROR = "error",
  EMPTY = "empty",
  HAS_CHILDREN = "hasChildren",
}

export const CONTAINER_STYLESHEET_CONFIG = {
  buttonColor: "{{appsmith.theme.colors.primaryColor}}",
  progressBarFillColor: "{{appsmith.theme.colors.primaryColor}}",
  iconFillColor: "{{appsmith.theme.colors.primaryColor}}",
};
// #endregion
