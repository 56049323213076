import type { RecursiveNode } from "@spread-ai/softy-renderer-react";

/**
 * In some cases, Appsmith passes a string without validation/parsing, so this is workaround.
 *
 * @param value - string to parse
 * @param fallback - fallback value to return if parsing fails
 * @returns {T}
 */
export const parseString = <T>(value: string, fallback: T): T => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return fallback;
  }
};

/**
 * Short version of RecursiveNode.
 * It contains only id, label and children.
 */
export type ShortRecursiveNode = Pick<RecursiveNode, "id" | "label"> & {
  children?: ShortRecursiveNode[];
};

export const shortifyRecursiveNode = (
  node: RecursiveNode,
): ShortRecursiveNode => {
  const { id, label } = node;

  const children = node.children?.length
    ? node.children?.map(shortifyRecursiveNode)
    : undefined;

  return {
    id,
    label,
    children,
  };
};

export function compareArraysUnordered<T>(array1: T[], array2: T[]): boolean {
  // Early exit if array lengths differ
  if (array1.length !== array2.length) return false;

  // The frequency counter now uses Map to handle all types of keys properly
  const frequencyCounter: Map<T, number> = new Map();

  // Count the frequency of each element in the first array
  array1.forEach((elem) => {
    const count = frequencyCounter.get(elem) || 0;
    frequencyCounter.set(elem, count + 1);
  });

  // Decrement the frequency for each element found in the second array
  for (const elem of array2) {
    const count = frequencyCounter.get(elem);
    if (!count) return false; // Element not found or frequency mismatch

    if (count === 1) {
      frequencyCounter.delete(elem); // Remove the key if count becomes 0
    } else {
      frequencyCounter.set(elem, count - 1);
    }
  }

  // If the frequencyCounter is empty, arrays are equal disregarding the order
  return frequencyCounter.size === 0;
}
