import { isNil, prop } from "ramda";
import React from "react";
import styled, { css } from "styled-components";
import { DEFAULT_BADGE_COLOR } from "widgets/SpreadBadgeWidget/constants";
import {
  calculateHoverColor,
  getComplementaryGrayscaleColor,
} from "widgets/WidgetUtils";

export interface BadgeComponentProps {
  label: string;
  badgeColor: string;
  isOnPage: boolean;
  isFullWidth?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

interface BadgeButtonProps {
  $backgroundColor: string;
  $cursor: "pointer" | "inherit";
  $hasOnClickHandler: boolean;
  $isFullWidth: boolean;
}

const BadgeButton = styled.button<BadgeButtonProps>`
  display: inline-flex;
  cursor: ${prop("$cursor")};
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  background-color: ${prop("$backgroundColor")};
  color: ${({ $backgroundColor }) =>
    getComplementaryGrayscaleColor($backgroundColor)};
  width: ${({ $isFullWidth }) => ($isFullWidth ? "100%" : undefined)};
  max-width: 100%;

  border-radius: 600px;

  ${({ $backgroundColor, $hasOnClickHandler }) => {
    if (!$hasOnClickHandler) {
      return undefined;
    }

    return css<BadgeButtonProps>`
      &:hover,
      &:focus-visible {
        background-color: ${calculateHoverColor($backgroundColor, false)};
      }
    `;
  }}
`;

const BadgeText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  line-height: 21px;
`;

function BadgeComponent(props: BadgeComponentProps) {
  const {
    badgeColor = DEFAULT_BADGE_COLOR,
    isFullWidth,
    isOnPage,
    label,
    onClick,
  } = props;
  const hasOnClickHandler = !!onClick;
  const hasLabel = !isNil(label) && String(label).trim().length > 0;

  if (!hasLabel) {
    return null;
  }

  return (
    <BadgeButton
      $backgroundColor={badgeColor}
      $cursor={isOnPage && hasOnClickHandler ? "pointer" : "inherit"}
      $hasOnClickHandler={hasOnClickHandler}
      $isFullWidth={!!isFullWidth}
      data-tabbable={String(hasOnClickHandler)}
      onClick={onClick}
    >
      <BadgeText>{label}</BadgeText>
    </BadgeButton>
  );
}

export default BadgeComponent;
