import React, { useEffect, useMemo, useRef } from "react";

import type { NodeApi, TreeApi } from "react-arborist";
import { Tree as TreeArborist } from "react-arborist";
import type { TreeNode, TreeNodeId } from "./Node";
import { Node } from "./Node";
import styled from "styled-components";
import { equals, last } from "ramda";

import { DEFAULT_ROW_HEIGHT } from "./constants";

import { isEmpty } from "lodash";
import { Loader } from "../../../spread/components/Loader";
import { ProgressVariant } from "../../ProgressWidget/constants";
import { PROGRESS_BAR_HEIGHT, type TreeSelectionType } from "../widget";
import type { ChildrenIdsMap } from "./createIdToLeafIdsMap";

export interface TreeProps {
  sourceData: TreeNode[];
  searchTerm: string;
  height: number;
  width: number;
  isParentNodeSelectable: boolean;
  onLeafNodesIdsSelectionChange: (ids: TreeNodeId[]) => void;
  onSelectedIdsChange: (ids: TreeNodeId[]) => void;
  defaultSelectedIds: TreeNodeId[];
  selectionType: TreeSelectionType;
  selectedIds: TreeNodeId[];
  filterParentIds: (ids: TreeNodeId[]) => TreeNodeId[];
  selectedLeafNodesIds: string[];

  rowHeight: number;
  checkboxAccentColor: string;
  checkboxBorderRadius: string;
  rowBackgroundAccentColor: string;
  progressBarFillColor: string;
  isLoading: boolean;
  childrenIdsMap: ChildrenIdsMap;
}

const NoSearchResults = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-family: "Open Sans";
`;

export function getFlattenTree(treeData: TreeNode[]): TreeNode[] {
  let childrenValues: TreeNode[] = [];

  for (const node of treeData) {
    childrenValues.push(node);

    if (node.children) {
      const children = getFlattenTree(node.children);
      childrenValues = childrenValues.concat(children);
    }
  }

  return childrenValues;
}

function debounce<F extends (...args: any[]) => void>(
  func: F,
  timeframe: number,
): (...args: Parameters<F>) => void {
  let timeoutId: number | undefined;

  return function (...args: Parameters<F>): void {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => func(...args), timeframe);
  };
}

export const Tree: React.FC<TreeProps> = ({
  checkboxAccentColor,
  checkboxBorderRadius,
  childrenIdsMap,
  height,

  isLoading,
  isParentNodeSelectable,
  onLeafNodesIdsSelectionChange,
  onSelectedIdsChange,
  progressBarFillColor,
  rowBackgroundAccentColor,
  rowHeight,
  searchTerm,
  selectedIds,
  selectedLeafNodesIds,
  selectionType,
  sourceData,
  width,
}) => {
  const tree = useRef<TreeApi<TreeNode>>();
  const flattenTree = useMemo(() => getFlattenTree(sourceData), [sourceData]);

  const currentSelectedIds = tree.current?.selectedIds;
  const previousSelectedIds = useRef<Set<string>>(new Set<string>());

  const isSearchMatch = useMemo(() => {
    if (!searchTerm) {
      return;
    }

    const match = flattenTree.find((element) => {
      if (!element?.label) {
        return;
      }

      return element.label.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return Boolean(match);
  }, [searchTerm, flattenTree]);

  useEffect(() => {
    if (!childrenIdsMap) {
      return;
    }

    const getIds = () => {
      if (isParentNodeSelectable || selectionType === "directory") {
        return selectedIds;
      }

      // Remove parent nodes from selectedIds
      return selectedIds.filter((id) => {
        if (
          childrenIdsMap &&
          !childrenIdsMap.hasOwnProperty(id) &&
          !childrenIdsMap[id]?.length
        ) {
          return true;
        }
      });
    };

    const ids = getIds();

    tree?.current?.setSelection({
      mostRecent: !isEmpty(ids)
        ? /* Adding id of the last node will select all nodes between first selected node and the last selected when holding shift
               https://github.com/brimdata/react-arborist/issues/176#issuecomment-1702994854
            */
          (last(ids) as string)
        : null,
      anchor: null,
      ids: ids,
    });
  }, [selectedIds]);

  const onSelect = (idsMap: NodeApi<TreeNode>[]) => {
    const ids = Array.from(idsMap.values()).map((node) => node.id);

    if (equals(ids, selectedIds)) {
      return;
    }

    const leafNodeIds = ids.filter((id) => {
      if (!childrenIdsMap.hasOwnProperty(id)) {
        return true;
      }
    });

    onLeafNodesIdsSelectionChange(leafNodeIds);

    const getIds = () => {
      if (isParentNodeSelectable || selectionType === "directory") {
        return ids;
      }

      return leafNodeIds;
    };

    onSelectedIdsChange(getIds());
  };

  // We don't need to call onSelect on every selection change, so we debounce it to avoid unnecessary calls,
  // it's very useful when we work with big set of data
  const onSelectionChangeDebounced = debounce(onSelect, 400);

  const selectedNodesIdsSet = new Set(selectedIds);
  const selectedLeafNodesIdsSet = new Set(selectedLeafNodesIds);

  useEffect(() => {
    const deselectionOccurred = Boolean(
      currentSelectedIds &&
        currentSelectedIds.size < previousSelectedIds.current.size,
    );
    previousSelectedIds.current = currentSelectedIds || new Set<string>();

    // I don't want to scroll when deselection occurred
    if (deselectionOccurred) {
      return;
    }

    const lastSelectedId = last(selectedLeafNodesIds);

    if (
      !lastSelectedId ||
      (currentSelectedIds && currentSelectedIds.size > 1)
    ) {
      return;
    }

    tree.current?.scrollTo(lastSelectedId, "smart");
  }, [currentSelectedIds]);

  return (
    <div style={{ backgroundColor: "white" }}>
      <Loader
        borderRadius="0px"
        fillColor={progressBarFillColor}
        height={PROGRESS_BAR_HEIGHT}
        isVisible={isLoading}
        progressValue={50}
        variant={ProgressVariant.INDETERMINATE}
      />

      <div style={{ visibility: isLoading ? "hidden" : undefined }}>
        {!isSearchMatch && searchTerm !== "" ? (
          <NoSearchResults>No search result</NoSearchResults>
        ) : (
          <TreeArborist
            className="tree-arborist"
            data={sourceData}
            disableMultiSelection={selectionType === "default"}
            height={height}
            indent={24}
            onSelect={onSelectionChangeDebounced}
            ref={tree}
            rowHeight={
              rowHeight === 0 || !rowHeight ? DEFAULT_ROW_HEIGHT : rowHeight
            }
            searchTerm={searchTerm}
            width={width}
          >
            {(props) => (
              <Node
                {...props}
                checkboxAccentColor={checkboxAccentColor}
                checkboxBorderRadius={checkboxBorderRadius}
                childrenIdsMap={childrenIdsMap}
                disableMultiSelection={selectionType === "default"}
                isParentNodeSelectable={isParentNodeSelectable}
                onLeafNodesIdsSelectionChange={onLeafNodesIdsSelectionChange}
                onSelectedIdsChange={onSelectedIdsChange}
                rowBackgroundAccentColor={rowBackgroundAccentColor}
                selectedIds={selectedNodesIdsSet}
                selectedLeafNodesIds={selectedLeafNodesIdsSet}
                selectionType={selectionType}
              />
            )}
          </TreeArborist>
        )}
      </div>
    </div>
  );
};

Tree.displayName = "Tree";
