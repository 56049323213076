import type { ValidationConfig } from "constants/PropertyControlConstants";
import { ValidationTypes } from "constants/WidgetValidation";

/**
 * Represents type `ClusterData[]`
 */
export const CLUSTERS_ARRAY_VALIDATION: ValidationConfig = {
  type: ValidationTypes.ARRAY,
  params: {
    children: {
      type: ValidationTypes.OBJECT,
      params: {
        allowedKeys: [
          {
            name: "id",
            type: ValidationTypes.TEXT,
            params: {
              unique: true,
              required: true,
            },
          },
          {
            name: "title",
            type: ValidationTypes.TEXT,
            params: {
              required: true,
            },
          },
          {
            name: "color",
            type: ValidationTypes.TEXT,
            params: {
              required: true,
            },
          },
          {
            name: "borderColor",
            type: ValidationTypes.TEXT,
            params: {
              required: true,
            },
          },
          {
            name: "column",
            type: ValidationTypes.NUMBER,
            params: {
              required: true,
            },
          },
        ],
      },
    },
  },
};

/**
 * Represents type `ClusterCards`
 */
export const CLUSTERS_CARDS_VALIDATION: ValidationConfig = {
  type: ValidationTypes.OBJECT,
  params: {
    children: {
      type: ValidationTypes.ARRAY,
      params: {
        children: {
          type: ValidationTypes.OBJECT,
          params: {
            allowedKeys: [
              {
                name: "id",
                type: ValidationTypes.TEXT,
                params: {
                  unique: true,
                  required: true,
                },
              },
              {
                name: "title",
                type: ValidationTypes.TEXT,
                params: {
                  required: true,
                },
              },
              {
                name: "subtitle",
                type: ValidationTypes.TEXT,
                params: {
                  required: true,
                },
              },
              {
                name: "isAlert",
                type: ValidationTypes.BOOLEAN,
                params: {
                  default: false,
                },
              },
              {
                name: "alertMessage",
                type: ValidationTypes.TEXT,
                params: {
                  default: "",
                },
              },
              {
                name: "items",
                type: ValidationTypes.ARRAY,
                params: {
                  children: {
                    type: ValidationTypes.OBJECT,
                    params: {
                      allowedKeys: [
                        {
                          name: "id",
                          type: ValidationTypes.TEXT,
                          params: {
                            unique: true,
                            required: true,
                          },
                        },
                        {
                          name: "title",
                          type: ValidationTypes.TEXT,
                          params: {
                            required: true,
                          },
                        },
                        {
                          name: "isAlert",
                          type: ValidationTypes.BOOLEAN,
                          params: {
                            default: false,
                          },
                        },
                        {
                          name: "alertMessage",
                          type: ValidationTypes.TEXT,
                          params: {
                            default: "",
                          },
                        },
                      ],
                    },
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
};

/**
 * Represents type `Link[]`
 */
export const LINKS_ARRAY_VALIDATION: ValidationConfig = {
  type: ValidationTypes.ARRAY,
  params: {
    children: {
      type: ValidationTypes.OBJECT,
      params: {
        allowedKeys: [
          {
            name: "from",
            type: ValidationTypes.TEXT,
            params: {
              required: true,
            },
          },
          {
            name: "to",
            type: ValidationTypes.TEXT,
            params: {
              required: true,
            },
          },
          {
            name: "dashed",
            type: ValidationTypes.BOOLEAN,
            params: {
              default: false,
            },
          },
        ],
      },
    },
  },
};
