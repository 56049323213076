import * as go from "gojs";

const SPACING_BETWEEN_BOTTOM_NODES = 50;
/**
 * This is a copied and slightly tweaked layout from this source:
 * https://gojs.net/latest/samples/parseTree.html
 * Instead of a TreeLayout it is extending the LayeredDigraphLayout
 */
export class FlatLayeredDigraphLayout extends go.LayeredDigraphLayout {
  // This assumes the TreeLayout.angle is 90 -- growing downward
  commitLayout() {
    super.commitLayout(); // call base method first
    // find maximum Y position of all Nodes
    // eslint-disable-next-line no-restricted-syntax
    let y = -Infinity;
    const vertexes =
      this.network?.vertexes.filter((v) => Boolean(v.node)) ||
      new go.Set<go.LayoutVertex>();
    vertexes.each((v) => {
      if (v.node) {
        y = Math.max(y, v.node.position.y || 0);
      }
    });
    // move down all leaf nodes to that Y position, and align next to each other by setting X position
    const bottomVertexes = vertexes
      .toArray()
      .filter((vertex) => vertex.destinationEdges.count === 0);
    // eslint-disable-next-line no-restricted-syntax
    let widthOfPreviousItems = 0;
    bottomVertexes.forEach((v: go.LayoutVertex) => {
      if (v.node) {
        // shift the node down to Y and to calculated X position so nodes don't overlap
        v.node.position = new go.Point(widthOfPreviousItems, y);
        // extend the last segment vertically
        v.node.toEndSegmentLength = Math.abs(v.centerY - y);
      }
      // sum width of all bottom nodes before and adding spacing
      widthOfPreviousItems += v.width + SPACING_BETWEEN_BOTTOM_NODES;
    });
  }
}
