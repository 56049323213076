import { SpreadFunction } from "components/editorComponents/ActionCreator/constants";
import type { SpreadRunFlowDropdownOption } from "spread/flows/actionCreator/generateFlowsActionList";
import type { EnabledFlow } from "spread/flows/types";

/**
 * @example
 * getRunFlowCodeFromDropdownOption({
 *  args: ["FLOW_ID", [{ name: "param1" }, { name: "param2" }]],
 * });
 * //> 'spread.flows.runFlow("FLOW_ID", {\n  param1: undefined,\n  param2: undefined,\n})'
 */
export function getRunFlowCodeFromDropdownOption(
  runFlowAction: Pick<SpreadRunFlowDropdownOption, "args">,
) {
  const [flowId = "", parameters = []] = runFlowAction.args ?? [];
  return `${SpreadFunction.runFlow}("${flowId}", ${serialiseFlowParametersToCode(parameters)})`;
}

/**
 * Get flow params as a JS (not JSON!) object string, with `undefined` as values.
 * This is useful so `undefined` values are not stripped out from the serialised data.
 *
 * @example
 * serialiseRunFlowParametersToCode([{ name: "key1" }, { name: "key2" }]);
 * //> "{\n  key1: undefined,\n  key2: undefined\n}"
 */
function serialiseFlowParametersToCode(
  parameters: EnabledFlow["parameters"],
): string {
  if (parameters.length === 0) {
    return "{}";
  }

  const paramsStr = `{${parameters.reduce((acc, param) => {
    return `${acc}\n  ${param.name}: undefined,`;
  }, "")}\n}`;

  return paramsStr;
}
