import React from "react";
import { Tooltip as TooltipComponent } from "design-system";
import { createGlobalStyle } from "styled-components";

const TooltipStyles = createGlobalStyle`
  .alert-tooltip.rc-tooltip.ads-v2-tooltip {
      --ads-v2-colors-content-tooltip-surface-default-bg: #FFFFFF;
      --tooltip-color: #EF4444;
    }
`;

interface AlertTooltipProps {
  children: React.ReactElement;
  content?: string;
  enabled?: boolean;
}

export const AlertTooltip: React.FC<AlertTooltipProps> = ({
  children,
  content,
  enabled,
}) => {
  if (!enabled || !content) {
    return children;
  }

  return (
    <TooltipComponent
      className="alert-tooltip"
      content={
        <>
          <TooltipStyles />
          {content}
        </>
      }
      // TODO: adjust tooltip delay with Ricardo
      mouseEnterDelay={0.2}
      mouseLeaveDelay={0}
      placement="top"
    >
      {children}
    </TooltipComponent>
  );
};
