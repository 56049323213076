import {
  EMPTY_BINDING_WITH_EMPTY_OBJECT,
  ViewTypes,
} from "components/editorComponents/ActionCreator/constants";
import type { AppsmithFunctionConfigValues } from "components/editorComponents/ActionCreator/types";
import {
  enumTypeSetter,
  objectSetter,
  textGetter,
} from "components/editorComponents/ActionCreator/utils";
import {
  RUN_FLOW_FLOW_ID_ARG_INDEX,
  RUN_FLOW_PARAMETERS_ARG_INDEX,
  RUN_FLOW_PARAMETERS_ARG_NAME,
} from "spread/flows/editorAutocompleteDefinitions";

export const EMPTY_FLOW_PARAMETERS_CODE = '{{\n{\n //param1: "value",\n}\n}}';

export const flowParametersObjectFieldConfig: AppsmithFunctionConfigValues = {
  label: (props) => props.field.label ?? RUN_FLOW_PARAMETERS_ARG_NAME,
  defaultText: "",
  exampleText: "{ parameter1: 'value' }",
  options: () => null,
  getter: (value: string) => {
    const flowParams = textGetter(value, RUN_FLOW_PARAMETERS_ARG_INDEX);
    if (flowParams === EMPTY_BINDING_WITH_EMPTY_OBJECT || flowParams === "")
      return EMPTY_FLOW_PARAMETERS_CODE;
    return flowParams;
  },
  setter: (value: any, currentValue: string) => {
    const isFlowIdSet = Boolean(
      textGetter(currentValue, RUN_FLOW_FLOW_ID_ARG_INDEX),
    );
    if (!isFlowIdSet) {
      currentValue = enumTypeSetter(
        '""',
        currentValue,
        RUN_FLOW_FLOW_ID_ARG_INDEX,
      );
    }
    return objectSetter(value, currentValue, RUN_FLOW_PARAMETERS_ARG_INDEX);
  },
  view: ViewTypes.TEXT_VIEW,
};
