import { ValidationTypes } from "constants/WidgetValidation";
import { SUPPORTED_LANGUAGES } from "../utils/languages";

export const propertyPaneContentConfig = [
  {
    sectionName: "General",
    children: [
      {
        propertyName: "defaultValue",
        label: "Content",
        helperText: "Defines the content of the code editor.",
        controlType: "INPUT_TEXT",
        placeholderText: "console.log('Hello World');",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: {
          type: ValidationTypes.TEXT,
        },
      },
      {
        propertyName: "language",
        label: "Language",
        helperText: "Defines programming language for syntax highlighting.",
        controlType: "DROP_DOWN",
        options: SUPPORTED_LANGUAGES.map((item) => ({
          label: item,
          value: item,
        })),
        placeholderText: "typescript",
        isBindProperty: true,
        isTriggerProperty: false,
        isJSConvertible: true,
        validation: {
          type: ValidationTypes.TEXT,
          params: {
            allowedValues: SUPPORTED_LANGUAGES,
          },
        },
      },
      {
        propertyName: "isVisible",
        label: "Visible",
        helpText: "Controls the visibility of the widget",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
    ],
  },
];

export const defaultPropsValues = {
  defaultValue: `// Define an interface for contact information
      interface ContactInfo {
        email: string;
        phone?: string; // Optional phone number
      }

      // Define a class called "Person" with updated properties and methods
      class Person {
        constructor(
          public firstName: string,
          public lastName: string,
          public age: number,
          public contactInfo: ContactInfo
        ) {}

        getFullName(): string {
          return \`\${this.firstName} \${this.lastName}\`;
        }

        displayInfo(): void {
          console.log(\`Name: \${this.getFullName()}\`);
          console.log(\`Age: \${this.age}\`);
          console.log(\`Email: \${this.contactInfo.email}\`);
          if (this.contactInfo.phone) {
            console.log(\`Phone: \${this.contactInfo.phone}\`);
          }
        }
      }

      // Create an instance of the Person class
      const person1 = new Person(
        "John",
        "Doe",
        30,
        { email: "john@example.com", phone: "555-555-5555" }
      );

      // Access properties and methods of the instance
      person1.displayInfo();
      `,
  language: "typescript",
} as const;
