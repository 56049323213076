// This function is used to update the link sides based on the position of the nodes (if the link is bidirectional and the sides are not set)
export function updateLinkSides(diagram: go.Diagram | null) {
  if (!diagram) return;
  diagram.startTransaction("updateLinkSides");
  diagram.links.each((link) => {
    const fromNode = link.fromNode;
    const toNode = link.toNode;
    const fromSide = link.data.fromSide;
    const toSide = link.data.toSide;

    if (fromNode && toNode && link.data.bidirect && !fromSide && !toSide) {
      const fromPos = fromNode.position;
      const toPos = toNode.position;

      if (fromPos.y > toPos.y) {
        // Swap the from and to nodes
        link.fromNode = toNode;
        link.toNode = fromNode;
      }
    }
  });
  diagram?.commitTransaction("updateLinkSides");
}

// This function is used to update the node data with the ports information (based on the links connected to the node)
export function updateNodeDataWithPorts(diagram: go.Diagram) {
  diagram.model.startTransaction("updatePorts");
  diagram.nodes.each((node) => {
    const data = node.data;
    const leftPorts: { portId: string; color: string }[] = [];
    const rightPorts: { portId: string; color: string }[] = [];
    const topPorts: { portId: string; color: string }[] = [];
    const bottomPorts: { portId: string; color: string }[] = [];

    const inboundLinkTypes = new Map<string, Set<string>>();
    const outboundLinkTypes = new Map<string, Set<string>>();

    node.findLinksInto().each((link) => {
      if (!link.visible) return;
      const side = link.data.toSide;
      if (!side) return;
      if (!inboundLinkTypes.has(side)) {
        inboundLinkTypes.set(side, new Set<string>());
      }
      inboundLinkTypes.get(side)!.add(link.data.color);
    });

    node.findLinksOutOf().each((link) => {
      if (!link.visible) return;
      const side = link.data.fromSide;
      if (!side) return;
      if (!outboundLinkTypes.has(side)) {
        outboundLinkTypes.set(side, new Set<string>());
      }
      outboundLinkTypes.get(side)!.add(link.data.color);
    });

    const addPorts = (side: string, colors: Set<string>) => {
      colors.forEach((color) => {
        if (side === "left") {
          leftPorts.push({ portId: "left" + color, color });
        } else if (side === "right") {
          rightPorts.push({ portId: "right" + color, color });
        } else if (side === "top") {
          topPorts.push({ portId: "top" + color, color });
        } else if (side === "bottom") {
          bottomPorts.push({ portId: "bottom" + color, color });
        }
      });
    };

    inboundLinkTypes.forEach((colors, side) => {
      addPorts(side, colors);
    });

    outboundLinkTypes.forEach((colors, side) => {
      addPorts(side, colors);
    });

    diagram.model.setDataProperty(data, "leftPorts", leftPorts);
    diagram.model.setDataProperty(data, "rightPorts", rightPorts);
    diagram.model.setDataProperty(data, "topPorts", topPorts);
    diagram.model.setDataProperty(data, "bottomPorts", bottomPorts);
  });

  diagram.model.commitTransaction("updatePorts");
}

// This function is used to assign the port ids to the links based on the ports information in the nodes
export function assignPortIdsToLinks(diagram: go.Diagram) {
  diagram.model.startTransaction("assignPorts");

  const getPortId = (
    node: go.Node,
    side: string,
    color: string,
  ): string | undefined => {
    // Find the port with the specified color on the specified side of the node
    const ports = node.data[`${side}Ports`];
    if (ports) {
      const port = ports.find(
        (port: { portId: string; color: string }) => port.color === color,
      );
      return port ? port.portId : undefined;
    }
    return undefined;
  };

  diagram.links.each((link) => {
    const fromNode = link.fromNode;
    const toNode = link.toNode;

    if (fromNode && toNode) {
      const fromPortId = getPortId(
        fromNode,
        link.data.fromSide,
        link.data.color,
      );
      const toPortId = getPortId(toNode, link.data.toSide, link.data.color);

      if (fromPortId) {
        diagram.model.setDataProperty(link.data, "fromPortId", fromPortId);
      }
      if (toPortId) {
        diagram.model.setDataProperty(link.data, "toPortId", toPortId);
      }
    }
  });

  diagram.model.commitTransaction("assignPorts");
}
