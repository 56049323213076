import { getAppsmithConfigs } from "@appsmith/configs";
import { ReactDiagram } from "gojs-react";
import React from "react";
import { PrecedenceGraphContextMenu } from "./PrecedenceGraphContextMenu/PrecedenceGraphContextMenu";
import { GRAPH_STYLES, PROGRESS_BAR_HEIGHT } from "./constants";
import { makeInitGraph } from "./makeInitGraph";
import type { UseDiagramConfig } from "./useDiagram";
import { useDiagram } from "./useDiagram";

import { Toolbar } from "../../../components/Toolbar/Toolbar";
import styled from "styled-components";
import { Loader } from "spread/components/Loader";
import { ProgressVariant } from "../../ProgressWidget/constants";

export interface PrecedenceGraphComponentProps extends UseDiagramConfig {
  hasToolbar: boolean;

  onAddNode: () => void;
  onNodeClick: () => void;
  onUnmatchedNodeRemove: () => void;
  progressBarFillColor: string;

  isForcedLoading: boolean;
}

export const PrecedenceGraph: React.FC<PrecedenceGraphComponentProps> = ({
  hasToolbar,
  isForcedLoading,
  onAddNode,
  onNodeClick,
  onUnmatchedNodeRemove,
  progressBarFillColor,
  ...useDiagramProps
}) => {
  const {
    contextMenuData,
    edgesTransformed,
    hiddenNodeIds,
    isLoading,
    newEdgeStartNodeId,
    nodesTransformed,
    onAutoLayout,
    onBackgroundSingleClick,
    onContextMenu,
    onCreateNewEdge,
    onEdgeSelectionChange,
    onHidePart,
    onModelChange,
    onNodeSelectionChange,
    onRemoveEdge,
    onRemovePart,
    onUnhidePart,
    onZoomToSelectedPart,
    ref,
    selectedNodeIds,
    setNewEdgeStartNodeId,
  } = useDiagram(useDiagramProps);

  const diagram = ref.current?.getDiagram();
  const getIsLoading = () => isLoading || isForcedLoading;
  const isLoader = getIsLoading();

  return (
    <Container>
      {isLoader && (
        <LoaderContainer>
          <Loader
            borderRadius="0px"
            fillColor={progressBarFillColor}
            height={PROGRESS_BAR_HEIGHT}
            isVisible
            progressValue={50}
            variant={ProgressVariant.INDETERMINATE}
          />
        </LoaderContainer>
      )}

      {nodesTransformed.length ? (
        <ReactDiagram
          divClassName="precedence-graph"
          initDiagram={makeInitGraph({
            onNodeSelectionChange,
            onEdgeSelectionChange,
            events: {
              onContextMenu,
              onNodeClick,
              onBackgroundSingleClick,
            },
            gojsLicenseKey: getAppsmithConfigs().gojsLicenseKey,
          })}
          linkDataArray={edgesTransformed}
          nodeDataArray={nodesTransformed}
          onModelChange={onModelChange}
          ref={ref}
          style={GRAPH_STYLES}
        />
      ) : null}

      <PrecedenceGraphContextMenu
        contextMenuData={contextMenuData}
        hiddenNodeIds={hiddenNodeIds}
        newEdgeStartNodeId={newEdgeStartNodeId}
        onAddNode={onAddNode}
        onAutoLayout={onAutoLayout}
        onClose={() => onContextMenu(undefined)}
        onCreateNewEdge={onCreateNewEdge}
        onHidePart={onHidePart}
        onRemoveEdge={onRemoveEdge}
        onRemovePart={onRemovePart}
        onUnhidePart={onUnhidePart}
        onUnmatchedNodeRemove={onUnmatchedNodeRemove}
        selectedNodeIds={selectedNodeIds}
        setNewEdgeStartNodeId={setNewEdgeStartNodeId}
      />

      {hasToolbar && (
        <Toolbar
          onZoomIn={() => {
            diagram?.commandHandler.increaseZoom(2);
          }}
          onZoomOut={() => {
            diagram?.commandHandler.decreaseZoom(0.3);
          }}
          onZoomToSelectedPart={onZoomToSelectedPart}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1000;
  cursor: not-allowed;
`;
