import React, { type MouseEventHandler } from "react";
import styled from "styled-components";
import CompletedIcon from "../../../assets/svg/spread/stepper/completed.svg";
import { TooltipComponent } from "@design-system/widgets-old";

interface StepWrapperProps {
  isActive: boolean;
  isCompleted: boolean;
  children: React.ReactNode;
  isAccessible: boolean;
  onClick?:
    | (MouseEventHandler<HTMLDivElement> & ((index: number) => void))
    | undefined;
}

interface StepContainerProps {
  title: string;
  subTitle?: string;
  isActive: boolean;
  isAccessible: boolean;
  isCompleted: boolean;
  onClick?:
    | (MouseEventHandler<HTMLDivElement> & ((index: number) => void))
    | undefined;
}

export const StepContainer: React.FC<StepContainerProps> = ({
  isAccessible,
  isActive,
  isCompleted,
  onClick,
  subTitle,
  title,
}) => {
  const StepperTooltip = (
    <>
      {title}
      <br />
      <br />
      {subTitle}
    </>
  );
  return (
    <StepWrapper
      isAccessible={isAccessible}
      isActive={isActive}
      isCompleted={isCompleted}
      onClick={onClick}
    >
      <ContentWrapper>
        <IconContainer>
          <IconWrapper
            className="icon-wrapper"
            isAccessible={isAccessible}
            isActive={isActive}
            isCompleted={isCompleted}
          >
            {isCompleted && <img alt="icon" src={CompletedIcon} />}
          </IconWrapper>
        </IconContainer>
        <TextWrapper>
          <TooltipComponent content={StepperTooltip} position="auto">
            <Title isAccessible={isAccessible}>{title}</Title>
          </TooltipComponent>
          <TooltipComponent content={StepperTooltip} position="auto">
            <SubTitle isAccessible={isAccessible}>{subTitle}</SubTitle>
          </TooltipComponent>
        </TextWrapper>
      </ContentWrapper>
    </StepWrapper>
  );
};

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 1;
  min-width: 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
`;

const IconContainer = styled.div`
  width: 32px;
  height: 100%;
  justify-content: center;
  display: flex;
`;

const IconWrapper = styled.div<{
  isAccessible: boolean;
  isActive: boolean;
  isCompleted: boolean;
}>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 8px;
  border: ${({ isActive, isCompleted }) => {
    if (isActive && !isCompleted) {
      return "2px solid #485162";
    }

    if (isActive || isCompleted) {
      return "none";
    }

    return "2px dashed #e0dede";
  }};
`;

const Title = styled.div<{ isAccessible: boolean }>`
  font-family: Open Sans;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ isAccessible }) => {
    return isAccessible ? "#4c5664" : "#a9a7a7";
  }};
  margin-bottom: 5px;
  padding-bottom: 5px;
`;

const SubTitle = styled.div<{ isAccessible: boolean }>`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;

  color: ${({ isAccessible }) => {
    return isAccessible ? "#4c5664" : "#a9a7a7";
  }};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 5px;
  padding-right: 5px;
  height: 17px;
  width: 100%;
`;

const StepWrapper = styled.div<StepWrapperProps>`
  border-top: 2px solid
    ${({ isActive, isCompleted }) => {
      if (isActive || isCompleted) {
        return "#485162";
      }
      return "#e0dede";
    }};
  min-width: 98px;
  height: 56px;
  cursor: ${({ isAccessible }) => (isAccessible ? "pointer" : "not-allowed")};
  flex: 1;
  background: "white";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 5px;

  &:hover {
    background: ${({ isAccessible, isCompleted }) => {
      if (isAccessible && isCompleted) {
        return "rgba(72, 81, 98, 0.1)";
      }

      if (isAccessible) {
        return "rgba(169, 167, 167, 0.1)";
      }
    }};

    border-top: 2px solid
      ${({ isAccessible, isActive, isCompleted }) => {
        if (isAccessible && !isCompleted && !isActive) {
          return "rgba(169, 167, 167, 1)";
        }

        if (!isAccessible) {
          return "#e0dede";
        }
      }};

    ${IconWrapper} {
      border: ${({ isAccessible, isActive, isCompleted }) =>
        isAccessible && !isCompleted && !isActive ? "2px solid #a9a7a7" : ""};
    }
  }
`;
