import type { CardId, CardItemId, ClusterId } from "../types";

// #region Actions

export type ActionType =
  | "cluster:add"
  | "card:edit"
  | "card:add"
  | "card:delete"
  | "cardItem:edit"
  | "cardItem:delete";

export interface ActionPayload {
  type: ActionType;
  target: {
    clusterId: ClusterId;
    cardId?: CardId; // might be undefined if type is "cluster:add"
    cardItemId?: CardItemId; // might be undefined if type is "cluster:*" or "card:*"
  };
}

// #endregion Actions

// #region Callbacks

export const callbacksDict = [
  // Cluster
  {
    name: "onAddOnClusterClick",
    triggersActionWhen: "an add button on cluster is clicked",
  },
  // Card
  {
    name: "onEditOnCardClick",
    triggersActionWhen: 'an "Edit" button on card is clicked',
  },
  {
    name: "onAddOnCardClick",
    triggersActionWhen: 'an "Add" button on card is clicked',
  },
  {
    name: "onDeleteOnCardClick",
    triggersActionWhen: 'an "Delete" button on card is clicked',
  },
  // Card item
  {
    name: "onEditOnCardItemClick",
    triggersActionWhen: 'an "Edit" button on card item is clicked',
  },
  {
    name: "onDeleteOnCardItemClick",
    triggersActionWhen: 'an "Delete" button on card item is clicked',
  },
] as const;

const callbacks = callbacksDict.map((child) => child.name);

type Callback = (typeof callbacks)[number];

// #endregion Callbacks

export const actionToCallbackMap: Record<ActionType, Callback> = {
  "cluster:add": "onAddOnClusterClick",
  "card:edit": "onEditOnCardClick",
  "card:add": "onAddOnCardClick",
  "card:delete": "onDeleteOnCardClick",
  "cardItem:edit": "onEditOnCardItemClick",
  "cardItem:delete": "onDeleteOnCardItemClick",
};
