import React, { useEffect, useRef } from "react";

import { ReactDiagram } from "gojs-react";
import { isNil } from "lodash";

import "./styles.css";
import type {
  GraphWidgetHierarchicalModeDirection,
  GraphWidgetHierarchicalModeLayering,
} from "../widget/types";
import { getDiagramLayout } from "./layout";
import { Toolbar } from "../../../components/Toolbar/Toolbar";
import type { InitDiagramProps, NodeId } from "./makeInitDiagram";
import { makeInitDiagram } from "./makeInitDiagram";
import type { GraphWidgetMode } from "../widget/types";
import { useGraphData } from "./useGraphData";

export type EdgeId = string | number;

export interface Graph {
  nodes: Node[];
  edges: Edge[];
}

export interface Node {
  id: NodeId;
  label: string;
  color?: string;
}

export interface Edge {
  id: EdgeId;
  label?: string;
  from: NodeId;
  to: NodeId;
}

export interface GraphComponentProps
  extends Omit<InitDiagramProps, "onAddNodeToSingleSelection"> {
  graph: Graph;

  nodeFigure?: "Circle" | "RoundedRectangle";

  nodeTextColor?: string;
  nodeColor?: string;
  edgeColor?: string;
  backgroundColor?: string;
  nodeBorderColor?: string;

  nodeDiameter?: number;
  nodeTextSize?: number;
  nodeBorderSize?: number;

  selectedNodeBorderColor?: string;
  selectedNodeBorderSize?: number;
  selectedNodeBackgroundColor?: string;

  mode: GraphWidgetMode;
  hierarchicalModeDirection: GraphWidgetHierarchicalModeDirection;
  hierarchicalModeLayering: GraphWidgetHierarchicalModeLayering;
  hierarchicalModeLayerSpacing: number;
  hierarchicalModeColumnSpacing: number;
  hierarchicalModeAlignOption: number;
  hasToolbar: boolean;

  onSelectionChange: () => void;
  onRightClick: () => void;

  defaultSelectedNodes: NodeId[];
  updateSelectedNodesInMeta: (selectedNodesIds: Set<NodeId>) => void;

  hiddenNodeIds: NodeId[];
}

export const GraphComponent: React.FC<GraphComponentProps> = ({
  backgroundColor = "white",
  defaultSelectedNodes,
  edgeColor = "black",
  hasToolbar,
  nodeBorderColor = "black",
  nodeBorderSize = 0,
  nodeColor,
  nodeDiameter = 60,
  nodeFigure = "Circle",
  nodeTextColor = "black",
  nodeTextSize = 10,
  onRightClick,
  onSelectionChange,
  selectedNodeBackgroundColor,
  selectedNodeBorderColor = "#4ade80",
  selectedNodeBorderSize = 2,
  updateSelectedNodesInMeta,
  ...props
}) => {
  const diagramRef = useRef<ReactDiagram>(null);

  const {
    edges,
    nodes,
    onAddNodeToSelection,
    onAddNodeToSingleSelection,
    onClearSelection,
    onNodeRightClick,
    onRemoveNodeFromSelection,
    updateSelectedNodes,
  } = useGraphData({
    hiddenNodeIds: props.hiddenNodeIds,
    graphNodes: props.graph.nodes,
    graphEdges: props.graph.edges,
    updateSelectedNodesInMeta,
    defaultSelectedNodes,
    onSelectionChange,
    backgroundColor,
    selectedNodeBackgroundColor,
    nodeColor,
    onRightClick,
  });

  const diagram = diagramRef.current?.getDiagram();

  const zoomToFit = () => {
    const diagram = diagramRef.current?.getDiagram();

    if (!diagram?.scale) {
      return;
    }

    diagram?.commandHandler.zoomToFit();
  };

  useEffect(() => {
    updateSelectedNodes();
  }, [defaultSelectedNodes]);

  useEffect(() => {
    const diagram = diagramRef.current?.getDiagram();
    if (isNil(diagram)) {
      return;
    }
    const transaction = "update graph layout";
    diagram.startTransaction(transaction);

    diagram.layout = getDiagramLayout(
      props.mode,
      props.hierarchicalModeDirection,
      props.hierarchicalModeLayering,
      props.hierarchicalModeLayerSpacing,
      props.hierarchicalModeColumnSpacing,
      props.hierarchicalModeAlignOption,
    );

    diagram.commitTransaction(transaction);
  }, [
    props.mode,
    props.hierarchicalModeDirection,
    props.hierarchicalModeLayering,
    props.hierarchicalModeLayerSpacing,
    props.hierarchicalModeColumnSpacing,
    props.hierarchicalModeAlignOption,
    props.graph.nodes,
  ]);

  useEffect(() => {
    zoomToFit();
  }, [props.graph.nodes, props.graph.edges]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <ReactDiagram
        divClassName="border"
        initDiagram={makeInitDiagram({
          ...props,
          onAddNodeToSingleSelection,
          onAddNodeToSelection,
          onClearSelection,
          onRemoveNodeFromSelection,
          onRightClick: onNodeRightClick,
        })}
        linkDataArray={edges}
        modelData={{
          nodeColor: nodeColor,
          nodeTextColor: nodeTextColor,
          edgeColor: edgeColor,
          nodeDiameter: nodeDiameter,
          nodeTextWidth: (nodeDiameter * 2) / 3,
          nodeFont: `bold ${nodeTextSize}pt helvetica, bold arial, sans-serif`,
          backgroundColor: backgroundColor,
          nodeFigure: nodeFigure,
          selectedNodeBackgroundColor: selectedNodeBackgroundColor,
          nodeBorderSize: nodeBorderSize,
          nodeBorderColor: nodeBorderColor,
          selectedNodeBorderColor: selectedNodeBorderColor,
          selectedNodeBorderSize: selectedNodeBorderSize,
        }}
        nodeDataArray={nodes}
        ref={diagramRef}
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: backgroundColor,
        }}
      />

      {hasToolbar && (
        <Toolbar
          onZoomIn={() => {
            diagram?.commandHandler.increaseZoom();
          }}
          onZoomOut={() => {
            diagram?.commandHandler.decreaseZoom();
          }}
          onZoomToSelectedPart={zoomToFit}
        />
      )}
    </div>
  );
};
