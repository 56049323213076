import * as go from "gojs";
import { nodeVariantStyleMap } from "./helpers";
import type { NodeVariant } from "../widget/types";

import type { GoClickHandler, Events, NodeTransformed } from "./types";

export const nodeTemplate = (events: Events) => {
  const $ = go.GraphObject.make;

  const onContextMenu: GoClickHandler = (event, object) => {
    const node = object.part?.data as NodeTransformed;

    const clientX = event.viewPoint.x;
    const clientY = event.viewPoint.y;

    events.onContextMenu({
      variant: node.variant,
      targetPartType: "node",
      anchorPosition: { left: clientX, top: clientY },
      documentPoint: event?.documentPoint,
      targetId: node?.id,
    });
  };

  return $(
    go.Node,
    "Vertical",
    {
      selectionAdorned: false,
      zOrder: 10,
      // TODO @$ change to parameter defined in property pane
      scale: 2,
      contextClick: onContextMenu,
      click: () => {
        events.onNodeClick();
      },
    },
    new go.Binding("visible", "visible"),
    new go.Binding("location", "location", go.Point.parse).makeTwoWay(
      go.Point.stringify,
    ),

    [
      $(
        go.Panel,
        "Spot",
        $(go.Shape, "rectangle", {
          width: 20,
          height: 20,
          portId: "",
          fill: "transparent",
          stroke: "transparent",
        }),
        $(
          go.Shape,
          "Circle",
          new go.Binding(
            "fill",
            "variant",
            (variant: NodeVariant) => nodeVariantStyleMap[variant].background,
          ),
          {
            width: 16,
            strokeWidth: 0,
          },
        ),
        $(
          go.Picture,
          new go.Binding(
            "source",
            "variant",
            (state: NodeVariant) => nodeVariantStyleMap[state].icon,
          ),
          {
            width: 12,
            height: 12,
          },
        ),
      ),
      $(
        go.TextBlock,
        {
          margin: 1,
          overflow: go.TextBlock.OverflowEllipsis,
          maxLines: 2,
          width: 100,
          wrap: go.TextBlock.WrapFit,
          textAlign: "center",
          font: "700 6px Open Sans",
        },
        new go.Binding("text", "text"),
        new go.Binding(
          "stroke",
          "variant",
          (state: NodeVariant) => nodeVariantStyleMap[state].labelStroke,
        ),
      ),
    ],
  );
};
