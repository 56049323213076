import * as React from "react";
import log from "loglevel";
import styled from "styled-components";
import { Button } from "design-system";
import BaseControl from "../BaseControl";
import type { ControlProps } from "../BaseControl";

export const StyledImagePickerInput = styled.input`
  display: none;
`;

export interface Base64ImagePickerControlProps extends ControlProps {
  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   */
  acceptedImageTypes?: string;
  title?: string;
}

class Base64ImagePickerControl extends BaseControl<Base64ImagePickerControlProps> {
  private readonly inputOpenFileRef: React.RefObject<HTMLInputElement>;

  constructor(props: ControlProps) {
    super(props);
    this.inputOpenFileRef = React.createRef<HTMLInputElement>();
  }

  handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (!file) return;

    this.convertToBase64(file);
  }

  convertToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      this.updateProperty?.(this.props.propertyName, reader.result, true);
    });
    reader.addEventListener("error", (error) => {
      log.error("Error reading image:", error);
    });
    reader.readAsDataURL(file);
  };

  handleClick = () => {
    this.inputOpenFileRef.current?.click();
  };

  render() {
    const { acceptedImageTypes, label, title } = this.props;
    return (
      <>
        <Button
          aria-label={title || "Upload image"}
          className="button-upload"
          kind="secondary"
          onClick={this.handleClick}
          size="sm"
        >
          {label || "Upload image"}
        </Button>

        <StyledImagePickerInput
          accept={acceptedImageTypes}
          onChange={(e) => this.handleImageUpload(e)}
          ref={this.inputOpenFileRef}
          type="file"
        />
      </>
    );
  }

  static getControlType() {
    return "BASE64_IMAGE_PICKER";
  }
}

export default Base64ImagePickerControl;
