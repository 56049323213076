import type { WidgetBlueprint } from "WidgetProvider/constants";
import { GridDefaults } from "constants/WidgetConstants";
import { Positioning } from "layoutSystems/common/utils/constants";
import { mergeDeepLeft } from "ramda";
import type { DeepNonNullable } from "react-select/src/components";
import { DynamicHeight, RegisteredWidgetFeatures } from "utils/WidgetFeatures";
import type { WidgetProps } from "widgets/BaseWidget";
import type { ContainerWidgetProps } from "widgets/ContainerWidget/widget";
import type { ContainerPropsExtension } from "widgets/SpreadSplitContainerWidget/types";

export const SPREAD_SPLIT_CONTAINER_WIDGET_CONSTANT = "";

export const WIDGET_DEFAULT_COLS = GridDefaults.DEFAULT_GRID_COLUMNS;
export const WIDGET_DEFAULT_ROWS = 64;

// MUST (!!!) sum up to 1 AND all final 3 numbers must *NOT* be a float number eg.:
// - 0.375 * 64 = 24 ✅
// - 0.20 * 64 = 12.8 ❌
export const LEFT_AREA_GROW = 0.25;
export const MIDDLE_AREA_GROW = 0.375;
export const RIGHT_AREA_GROW = 0.375;

export const LEFT_AREA_COLUMNS = Math.floor(
  WIDGET_DEFAULT_COLS * LEFT_AREA_GROW,
);
export const MIDDLE_AREA_COLUMNS = Math.floor(
  WIDGET_DEFAULT_COLS * MIDDLE_AREA_GROW,
);
export const RIGHT_AREA_COLUMNS = Math.floor(
  WIDGET_DEFAULT_COLS * RIGHT_AREA_GROW,
);

type WidgetRectConfig = DeepNonNullable<
  Pick<NonNullable<WidgetBlueprint["view"]>[number], "size" | "position">
>;
export const BLUEPRINT_CONFIG = {
  leftArea: {
    position: { top: 0, left: 0 },
    size: { rows: WIDGET_DEFAULT_ROWS - 1, cols: LEFT_AREA_COLUMNS },
  } as WidgetRectConfig,
  middleArea: {
    position: { top: 0, left: LEFT_AREA_COLUMNS },
    size: { rows: WIDGET_DEFAULT_ROWS - 1, cols: MIDDLE_AREA_COLUMNS },
  } as WidgetRectConfig,
  rightArea: {
    position: { top: 0, left: LEFT_AREA_COLUMNS + MIDDLE_AREA_COLUMNS },
    size: { rows: WIDGET_DEFAULT_ROWS - 1, cols: RIGHT_AREA_COLUMNS },
  } as WidgetRectConfig,
};

function createContainerWidget(
  blueprintConfig: Required<
    Omit<NonNullable<WidgetBlueprint["view"]>[number], "props" | "type">
  > & {
    props?: Partial<ContainerWidgetProps<WidgetProps>> &
      ContainerPropsExtension;
  },
) {
  return mergeDeepLeft(blueprintConfig, {
    type: "CONTAINER_WIDGET",
    props: {
      shouldScrollContents: false,
      dragDisabled: true,
      resizeDisabled: true,
      isDeletable: false,
      isListItemContainer: false,
      disallowCopy: true,
      noContainerOffset: true,
      positioning: Positioning.Fixed,

      dynamicHeight: DynamicHeight.FIXED,
      disabledWidgetFeatures: [RegisteredWidgetFeatures.DYNAMIC_HEIGHT],
      // or
      // dynamicHeight: DynamicHeight.AUTO_HEIGHT,
      // minDynamicHeight: 8,

      children: [],
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: { top: 0, left: 0 },
            props: {
              containerStyle: "none",
              canExtend: false,
              children: [],
            },
          },
        ],
      },
    },
  });
}

export const WIDGET_DEFAULTS = {
  widgetName: "SplitContainer",
  rows: WIDGET_DEFAULT_ROWS,
  columns: WIDGET_DEFAULT_COLS,
  version: 1,
  isLeftAreaVisible: true,
  isMiddleAreaVisible: true,
  isRightAreaVisible: true,
  blueprint: {
    view: [
      {
        type: "CANVAS_WIDGET",
        position: { top: 0, left: 0 },
        props: {
          useAutoLayout: false,
          dropDisabled: true,
          noPad: true,
          children: [],
          blueprint: {
            view: [
              createContainerWidget({
                ...BLUEPRINT_CONFIG.leftArea,
                props: {
                  splitContainerAreaIndex: 0,
                },
              }),
              createContainerWidget({
                ...BLUEPRINT_CONFIG.middleArea,
                props: {
                  splitContainerAreaIndex: 1,
                },
              }),
              createContainerWidget({
                ...BLUEPRINT_CONFIG.rightArea,
                props: {
                  splitContainerAreaIndex: 2,
                },
              }),
            ],
          },
        },
      },
    ],
  },
};
