import type { Connection, SignalPathItem, Terminal } from "./types";

export function isConnection(
  component: SignalPathItem,
): component is Connection {
  return component.entityType === "connection";
}

export function isTerminal(component: SignalPathItem): component is Terminal {
  return component.entityType === "terminal";
}
