import React from "react";

import BaseWidget from "widgets/BaseWidget";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import { DefaultAutocompleteDefinitions } from "widgets/WidgetUtils";
import type {
  AutoLayoutConfig,
  AutocompletionDefinitions,
} from "WidgetProvider/constants";
import type { Stylesheet } from "entities/AppTheming";

import type { StyleProps } from "./styleConfig";
import { propertyPaneStyleConfig, stylesheetConfig } from "./styleConfig";
import { propertyPaneContentConfig } from "./properiesConfig";
import CodeEditor from "../component";
import type { SupportedLanguage } from "../utils/languages";
import type { EditorTheme } from "../utils/theme";
import ThumbnailSVG from "../../spread_tmp_thumbnail.svg";

export interface CodeEditorWidgetProps extends WidgetProps, StyleProps {
  // Content
  defaultValue: string;
  language: SupportedLanguage;
  isVisible?: boolean;

  // Style
  theme: EditorTheme;
}

import { defaultPropsValues } from "./properiesConfig";
import { defaultStylesheet } from "./styleConfig";
import IconSVG from "../icon.svg";
import { WIDGET_TAGS } from "constants/WidgetConstants";

const defaultProps = {
  ...defaultPropsValues,
  ...defaultStylesheet,
};

class CodeEditorWidget extends BaseWidget<CodeEditorWidgetProps, WidgetState> {
  static type = "CODE_EDITOR_WIDGET";

  static getConfig() {
    return {
      name: "Code Editor", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      thumbnailSVG: ThumbnailSVG,
      tags: [WIDGET_TAGS.BETA],
      needsMeta: true, // Defines if this widget adds any meta properties
      isCanvas: false, // Defines if this widget has a canvas within in which we can drop other widgets
      searchTags: ["percent"],
    };
  }

  static getDefaults() {
    return {
      ...defaultProps,
      widgetName: "CodeEditor",
      rows: 40,
      columns: 80,
      version: 1,
    };
  }

  static getAutoLayoutConfig(): AutoLayoutConfig | null {
    // TODO: add proper auto layout config
    return {};
  }

  updateValue = (value?: string) => {
    this.props.updateWidgetMetaProperty("value", value);
  };

  componentDidMount(): void {
    const { defaultValue } = this.props;
    this.updateValue(defaultValue);
  }

  componentDidUpdate(prevProps: CodeEditorWidgetProps): void {
    const { defaultValue } = this.props;

    if (prevProps.defaultValue !== defaultValue) {
      this.updateValue(defaultValue);
    }
  }

  static getDerivedPropertiesMap() {
    return {};
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return () => ({
      "!doc": "Renderer widget is used to display 3D models.",
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      value: {
        "!type": "string",
        "!doc": "Content of the code editor",
      },
    });
  }

  static getStylesheetConfig(): Stylesheet {
    return stylesheetConfig;
  }

  static getPropertyPaneStyleConfig() {
    return propertyPaneStyleConfig;
  }

  static getPropertyPaneContentConfig() {
    return propertyPaneContentConfig;
  }

  getWidgetView() {
    const { defaultValue, language, theme } = this.props;

    return (
      <CodeEditor
        language={language}
        onChange={this.updateValue}
        theme={theme}
        value={defaultValue}
      />
    );
  }
}

export default CodeEditorWidget;
