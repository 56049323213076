import React from "react";
import BaseControl, { type ControlProps } from "../BaseControl";
import RadioGroupComponent from "../../../widgets/RadioGroupWidget/component";
import { LabelPosition } from "../../constants";

const OPTIONS = [
  {
    label: "Default",
    value: "default",
  },
  {
    label: "Controlled",
    value: "controlled",
  },
  {
    label: "Directory",
    value: "directory",
  },
];

class TreeModeControl extends BaseControl<TreeModeControlProps> {
  constructor(props: TreeModeControlProps) {
    super(props);
  }

  static getControlType() {
    return "TREE_MODE_CONTROL";
  }

  public onUpdatePropertyValue = (
    value = "",
    makeDynamicPropertyPath?: boolean,
  ) => {
    this.props.onPropertyChange?.(
      this.props.propertyName,
      value,
      false,
      makeDynamicPropertyPath,
    );
  };

  public render() {
    const onSelect = (value: string) => this.onUpdatePropertyValue(value);

    return (
      <div style={{ paddingTop: "5px" }}>
        <RadioGroupComponent
          accentColor="#4C5664"
          alignment="left"
          compactMode={false}
          disabled={false}
          height={100}
          inline={false}
          isDynamicHeightEnabled
          labelPosition={LabelPosition.Right}
          labelText=""
          loading={false}
          onRadioSelectionChange={onSelect}
          options={OPTIONS}
          required
          selectedOptionValue={this.props.propertyValue as string}
          widgetId=""
        />
      </div>
    );
  }
}

export default TreeModeControl;

export type TreeModeControlProps = ControlProps;
