import type { NodeRendererProps } from "react-arborist";

import { ArrowRight } from "../arrowRight";
import styled from "styled-components";

import type { ChangeEvent } from "react";
import React, { useMemo } from "react";

import type { TreeNodeIdMap } from "./types";

import { StyledCheckbox } from "../../CheckboxWidget/component";
import {
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_BORDER_RADIUS,
  INTERNAL_TREE_ROOT,
} from "./constants";
import { lightenColor } from "../../WidgetUtils";
import type { TreeSelectionType } from "../widget";

export type TreeNodeId = string;
export interface TreeNode {
  id: string;
  label: string;
  style?: { iconUrl?: string; opacity?: number };
  children?: TreeNode[];
}

const Label = styled.div<{ opacity?: number }>`
  opacity: ${({ opacity }) => opacity ?? 1};
  flex: 0 0 auto;
  font-weight: 400;
  font-size: 14px;
  font-family: "Nunito Sans";
  white-space: nowrap;
  color: var(--wds-color-text);
`;

const CheckboxWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 2px;
  padding-bottom: 3px;
`;

const Row = styled.div<{
  isSelected: boolean;
  rowBackgroundAccentColor: string;
}>`
  background: ${({ isSelected, rowBackgroundAccentColor }) =>
    isSelected ? lightenColor(rowBackgroundAccentColor) : "white"};
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  &:hover {
    background: ${({ isSelected, rowBackgroundAccentColor }) => {
      return isSelected
        ? lightenColor(rowBackgroundAccentColor, "90")
        : "var(--wds-color-bg-hover);";
    }};
  }
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Space = styled.div`
  width: 32px;
  height: 100%;
`;

const ArrowIconContainer = styled.div`
  width: 32px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ArrowIconWrapper = styled.div<{ isOpen: boolean }>`
  transform: rotate(
    ${({ isOpen }) => {
      return isOpen ? "90" : "0";
    }}deg
  );

  transition: transform ease 0.3s;
`;

const IconsWrapper = styled.div<{ opacity?: number }>`
  opacity: ${({ opacity }) => opacity ?? 1};
  display: flex;
  align-items: center;
`;

const CustomIconContainer = styled.div`
  width: 20px;
  margin-right: 8px;
`;

interface NodeProps extends NodeRendererProps<TreeNode> {
  childrenIdsMap: TreeNodeIdMap;
  checkboxAccentColor?: string;
  checkboxBorderRadius?: string;
  rowBackgroundAccentColor: string;
  isParentNodeSelectable: boolean;
  selectionType: TreeSelectionType;
  onSelectedIdsChange: (ids: TreeNodeId[]) => void;
  selectedIds: Set<TreeNodeId>;
  selectedLeafNodesIds: Set<TreeNodeId>;
  onLeafNodesIdsSelectionChange: (ids: TreeNodeId[]) => void;
  disableMultiSelection: boolean;
}

export const Node: React.FC<NodeProps> = ({
  checkboxAccentColor,
  checkboxBorderRadius,
  childrenIdsMap,
  isParentNodeSelectable,
  node,
  onLeafNodesIdsSelectionChange,
  onSelectedIdsChange,
  rowBackgroundAccentColor,
  selectedIds,
  selectedLeafNodesIds,
  selectionType,
  style,
  tree,
}) => {
  const isMultiSelectionEnabled = !tree.props.disableMultiSelection;
  const hasParent = node.parent?.id !== INTERNAL_TREE_ROOT;
  const childrenIds = childrenIdsMap[node.id] ?? [];

  const hasChildren = childrenIds.length > 0;
  const selectionIds = Array.from(tree.selectedIds.values());

  const selectionState = useMemo(() => {
    if (
      (selectionType === "default" && isParentNodeSelectable) ||
      selectionType === "directory"
    ) {
      if (tree.selectedIds.has(node.id)) {
        return "selected";
      }
      return "unselected";
    }

    // If a node is a leaf, or if a tree has only one node, or if a tree has more than one root node
    if (!hasChildren && tree.selectedIds.has(node.id)) {
      return "selected";
    }

    if (!hasChildren) {
      return "unselected";
    }

    // If node has children
    let isSomeChildSelected = false;

    childrenIds.some((childrenId) => {
      const isSelected = tree.selectedIds.has(childrenId);
      if (isSelected) {
        isSomeChildSelected = true;
      }
      return isSelected;
    });

    const isSomeChildDeselected = childrenIds.some(
      (childrenId) => !tree.selectedIds.has(childrenId),
    );
    const areAllChildrenSelected = !isSomeChildDeselected;

    // If there is one child and its selected and parent is not selectable don't show this parent as selected
    if (
      areAllChildrenSelected &&
      !(selectionType === "default" && !isParentNodeSelectable)
    ) {
      return "selected";
    }

    if (isSomeChildSelected) {
      return "indeterminate";
    }

    return "unselected";
  }, [childrenIds, tree.selectedIds]);

  const isSelected = selectionState === "selected";

  const setTreeSelection = (
    ids: string[],
    mostRecent = node.id,
    anchor = null,
  ) => {
    return tree.setSelection({
      ids,
      mostRecent,
      anchor,
    });
  };

  const onCheckboxChangeHandle = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    if (!isSelected) {
      const newIds = [
        ...selectionIds,
        ...(hasChildren ? childrenIds : [node.id]),
      ];

      setTreeSelection(newIds);
      return;
    }

    const idsToUnselect = [...childrenIds, node.id];

    const newTreeSelection = new Set(tree.selectedIds);
    idsToUnselect.forEach((id) => newTreeSelection.delete(id));

    const newIds = Array.from(newTreeSelection);

    setTreeSelection(newIds);
  };

  /* TODO This function needs to be simplified to make it
      easy testable https://solveit.atlassian.net/browse/EIN-1186 */
  const onRowClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // multi selection
    const {
      ctrlKey: isCtrlKey,
      metaKey: isMetaKey,
      shiftKey: isShiftKey,
    } = event;

    const isSelectionKeyHold = isCtrlKey || isMetaKey || isShiftKey;

    if (
      (selectionType === "default" && isParentNodeSelectable) ||
      selectionType === "directory"
    ) {
      if (isSelectionKeyHold && isSelected) {
        selectedIds.delete(node.id);
        onSelectedIdsChange([...selectedIds]);

        selectedLeafNodesIds.delete(node.id);
        onLeafNodesIdsSelectionChange([...selectedLeafNodesIds]);
      }

      return;
    }

    // single selection
    if (!isMultiSelectionEnabled) {
      // prevent selection of parent node ids
      event.stopPropagation();
      if (hasChildren) {
        return;
      }

      if (!isSelected) {
        setTreeSelection([node.id]);
        return;
      }
      return;
    }

    if (!isSelectionKeyHold) {
      // Without the line below, clicking on the parent row (not checkbox) will not cause any selection
      event.stopPropagation();

      setTreeSelection(hasChildren ? childrenIds : [node.id]);
      return;
    }

    if (isShiftKey && !isSelected) {
      const nodeIdsBetween = tree.mostRecentNode?.id
        ? tree
            .nodesBetween(tree.mostRecentNode?.id, node.id)
            .reduce((acc: TreeNodeId[], node) => {
              if (!(node.id in childrenIdsMap) || !hasParent) {
                acc.push(node.id);
              }
              return acc;
            }, [])
        : [];

      setTreeSelection([
        ...selectionIds,
        ...nodeIdsBetween,
        ...(hasChildren ? childrenIds : [node.id]),
      ]);
      return;
    }

    if ((isCtrlKey || isMetaKey) && !isSelected) {
      // Without line below keeping ctrl or meta key and selecting part through clicking on a row (not checkbox) will not cause any selection
      event.stopPropagation();
      setTreeSelection([
        ...selectionIds,
        ...(hasChildren ? childrenIds : [node.id]),
      ]);
      return;
    }

    if ((isCtrlKey || isMetaKey) && isSelected) {
      // Without line below keeping ctrl or meta key and clicking on a row (not checkbox) that was selected will not cause deselection
      event.stopPropagation();

      const newTreeSelection = new Set(tree.selectedIds);
      newTreeSelection.delete(node.id);
      childrenIds.forEach((id) => newTreeSelection.delete(id));

      setTreeSelection([...newTreeSelection]);
      return;
    }
  };

  const onCheckboxWrapperClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
  };

  return (
    <Row
      isSelected={isSelected}
      onClick={onRowClick}
      rowBackgroundAccentColor={rowBackgroundAccentColor}
      style={{
        ...style,
        width: Number(style.paddingLeft) + Number(tree.props.width),
      }}
    >
      <IconsWrapper opacity={node.data?.style?.opacity}>
        {!hasChildren ? (
          <Space />
        ) : (
          <ArrowIconContainer
            onClick={(event) => {
              event.stopPropagation();
              node.toggle();
            }}
          >
            <ArrowIconWrapper isOpen={node.isOpen}>
              <ArrowRight />
            </ArrowIconWrapper>
          </ArrowIconContainer>
        )}

        {selectionType === "controlled" && (
          <CheckboxWrapper onClick={onCheckboxWrapperClick}>
            <StyledCheckbox
              accentColor={checkboxAccentColor || DEFAULT_BACKGROUND_COLOR}
              borderRadius={checkboxBorderRadius || DEFAULT_BORDER_RADIUS}
              checked={selectionState === "selected"}
              disabled={false}
              indeterminate={selectionState === "indeterminate"}
              onChange={onCheckboxChangeHandle}
              width={14}
            />
          </CheckboxWrapper>
        )}

        {node.data?.style?.iconUrl && (
          <CustomIconContainer>
            <img alt="icon" src={node.data?.style?.iconUrl} />
          </CustomIconContainer>
        )}
      </IconsWrapper>

      <Label opacity={node.data?.style?.opacity}>{node.data.label}</Label>
    </Row>
  );
};

Node.displayName = "Node";
