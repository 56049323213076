import { ValidationTypes } from "../../../constants/WidgetValidation";

const NODE_ID_VALIDATION_TYPE = ValidationTypes.TEXT || ValidationTypes.NUMBER;

const EDGES_VALIDATION_CONFIG = {
  type: ValidationTypes.ARRAY,
  params: {
    unique: ["id"],
    children: {
      type: ValidationTypes.OBJECT,
      params: {
        required: true,
        allowedKeys: [
          {
            name: "id",
            type: NODE_ID_VALIDATION_TYPE,
            params: {
              requiredKey: true,
            },
          },
          {
            name: "from",
            type: NODE_ID_VALIDATION_TYPE,
            params: {
              requiredKey: true,
            },
          },
          {
            name: "to",
            type: NODE_ID_VALIDATION_TYPE,
            params: {
              requiredKey: true,
            },
          },
          {
            name: "width",
            type: ValidationTypes.NUMBER,
            params: {
              requiredKey: false,
            },
          },
          {
            name: "color",
            type: ValidationTypes.TEXT,
            params: {
              requiredKey: false,
            },
          },
          {
            name: "label",
            type: ValidationTypes.TEXT,
            params: {
              requiredKey: false,
            },
          },
          {
            name: "labelVisible",
            type: ValidationTypes.BOOLEAN,
            params: {
              requiredKey: false,
            },
          },
          {
            name: "fromSide",
            type: ValidationTypes.TEXT,
            params: {
              allowedValues: ["top", "bottom", "left", "right"],
              requiredKey: false,
            },
          },
          {
            name: "toSide",
            type: ValidationTypes.TEXT,
            params: {
              allowedValues: ["top", "bottom", "left", "right"],
              requiredKey: false,
            },
          },
          {
            name: "bidirect",
            type: ValidationTypes.BOOLEAN,
            params: {
              requiredKey: false,
            },
          },
        ],
      },
    },
  },
};
const NODES_VALIDATION_CONFIG = {
  type: ValidationTypes.ARRAY,
  params: {
    unique: ["id"],
    children: {
      type: ValidationTypes.OBJECT,
      params: {
        required: true,
        allowedKeys: [
          {
            name: "id",
            type: NODE_ID_VALIDATION_TYPE,
            params: {
              requiredKey: true,
            },
          },
          {
            name: "text",
            type: ValidationTypes.TEXT,
            params: {
              requiredKey: false,
            },
          },
          {
            name: "color",
            type: ValidationTypes.TEXT,
            params: {
              requiredKey: false,
            },
          },
          {
            name: "orientation",
            type: ValidationTypes.TEXT,
            params: {
              allowedValues: ["horizontal", "vertical"],
              default: "vertical",
            },
          },
          {
            name: "group",
            type: NODE_ID_VALIDATION_TYPE,
            params: {
              requiredKey: false,
            },
          },
          {
            name: "isGroup",
            type: ValidationTypes.BOOLEAN,
            params: {
              requiredKey: false,
            },
          },
        ],
      },
    },
  },
};
export const contentConfig = [
  {
    sectionName: "Data",
    children: [
      {
        helpText: "Defines edges of the topology view tree.",
        propertyName: "defaultEdges",
        label: "Edges",
        controlType: "INPUT_TEXT",
        placeholderText:
          '[{ "id": "1_2", "from": "1", "to": "2", "color": "black", "label": "title" }]',
        isBindProperty: true,
        isTriggerProperty: false,
        validation: EDGES_VALIDATION_CONFIG,
      },
      {
        helpText: "Defines nodes of the topology view tree.",
        propertyName: "defaultNodes",
        label: "Nodes",
        controlType: "INPUT_TEXT",
        placeholderText:
          '[{ "id": "1", "text": "node", "color": "blue", "group": "group1" }]',
        isBindProperty: true,
        isTriggerProperty: false,
        validation: NODES_VALIDATION_CONFIG,
      },
    ],
  },
  {
    sectionName: "Events",
    children: [
      {
        helpText: "function that will be fired on tree node selection change",
        propertyName: "onSelectionChange",
        label: "onSelectionChange",
        controlType: "ACTION_SELECTOR",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: true,
      },
    ],
  },
  {
    sectionName: "General",
    children: [
      {
        helpText: "Controls the visibility of the widget",
        propertyName: "isVisible",
        label: "Visible",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        propertyName: "hasToolbar",
        label: "Toolbar",
        helpText: "Toolbar helping in interactions with diagram",
        controlType: "SWITCH",
        defaultValue: false,
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.BOOLEAN },
      },
      {
        helpText: "Sets which nodes to highlight when a node is selected",
        propertyName: "highlightMode",
        label: "Highlight Mode",
        controlType: "LABEL_ALIGNMENT_OPTIONS",
        options: [
          { label: "Root", value: "root" },
          { label: "Neighbors", value: "neighbors" },
          { label: "None", value: "none" },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
];
