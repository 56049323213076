import React from "react";

import {
  type AnvilConfig,
  type AutocompletionDefinitions,
  BlueprintOperationTypes,
} from "WidgetProvider/constants";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { WithMeta } from "widgets/MetaHOC";
import {
  GridDefaults,
  WIDGET_PADDING,
  WIDGET_TAGS,
  WidgetHeightLimits,
} from "constants/WidgetConstants";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../../spread_tmp_thumbnail.svg";
import { Stepper } from "../component";
import { contentConfig } from "./contentConfig";
import {
  type Alignment,
  FlexVerticalAlignment,
  LayoutDirection,
  Positioning,
  ResponsiveBehavior,
  type Spacing,
} from "../../../layoutSystems/common/utils/constants";
import { FILL_WIDGET_MIN_WIDTH } from "../../../constants/minWidthConstants";
import {
  DefaultAutocompleteDefinitions,
  isAutoHeightEnabledForWidget,
  isAutoHeightEnabledForWidgetWithLimits,
} from "../../WidgetUtils";
import { LayoutSystemTypes } from "../../../layoutSystems/types";
import { renderAppsmithCanvas } from "../../../layoutSystems/CanvasFactory";
import type { SetterConfig, Stylesheet } from "../../../entities/AppTheming";
import { EventType } from "../../../constants/AppsmithActionConstants/ActionConstants";

import derivedProperties from "./parseDerivedProperties";

import { find } from "lodash";

import { equals } from "ramda";
import { styleConfig } from "./styleConfig";
import type { ValidationResponse } from "../../../constants/WidgetValidation";

export interface StepperContainerWidgetProps extends WidgetProps {
  stepId: string;
}

export interface Step {
  id: string;
  label: string;
  widgetId: string;
  subTitle?: string;
  isVisible?: boolean;
  isOptional?: boolean;
  positioning?: Positioning;
}

export interface StepsObj extends Step {
  index: number;
  positioning: Positioning;
  alignment: Alignment;
  spacing: Spacing;
}

export interface StepperWidgetProps<T extends StepperContainerWidgetProps>
  extends WidgetProps,
    WithMeta {
  steps: Step[];
  stepsObj: Record<string, StepsObj>;
  children: T[];
  shouldScrollContents: boolean;
  borderColor: string;
  borderWidth: string;
  borderRadius: string;
  boxShadow: string;
}

export function selectedStepValidation(
  value: unknown,
  props: StepperContainerWidgetProps,
): ValidationResponse {
  const steps: Array<{
    label: string;
    id: string;
  }> = props.stepsObj ? Object.values(props.stepsObj) : props.steps || [];
  const stepNames = steps.map((i: { label: string; id: string }) => i.label);
  return {
    isValid: value === "" ? true : stepNames.includes(value as string),
    parsed: value,
    messages: [
      {
        name: "ValidationError",
        message: `Step name ${value} does not exist`,
      },
    ],
  };
}

class StepperWidget extends BaseWidget<
  StepperWidgetProps<StepperContainerWidgetProps>,
  WidgetState
> {
  static type = "STEPPER_WIDGET";

  static getConfig() {
    return {
      name: "Stepper", // The display name which will be made in uppercase and show in the widgets panel ( can have spaces )
      iconSVG: IconSVG,
      thumbnailSVG: ThumbnailSVG,
      tags: [WIDGET_TAGS.BETA],
      needsMeta: true, // Defines if this widget adds any meta properties
      isCanvas: true,
    };
  }

  static getFeatures() {
    return {
      dynamicHeight: {
        sectionIndex: 1,
        active: true,
      },
    };
  }

  static getDefaults() {
    return {
      isActiveStepCompleted: false,
      flexVerticalAlignment: FlexVerticalAlignment.Stretch,
      responsiveBehavior: ResponsiveBehavior.Fill,
      minWidth: FILL_WIDGET_MIN_WIDTH,
      rows: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS + 5,
      columns: 24,
      shouldScrollContents: false,
      widgetName: "Stepper",
      minDynamicHeight: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS + 5,
      stepsObj: {
        step1: {
          label: "Step 1",
          subTitle: "sub title 1",
          id: "step1",
          widgetId: "",
          isVisible: true,
          isOptional: false,
          index: 0,
          positioning: Positioning.Vertical,
        },
        step2: {
          label: "Step 2",
          subTitle: "sub title 2",
          id: "step2",
          widgetId: "",
          isVisible: true,
          isOptional: false,
          index: 1,
          positioning: Positioning.Vertical,
        },
      },
      shouldShowSteps: true,
      defaultStep: "Step 1",
      blueprint: {
        view: [
          {
            type: "CANVAS_WIDGET",
            position: { left: 0, top: 0 },
            props: {
              detachFromLayout: true,
              canExtend: true,
              isVisible: true,
              isDisabled: false,
              shouldScrollContents: false,
              stepId: "step1",
              stepName: "Step 1",
              children: [],
              version: 1,
              bottomRow: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS,
            },
          },
          {
            type: "CANVAS_WIDGET",
            position: { left: 0, top: 0 },
            props: {
              detachFromLayout: true,
              canExtend: true,
              isVisible: true,
              isDisabled: false,
              shouldScrollContents: false,
              stepId: "step2",
              stepName: "Step 2",
              children: [],
              version: 1,
              bottomRow: WidgetHeightLimits.MIN_CANVAS_HEIGHT_IN_ROWS,
            },
          },
        ],
        operations: [
          {
            type: BlueprintOperationTypes.MODIFY_PROPS,
            fn: (widget: WidgetProps & { children?: WidgetProps[] }) => {
              const steps = Object.values({ ...widget.stepsObj });
              const stepIds: Record<string, string> = (
                widget.children || []
              ).reduce((idsObj, eachChild) => {
                idsObj = { ...idsObj, [eachChild.stepId]: eachChild.widgetId };
                return idsObj;
              }, {});
              const stepsObj = steps.reduce((obj: any, step: any) => {
                const newStep = { ...step };
                newStep.widgetId = stepIds[newStep.id];
                obj[newStep.id] = newStep;
                return obj;
              }, {});
              const updatePropertyMap = [
                {
                  widgetId: widget.widgetId,
                  propertyName: "stepsObj",
                  propertyValue: stepsObj,
                },
              ];
              return updatePropertyMap;
            },
          },
        ],
      },
      version: 1,
    };
  }

  static getMethods() {
    return {
      getCanvasHeightOffset: (props: WidgetProps): number => {
        let offset =
          props.borderWidth && props.borderWidth > 1
            ? Math.ceil(
                (2 * parseInt(props.borderWidth, 10) || 0) /
                  GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
              )
            : 0;

        if (props.shouldShowSteps === true) {
          offset += 6;
        }
        return offset;
      },
    };
  }

  static getAutoLayoutConfig() {
    return {
      widgetSize: [
        {
          viewportMinWidth: 0,
          configuration: () => {
            return {
              minWidth: "280px",
              minHeight: "300px",
            };
          },
        },
      ],
      disableResizeHandles: {
        vertical: true,
      },
    };
  }

  static getAnvilConfig(): AnvilConfig | null {
    return {
      isLargeWidget: false,
      widgetSize: {
        maxHeight: {},
        maxWidth: {},
        minHeight: { base: "300px" },
        minWidth: { base: "280px" },
      },
    };
  }

  static getDependencyMap(): Record<string, string[]> {
    return {
      defaultStep: ["stepsObj", "steps"],
    };
  }
  static getPropertyPaneContentConfig() {
    return contentConfig;
  }

  static getPropertyPaneStyleConfig() {
    return styleConfig;
  }

  static getSetterConfig(): SetterConfig {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
        setSelectedStepIndex: {
          path: "selectedStepIndex",
          type: "number",
        },
        setIsSelectedStepCompleted: {
          path: "isSelectedStepCompleted",
          type: "boolean",
        },
      },
    };
  }

  callDynamicHeightUpdates = () => {
    const { checkContainersForAutoHeight } = this.context;
    checkContainersForAutoHeight && checkContainersForAutoHeight();
  };

  callPositionUpdates = (stepWidgetId: string) => {
    const { updatePositionsOnTabChange } = this.context;
    updatePositionsOnTabChange &&
      updatePositionsOnTabChange(this.props.widgetId, stepWidgetId);
  };

  onStepChange = (stepWidgetId: string) => {
    this.props.updateWidgetMetaProperty("selectedStepWidgetId", stepWidgetId, {
      triggerPropertyName: "onStepSelected",
      dynamicString: this.props.onStepSelected,
      event: {
        type: EventType.ON_STEP_CHANGE,
      },
    });
    setTimeout(this.callDynamicHeightUpdates, 0);
    setTimeout(() => this.callPositionUpdates(stepWidgetId), 0);
  };

  static getStylesheetConfig(): Stylesheet {
    return {
      accentColor: "{{appsmith.theme.colors.primaryColor}}",
      borderRadius: "{{appsmith.theme.borderRadius.appBorderRadius}}",
      boxShadow: "{{appsmith.theme.boxShadow.appBoxShadow}}",
    };
  }

  static getDerivedPropertiesMap() {
    return {
      selectedStep: `{{(()=>{${derivedProperties.getSelectedStep}})()}}`,
      selectedStepIndex: `{{(()=>{${derivedProperties.getSelectedStepIndex}})()}}`,
    };
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return {
      isVisible: DefaultAutocompleteDefinitions.isVisible,
      selectedStep: "string",
      selectedStepIndex: "number",
    };
  }
  static getMetaPropertiesMap(): Record<string, any> {
    return {
      selectedStepWidgetId: undefined,
    };
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  getWidgetView() {
    const { componentWidth, isSelectedStepCompleted } = this.props;

    const stepsComponentProps = {
      ...this.props,
      steps: this.getVisibleSteps().map((step) => ({
        ...step,
        title: step.label,
      })),
      width: componentWidth - WIDGET_PADDING * 2,
    };

    const isAutoHeightEnabled: boolean =
      isAutoHeightEnabledForWidget(this.props) &&
      !isAutoHeightEnabledForWidgetWithLimits(this.props);

    return (
      <Stepper
        {...stepsComponentProps}
        $noScroll={isAutoHeightEnabled}
        isSelectedStepCompleted={isSelectedStepCompleted}
        onStepChange={this.onStepChange}
        selectedStepIndex={this.props.selectedStepIndex}
        selectedStepWidgetId={this.getSelectedStepWidgetId()}
        shouldScrollContents={
          this.props.shouldScrollContents &&
          this.props.layoutSystemType === LayoutSystemTypes.FIXED
        }
        shouldShowSteps={this.props.shouldShowSteps}
      >
        {this.renderComponent()}
      </Stepper>
    );
  }

  renderComponent = () => {
    const selectedStepWidgetId = this.getSelectedStepWidgetId();
    const childWidgetData = {
      ...this.props.children?.filter(Boolean).filter((item) => {
        return selectedStepWidgetId === item.widgetId;
      })[0],
    };
    if (!childWidgetData) {
      return null;
    }

    childWidgetData.canExtend = this.props.shouldScrollContents;
    const { componentHeight, componentWidth } = this.props;
    childWidgetData.rightColumn = componentWidth;
    childWidgetData.isVisible = this.props.isVisible;
    childWidgetData.bottomRow = this.props.shouldScrollContents
      ? childWidgetData.bottomRow
      : componentHeight - 1;
    childWidgetData.parentId = this.props.widgetId;
    childWidgetData.minHeight = componentHeight;
    const selectedStepProps = Object.values(this.props.stepsObj)?.filter(
      (item) => item.widgetId === selectedStepWidgetId,
    )[0];
    const positioning: Positioning =
      this.props.layoutSystemType == LayoutSystemTypes.AUTO
        ? Positioning.Vertical
        : Positioning.Fixed;
    childWidgetData.positioning = positioning;
    childWidgetData.useAutoLayout = positioning !== Positioning.Fixed;
    childWidgetData.direction =
      positioning === Positioning.Vertical
        ? LayoutDirection.Vertical
        : LayoutDirection.Horizontal;
    childWidgetData.alignment = selectedStepProps?.alignment;
    childWidgetData.spacing = selectedStepProps?.spacing;
    return renderAppsmithCanvas(childWidgetData as WidgetProps);
  };

  private getSelectedStepWidgetId() {
    let selectedStepWidgetId = this.props.selectedStepWidgetId;
    if (this.props.children) {
      selectedStepWidgetId =
        this.props.children.find((step) =>
          this.props.selectedWidgetAncestry?.includes(step.widgetId),
        )?.widgetId ?? this.props.selectedStepWidgetId;
    }
    return selectedStepWidgetId;
  }

  componentDidUpdate(
    prevProps: StepperWidgetProps<StepperContainerWidgetProps>,
  ) {
    const visibleSteps = this.getVisibleSteps();
    const selectedStep = find(visibleSteps, {
      widgetId: this.props.selectedStepWidgetId,
    });

    if (this.props.defaultStep !== prevProps.defaultStep || !selectedStep) {
      this.setDefaultSelectedStepWidgetId();
    }

    if (
      this.props.selectedStepIndex !== prevProps.selectedStepIndex &&
      equals(this.props.stepsObj, prevProps.stepsObj)
    ) {
      const selectedStepWidgetId = this.getVisibleSteps().find(
        (_, index) => index === this.props.selectedStepIndex,
      )?.widgetId;

      selectedStepWidgetId && this.onStepChange(selectedStepWidgetId);
    }
  }

  getVisibleSteps = () => {
    const steps = Object.values(this.props.stepsObj || {});
    if (steps.length) {
      return steps
        .filter(
          (step) => step.isVisible === undefined || !!step.isVisible === true,
        )
        .sort((step1, step2) => step1.index - step2.index);
    }
    return [];
  };

  setDefaultSelectedStepWidgetId = () => {
    const visibleSteps = this.getVisibleSteps();
    // Find the default Step object
    const defaultStep = find(visibleSteps, {
      label: this.props.defaultStep,
    });
    // Find the default Step id
    const defaultStepWidgetId =
      defaultStep?.widgetId ?? visibleSteps?.[0]?.widgetId; // in case the default step is deleted

    // If we have a legitimate default step Id and it is not already the selected Step
    if (
      defaultStepWidgetId &&
      defaultStepWidgetId !== this.props.selectedStepWidgetId
    ) {
      // Select the default step
      this.props.updateWidgetMetaProperty(
        "selectedStepWidgetId",
        defaultStepWidgetId,
      );
      setTimeout(this.callDynamicHeightUpdates, 0);
    }
  };

  componentDidMount() {
    Object.keys(this.props.stepsObj || {}).length &&
      this.setDefaultSelectedStepWidgetId();
  }
}

export default StepperWidget;
