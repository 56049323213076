import * as go from "gojs";

import { groupTemplate } from "./groupTemplate";
import { handleNodeSelection } from "./nodeSelection";
import { nodeTemplate } from "./nodeTemplate";
import { linkTemplate } from "./linkTemplate";

interface MakeInitGraphConfig {
  gojsLicenseKey: string;
  eventHandlers: EventHandlers;
  highlightMode: string | undefined;
  layerSpacing: number | undefined;
  nodeSpacing: number | undefined;
  groupSpacing: number | undefined;
}

export interface EventHandlers {
  onNodeIdsSelectionChange: (ids: string[]) => void;
}

class CustomTreeLayout extends go.TreeLayout {
  // Override makeNetwork to create bidirectional edges
  makeNetwork(
    coll: go.Diagram | go.Group | go.Iterable<go.Part>,
  ): go.TreeNetwork {
    const net = super.makeNetwork(coll);
    const bidirectionalEdges: go.TreeEdge[] = [];

    // Collect bidirectional edges
    net.edges.each((edge) => {
      if (edge.link && edge.link.data.bidirect) {
        bidirectionalEdges.push(edge as go.TreeEdge);
      }
    });

    // Process bidirectional edges
    bidirectionalEdges.forEach((edge) => {
      const fromNode = edge.fromVertex?.node;
      const toNode = edge.toVertex?.node;
      if (fromNode && toNode) {
        const reverseEdge = net.createEdge();
        reverseEdge.fromVertex = edge.toVertex;
        reverseEdge.toVertex = edge.fromVertex;
        reverseEdge.link = edge.link; // Link the reverse edge to the original link
        net.addEdge(reverseEdge);
      }
    });

    return net;
  }
}

export const makeInitGraph = ({
  eventHandlers: { onNodeIdsSelectionChange },
  gojsLicenseKey,
  groupSpacing,
  highlightMode,
  layerSpacing,
  nodeSpacing,
}: MakeInitGraphConfig) => {
  const $ = go.GraphObject.make;

  go.Diagram.licenseKey = gojsLicenseKey || "";

  const diagram = $(go.Diagram, {
    initialAutoScale: go.Diagram.UniformToFill,
    "undoManager.isEnabled": true, // must be set to allow for model change listening
    model: $(go.GraphLinksModel, {
      nodeKeyProperty: "id",
      linkKeyProperty: "id",
      linkFromPortIdProperty: "fromPortId",
      linkToPortIdProperty: "toPortId",
    }),
    layout: $(CustomTreeLayout, {
      angle: 90, // Top-to-bottom sorting
      layerSpacing: layerSpacing || 150, // Input or default layer spacing
      nodeSpacing: nodeSpacing || 100, // Input or default node spacing
    }),
    "draggingTool.isEnabled": true, // Enable dragging
    "resizingTool.isEnabled": false, // Disable resizing
    "rotatingTool.isEnabled": false, // Disable rotating
    "linkingTool.isEnabled": false, // Disable linking
    "linkReshapingTool.isEnabled": true, // Enable link reshaping
    "relinkingTool.isEnabled": false, // Disable relinking
    ChangedSelection: function () {
      // Highlight selected nodes
      handleNodeSelection(diagram);

      const selectedParts = diagram.selection.toArray();
      const selectedNodeIds: string[] = [];

      selectedParts.forEach((part) => {
        if (!part.data?.isGroup && !part.data?.to) {
          selectedNodeIds.push(String(part.data.id));
        }
      });

      // Notify parent component of selected node IDs
      onNodeIdsSelectionChange(selectedNodeIds);
    },
  });

  diagram.toolManager.contextMenuTool.defaultTouchContextMenu = null;

  diagram.model.modelData.highlightMode = highlightMode;
  const { regularNode } = nodeTemplate();
  diagram.nodeTemplate = regularNode;
  diagram.linkTemplate = linkTemplate();
  diagram.groupTemplate = groupTemplate(groupSpacing);

  diagram.addDiagramListener("InitialLayoutCompleted", function () {
    diagram.layout.isOngoing = false; // Disable ongoing layout to let user drag nodes
  });
  return diagram;
};
