import type { ValidationConfig } from "constants/PropertyControlConstants";
import { ValidationTypes } from "constants/WidgetValidation";

export const IDS_ARRAY_VALIDATION: ValidationConfig = {
  type: ValidationTypes.ARRAY,
  params: {
    children: {
      type: ValidationTypes.TEXT,
    },
  },
};

export const MARKERS_VALIDATION: ValidationConfig = {
  type: ValidationTypes.ARRAY,
  params: {
    children: {
      type: ValidationTypes.OBJECT,
      params: {
        allowedKeys: [
          {
            name: "id",
            type: ValidationTypes.TEXT,
            params: {
              unique: true,
              required: true,
            },
          },
          {
            name: "label",
            type: ValidationTypes.TEXT,
            params: {
              required: true,
            },
          },
          {
            name: "shape",
            type: ValidationTypes.TEXT,
            params: {
              regex: /^(sphere|cube|triangle|diamond|cross)$/,
              required: true,
            },
          },
          {
            name: "position",
            type: ValidationTypes.OBJECT,
            params: {
              required: true,
              allowedKeys: [
                {
                  name: "x",
                  type: ValidationTypes.NUMBER,
                  params: {
                    required: true,
                  },
                },
                {
                  name: "y",
                  type: ValidationTypes.NUMBER,
                  params: {
                    required: true,
                  },
                },
                {
                  name: "z",
                  type: ValidationTypes.NUMBER,
                  params: {
                    required: true,
                  },
                },
              ],
            },
          },
          {
            name: "digit",
            type: ValidationTypes.NUMBER,
          },
        ],
      },
    },
  },
};

export const UUID_REGEX =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89ABab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

export const PILLS_VALIDATION: ValidationConfig = {
  type: ValidationTypes.ARRAY,
  params: {
    children: {
      type: ValidationTypes.OBJECT,
      params: {
        allowedKeys: [
          {
            name: "id",
            type: ValidationTypes.TEXT,
            params: {
              unique: true,
              required: true,
            },
          },
          {
            name: "label",
            type: ValidationTypes.TEXT,
            params: {
              required: true,
            },
          },
          {
            name: "position",
            type: ValidationTypes.OBJECT,
            params: {
              required: true,
              allowedKeys: [
                {
                  name: "x",
                  type: ValidationTypes.NUMBER,
                  params: {
                    required: true,
                  },
                },
                {
                  name: "y",
                  type: ValidationTypes.NUMBER,
                  params: {
                    required: true,
                  },
                },
                {
                  name: "z",
                  type: ValidationTypes.NUMBER,
                  params: {
                    required: true,
                  },
                },
              ],
            },
          },
        ],
      },
    },
  },
};

export const GROUPS_COLLECTIONS_VALIDATION_CONFIG = {
  type: ValidationTypes.ARRAY,
  params: {
    children: {
      type: ValidationTypes.OBJECT,
      params: {
        allowedKeys: [
          {
            name: "collectionId",
            type: ValidationTypes.TEXT,
            params: {
              unique: true,
              required: true,
              regex: UUID_REGEX,
            },
          },
          {
            name: "groups",
            type: ValidationTypes.ARRAY,
            params: {
              children: {
                type: ValidationTypes.OBJECT,
                params: {
                  allowedKeys: [
                    {
                      name: "id",
                      type: ValidationTypes.TEXT,
                      params: {
                        unique: true,
                        required: true,
                        regex: UUID_REGEX,
                      },
                    },
                    {
                      name: "nodeIds",
                      type: ValidationTypes.ARRAY,
                      params: {
                        children: {
                          type: ValidationTypes.TEXT,
                          params: {
                            regex: UUID_REGEX,
                            required: true,
                          },
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    },
  },
};
