import styled from "styled-components";

// TODO: find out better prop logic/name
export const IconButton = styled.button<{ red?: boolean; size?: number }>`
  /* Reset default button styles */
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  cursor: pointer;

  // Layout
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => {
    const size = props.size || 24;
    return `width: ${size}px; height: ${size}px;`;
  }}

  // Style
  border-radius: 6px;

  // Hover
  &:hover {
    background-color: ${(props) =>
      props.red ? "rgba(239, 68, 68, 0.10)" : "#ebebeb"};
  }
`;
