import React from "react";
import LineIcon from "./LineIcon";
import SquareIcon from "./SquareIcon";
import DashedLineIcon from "./DashedLineIcon";
import LineCircleIcon from "./LineCircleIcon";
import SVGComponent from "./SVGComponent";

export const legendEntryIconMap: Record<
  LegendIcon,
  React.FC<{ strokeColor: string }>
> = {
  line: LineIcon,
  square: SquareIcon,
  "dashed-line": DashedLineIcon,
  "circle-line": LineCircleIcon,
};

interface IconComponentProps {
  isSelected: boolean;
  strokeColor: string;
  icon?: string;
}

export type LegendIcon = "line" | "square" | "dashed-line" | "circle-line";
export const IconComponent: React.FC<IconComponentProps> = ({
  icon,
  isSelected,
  strokeColor,
}) => {
  const iconColour = isSelected ? strokeColor : "#CCCCCC";

  if (icon && icon in legendEntryIconMap) {
    const Icon = legendEntryIconMap[icon as LegendIcon];
    return <Icon strokeColor={iconColour} />;
  }

  if (icon) {
    return (
      <SVGComponent
        base64String={icon}
        color={iconColour}
        defaultIcon={<SquareIcon strokeColor={iconColour} />}
      />
    );
  }

  return <SquareIcon strokeColor={iconColour} />;
};
