import * as go from "gojs";

export const groupTemplate = (spacing: number | undefined) => {
  const $ = go.GraphObject.make;

  return $(
    go.Group,
    "Spot",
    {
      isSubGraphExpanded: true,
      layoutConditions: go.Part.LayoutStandard, // Update layout only when necessary
      computesBoundsIncludingLinks: false,
      selectionObjectName: "BODY",
      ungroupable: true,
      selectable: false, // Make groups unselectable
      zOrder: 1,
    },
    new go.Binding("layout", "orientation", (orientation) => {
      return $(go.GridLayout, {
        wrappingColumn: orientation === "horizontal" ? Infinity : 1,
        wrappingWidth: orientation === "horizontal" ? Infinity : 1,
        alignment: go.GridLayout.Position,
        cellSize: new go.Size(1, 1),
        spacing: new go.Size(spacing || 20, spacing || 20), // Adjust spacing as needed
      });
    }),
    // Invisible wrapper without background
    $(
      go.Panel,
      "Vertical",
      { background: null },
      // Text block positioned outside the group container
      $(
        go.TextBlock,
        {
          font: "bold 12pt Sans-Serif",
          stroke: "#000000", // Default text color
          alignment: go.Spot.TopLeft,
          alignmentFocus: go.Spot.BottomLeft,
        },
        new go.Binding("text", "text"),
        new go.Binding("stroke", "textColor"),
      ),
      // Group container with background defined in data
      $(
        go.Panel,
        "Auto",
        new go.Binding("background", "color"),
        $(go.Placeholder, { padding: spacing || 20 }), // Padding for nodes
      ),
    ),
  );
};
