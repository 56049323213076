import type { ElectricPartComponent } from "./types";
import type { Terminal } from "../SignalPath";
import { getComponentTitle } from "../SignalPath";

export const getPartTitle = (
  part: ElectricPartComponent | Terminal,
): string => {
  return getComponentTitle({
    referenceId: part.componentReferenceId,
    diagnosisId: part.diagnosisId,
  });
};
