import React from "react";
import { ZoomInIcon } from "./ZoomInIcon";
import { ZoomOutIcon } from "./ZoomOutIcon";

interface ToolbarProps {
  onZoomChange: (scale: number) => void;
}
export const Toolbar: React.FC<ToolbarProps> = ({ onZoomChange }) => {
  return (
    <div
      style={{
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.1)",
        position: "absolute",
        width: "32px",
        borderRadius: "6px",
        bottom: "8px",
        right: "8px",
        zIndex: "100",
        cursor: "pointer",
        backgroundColor: "white",
      }}
    >
      <div
        onClick={() => onZoomChange(0.1)}
        style={{
          width: "100%",
          height: "35px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "12px", height: "12px", color: "#485162" }}>
          <ZoomInIcon />
        </div>
      </div>
      <div
        onClick={() => onZoomChange(-0.1)}
        style={{
          width: "100%",
          height: "35px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "12px", height: "12px", color: "#485162" }}>
          <ZoomOutIcon />
        </div>
      </div>
    </div>
  );
};

Toolbar.displayName = "Toolbar";
