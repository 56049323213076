export const base64SVG =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxOCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wLjMzMzAwOCA4TDQuNjY2MzQgMTUuNUgxMy4zMzNMMTcuNjY2MyA4TDEzLjMzMyAwLjVINC42NjYzNEwwLjMzMzAwOCA4Wk04Ljk5OTY4IDExLjc1QzExLjA3MDcgMTEuNzUgMTIuNzQ5NyAxMC4wNzExIDEyLjc0OTcgOEMxMi43NDk3IDUuOTI4OTMgMTEuMDcwNyA0LjI1IDguOTk5NjggNC4yNUM2LjkyODYxIDQuMjUgNS4yNDk2OCA1LjkyODkzIDUuMjQ5NjggOEM1LjI0OTY4IDEwLjA3MTEgNi45Mjg2MSAxMS43NSA4Ljk5OTY4IDExLjc1WiIgZmlsbD0iIzQ4NTE2MiIvPgo8L3N2Zz4K";
export const defaultProps = {
  entries: [
    {
      id: "1",
      label: "Dashed line icon",
      icon: "dashed-line",
      color: "pink",
    },
    { id: "2", label: "Default icon (square)" },
    { id: "3", label: "Square icon", icon: "square", color: "#FDBA74" },
    {
      id: "4",
      label: "Line icon",
      icon: "line",
      color: "#BEF264",
    },
    {
      id: "5",
      label: "Circle line icon",
      icon: "circle-line",
      color: "#BEF264",
    },

    {
      id: "6",
      color: "red",
      label: "Custom icon - base64 SVG",
      icon: base64SVG,
    },
  ],
  orientation: "auto",
  backgroundColor: "white",
  defaultDisabledIds: ["1", "2"],
};
