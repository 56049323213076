import type { ClusterCards, ClusterData, Link } from "./types";
import { defaultLocalizedStrings } from "./widget/localizedStrings";

const clusters: ClusterData[] = [
  {
    id: "swc",
    title: "SWC",
    color: "#F0FDFA",
    borderColor: "#5EEAD4",
    column: 0,
  },
  {
    id: "swcl",
    title: "SWCL",
    color: "#EFF6FF",
    borderColor: "#93C5FD",
    column: 1,
  },
  {
    id: "ecu_hcp_hw_sw",
    title: "ECU HCP HW+SW",
    color: "#FFF7ED",
    borderColor: "#FDBA74",
    column: 2,
  },
  {
    id: "ecu_embedded",
    title: "ECU Embedded",
    color: "#FFF7ED",
    borderColor: "#FDBA74",
    column: 2,
  },
  {
    id: "ecu_hcp_hw",
    title: "ECU HCP HW",
    color: "#FFF7ED",
    borderColor: "#FDBA74",
    column: 3,
  },
  {
    id: "function",
    title: "Function",
    color: "#FAF5FF",
    borderColor: "#D8B4FE",
    column: 4,
  },
];

const clustersCards: ClusterCards = {
  swc: [
    {
      id: "swc133",
      title: "SWC133",
      subtitle: "Charging Management Server",
      isAlert: true,
      items: [
        {
          id: "swc133_pin1",
          title: "HCP5 ZSB Elemental Audio",
          isAlert: true,
          alertMessage: "This is an alert message",
        },
        {
          id: "swc133_pin2",
          title: "HCP5 ZSB Advanced Audio",
        },
        {
          id: "swc133_pin3",
          title: "HCP5 ZSB Elemental PAG",
          isAlert: true,
          alertMessage: "This is an alert message",
        },
        {
          id: "swc133_pin4",
          title: "HCP5 ZSB Advanced PAG",
        },
      ],
    },
    {
      id: "swc557",
      title: "SWC557",
      subtitle: "ActuatorInterfaceCombustionEng",
      items: [
        {
          id: "swc557_pin1",
          title: "HCP1",
        },
      ],
    },
  ],
  swcl: [
    {
      id: "swcl1",
      title: "SWCL1",
      subtitle: "HCP4 SWCL 1",
      items: [
        {
          id: "swcl1_pin1",
          title: "HCP4 BODYCOMPUTER 6",
        },
        {
          id: "swcl1_pin2",
          title: "HCP4 BODYCOMPUTER 7",
        },
        {
          id: "swcl1_pin3",
          title: "HCP4 BODYCOMPUTER 8",
        },
      ],
    },
    {
      id: "swcl3",
      title: "SWCL3",
      subtitle: "HCP4 SWCL 2",
      items: [
        {
          id: "swcl3_pin1",
          title: "HCP4 BODYCOMPUTER 6",
        },
        {
          id: "swcl3_pin2",
          title: "HCP4 BODYCOMPUTER 7",
        },
        {
          id: "swcl3_pin3",
          title: "HCP4 BODYCOMPUTER 8",
        },
      ],
    },
  ],
  ecu_hcp_hw_sw: [
    {
      id: "sg113",
      title: "SG113",
      subtitle: "HCP3 Hoststeuergerät Base HCP3",
      items: [
        {
          id: "sg113_pin1",
          title: "HCP3 Hoststeuergerät Base HCP3_Prem_KOR_AU",
        },
      ],
    },
    {
      id: "sg305",
      title: "SG305",
      subtitle: "HCP3 Hoststeuergerät Base HCP3",
      items: [
        {
          id: "sg305_pin1",
          title: "HCP3 Hoststeuergerät Base HCP3_Prem_KOR_AU",
        },
      ],
    },
    {
      id: "sg306",
      title: "SG306",
      subtitle: "HCP3 Hoststeuergerät Base HCP3",
      items: [
        {
          id: "sg306_pin1",
          title: "HCP3 Hoststeuergerät Base HCP3_Prem_KOR_AU",
        },
      ],
    },
  ],
  ecu_hcp_hw: [
    {
      id: "sg104",
      title: "SG104",
      subtitle: "Diagnoseinterface für Datenbus",
      items: [
        {
          id: "sg104_pin1",
          title: "HCP5 ZSB Advanced Audio",
        },
        {
          id: "sg104_pin2",
          title: "HCP5 ZSB Advanced PAG",
        },
      ],
    },
    {
      id: "sg105",
      title: "SG105",
      subtitle: "Diagnoseinterface für Datenbus",
      items: [
        {
          id: "sg105_pin1",
          title: "HCP5 ZSB Advanced Audio",
        },
        {
          id: "sg105_pin2",
          title: "HCP5 ZSB Advanced PAG",
        },
      ],
    },
  ],
  function: [
    {
      id: "fkt75",
      title: "FKT75",
      subtitle: "Klemensteuerung (KLS)",
      items: [
        {
          id: "fkt75_pin1",
          title: "Default",
        },
      ],
    },
    {
      id: "fkt76",
      title: "FKT76",
      subtitle: "Klemensteuerung (KLS)",
      items: [
        {
          id: "fkt76_pin1",
          title: "Default",
        },
      ],
    },
    {
      id: "fkt188",
      title: "FKT188",
      subtitle: "Driver Warning Manager (DWM)",
      items: [
        {
          id: "fkt188_pin1",
          title: "Default",
        },
      ],
    },
  ],
};

const links: Link[] = [
  {
    from: "swcl3_pin1",
    to: "sg113_pin1",
  },
  {
    from: "fkt188_pin1",
    to: "swcl1_pin1",
    dashed: true,
  },
  {
    from: "fkt188_pin1",
    to: "swcl1_pin2",
    dashed: true,
  },
  {
    from: "fkt188_pin1",
    to: "swcl1_pin3",
    dashed: true,
  },
];

export const defaultValues = {
  clusters,
  clustersCards,
  links,
  localizedStrings: defaultLocalizedStrings,
};
