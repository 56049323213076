import type { Stylesheet } from "entities/AppTheming";

import { EditorTheme } from "../utils/theme";

export interface StyleProps {
  theme?: EditorTheme;
}

export const defaultStylesheet: StyleProps = {
  theme: EditorTheme.Dark,
};

// We need to cast the defaultStylesheet to any because the type of the defaultStylesheet
// is not compatible with the type of the stylesheetConfig because of the `number` types.
export const stylesheetConfig = defaultStylesheet as any as Stylesheet;

export const propertyPaneStyleConfig = [
  {
    sectionName: "General",
    children: [
      {
        helpText: "Theme of the editor",
        propertyName: "theme",
        label: "Theme",
        controlType: "LABEL_ALIGNMENT_OPTIONS",
        options: [
          { label: "Dark", value: EditorTheme.Dark },
          { label: "Light", value: EditorTheme.Light },
        ],
        isBindProperty: true,
        isTriggerProperty: false,
        isJSConvertible: false,
      },
    ],
  },
];
