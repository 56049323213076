export const decodeBase64 = (base64: string): string => {
  return atob(base64);
};

export const stripDataUriPrefix = (dataUri: string): string => {
  const matches = dataUri.match(/^data:image\/svg\+xml;base64,(.*)$/);
  if (!matches) {
    throw new Error("Invalid SVG Data URI");
  }
  return matches[1];
};

export const parseSvg = (svgString: string): SVGElement => {
  const parser = new DOMParser();
  const svgDocument = parser.parseFromString(svgString, "image/svg+xml");
  const svgElement = svgDocument.documentElement;

  if (!(svgElement instanceof SVGElement)) {
    throw new Error("Parsed element is not an SVGElement");
  }

  return svgElement as SVGElement;
};

export const serializeSvg = (svgElement: SVGElement): string => {
  const serializer = new XMLSerializer();
  return serializer.serializeToString(svgElement);
};

export const resizeSvg = (
  svgElement: SVGElement,
  width: number,
  height: number,
): SVGElement => {
  svgElement.setAttribute("width", width.toString());
  svgElement.setAttribute("height", height.toString());
  return svgElement;
};

export const updateSvgColors = (
  svgElement: SVGElement,
  color: string,
): SVGElement => {
  const hasFill = svgElement.querySelector("[fill]") !== null;
  const hasStroke = svgElement.querySelector("[stroke]") !== null;

  if (!hasFill && !svgElement.hasAttribute("fill")) {
    svgElement.setAttribute("fill", color);
  }

  if (!hasStroke && !svgElement.hasAttribute("stroke")) {
    svgElement.setAttribute("stroke", color);
  }

  // Update all elements with a fill attribute
  svgElement.querySelectorAll("[fill]").forEach((el) => {
    if (el instanceof SVGElement) {
      el.setAttribute("fill", color);
    }
  });

  // Update all elements with a stroke attribute
  svgElement.querySelectorAll("[stroke]").forEach((el) => {
    if (el instanceof SVGElement) {
      el.setAttribute("stroke", color);
    }
  });

  return svgElement;
};
