import React from "react";

import type { ControlData, ControlProps } from "../../BaseControl";
import BaseControl from "../../BaseControl";

import { CadDomainModelSelector as Selector } from "./CadDomainModelSelector";
import { DEFAULT_MODEL_OPTIONS } from "./constants";

class CadDomainModelSelector extends BaseControl<CadDomainModelSelectorProps> {
  constructor(props: CadDomainModelSelectorProps) {
    super(props);
  }

  static getControlType() {
    return "CAD_DOMAIN_MODEL_SELECTOR";
  }

  /**
   * Switching from JS mode to UI mode possible only if the value is one of the default values
   */
  static canDisplayValueInUI(config: ControlData, value: any): boolean {
    return DEFAULT_MODEL_OPTIONS.some((option) => option.value === value);
  }

  static shouldValidateValueOnDynamicPropertyOff() {
    return false;
  }

  public onUpdatePropertyValue = (
    value = "",
    makeDynamicPropertyPath?: boolean,
  ) => {
    this.props.onPropertyChange?.(
      this.props.propertyName,
      value,
      false,
      makeDynamicPropertyPath,
    );
  };

  public render() {
    const onSelect = (value: string) => this.onUpdatePropertyValue(value);

    return <Selector onSelect={onSelect} value={this.props.propertyValue} />;
  }
}

export default CadDomainModelSelector;

export type CadDomainModelSelectorProps = ControlProps;
