import * as go from "gojs";
import { edgeVariantStyleMap } from "./helpers";
import type { Edge, EdgeVariant } from "../widget/types";
import type { Events, GoClickHandler } from "./types";
import {
  DEFAULT_EDGE_Z_ORDER,
  SELECTED_EDGE_Z_ORDER,
  WHITE_COLOR,
} from "./constants";
import { MultiColorLink } from "./MultiColorLink";

// eslint-disable-next-line @typescript-eslint/unbound-method
const $ = go.GraphObject.make;

export const makeMultiColorLinkBorderShape = () =>
  $(
    go.Shape,
    {
      name: "MultiColorLinkBorder",
      isPanelMain: true,
    },
    new go.Binding(
      "stroke",
      "variant",
      (state: EdgeVariant) => edgeVariantStyleMap[state].stroke,
    ),
    new go.Binding(
      "strokeWidth",
      "variant",
      (state: EdgeVariant) => edgeVariantStyleMap[state].strokeWidth,
    ),
  );

export const makeMultiColorLinkDashShape = () =>
  $(
    go.Shape,
    {
      name: "MultiColorLinkDash",
      isPanelMain: true,
    },
    new go.Binding("stroke", "variant", (state: EdgeVariant) =>
      state.endsWith("dashed")
        ? WHITE_COLOR
        : edgeVariantStyleMap[state].stroke,
    ),
    new go.Binding(
      "strokeWidth",
      "variant",
      (state: EdgeVariant) => edgeVariantStyleMap[state].strokeWidth,
    ),
  );

export const linkTemplate = (events: Events) => {
  const $ = go.GraphObject.make;

  const onContextMenu: GoClickHandler = (event, object) => {
    const edge = object.part?.data as Edge;

    const clientX = event.viewPoint.x;
    const clientY = event.viewPoint.y;

    events.onContextMenu({
      targetPartType: "edge",
      anchorPosition: { left: clientX, top: clientY },
      documentPoint: event?.documentPoint,
      targetId: edge?.id,
    });
  };

  return $(
    MultiColorLink, // the whole link panel
    {
      selectionAdorned: false,
      toShortLength: 5,
      zOrder: 9,
      routing: go.Link.AvoidsNodes,
      curve: go.Link.JumpOver,
      corner: 10,
      contextClick: onContextMenu,
    },
    new go.Binding("visible", "visible"),
    new go.Binding("zOrder", "isSelected", (isSelected: boolean) =>
      isSelected ? SELECTED_EDGE_Z_ORDER : DEFAULT_EDGE_Z_ORDER,
    ),
    makeMultiColorLinkBorderShape(),
    makeMultiColorLinkDashShape(),
    $(
      go.Shape, // the arrowhead
      { toArrow: "Standard" },
      new go.Binding(
        "stroke",
        "variant",
        (state: EdgeVariant) => edgeVariantStyleMap[state].stroke,
      ),
      new go.Binding(
        "fill",
        "variant",
        (state: EdgeVariant) => edgeVariantStyleMap[state].stroke,
      ),
    ),
  );
};
