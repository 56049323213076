import React from "react";
import type { ComponentProps } from "widgets/BaseComponent";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import Button, { ButtonType } from "widgets/ButtonWidget/component";
import { Colors } from "constants/Colors";
import { ButtonVariantTypes } from "components/constants";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

function FilePickerComponent(props: FilePickerComponentProps) {
  return (
    <Button
      borderRadius={props.borderRadius}
      boxShadow={props.boxShadow}
      buttonColor={props.buttonColor}
      buttonVariant={ButtonVariantTypes.TERTIARY}
      clickWithRecaptcha={noop}
      icon="upload"
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      onClick={props.openModal}
      shouldFitContent={props.shouldFitContent}
      text={props.label}
      tooltip={props.tooltip}
      type={ButtonType.BUTTON}
      widgetId={props.widgetId}
    />
  );
}
export interface FilePickerComponentProps extends ComponentProps {
  label: string;
  tooltip: string;
  uppy: any;
  isLoading: boolean;
  buttonColor: string;
  borderRadius: string;
  boxShadow?: string;
  shouldFitContent: boolean;
  openModal: () => void;
}

FilePickerComponent.defaultProps = {
  backgroundColor: Colors.GREEN,
};

export default FilePickerComponent;
