import * as go from "gojs";

// Helper function to ensure a horizontal segment
function ensureHorizontalSegment(
  pts: go.List<go.Point>,
  point1: go.Point,
  point2: go.Point,
  offset: number,
  adjustFirst: boolean,
) {
  const sameXPoints = pts.filter((p) => p.x === point2.x && p !== point2);
  sameXPoints.each((p) => {
    if (adjustFirst) {
      p.x -= offset;
    } else {
      p.x += offset;
    }
    if (p === point1) {
      p.y = point2.y;
    }
  });
}

const MAX_LABEL_LENGTH = 30;

// Define the CustomLink class to connect nodes with a horizontal segment
class CustomLink extends go.Link {
  constructor() {
    super();
  }

  // Override the computePoints method to ensure the final segment is horizontal
  computePoints(): boolean {
    const offset = 20;
    const result = super.computePoints();
    const isToLeft = this.toPortId.includes("left");
    const isToRight = this.toPortId.includes("right");
    const isFromLeft = this.fromPortId.includes("left");
    const isFromRight = this.fromPortId.includes("right");
    // If the link is connected to a port on the left/right side, adjust the points to ensure the final segment is horizontal
    if (result && (isToLeft || isToRight || isFromLeft || isFromRight)) {
      const pts = this.points.copy();
      if (pts.length >= 3) {
        let needChange = false;
        if (isToLeft || isToRight) {
          // Ensure the segment is horizontal
          const lastPt = pts.elt(pts.length - 1);
          const penultimatePt = pts.elt(pts.length - 2);
          if (lastPt.x === penultimatePt.x) {
            ensureHorizontalSegment(
              pts,
              penultimatePt,
              lastPt,
              offset,
              isToLeft,
            );
            needChange = true;
          }
        }

        if (isFromLeft || isFromRight) {
          // Ensure the first segment is horizontal
          const firstPt = pts.elt(0);
          const secondPt = pts.elt(1);
          if (firstPt.x === secondPt.x) {
            ensureHorizontalSegment(pts, secondPt, firstPt, offset, isFromLeft);
            needChange = true;
          }
        }
        if (needChange) {
          this.points = pts;
        }
      }

      // Determine the longest segment
      let longestSegmentIndex = 0;
      let maxSegmentLength = 0;
      for (let i = 1; i < pts.count; i++) {
        const segmentLength =
          Math.abs(pts.elt(i).x - pts.elt(i - 1).x) +
          Math.abs(pts.elt(i).y - pts.elt(i - 1).y);
        if (segmentLength > maxSegmentLength) {
          maxSegmentLength = segmentLength;
          longestSegmentIndex = i - 1;
        }
      }

      // Set the segmentIndex for the label if it exists
      const label = this.findObject("LABEL");
      if (label) {
        label.segmentIndex = longestSegmentIndex;
        label.segmentFraction = 0.9; // Position the label at the end of the longest segment
      }
    }
    return result;
  }
}

const $ = go.GraphObject.make;

export const linkTemplate = () => {
  return $(
    CustomLink, // the whole link panel
    {
      selectionAdorned: false,
      toShortLength: 5,
      zOrder: 5,
      routing: go.Link.AvoidsNodes,
      curve: go.Link.JumpOver,
      // curviness: 20,
      // fromEndSegmentLength: 30,
      toEndSegmentLength: 30,
    },
    new go.Binding("visible", "visible"),
    new go.Binding("fromPortId", "fromPortId"),
    new go.Binding("toPortId", "toPortId"),
    $(
      go.Shape, // the link path shape
      {
        isPanelMain: true,
        stroke: "black", // default color
        strokeWidth: 2,
      },
      new go.Binding("stroke", "color"),
      new go.Binding("strokeWidth", "width"),
    ),
    $(
      go.Shape, // the arrowhead at the to end
      {
        name: "ARROW_TO",
        toArrow: "Standard",
        stroke: null,
        fill: "black",
      },
      new go.Binding("fill", "color"),
    ),
    $(
      go.Shape, // the arrowhead to the from start
      {
        name: "ARROW_FROM",
        fromArrow: "Backward",
        stroke: null,
        fill: "black",
      },
      new go.Binding("fill", "color"),
      new go.Binding("visible", "bidirect"),
    ),
    $(
      go.TextBlock, // the link label
      {
        name: "LABEL",
        segmentOrientation: go.Link.OrientUpright, // orient the label along the link line
        stroke: "black", // default color
        font: "12pt Inter",
      },
      // Custom binding to truncate text if longer than 20 characters
      new go.Binding("text", "label", (text) => {
        if (text.length > MAX_LABEL_LENGTH) {
          return text.substring(0, MAX_LABEL_LENGTH) + "...";
        }
        return text;
      }),
      new go.Binding("segmentOffset", "label", (text) => {
        const lines = text.substring(0, MAX_LABEL_LENGTH).split("\n").length;
        return new go.Point(0, 10 + (lines - 1) * 10); // Adjust the y-offset based on the number of lines
      }),
      new go.Binding("visible", "labelVisible"),
      new go.Binding("stroke", "textColor"),
    ),
    {
      toolTip: $(
        "ToolTip",
        $(
          go.TextBlock,
          { margin: 2 },
          new go.Binding("text", "label"), // Show the full text in the tooltip
        ),
      ),
    },
  );
};
