import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { type LegendItem, LegendItemComponent } from "./LegendItem";

export type LegendOrientationType = "auto" | "horizontal" | "vertical";

export interface LegendComponentProps {
  entries: LegendItem[];
  orientation: LegendOrientationType;
  itemHeight: number;
  onDisabledEntriesChange: (selectedEntries: string[]) => void;
  defaultDisabledIds?: string[];
  backgroundColor: string;
  borderColor: string;
  borderRadius: string;
  borderWidth: string;
  boxShadow: string;
  width: number;
}

const LegendContainer = styled.div<{
  orientation: LegendOrientationType;
  backgroundColor: string;
  borderColor: string;
  borderRadius: string;
  borderWidth: string;
  boxShadow: string;
  width: number;
}>`
  display: flex;
  flex-direction: ${({ orientation }) =>
    orientation === "horizontal" ? "row" : "column"};
  flex-wrap: ${({ orientation }) =>
    orientation === "horizontal" ? "wrap" : "nowrap"};
  justify-content: flex-start;
  align-content: flex-start; /* Align lines to the start */
  padding: 4px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: ${({ boxShadow }) => boxShadow};
  box-sizing: border-box;
  border: ${({ borderColor, borderWidth }) =>
    `${borderWidth}px solid ${borderColor}`};
`;

function areSetsEqual<T>(set1: Set<T>, set2: Set<T>): boolean {
  if (set1.size !== set2.size) {
    return false;
  }
  for (const item of set1) {
    if (!set2.has(item)) {
      return false;
    }
  }
  return true;
}

export const Legend: React.FC<LegendComponentProps> = ({
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  boxShadow,
  defaultDisabledIds = [],
  entries = [],
  itemHeight = 16,
  onDisabledEntriesChange,
  orientation = "horizontal",
  width,
}) => {
  const [disabledIds, setDisabledIds] = useState<string[]>(defaultDisabledIds);
  const prevDefaultDisabledIds = useRef<string[]>(defaultDisabledIds);

  useEffect(() => {
    if (
      !areSetsEqual(
        new Set(prevDefaultDisabledIds.current),
        new Set(defaultDisabledIds),
      )
    ) {
      setDisabledIds(defaultDisabledIds);
      prevDefaultDisabledIds.current = defaultDisabledIds;
    }
  }, [defaultDisabledIds, onDisabledEntriesChange]);

  const handleItemClick = (id: string) => {
    setDisabledIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  useEffect(() => {
    onDisabledEntriesChange(disabledIds);
  }, [disabledIds]);

  return (
    <LegendContainer
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      boxShadow={boxShadow}
      orientation={orientation}
      width={width}
    >
      {entries.map((entry) => (
        <LegendItemComponent
          color={entry.color}
          height={itemHeight}
          icon={entry.icon}
          id={entry.id}
          isSelected={!disabledIds.includes(entry.id)}
          key={entry.id}
          label={entry.label}
          onClick={handleItemClick}
        />
      ))}
    </LegendContainer>
  );
};
