import React from "react";
import styled from "styled-components";

import type { PillData } from "./types";

/**
 * NOTE: this is a temporary solution for the pills
 * once components library is imported (https://github.com/spread-ai/spread-appsmith/pull/21)
 * `PillComponent` should be replaced with the pill component from the library
 */
const PillComponent = styled.div<{
  positionTop?: number;
  positionLeft?: number;
}>`
  position: absolute;
  top: ${(props) => props.positionTop}px;
  left: ${(props) => props.positionLeft}px;

  display: flex;
  align-items: center;

  height: 24px;
  padding: 0px 12px;

  background-color: rgb(255, 255, 255);
  color: rgb(72, 81, 98);

  border: 1px solid rgb(189, 189, 189);
  border-radius: 16px;

  font-family: OpenSans, sans-serif;
  font-size: 9px;
  font-weight: 800;
  text-transform: uppercase;
  white-space: nowrap;
`;

interface PillProps {
  pillData: PillData;
}

export const Pill = ({ pillData }: PillProps) => {
  return (
    <PillComponent
      positionLeft={pillData.position.left}
      positionTop={pillData.position.top}
    >
      {pillData.label}
    </PillComponent>
  );
};
